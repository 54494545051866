// Sets the options and values of the filter UI (selects and multiselects)
export default (context, payload) => {
    const moduleId = context.getters.moduleId;
    const currentFilter = payload?.targetId ? context.getters.currentFilter : '';
    // Do not update other filters (selects and multiselects) if training frequency changed
    if (!payload?.init && currentFilter === 'trainingFrequency') {
        context.commit('setAttribute', {
            id: `${payload.sourceId}_trainingFrequency`,
            attribute: 'options',
            value: context.getters.trainingFrequencyOptions,
        }, {
            root: true,
        });

        return;
    }

    const currentFilterOptions = currentFilter.length
        ? [...context.rootState[payload.targetId].options] : [];
    const filterOptions = {
        type: currentFilter === 'type' ? currentFilterOptions : context.getters.typeFilterOptions,
        topic: currentFilter === 'topic' ? currentFilterOptions : context.getters.topicFilterOptions,
        category: currentFilter === 'category'
            ? currentFilterOptions : context.getters.categoryFilterOptions,
        trainingFrequency: context.getters.trainingFrequencyOptions,
    };
    Object.keys(filterOptions).forEach(id => {
        context.commit('setAttribute', {
            id: `${moduleId}_${id}`,
            attribute: 'options',
            value: filterOptions[id],
        }, {
            root: true,
        });

        context.commit('setAttribute', {
            id: `${moduleId}_${id}`,
            attribute: 'value',
            value: context.state.filterValues[id],
        }, {
            root: true,
        });
    });
};