<!-- eslint-disable vue/no-v-html -->
<template>
    <div class="lni-c-ship-grant-projects-card lni-u-border-radius--base lni-u-mb2">
        <div class="lni-u-pa2">
            <div class="lni-c-ship-grant-projects-card__title lni-u-line-height--tight">
                {{ displayedProjectTitle }}
            </div>
            <div
                class="lni-c-ship-grant-projects-card__grantee lni-u-type--xxs lni-u-line-height--tight lni-u-mt2 lni-u-mb2"
            >
                {{ grantees }}
            </div>
            <div
                v-if="hasFinalReport"
                class="lni-u-type--xxs lni-u-line-height--tight"
            >
                <a
                    :href="finalReport"
                    class="lni-u-text--no-underline"
                    target="_blank"
                >
                    Download project {{ projectId }} final report
                </a>
            </div>
        </div>
        <div class="lni-c-ship-grant-projects-card__details lni-u-flex lni-u-type--xxs lni-u-line-height--tight">
            <div class="lni-u-pv2 lni-c-ship-grant-projects-card__amount">
                <span class="lni-u-text--all-caps lni-u-type--bold">Amount</span>
                <div class="lni-u-mt1">
                    {{ grantAmount }}
                </div>
            </div>
            <div class="lni-u-mvauto lni-u-mhauto lni-u-text--center lni-c-ship-grant-projects-card__status">
                <div v-if="projectCompleted">
                    <lni-status-badge
                        :id="`${projectId}_status-badge--completed`"
                        text="Completed"
                        statusType="info"
                        icon="checkmark"
                        size="md"
                    />
                </div>
                <div v-else>
                    <lni-status-badge
                        :id="`${projectId}_status-badge--active`"
                        text="Active"
                        statusType="ok"
                        size="md"
                    />
                </div>
            </div>
            <div class="lni-u-pv2 lni-c-ship-grant-projects-card__project-id">
                <span class="lni-u-text--all-caps lni-u-type--bold">Project ID</span>
                <div class="lni-u-mt1">
                    {{ projectId }}
                </div>
            </div>
        </div>
        <div class="lni-c-ship-grant-projects-card__summary lni-u-pa1 lni-u-type--xxs lni-u-line-height--tight">
            <button
                class="lni-u-link-style lni-u-type--bold lni-u-text--all-caps lni-u-type--xxs"
                :aria-expanded="`${summaryIsExpanded}`"
                :aria-controls="`${projectId}_summary`"
                role="button"
                @click.prevent="toggleSummary()"
            >
                Summary
                <span
                    :class="`${summaryIsExpanded ? 'lnicon--triangle--up' : 'lnicon--triangle--down' }`"
                />
            </button>
            <div
                :id="`${projectId}_summary`"
                v-slide="summaryIsExpanded"
                :aria-hidden="`${!summaryIsExpanded}`"
            >
                <div class="lni-u-pa1">
                    <lni-html-element
                        :id="`${projectId}_project-summary`"
                        element="span"
                        :htmlString="projectSummary"
                    />
                </div>
            </div>
        </div>
        <div
            v-if="hasProducts"
            class="lni-c-ship-grant-projects-card__products lni-u-pa1 lni-u-type--xxs lni-u-line-height--tight"
        >
            <button
                class="lni-u-link-style lni-u-type--bold lni-u-text--all-caps lni-u-type--xxs"
                :aria-expanded="`${productsIsExpanded}`"
                :aria-controls="`${projectId}_products`"
                role="button"
                @click.prevent="toggleProducts()"
            >
                Products
                <span
                    :class="`${productsIsExpanded ? 'lnicon--triangle--up' : 'lnicon--triangle--down'}`"
                />
            </button>
            <div
                :id="`${projectId}_products`"
                v-slide="productsIsExpanded"
                :aria-hidden="`${!productsIsExpanded}`"
            >
                <ul
                    class="lni-u-type--xxs lni-u-pa1"
                >
                    <li
                        v-for="(product, index) in products"
                        :key="index"
                        class="lni-u-ml2"
                    >
                        <a
                            :href="product.url"
                            target="_blank"
                        >
                            {{ product.title }}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import slide from '@gov.wa.lni/framework.one-lni.directives/source/slide.js';

export default {
    name: 'LniShipGrantProjectsCard',
    directives: {
        slide,
    },
    props: {
        displayedProjectTitle: {
            type: String,
            required: true,
        },
        industries: {
            type: Array,
            required: true,
        },
        grantees: {
            type: String,
            required: true,
        },
        grantAmount: {
            type: String,
            required: true,
        },
        projectStatus: {
            type: String,
            required: true,
        },
        projectType: {
            type: String,
            required: true,
        },
        finalReport: {
            type: String,
            required: false,
            default: '',
        },
        projectId: {
            type: String,
            required: true,
        },
        projectSummary: {
            type: String,
            required: true,
        },
        products: {
            type: Array,
            default: () => [],
        },
        frontendKeywordTags: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            summaryIsExpanded: false,
            productsIsExpanded: false,
        };
    },
    computed: {
        projectCompleted() {
            return this.projectStatus === 'completed';
        },
        hasProducts() {
            return this.products.length > 0;
        },
        hasFinalReport() {
            return this.finalReport;
        },
    },
    methods: {
        toggleSummary() {
            this.summaryIsExpanded = !this.summaryIsExpanded;
        },
        toggleProducts() {
            this.productsIsExpanded = !this.productsIsExpanded;
        },
    },
};
</script>