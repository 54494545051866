import actions from '@gov.wa.lni/application.public-website/source/components/lni-site-feedback/actions';

export default () => ({
    state: {
        hasBeenSubmitted: false,
        labels: null,
        helperText: null,
        messages: null,
        validation: null,
        cookieName: 'lni-site-feedback',
        showModalSpinner: false,
    },
    actions: {
        ...actions,
    },
});