<template>
    <li class="lni-u-pt2 lni-u-pb3 lni-u-ph2 lni-u-mt3 lni-u-shadow--xs lni-u-border-radius--small">
        <div class="lni-u-type--xs lni-u-line-height--tight lni-u-flex lni-u-flex-column lni-u-content-start">
            <h3 class="lni-u-ma0 lni-u-type--md lni-u-line-height--tight lni-u-type--normal">
                <a
                    :href="route"
                    class="lni-u-text--no-underline">{{ title }}</a>
            </h3>
            <span class="lni-u-mt1">
                <span
                    aria-hidden="true"
                    :class="`lnicon--${icon}`"
                    class="lni-u-mlauto lni-u-mr0"></span>
                <span class="lni-u-visually-hidden">{{ icon }} Document </span>
                <span>{{ formId }} - {{ type }} ({{ date }})</span>
                {{ language }}
            </span>
        </div>
        <transition name="slide-from-top">
            <div
                v-show="showOrderControls"
                class="lni-c-checkbox lni-u-pt2">
                <h4
                    v-if="!availableFrom && !copies.length"
                    class="lni-u-type--xs lni-u-type--bold lni-u-mt2">
                    Available online only
                </h4>
                <div
                    v-if="availableFrom"
                    class="lni-u-type--xs">
                    <!-- has special instructions -->
                    <h4 class="lni-u-type--xs lni-u-type--bold lni-u-mt2">
                        For paper copies:
                    </h4>
                    <p class="lni-u-mt0 lni-u-line-height--tight">
                        {{ availableFrom }}
                    </p>
                </div>
                <div v-show="copies.includes('warehouse') && orderable">
                    <button
                        :id="`add-to-cart_${formId}`"
                        class="lni-c-add-to-cart-button lni-c-button lni-c-button --text lni-u-type--xs lni-u-pl0"
                        type="button"
                        @click="onSelection($props)">
                        <lni-html-element
                            :id="`${id}_order-button__text`"
                            element="span"
                            :htmlString="orderButtonText(inOrder.includes(formId), title)"></lni-html-element>
                        <span class="lni-u-type--xxs">({{ language }})</span>
                    </button>
                </div>
            </div>
        </transition>

        <div v-if="translations.length > 0 && groupTranslations">
            <button
                class="lni-u-button-reset lni-u-mt1 lni-u-pa0 lni-u-text--left lni-u-link-style lni-u-type--xs"
                :aria-expanded="`${showTranslations}`"
                :aria-controls="`translations_${formId}`"
                @click="showTranslations = !showTranslations">
                Other languages
                <span
                    :class="`lnicon--triangle--${showTranslations ? 'up' : 'down'}`"
                    class="lni-u-type--xxs"
                    aria-hidden="true"></span>
            </button>
            <transition name="slide-from-top">
                <ul
                    v-show="showTranslations"
                    :id="`translations_${formId}`"
                    class="lni-u-list-reset">
                    <li
                        v-for="translation in translations"
                        :key="translation.formId"
                        class="lni-u-items-center lni-u-pt1">
                        <div class="lni-c-forms-and-pubs-search__language">
                            <a
                                class="lni-u-mr1 --lang"
                                :href="translation.route">
                                <span
                                    :lang="translation.languageCode">
                                    {{ translation.languageNativeString }}
                                </span>
                                ({{ translation.language }})
                            </a>
                            <span class="lni-u-type--xxs --id">
                                {{ translation.formId }}
                            </span>
                        </div>
                        <div
                            v-show="translation.copies.includes('warehouse') && orderable"
                            class="lni-c-checkbox lni-u-ml2 lni-u-flex lni-u-items-center">
                            <button
                                :id="`add-to-cart_${translation.formId}`"
                                class="lni-c-add-to-cart-button lni-c-button lni-c-button --text lni-u-type--xs lni-u-pl0"
                                type="button"
                                @click="onSelection(translation)">
                                <lni-html-element
                                    :id="`${id}_order-button__text-translation`"
                                    element="span"
                                    :htmlString="orderButtonText(inOrder.includes(translation.formId), translation.title, translation.language)"></lni-html-element>
                            </button>
                        </div>
                    </li>
                </ul>
            </transition>
        </div>
    </li>
</template>

<script>
export default {
    name: 'lni-form-and-pub-search-result',
    filters: {
        snippet(text, length) {
            return text.length > length ? `${text.substring(0, length)}...` : text;
        },
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        route: {
            type: String,
            required: true,
        },
        date: {
            type: String,
            required: true,
        },
        copies: {
            type: Array,
            default: () => [],
        },
        availableFrom: {
            type: String,
            default: '',
        },
        formId: {
            type: String,
            required: true,
        },
        filename: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
        filetype: {
            type: String,
            required: true,
        },
        language: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            default: '',
        },
        translations: {
            type: Array,
            default: () => [],
        },
        showOrderControls: {
            type: Boolean,
            default: false,
        },
        groupTranslations: {
            type: Boolean,
            default: true,
        },
        idsInOrder: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            showTranslations: false,
        };
    },
    computed: {
        orderable() {
            return this.showOrderControls;
        },
        ids() {
            return this.translations.length
                ? [...this.translations.map(item => item.formId), this.formId] : [this.formId];
        },
        inOrder() {
            return this.idsInOrder.filter(id => this.ids.includes(id));
        },
    },
    methods: {
        onSelection(item) {
            const {
                formId,
                title,
                language,
            } = item;
            const shouldAdd = !this.inOrder.includes(formId);
            this.$emit('selection-made',
                {
                    formId,
                    title,
                    language,
                    shouldAdd,
                },
            );
        },
        orderButtonText(isInOrder, title, lang) {
            const langText = lang ? ` , ${lang}` : '';
            if (isInOrder) {
                return `Remove <span class="lni-u-visually-hidden">${title}${langText}</span> from order`;
            }
            return `Add <span class="lni-u-visually-hidden">${title}${langText}</span> to order`;
        },
    },
}; </script>

<style lang="scss">
@import 'lni-forms-and-pubs-search';
</style>