export default context => {
    const moduleId = context.getters.moduleId;
    const moduleState = context.rootState[moduleId];

    const val = context.rootState[`${moduleId}_auto-suggest`].value;
    if (val.length > 0) {
        moduleState.definitions.forEach(group => {
            const index = group.data.findIndex(definition =>
                val === `${definition.value} - ${definition.text}`);
            if (index >= 0) {
                // Make sure page jumps to result even if search isn't changed
                context.commit('setAttribute', {
                    id: moduleId,
                    attribute: 'highlightTargetId',
                    value: '',
                }, {
                    root: true,
                });
                setTimeout(() => {
                    context.commit('setAttribute', {
                        id: moduleId,
                        attribute: 'highlightTargetId',
                        value: `${group.heading}-${index}`,
                    }, {
                        root: true,
                    });
                });
            }
        });
    }
};