<template>
    <div
        v-click-outside="onClickOutside"
        class="lni-c-shopping-cart"
    >
        <transition
            name="inhale"
            mode="out-in"
        >
            <button
                :id="`${id}_button`"
                :key="cart.length"
                ref="toggle"
                class="lni-c-shopping-cart__toggle lni-u-flex lni-u-items-center lni-u-mr0 lni-u-mlauto"
                type="button"
                :aria-expanded="isOpen ? 'true' : 'false'"
                aria-live="polite"
                @click="open"
            >
                <span
                    v-if="cart.length"
                    class="lni-u-mr1"
                ><span :key="cart.length">{{ cart.length }}</span> <span class="lni-u-visually-hidden">items in</span></span>
                <span class="lni-u-visually-hidden">Cart</span>

                <svg
                    version="1.0"
                    xmlns="http://www.w3.org/2000/svg"
                    width="29"
                    height="28"
                    fill="currentColor"
                    viewBox="0 0 1280 1225"
                ><path d="M1139 17l-105 19-9 5c-8 7-7 6-32 68l-40 103-30 78h-8l-147 4-300 7C14 312 31 312 25 314s-10 4-15 9c-5 6-9 14-10 21l9 80 22 183c20 167 19 154 22 161 2 6 10 14 16 17 7 4-10 2 237 22l177 14 160 13 72 6 66 51 71 58c7 7 14 20 15 29 3 16-4 29-18 34l-5 2-95 1-395 2-300 1-6 2c-8 3-17 11-21 19-2 5-2 8-2 14l1 13c3 6 8 13 14 17 8 6 10 6 44 7h31l-5 5c-12 8-22 23-26 38l-1 19c0 12 0 15 2 21 10 30 35 50 66 52 28 1 56-14 69-39 12-22 11-50-2-72-4-7-12-16-19-22l-4-3h85l310-1h226l-5 3a74 74 0 00-33 65c2 33 27 61 59 67 16 4 31 2 47-6 9-5 12-6 19-14 7-7 9-10 13-18 7-13 8-20 8-33 0-14-1-21-7-32a75 75 0 00-45-39c-5 0-5-1 1-3a117 117 0 0067-76c1-9 1-27-1-36-2-10-7-25-11-34-13-25-25-37-85-84l-46-37 16-42 24-61 26-67 26-67 65-166 25-64 50-128 39-100 6-14 90-15c100-17 97-17 105-26 8-8 10-15 10-25s-2-17-8-23c-7-8-18-13-27-13l-105 17zM893 353l-47 121-2 3h-65V349l40-2 59-1h18l-3 7zm-140 60l-1 64H631V352h12l55-1 50-2h6v64zm-149 2v62H481V356h4a7975 7975 0 01105-3h14v62zm-148 2v60H333V360l104-3h19v60zm-149 2v58H185V363h14a5433 5433 0 00105-3l3 1v58zm-148 2v56h-44l-46-1c-1-2-13-108-12-109l91-3h11v57zm0 139v57H86L73 505l-1-3h87v58zm149 0v57H185V502h123v58zm148 0v57H333V502h123v58zm148-1v58H481V502h123v57zm149 1v57H630V502h123v58zm80-52l-42 107c-1 2-2 2-7 2h-6V502h57l-2 6zM159 683v42l-30-3-30-3a599 599 0 01-10-74v-3h70v41zm149 6v47h-3a3908 3908 0 01-117-9h-3v-85h123v47zm148 6l-1 53-121-10-1-48v-48h123v53zm148 6v59h-5a9093 9093 0 00-114-10h-3V642h122v59zm149-24v35l-11 28-11 30c-1 1-2 2-3 1l-48-5-45-4h-5V642h123v35zm27-34l-1 2c-1 1-1 1-1-1l1-2 1 1zm-607 470c11 5 20 15 23 26 3 10 2 23-2 31s-13 17-20 21c-26 11-56-4-61-33-3-18 7-37 23-45 11-5 27-6 37 0zm698 0c8 4 16 13 20 20 16 38-22 74-59 57-16-7-26-27-23-45 5-28 36-45 62-32z" /><path d="M146 1131c-6 3-9 6-12 12-8 18 9 37 27 32 11-4 17-12 17-23 0-13-8-22-21-23-5 0-7 0-11 2zm698-1c-6 2-10 6-13 11-2 4-2 6-2 12 0 7 1 11 6 16 5 4 10 6 17 6s12-2 17-8c15-18-3-44-25-37z" /></svg>
            </button>
        </transition>

        <transition
            name="slide-from-right"
            @after-enter="afterOpen"
        >
            <div
                v-show="isOpen"
                ref="content"
                class="lni-c-shopping-cart__content lni-u-pa3 lni-u-shadow--sm"
                aria-live="polite"
            >
                <div class="lni-u-flex">
                    <button
                        ref="close"
                        class="lni-c-shopping-cart__close-button lni-c-button --text
                        lni-u-type--lg lni-u-mr0 lni-u-mlauto"
                        type="button"
                        aria-label="close"
                        @click="close"
                        @keydown.tab.shift.prevent="$refs.checkout.focus()"
                    >
                        <span
                            aria-hidden="true"
                            class="lnicon--close"
                        />
                    </button>
                </div>
                <ul
                    v-if="cart.length"
                    class="lni-c-shopping-cart__items lni-u-list-reset lni-u-line-height--tight"
                >
                    <transition-group name="list">
                        <li
                            v-for="item in cart"
                            :key="item.formId"
                            class="lni-u-flex lni-u-mt2"
                        >
                            <span
                                class="
                                lni-u-pr1 lni-u-mr1
                                lni-u-line-height--tight
                                lni-u-text--center
                                lni-u-br lni-u-b--gray-base
                            "
                            >
                                <input
                                    :id="`${id}_item-${item.formId}`"
                                    v-constrain="/^\d*$/"
                                    pattern="[0-9]*"
                                    inputmode="numeric"
                                    class="lni-c-input-quantity lni-u-block"
                                    type="text"
                                    :value="item.quantity"
                                    @input="updateQuantity(item, $event)"
                                >
                                <label
                                    :for="`${id}_item-${item.formId}`"
                                    class="lni-u-type--xxs"
                                >copies</label>
                            </span>
                            <div class="lni-c-shopping-cart__item-title lni-u-flex lni-u-flex-column">
                                <span>{{ item.title }}</span>
                                <div class="lni-u-type--xs">
                                    <span>{{ item.formId }}</span> - <span>{{ item.language }}</span>
                                </div>
                            </div>
                            <button
                                class="
                                lni-u-button-reset lni-u-link-style lni-u-ph2 lni-u-mlauto lni-u-mr0
                                lni-u-flex lni-u-flex-column lni-u-items-center lni-u-justify-center"
                                @click="removeItem(item)"
                            >
                                <span
                                    class="lnicon--remove"
                                    aria-hidden="true"
                                />
                                <span class="lni-u-type--xxs lni-u-line-height--tight">Remove</span>
                            </button>
                        </li>
                    </transition-group>
                </ul>
                <p v-if="cart.length">
                    We recommend ordering a three month supply. Orders are subject to availability.
                </p>
                <p v-else>
                    No items in cart.
                </p>
                <div
                    v-if="cart.length"
                    class="lni-u-flex"
                >
                    <a
                        ref="checkout"
                        class="lni-c-button lni-u-link-reset lni-u-mlauto lni-u-mr0 lni-u-mb3"
                        href="/agency/order-paper-forms"
                        @keydown.tab.exact.prevent="$refs.close.focus()"
                    >Place order</a>
                </div>
                <button
                    ref="order-more"
                    class="lni-u-button-reset lni-u-link-style"
                    type="button"
                    @click="close"
                >
                    &larr; Add more items to order.
                </button>
            </div>
        </transition>
    </div>
</template>

<script>
import constrain from '@gov.wa.lni/framework.one-lni.directives/source/constrain.js';
import clickOutside from '@gov.wa.lni/framework.one-lni.directives/source/clickOutside.js';
export default {
    name: 'LniFormsAndPubsCart',
    directives: {
        constrain,
        clickOutside,
    },
    mounted() {
        this.dispatchEvent(`${this.id}/initializeCart`);
        const cartMutations = [
            `${this.id}/ADD_ITEM`,
            `${this.id}/REMOVE_ITEM`,
            `${this.id}/UPDATE_ITEM`,
        ];
        this.$store.subscribe(mutation => {
            if (cartMutations.includes(mutation.type)) {
                this.dispatchEvent(`${this.id}/syncCookie`);
            }
        });
    },
    methods: {
        updateQuantity(item, $event) {
            const val = $event.currentTarget.value;
            const maxLength = 3;
            if ( val.length > maxLength ) {
                $event.currentTarget.value = val.substring(0, maxLength);
                return;
            }
            const updatedItem = {
                ...item,
                quantity: $event.currentTarget.value,
            };
            this.$store.commit(`${this.id}/UPDATE_ITEM`, updatedItem);
        },
        removeItem(item) {
            this.$store.commit(`${this.id}/REMOVE_ITEM`, item);
            this.$emit('item-removed', item.formId);
        },
        open() {
            this.isOpen = true;
            document.body.classList.add('--scroll-lock');
        },
        close() {
            this.isOpen = false;
            document.body.classList.remove('--scroll-lock');
            this.$refs.toggle.focus();
        },
        afterOpen() {
            this.$refs.close.focus();
            this.$refs.content.scrollIntoView({
                behavior: 'smooth',
            });
        },
        onClickOutside() {
            if (this.isOpen) {
                this.close();
            }
        },
    },
}; </script>
<style lang="scss">
@import 'lni-forms-and-pubs-cart';
</style>