import initialize from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-consultation-request/actions/initialize.js';
import onChange from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-consultation-request/actions/onChange.js';
import persist from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-consultation-request/actions/persist.js';
import setOutOfState from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-consultation-request/actions/setOutOfState.js';
import submit from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-consultation-request/actions/submit.js';
import syncStore from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-consultation-request/actions/syncStore.js';
import syncUi from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-consultation-request/actions/syncUi.js';

const OTHER = 'Other';

import {
    formFields,
} from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-consultation-request/data/formModel.js';
import counties from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-consultation-request/data/counties.json';

export default () => ({
    state: {
        counties,
        preferredLanguages: [
            {
                text: 'English',
                value: 'English',
            },
            {
                text: 'Español (Spanish)',
                value: 'Spanish',
            },
            {
                text: 'ប្រទេសកម្ពុជា (Cambodian)',
                value: 'Cambodian',
            },
            {
                text: '中国 - 简体 (Chinese-Simplified)',
                value: 'Chinese-Simplified',
            },
            {
                text: '中文繁體 (Chinese-Traditional)',
                value: 'Chinese-Traditional',
            },
            {
                text: '한국어 (Korean)',
                value: 'Korean',
            },
            {
                text: 'русский (Russian)',
                value: 'Russian',
            },
            {
                text: 'Soomaali (Somali)',
                value: 'Somali',
            },
            {
                text: 'tiếng Việt (Vietnamese)',
                value: 'Vietnamese',
            },
            {
                text: 'Other',
                value: OTHER,
            },
        ],
        formData: formFields,
        emailsByRegion: {},
        sendEmailEndpoint: '',
        senderEmail: '',
        showModalSpinner: false,
        trackedErrors: [],
    },
    getters: {
        region(state, getters, rootState) {
            const selectedCounty = rootState[`${getters.moduleId}_county`].value;
            if (selectedCounty) {
                return state.counties.find(item => item.value === selectedCounty).region;
            }
            return 0; //default
        },
        consultantEmail(state, getters) {
            return state.emailsByRegion[getters.region];
        },
        isOtherLanguage(state, getters, rootState) {
            return rootState[`${getters.moduleId}_preferred-language`].value === OTHER;
        },
    },
    actions: {
        initialize,
        onChange,
        persist,
        setOutOfState,
        submit,
        syncStore,
        syncUi,
    },
    mutations: {
        UPDATE_FORM_ITEM(state, payload) {
            const itemIndex = state.formData.findIndex(item => item.id === payload.id);
            const newItem = {
                ...state.formData[itemIndex],
                ...payload,
            };
            state.formData.splice(itemIndex, 1, newItem);
        },
        ADD_TRACKED_ERRORS(state, payload) {
            for (let i = 0; i < payload.length; i++) {
                if (state.trackedErrors.indexOf(payload[i]) === -1) {
                    state.trackedErrors.push(payload[i]);
                }
            }
        },
        REMOVE_TRACKED_ERRORS(state, payload) {
            for (let i = 0; i < payload.length; i++) {
                let index = state.trackedErrors.indexOf(payload[i]);
                if (index > -1) {
                    state.trackedErrors.splice(index, 1);
                }
            }
        },
    },
});