import stateAverageRate from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-lep-calculator/actions/stateAverageRate.js';
import updateResultsValue from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-lep-calculator/actions/updateResultsValue.js';
import formatConversion from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-lep-calculator/actions/formatConversion.js';
import showInformationalMessage from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-lep-calculator/actions/showInformationalMessage.js';

export default (context, payload) => {
    if (!context.rootGetters['lep-calculator-error-container/isValid']) {
        return;
    }

    let results = {
        entitlement: {
            name: 'LEP Entitlement',
            value: null,
            format: 'Currency',
        },
        method: {
            name: 'Method used',
            value: '',
            format: 'String',
        },
        startDate: {
            name: 'Start Date',
            value: new Date(context.rootState[`${payload.sourceId}_from-date`].value),
        },
        endDate: {
            name: 'End Date',
            value: new Date(context.rootState[`${payload.sourceId}_to-date`].value),
        },
        totalDays: {
            name: 'Total Days',
            value: 0,
            format: 'Integer',
        },
        stateRate: {
            name: 'State\'s Average Wage',
            value: 0,
            format: 'Currency',
        },
        wageDOI: {
            name: 'Wage at Time of Injury',
            value: parseFloat(context.rootState[`${payload.sourceId}_wages-at-doi`].value),
            format: 'Currency',
        },
        wageCurr: {
            name: 'Current Wage',
            value: parseFloat(context.rootState[`${payload.sourceId}_current-wages`].value),
            format: 'Currency',
        },
        timeLossRate: {
            name: 'Time-Loss Rate',
            value: parseFloat(context.rootState[`${payload.sourceId}_time-loss-rate`].value),
            type: 'Time-Loss Rate',
            format: 'Currency',
        },
        wagePctDOI: {
            name: 'Percent of Wages at the time of injury',
            formula: '{{wageCurr}} ÷ {{wageDOI}}',
            value: 0,
            format: 'Percent',
        },
        lepPct: {
            name: 'Loss of Earning Power Percent',
            value: 0,
            formula: '{{1.000}} - {{wagePctDOI}}',
            format: 'Percent',
        },
        lepA: {
            name: 'Method A',
            formula: '{{lepPct}} x {{timeLossRate}}',
            type: 'Method A',
            value: 0,
            format: 'Currency',
        },
        diff: {
            name: 'Difference',
            formula: '{{wageDOI}} - {{wageCurr}}',
            value: 0,
            format: 'Currency',
        },
        lepB: {
            name: 'Method B',
            formula: '{{diff}} x {{0.800}}',
            type: 'Method B',
            value: 0,
            format: 'Currency',
        },
        summed: {
            name: 'Sum',
            formula: '{{wageCurr}} + {{lepB}}',
            value: 0,
            format: 'Currency',
        },
        capB: {
            name: 'Capped Amount for Method B',
            formula: '{{stateRate}} - {{wageCurr}}',
            type: 'Capped Method B',
            value: 0,
            format: 'Currency',
        },
    };
    const percentFix = 4,
        floatFix = 3,
        lepBPercent = 0.800;

    let stateData = stateAverageRate(
        results.startDate.value, results.endDate.value, context.state.rates);
    results.stateRate.value = stateData[0];
    results.totalDays.value = stateData[1];

    results.wagePctDOI.value = parseFloat(
        (results.wageCurr.value / results.wageDOI.value)
            .toFixed(percentFix));
    results.lepPct.value = parseFloat(
        (1 - results.wagePctDOI.value)
            .toFixed(percentFix));
    results.lepA.value = parseFloat(
        (results.lepPct.value * results.timeLossRate.value)
            .toFixed(floatFix));
    results.lepB.value = parseFloat(
        ((results.wageDOI.value - results.wageCurr.value) * lepBPercent)
            .toFixed(floatFix));
    results.diff.value = parseFloat(
        (results.wageDOI.value - results.wageCurr.value)
            .toFixed(floatFix));
    results.summed.value = parseFloat(
        (results.wageCurr.value + results.lepB.value).toFixed(floatFix));
    results.capB.value = parseFloat(
        (stateData[0] - results.wageCurr.value)
            .toFixed(percentFix));

    results.showCapBSection = false;
    const percentVal = 0.05;
    if (results.lepPct.value <= percentVal) {
        results.method.value = `Loss of Earning Power Percent is 5% or less (${formatConversion('Percent', results.lepPct.value)})`;
    } else if (results.lepA.value > results.lepB.value) {
        results.entitlement.value = results.lepA.value;
        results.method.value = results.lepA.name;
    } else if (results.summed.value > stateData[0]) {
        results.showCapBSection = true;
        if (results.capB.value > results.timeLossRate.value) {
            results.entitlement.value = results.timeLossRate.value;
            results.method.value = results.timeLossRate.name;
        } else if (results.lepA.value > results.capB.value) {
            results.entitlement.value = results.lepA.value;
            results.method.value = results.lepA.name;
        } else {
            results.entitlement.value = results.capB.value;
            results.method.value = results.capB.name;
        }
    } else if (results.summed.value <= results.stateRate.value) {
        if (results.lepB.value > results.timeLossRate.value) {
            results.entitlement.value = results.timeLossRate.value;
            results.method.value = results.timeLossRate.name;
        } else {
            results.entitlement.value = results.lepB.value;
            results.method.value = results.lepB.name;
        }
    } else {
        showInformationalMessage(context, payload);
        return;
    }
    updateResultsValue('results', context, payload, results);
};