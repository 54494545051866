<template>
    <div class="lni-c-rulemaking-calendar">
        <lni-checkbox-list
            :id="`${id}_filter`"
            class="lni-u-mv3"
            labelText="Filter by meeting type:"
            :options="filterOptions"
            @change="onChange"
        />
        <div class="lni-u-mt2">
            <lni-date-filter
                :id="`${id}_calendar`"
                :minYears="minYears"
                :maxYears="maxYears"
                :startMonth="currentMonth"
                :startYear="currentYear"
                @change="setSelectedDate"
            />
        </div>
        <div class="lni-u-mt3">
            <div v-if="!Object.keys(displayedEvents).length">
                No events scheduled for {{ selectedDate }} with search criteria:
                <ul
                    v-if="selectedFilters.length"
                    class="lni-u-ml2"
                >
                    <li
                        v-for="(filter, index) in selectedFilters"
                        :key="index"
                    >
                        {{ eventTypeText(filter) }}
                    </li>
                </ul>
                <ul
                    v-else
                    class="lni-u-ml2"
                >
                    <li>None</li>
                </ul>
            </div>
            <template v-else>
                <div
                    v-for="(arr, date) in displayedEvents"
                    :key="date"
                    class="lni-o-grid--wrap"
                >
                    <div class="lni-c-rulemaking-calendar__mini-calendar lni-u-mt2 lni-o-cell--2of12">
                        <div class="--weekday lni-u-type--xxs">
                            {{ getDateData(date).weekdayShort }}
                        </div>
                        <div class="--date">
                            {{ getMiniCalendarDate(date) }}
                        </div>
                    </div>
                    <div
                        class="lni-u-type--xs lni-o-cell--10of12 lni-u-pt2"
                        :class="`${date !== lastDisplayedEvent ? 'lni-u-b--subtle lni-u-bb' : ''}`"
                    >
                        <div
                            v-for="(event, index) in arr"
                            :key="index"
                            class="lni-u-pb2"
                        >
                            <a
                                :href="`${event.route}`"
                                class="lni-u-type--bold"
                            >
                                {{ event.title }}
                            </a><br>
                            {{ eventTypeText(event.type) }} – {{ event.location }}<br>
                            {{ getStartEndTime(event.startTime, event.endTime) }}
                        </div>
                    </div>
                </div>
            </template>
        </div>

        <div class="lni-c-rulemaking-calendar__button-bar lni-u-mv3 lni-u-flex lni-u-justify-center">
            <button
                :id="`${id}_prev`"
                class="lni-c-button --alternate lni-u-text--all-caps lni-u-mr2"
                :class="isMinDate ? `--hidden` : ''"
                :disabled="isMinDate"
                @click="changeSelectedDate(-1)"
            >
                <span class="lnicon--triangle--left" /> Prev Month
            </button>
            <button
                :id="`${id}_next`"
                class="lni-c-button --alternate lni-u-text--all-caps"
                :class="isMaxDate ? `--hidden` : ''"
                :disabled="isMaxDate"
                @click="changeSelectedDate(1)"
            >
                Next Month <span class="lnicon--triangle--right" />
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LniRulemakingCalendar',
    data() {
        return {
            selectedFilters: [],
            selectedDate: null,
            selectedMonth: null,
            selectedYear: null,
            currentMonth: new Date().getMonth(),
            currentYear: new Date().getFullYear(),
        };
    },
    computed: {
        isMinDate() {
            return this.$store.getters.isMinDate;
        },
        isMaxDate() {
            return this.$store.getters.isMaxDate;
        },
        displayedEvents() {
            let result = this.events;
            if (this.selectedMonth >= 0 && this.selectedYear > 0) {
                result = this.events.filter(event => {
                    const eventDate = this.getDateData(event.date);
                    return eventDate.month === this.selectedMonth
                        && eventDate.year === this.selectedYear
                        && this.selectedFilters.includes(event.type);
                }).reverse();
            }

            //Group by key (event date in YYYY-MM-DD format)
            let groupedResult = result.reduce((r, item) => {
                (r[item.date] = r[item.date] || []).push(item);
                return r;
            }, {});

            return groupedResult;
        },
        lastDisplayedEvent() {
            const keys = Object.keys(this.displayedEvents);
            return keys.length > 0 ? keys[keys.length - 1] : null;
        },
    },
    mounted() {
        this.minDate = {
            month: this.currentMonth,
            year: this.currentYear - this.minYears,
        };
        this.maxDate = {
            month: this.currentMonth,
            year: this.currentYear + this.maxYears,
        };

        //Select all checkbox options
        const options = Object.keys(this.filterOptions).map(key => this.filterOptions[key].value);
        this.$store.commit('setAttribute', {
            id: `${this.id}_filter`,
            attribute: 'value',
            value: options,
        });
        this.selectedFilters = this.$store.state[`${this.id}_filter`].value;
    },
    methods: {
        getMiniCalendarDate(date) { //YYYY-MM-DD
            let month = this.getDateData(date).monthShort;
            if (month !== 'May') {
                month += '.';
            }
            const day = this.getDateData(date).day;
            return `${month} ${day}`;
        },
        //TODO: Simplify how date strings are handled
        getDateData(date) { //YYYY-MM-dd
            let result = {
                year: '',
                month: '',
                monthShort: '',
                monthLong: '',
                day: '',
                weekdayShort: '',
                time: '',
            };

            if (date && date !== 'undefined') {
                const data = typeof date === 'string' ? this.dateStrToObj(date) : date;
                const d = new Date(data.year, data.month, data.day);

                result.year = d.getFullYear();
                result.month = d.getMonth();
                result.monthShort = d.toLocaleDateString('en', {
                    month: 'short',
                });
                result.monthLong = d.toLocaleDateString('en', {
                    month: 'long',
                });
                result.day = d.getDate();
                result.weekdayShort = d.toLocaleDateString('en', {
                    weekday: 'short',
                });
            }

            return result;
        },
        getTimeStr(time) { //HH:mm
            const timeParts = time.split(':');

            let hours = timeParts[0];
            let minutes = timeParts[1];
            const twelve = 12;
            const ampm = hours >= twelve ? 'p.m.' : 'a.m.';
            hours = hours % twelve;
            hours = hours ? hours : twelve; //The hour '0' should be '12'

            let result = minutes === '00' ? `${hours} ${ampm}` : `${hours}:${minutes} ${ampm}`;
            if (result === '12 p.m.') {
                result = 'Noon';
            }
            if (result === '12 a.m.') {
                result = 'Midnight';
            }

            return result;
        },
        getStartEndTime(startTime, endTime) {
            let startTimeStr = this.getTimeStr(startTime);
            let result = startTimeStr;
            if (endTime) {
                let endTimeStr = this.getTimeStr(endTime);
                let sameAmPm = (startTimeStr.indexOf('a.m.') > 0 && endTimeStr.indexOf('a.m.') > 0)
                    || (startTimeStr.indexOf('p.m.') > 0 && endTimeStr.indexOf('p.m.') > 0);
                if (sameAmPm) {
                    startTimeStr = startTimeStr.replace(' a.m.', '');
                    startTimeStr = startTimeStr.replace(' p.m.', '');
                }

                result = `${startTimeStr} – ${endTimeStr}`;
            }

            return result;
        },
        dateStrToObj(dateStr) {
            const dateParts = dateStr.split('-');
            return {
                year: Number(dateParts[0]),
                month: Number(dateParts[1] - 1), //Drop leading 0
                day: Number(dateParts[2]), //Drop leading 0
            };
        },
        eventTypeText(key) {
            return key && this.filterOptions.length > 0 ? this.filterOptions.find(item => item.value === key).text : '';
        },
        onChange() {
            this.selectedFilters = this.$store.state[`${this.id}_filter`].value;
        },
        setSelectedDate(data) {
            this.selectedDate = data.date;
            this.selectedMonth = data.month;
            this.selectedYear = data.year;
        },
        changeSelectedDate(value = 0) {
            let month = this.selectedMonth + value;
            let year = this.selectedYear;
            const numOfMonths = 12;
            if (month < 0 || month >= numOfMonths) {
                month = month < 0 ? numOfMonths - 1 : 0;
                year += value;
            }

            this.$store.commit(`${this.id}_calendar/SET_SELECTED_DATE`, {
                month,
                year,
            });
        },
    },
}; </script>