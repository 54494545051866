export default function( context, payload ) {
    const controlValue = context.rootState[payload.targetId].value;
    if (!controlValue) {
        return;
    }
    context.commit('SET_FILTER', controlValue);
    context.commit('SET_PAGE', 1);
    context.dispatch('setParam', {
        param: 'page',
        value: 1,
    });
    context.dispatch('setParam', {
        param: 'filter',
        value: controlValue,
    });
    const pagination = `${payload.sourceId}_pager`;
    const totalPages = context.getters.resultCount;
    setTimeout(() => {
        context.commit('setAttribute', {
            id: pagination,
            attribute: 'total',
            value: totalPages,
        }, {
            root: true,
        });
    });
}