<template>
    <div
        class="
        lni-c-rule
        lni-u-shadow--xs lni-u-pa2 lni-u-mt2 lni-u-bg--white"
        :class="!section ? '--isChapter lni-u-pb3': null">
        <header
            class="lni-c-rule__top-meta lni-u-type--xxs">
            <span
                class="lni-c-rule__parent-chapter-number"
                :class="!section ? 'lni-u-type--bold' : null">Chapter: 296-{{ chapter }}  </span>
            <span
                v-if="section"
                class="lni-c-rule__parent-chapter-title">{{ parentTitle }}</span>
        </header>
        <div class="lni-c-rule__main-content">
            <h2
                class="lni-c-rule__title lni-u-type--md lni-u-type--bold lni-u-line-height--tight "
                :class="section ? 'lni-u-mt0' : null">
                <a
                    class="lni-u-text--no-underline"
                    :href="chapterUrl">{{ title }}</a>
            </h2>
            <p class="lni-c-rule__description lni-u-type--xs lni-u-mt1 lni-u-line-height--tight">
                {{ shortDescription }}
            </p>
        </div>
        <footer
            v-if="section"
            class="lni-c-rule__bottom-meta lni-u-mt2 lni-u-flex  lni-u-type--xxs">
            <span class="lni-c-rule__section">Section: {{ fullSection }}</span>
            <span
                v-if="partName"
                class="lni-c-rule__partName  lni-u-mr0 lni-u-mlauto">{{ partName }}</span>
        </footer>
    </div>
</template>

<script>
export default {
    name: 'lni-rule',
    props: {
        parentTitle: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            required: true,
        },
        chapter: {
            type: Number,
            required: true,
        },
        section: {
            type: String,
            default: null,
        },
        sectionEnd: {
            type: String,
            default: '',
        },
        partName: {
            type: String,
            default: '',
        },
        shortDescription: {
            type: String,
            default: '',
        },
        related: {
            type: [Array, null],
            default: null,
        },
        chapterUrl: {
            type: String,
            required: true,
        },
        officialUrl: {
            type: String,
            required: true,
        },
        pdfUrl: {
            type: String,
            required: true,
        },
        index: {
            type: Number,
        },
        rank: {
            type: Number,
        },
    },
    computed: {
        fullSection() {
            if (!this.section) {
                return `296-${this.chapter}`;
            }
            if (!this.sectionEnd) {
                return `296-${this.chapter}-${this.section}`;
            }
            return `296-${this.chapter}-${this.section} — 296-${this.chapter}-${this.sectionEnd}`;
        },
    },
}; </script>