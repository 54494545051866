import api from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-logger-training-lookup/api/logger.api.js';

export default async (context, payload) => {
    const oneLni = payload.oneLni || payload;

    const response = await api(oneLni);
    const quarterlyFilterOptions = response.results.filterOptions.quarterlyFilterOptions;

    // Sync results
    context.commit('SET_TRAININGS', response);

    context.commit('UPDATE_FILTER_OPTIONS', quarterlyFilterOptions);
    const trainingFrequency = [];
    quarterlyFilterOptions.trainingFrequency.forEach(opt => {
        trainingFrequency.push(opt.value);
    });
    // Select both checkbox options for Training Frequency
    context.commit('setAttribute', {
        id: `${payload.sourceId}_trainingFrequency`,
        attribute: 'value',
        value: trainingFrequency,
    }, {
        root: true,
    });

    context.dispatch('updateFilterValues', payload);

    // Set filter controls options and values
    context.dispatch('syncFilterControls', {
        ...payload,
        init: true,
    });

    context.dispatch('updatePage', {
        pageNumber: 1,
    });
};