/* eslint-disable no-magic-numbers */
/* cable-n-roped hydraulic elevators */
import setAttribute from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-elevator-opening/actions/setAttribute.js';

const currencyConversion = val => {
    const floatDig = 2;

    return '$' + parseFloat(val).toFixed(floatDig)
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,');
};

export default (context, payload) => {
    const moduleId = context.getters.moduleId;
    if (!context.rootGetters[`${moduleId}_error-container/isValid`]) {
        setAttribute('openingsFee', context, payload, '');
        setAttribute('openingsFixed', context, payload, '');
        setAttribute('value', context, {
            sourceId: `${context.getters.moduleId}_result`,
        }, '');
        return;
    }

    /* TODO:  Cable and Rope Elevators are missing additional fee for traveling without openings.
     * Contact Business area to get this completed
     * Out of scope for MVP */
    let input = context.rootState[`${moduleId}_input`];
    let openings = parseFloat(input.value);
    let result = context.state.baseFee;
    if (openings > context.state.minOpenings) {
        result += (openings - context.state.minOpenings) * context.state.pricePer;
    }
    setAttribute('openingsFee', context, payload, currencyConversion(result));
    setAttribute('openingsFixed', context, payload, input.value);
    setTimeout(() => {
        setAttribute('value', context, {
            sourceId: `${context.getters.moduleId}_result`,
        }, currencyConversion(result));
    });
};