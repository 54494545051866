const SITE_SEARCH_ENDPOINT = '/site-search';

export const fetchResults = function(urlParams, oneLni) {
    const language = oneLni.getLanguage();
    const langIds = {
        en: '1',
        es: '2',
    };
    const languageParam = language === 'es' ? `&language_id=${langIds[language]}` : '';
    const apiLocation = oneLni.environment.current.apiLocation2;
    const endpoint = `${apiLocation}${SITE_SEARCH_ENDPOINT}/${urlParams}?${languageParam}`;
    let resultsPromise = fetch(endpoint, {
        mode: 'cors',
    }).then(response => response.json())
        .then(data => data);

    return resultsPromise;
};