<template>
    <div class="lni-c-overtime-exempt-budget-tool lni-u-mt3">
        <div class="lni-u-pv3 lni-u-b--subtle lni-u-bt lni-u-bb">
            <lni-radios
                :id="`${id}_employer-size`"
                labelText="How many employees (including part-time)?"
                :isColumn="true"
                :options="employerSizes"
                value="smBusiness"
            >
                <template #helperText>
                    <span>
                        For more information about how to calculate employer size, see <a href="/workers-rights/_docs/esa9.9.pdf">Administrative Policy ES.A.9.9</a>.
                    </span>
                </template>
            </lni-radios>
            <div class="lni-u-mt3 lni-u-line-height--tight">
                <p>Enter the annual salary and hours per week.</p>
                <span class="lni-c-overtime-exempt-budget-tool__note">
                    Important: Do not include bonuses, housing, or other types of compensation.
                </span>

                <lni-input-text
                    :id="`${id}_annual-salary`"
                    v-constrain="/^[0-9,]{0,7}$/"
                    pattern="[0-9,]"
                    :maxlength="7"
                    class="lni-u-block"
                    labelText="Employee Annual Salary"
                    :required="true"
                    :customValidityAction="`${id}/salaryCustomValidation`"
                >
                    <template #prefix>
                        <span>
                            $
                        </span>
                    </template>
                    <template #helperText>
                        <p>
                            Example: 40,000
                        </p>
                    </template>
                </lni-input-text>
                <lni-input-text
                    :id="`${id}_weekly-hours`"
                    type="number"
                    class="lni-u-block lni-u-mt0"
                    labelText="Average Hours per Week"
                    :required="true"
                    :customValidityAction="`${id}/hoursCustomValidation`"
                >
                    <template #helperText>
                        <p>
                            Example: 42
                        </p>
                    </template>
                </lni-input-text>
            </div>
            <lni-button
                :id="`${id}_calculate-btn`"
                class="lni-u-block lni-u-mt1"
                text="Calculate"
                type="submit"
                :errorContainerId="`${id}_calculation-errors`"
                @click.native="submit"
            />
            <lni-error-container
                :id="`${id}_calculation-errors`"
                class="lni-u-mv3"
                heading="Before we submit this form:"
                :errorsCollectedFrom="[
                    `${id}_annual-salary`,
                    `${id}_weekly-hours`,
                ]"
            />
            <div
                v-if="calculated"
                class="lni-u-mv1 lni-u-pt3"
            >
                <h2>Salary Test</h2>
                <div class="lni-c-overtime-exempt-budget-tool__row lni-u-mt2">
                    <div class="--cell-sm lni-c-overtime-exempt-budget-tool__results">
                        {{ currencyConversion(minimumSalary) }}
                    </div>
                    <div class="--cell-lg">
                        Minimum salary for <strong>{{ employerSizeText }}</strong> in <strong>{{ currentYear }}*</strong>
                    </div>
                </div>
                <div class="lni-c-overtime-exempt-budget-tool__row lni-u-mv1">
                    <div class="--cell-sm lni-c-overtime-exempt-budget-tool__results">
                        {{ currencyConversion(annualSalary) }}
                    </div>
                    <div class="--cell-lg">
                        Employee annual salary entered
                    </div>
                </div>
                <div class="lni-c-overtime-exempt-budget-tool__row lni-u-mv1">
                    <div class="--cell-sm lni-c-overtime-exempt-budget-tool__results">
                        {{ isExempt ? 'Yes' : 'No' }}
                    </div>
                    <div class="--cell-lg">
                        The salary <strong>does {{ isExempt ? '' : 'not ' }}</strong> meet the exempt threshold
                    </div>
                </div>
                <span class="lni-c-overtime-exempt-budget-tool__helper-text lni-u-line-height--tight">
                    *For future years, see the <a href="/forms-publications/F700-207-000.pdf">Salary Threshold Implementation Chart</a>.
                </span>

                <h2>Budget Estimate</h2>
                <div class="lni-c-overtime-exempt-budget-tool__row lni-u-mv1">
                    <div class="--cell-sm lni-c-overtime-exempt-budget-tool__results">
                        {{ currencyConversion(annualSalary) }}
                    </div>
                    <div class="--cell-lg">
                        Employee annual salary entered
                    </div>
                </div>
                <div class="lni-c-overtime-exempt-budget-tool__row lni-u-mv1">
                    <div class="--cell-sm lni-c-overtime-exempt-budget-tool__results">
                        {{ weeklyHours }}
                    </div>
                    <div class="--cell-lg">
                        Average hours per week entered
                    </div>
                </div>
                <div class="lni-c-overtime-exempt-budget-tool__row lni-u-mv1">
                    <div class="--cell-sm lni-c-overtime-exempt-budget-tool__results">
                        {{ Number(annualHours).toLocaleString() }}
                    </div>
                    <div class="--cell-lg">
                        Standard annual hours based on a 40-hour week (40 x 52 weeks)
                    </div>
                </div>
                <div class="lni-c-overtime-exempt-budget-tool__row lni-u-mv1">
                    <div class="--cell-sm lni-c-overtime-exempt-budget-tool__results">
                        {{ Number(employeeAnnualHours).toLocaleString() }}
                    </div>
                    <div class="--cell-lg">
                        Actual annual hours based on average weekly hours entered ({{ weeklyHours }} x 52)
                    </div>
                </div>
                <div class="lni-c-overtime-exempt-budget-tool__row lni-u-mv1">
                    <div class="--cell-sm lni-c-overtime-exempt-budget-tool__results">
                        {{ Number(overtimeHours).toLocaleString() }}
                    </div>
                    <div class="--cell-lg">
                        Overtime hours ({{ Number(employeeAnnualHours).toLocaleString() }} - {{ Number(annualHours).toLocaleString() }})
                    </div>
                </div>
                <div class="lni-c-overtime-exempt-budget-tool__row lni-u-mv1">
                    <div
                        class="--cell-sm lni-c-overtime-exempt-budget-tool__results"
                        :class="{
                            'lni-u-font-color--error': isLessThanMinimumWage
                        }"
                    >
                        {{ currencyConversion(baseHourlyRate) }}
                    </div>
                    <div class="--cell-lg lni-u-flex lni-u-flex-column">
                        <span>
                            Base hourly rate ({{ currencyConversion(annualSalary) }} / {{ Number(annualHours).toLocaleString() }})
                        </span>
                        <span
                            v-if="isLessThanMinimumWage"
                            class="lni-u-font-color--error lni-u-type--xs"
                        >
                            The base hourly rate must meet or exceed Washington's minimum wage of ${{ minimumWage }}.
                        </span>
                    </div>
                </div>
                <div class="lni-c-overtime-exempt-budget-tool__row lni-u-mv1">
                    <div class="--cell-sm lni-c-overtime-exempt-budget-tool__results">
                        {{ currencyConversion(overtimePay) }}
                    </div>
                    <div class="--cell-lg">
                        Overtime pay ({{ currencyConversion(baseHourlyRate) }} x 1.5 x {{ overtimeHours }})
                    </div>
                </div>
                <div class="lni-c-overtime-exempt-budget-tool__row lni-u-mv1">
                    <div class="--cell-sm lni-c-overtime-exempt-budget-tool__results">
                        {{ currencyConversion(estimatedCost) }}
                    </div>
                    <div class="--cell-lg">
                        Estimated cost of non-exempt salary and overtime
                    </div>
                </div>
                <div class="lni-c-overtime-exempt-budget-tool__footer lni-u-line-height--tight lni-u-mt3">
                    <slot name="footer" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import constrain from '@gov.wa.lni/framework.one-lni.directives/source/constrain.js';

const framework = window.activeOneLni();

export default {
    name: 'LniOvertimeExemptBudgetTool',
    directives: {
        constrain,
    },
    mixins: [framework.mixins.oneLniComponent],
    data() {
        return {
            numWeeks: 52,
            hoursPerWeek: 40,
            overtimeRate: 1.5,
        };
    },
    computed: {
        isLessThanMinimumWage() {
            return this.baseHourlyRate < this.minimumWage;
        },
        annualHours() {
            return this.hoursPerWeek * this.numWeeks;
        },
        baseHourlyRate() {
            return this.annualSalary / this.annualHours;
        },
        employerSizeText() {
            return this.employerSizes.find(opt => opt.value === this.employerSize).text.replace(' Washington state', '');
        },
        employeeAnnualHours() {
            return this.weeklyHours * this.numWeeks;
        },
        estimatedCost() {
            return Number(this.annualSalary) + Number(this.overtimePay);
        },
        isExempt() {
            return this.minimumSalary < this.annualSalary;
        },
        minimumSalary() {
            return this.minimumWage * this.annualHours * this.$store.getters[`${this.id}/multiplier`];
        },
        overtimeHours() {
            return Number(this.employeeAnnualHours) - Number(this.annualHours);
        },
        overtimePay() {
            return this.baseHourlyRate * this.overtimeRate * this.overtimeHours;
        },
    },
    mounted() {
        this.addCustomValidations();
    },
    methods: {
        addCustomValidations() {
            this.$store.commit(`${this.id}_annual-salary/addValidityTest`, {
                validityTest: 'salaryInvalid',
                global: () => 'The Employee Annual Salary must be a valid whole number',
                inline: () => 'The Employee Annual Salary must be a valid whole number (Example: 40,000)',
            });

            this.$store.commit(`${this.id}_weekly-hours/addValidityTest`, {
                validityTest: 'hoursInvalid',
                global: () => 'The Average Hours per Week must be at least 40',
                inline: () => 'The Average Hours per Week must be at least 40',
            });
        },
        currencyConversion(val) {
            const floatDig = 2;
            return '$' + parseFloat(val).toFixed(floatDig).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,');
        },
        submit() {
            const self = this;

            const payload = {
                sourceId: this.id,
                targetId: `${this.id}_calculate-btn`,
                validation: {
                    errorsCollectedBy: this.$store.state[this.id].errorsCollectedBy,
                },
            };
            this.$store.dispatch('validateForm', payload).then(() => {
                if (self.$store.getters[`${self.id}_calculation-errors/isValid`]) {
                    self.$store.commit(`${self.id}/SET_ATTRIBUTE`, {
                        attribute: 'employerSize',
                        value: self.$store.state[`${self.id}_employer-size`].value,
                    });

                    self.$store.commit(`${self.id}/SET_ATTRIBUTE`, {
                        attribute: 'annualSalary',
                        value: parseFloat(self.$store.state[`${self.id}_annual-salary`].value.replace(/,/g, '')),
                    });

                    self.$store.commit(`${self.id}/SET_ATTRIBUTE`, {
                        attribute: 'weeklyHours',
                        value: self.$store.state[`${self.id}_weekly-hours`].value,
                    });

                    self.$store.commit(`${self.id}/SET_ATTRIBUTE`, {
                        attribute: 'calculated',
                        value: true,
                    });
                }
            });
        },
    },
};
</script>
<style lang="scss">
@import 'lni-overtime-exempt-budget-tool';
</style>