import actions from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-lep-calculator/actions';

export default () => ({
    strict: false,
    state: {
        results: {},
        preparedBy: '',
        worker: '',
        claimNumber: '',
        fromDate: '',
        toDate: '',
        wagesAtDoi: '',
        currentWages: '',
        timeLossRate: '',
        rates: [],
    },
    actions: {
        ...actions,
    },
});