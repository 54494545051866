/* eslint-disable */
const IGNORABLE_KEY_CODES = [8, 9, 13, 19, 27, 33, 34, 35, 36, 37, 38, 39, 40, 45, 46, 93, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 127, 0, 229];
const validCharacters = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
/* eslint-enable */

export default class Mask {
    static insert(str, index, val) {
        return str.substr(0, index) + val + str.substr(index);
    }

    /**
     * Find the input and set mask parameters
     * @param  {Object} el The Vue el containing one input
     * @param  {Object} vnode The Vue component's VNode
     * @param  {Object} options options object
     * @param  {String} options.pattern example "###,###-##" or "###-###-####"
     * @param  {Object} options.separator example [",", "-"] or "-" (converts to ["-"])
     */
    constructor(el, vnode, options) {
        const opts = options || {};
        this.delete = false;
        this.el = el.tagName.toLowerCase() === 'input'
            ? el : el.querySelector('input:not([type="hidden"])');
        this.vnode = vnode;

        if (Array.isArray(opts.separator)) {
            this.separator = opts.separator;
        } else {
            this.separator = opts.separator ? [opts.separator] : [];
        }

        this.validCharacters = opts.validCharacters || validCharacters;
        this.pattern = opts.pattern; //TODO: we may not need to add this property
        this.maxlength = parseInt(this.el.getAttribute('maxlength'), 10);
        this.el.removeAttribute('maxlength');

        for (let i = 0; i <= this.separator.length; i++) {
            this.validCharacters.push(this.separator[i]);
        }

        this.separatorIndicesFromPattern(this.pattern, this.separator);
    }

    init() {
        this.el.addEventListener('keydown', event => this.onKeydown(event));
        this.el.addEventListener('input', event => this.onInput(event));
        this.el.addEventListener('paste', event => this.onPaste(event));
    }

    /**
     * Determine indices of separator from patterns and
     * separator string
     * @param  {String} pattern example '###-###-###'
     * @param  {Array} separator [description]
     * @return {Array}           [description]
     */
    separatorIndicesFromPattern(pattern, separator) {
        let separatorIndices = [];
        for (let i = 0; i < pattern.length; i++) {
            for (let j = 0; j < separator.length; j++) {
                if (pattern.charAt(i) === separator[j]) {
                    separatorIndices.push({
                        key: i,
                        value: separator[j],
                    });
                }
            }
        }
        this.separatorIndices = separatorIndices;
    }

    isValidKey(key, charPos) {
        const currentPattern = this.pattern[charPos];
        const alphaNumericRegex = '[A-Za-z0-9]';
        const isValidKey = new RegExp(alphaNumericRegex).test(key);
        return currentPattern === '*' ? isValidKey : this.validCharacters.indexOf(key) !== -1;
    }

    isSeparator(key, caretPos) {
        let result = false;
        let separator;
        for (let i = 0; i < this.separatorIndices.length; i++) {
            separator = this.separatorIndices[i];
            if (key === separator.value && caretPos === separator.key) {
                result = true;
            }
        }

        return result;
    }

    isSeparatorIndex(caretPos) {
        let result = false;
        for (let i = 0; i < this.separatorIndices.length; i++) {
            if (caretPos === this.separatorIndices[i].key + 1) {
                result = true;
            }
        }

        return result;
    }

    stripSeparators(str) {
        if (!str) {
            return '';
        }

        let result = str;
        for (let i = 0; i < this.separatorIndices.length; i++) {
            result = result.split(this.separatorIndices[i].value).join('');
        }
        return result;
    }

    insertSeparators(str) {
        if (!str) {
            return '';
        }
        let newString = str;
        for (let i = 0; i < this.separatorIndices.length; i++) {
            if (newString.length >= this.separatorIndices[i].key) {
                newString = Mask.insert(newString,
                    this.separatorIndices[i].key,
                    this.separatorIndices[i].value,
                );
            }
        }

        return newString;
    }

    stripNonValid(str) {
        if (!str) {
            return '';
        }

        let newString = '';
        for (let i = 0; i < str.length; i++) {
            if (this.validCharacters.indexOf(str[i]) !== -1) {
                newString += str[i];
            }
        }
        return newString || str;
    }

    sliceString(str) {
        if (!str) {
            return '';
        }

        let newString = '';
        if (str.length > this.maxlength) {
            newString = str.slice(0, this.maxlength);
        }
        return newString || str;
    }

    onKeydown(event) {
        const charCode = event.which || event.keyCode;
        const key = event.key;
        this.delete = false;

        /* eslint-disable */
        const isPasteKey = ( event.metaKey || event.ctrlKey )
        && ( charCode === 86 || key === 'v' );
        const isCopyKey = ( event.metaKey || event.ctrlKey )
        && (charCode === 65 || key === 'a' );
        const isCutKey =  ( event.metaKey || event.ctrlKey )
        && (charCode === 88 || key === 'x' );
        const isUndoKey = ( event.metaKey || event.ctrlKey )
        && ( charCode === 90 || key === 'z' );
        if ( IGNORABLE_KEY_CODES.indexOf(charCode) !== -1 ) {
            if (charCode === 46 ) {
                this.delete = true;
            }
        } else if (
            !this.isSeparator(key, event.currentTarget.selectionStart)
            && !this.isValidKey(key, event.currentTarget.selectionStart)
            && !isPasteKey
            && !isCopyKey
            && !isCutKey
            && !isUndoKey)
        {
            event.preventDefault();
        }
        /* eslint-enable */
    }

    onInput(event) {
        if (event.inputType !== 'deleteContentBackward') {
            this.processValue(event.target.value, event.target);
        }
    }

    onPaste(event) {
        //TODO: strip extra characters
        const value = (event.clipboardData || window.clipboardData).getData('Text');
        const newValue = this.stripNonValid( value );
        this.processValue(newValue, event.srcElement, event.type);
        event.preventDefault();
    }

    processValue(value, el) {
        let previousValue = el.value;
        let newValue = previousValue;
        let caretPos = el.selectionStart;
        const separatorDeleted = this.isSeparatorIndex(caretPos + 1) && this.delete;
        caretPos += this.isSeparatorIndex(caretPos) ? 1 : 0;
        let newCaretPos;

        if (!separatorDeleted) {
            newValue = this.stripSeparators(value);
            newValue = this.insertSeparators(newValue);
            newValue = this.sliceString(newValue);
        }

        if (newValue !== previousValue) {
            el.value = newValue;
            this.vnode.context.$emit('mask:update', el);

            newCaretPos = caretPos + newValue.length - previousValue.length;
            if (newCaretPos < newValue.length) {
                el.setSelectionRange(caretPos, caretPos);
            } else {
                el.setSelectionRange(newCaretPos, newCaretPos);
            }
        }
    }
}