<template>
    <div
        class="lni-c-ship-grant-projects__wrapper"
    >
        <div class="lni-c-ship-grant-projects__filter">
            <lni-radios
                :id="`${id}_project-status-option`"
                class="lni-u-full-width lni-u-mb2"
                labelText="Project Status"
                :hasInlineMessages="false"
                :isColumn="true"
                :changeAction="`${id}/filter`"
            />
            <lni-checkbox-list
                :id="`${id}_project-type-option`"
                class="lni-u-full-width lni-u-mb2"
                labelText="Project Type"
                :hasInlineMessages="false"
                :isColumn="true"
                :changeAction="`${id}/filter`"
            />
            <lni-select
                :id="`${id}_industry-select`"
                class="lni-u-full-width lni-u-mb3"
                labelText="For what industry?"
                :changeAction="`${id}/filter`"
            />
            <lni-input-text
                :id="`${id}_keyword-text`"
                class="lni-u-full-width lni-u-mb3"
                :dense="true"
                :filled="false"
                labelText="Filter by keyword (optional)"
                :inputAction="`${id}/filter`"
                :value="filterKeywords"
            >
                <template #suffix>
                    <button
                        aria-label="clear"
                        class="lni-u-button-reset"
                        @click="clearInput"
                    >
                        <span
                            aria-hidden="true"
                            class="lni-c-icon lnicon--cancel-circle"
                        />
                    </button>
                </template>
            </lni-input-text>
        </div>
        <div
            v-if="hasInitialized && totalItems !== 0"
            class="lni-c-ship-grant-projects__results"
        >
            <lni-ship-grant-projects-card
                v-for="project in pageOfItems"
                :id="`${id}-card_${project.projectId}`"
                :key="project.projectId"
                v-bind="project"
            />
            <div v-if="totalItems === 0">
                <p>
                    <span>We could not find any items</span>
                    <span v-if="filterKeywords.length > 0">containing "{{ filterKeywords }}"</span>
                    <span>with:</span>
                </p>
                <ul class="lni-u-pl3 lni-u-mt0">
                    <li
                        v-for="status in projectStatuses.filter(status => status.value === filterStatus)"
                        :key="status.value"
                    >
                        Status: {{ status.text }}
                    </li>
                    <li>
                        Project Type:
                        <span
                            v-for="project in projectTypes.filter(obj => filterType.includes(obj.value)).map(obj => obj.text)"
                            :key="project.value"
                        >{{ project }}    </span>
                    </li>
                    <li
                        v-for="industry in industries.filter(industry => industry.value === filterIndustry)"
                        :key="industry.value"
                    >
                        Industry: {{ industry.text }}
                    </li>
                </ul>
                <div class="lni-u-mt2">
                    <p>
                        Try searching using some of the following tips:
                        <ul class="lni-u-pl3">
                            <li>Make sure all words are spelled correctly</li>
                            <li>Try different keywords</li>
                            <li>Try more general keywords</li>
                            <li>Try fewer keywords</li>
                        </ul>
                    </p>
                </div>
            </div>
            <div v-else>
                <lni-pager
                    :id="`${id}_pager`"
                    class="lni-u-pt3"
                    :collectionId="id"
                    :page="page"
                    :totalItems="totalItems"
                    :pageSize="pageSize"
                    scrollTarget=".lni-c-ship-grant-projects-filter"
                    :showPager="true"
                    selectLabel="Items per Page"
                    @change-page="onChangePage"
                    @change-page-size="onChangePageSize"
                />
            </div>
        </div>
    </div>
</template>

<script>
import LniShipGrantProjectsCard from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-ship-grant-projects/lni-ship-grant-projects-card.vue';

export default {
    name: 'LniShipGrantProjects',
    components: {
        LniShipGrantProjectsCard,
    },
    computed: {
        totalItems() {
            return this.filteredProjects.length || 0;
        },
        pageOfItems() {
            let startItem = (this.page - 1) * this.pageSize;
            return [...this.filteredProjects].slice(startItem, startItem + this.pageSize) || [];
        },
    },
    mounted() {
        this.dispatchEvent(`${this.id}/initialize`);
    },
    methods: {
        clearInput() {
            this.$store.commit('setAttribute', {
                id: `${this.id}_keyword-text`,
                attribute: 'value',
                value: '',
            });

            this.dispatchEvent(`${this.id}/filter`);
        },
        onChangePage(data) {
            this.page = data.page;
        },
        onChangePageSize(data) {
            this.pageSize = data.pageSize;
        },
    },
};
</script>
<style lang="scss">
@import 'lni-ship-grant-projects';
</style>