export const fields = (text, counties) => [
    {
        id: 'contactTitle',
        labelText: text['contactTitle.labelText'],
        required: true,
        options: [
            {
                text: text['contactTitle.options.Ms.'],
                value: 'Ms.',
            },
            {
                text: text['contactTitle.options.Mr.'],
                value: 'Mr.',
            },
            {
                text: text['contactTitle.options.Mrs.'],
                value: 'Mrs.',
            },
            {
                text: text['contactTitle.options.Mx.'],
                value: 'Mx.',
            },
            {
                text: text['contactTitle.options.other'],
                value: 'other',
            },
        ],
    },
    {
        id: 'contactName',
        labelText: text['contactName.labelText'],
    },
    {
        id: 'contactPhone_phone',
        labelText: text['label.phone'],
    },
    {
        id: 'contactPhoneType',
        labelText: text['contactPhoneType.labelText'],
        options: [
            {
                text: text['contactPhoneType.options.true'],
                value: 'Yes',
            },
            {
                text: text['contactPhoneType.options.false'],
                value: 'No',
            },
        ],
    },
    {
        id: 'contactTextPermission',
        labelText: text['contactTextPermission.labelText'],
        helperText: text['contactTextPermission.helperText'],
        options: [
            {
                text: text['contactTextPermission.options.true'],
                value: 'Yes',
            },
            {
                text: text['contactTextPermission.options.false'],
                value: 'No',
            },
        ],
    },
    {
        id: 'contactEmail',
        labelText: text['contactEmail.labelText'],
        hide: true,
    },
    {
        id: 'contactEmail_input-text',
        parentId: 'contactEmail',
        labelText: text['contactEmail.labelText'],
    },
    {
        id: 'contactAddress',
        labelText: text['contactAddress.labelText'],
    },
    {
        id: 'contactAddress_address1',
        labelText: `${text['contactAddress.labelText']} 1`,
        parentId: 'contactAddress',
    },
    {
        id: 'contactAddress_address2',
        labelText: `${text['contactAddress.labelText']} 2`,
        parentId: 'contactAddress',
    },
    {
        id: 'contactAddress_city',
        labelText: text['label.city'],
        parentId: 'contactAddress',
    },
    {
        id: 'contactAddress_state',
        labelText: text['label.state'],
        value: 'WA',
        parentId: 'contactAddress',
    },
    {
        id: 'contactAddress_zip',
        labelText: text['label.zip'],
        parentId: 'contactAddress',
    },
    {
        id: 'contactCounty',
        labelText: text['contactCounty.labelText'],
        options: counties,
    },
    {
        id: 'contactPreference',
        labelText: text['contactPreference.labelText'],
        required: true,
        hide: true,
    },
    {
        id: 'contactPreference_input-text',
        parentId: 'contactPreference',
    },
    {
        id: 'employerName',
        labelText: text['employerName.labelText'],
        required: 'Yes',
    },
    {
        id: 'employerPhone',
        labelText: text['employerPhone.labelText'],
    },
    {
        id: 'employerPhone_phone',
        labelText: text['employerPhone.labelText'],
        parentId: 'employerPhone',
    },
    {
        id: 'employerPhone_ext',
        labelText: text['label.ext'],
        parentId: 'employerPhone',
    },
    {
        id: 'employerAddress',
        labelText: text['employerAddress.labelText'],
    },
    {
        id: 'employerAddress_address1',
        labelText: `${text['contactAddress.labelText']} 1`,
    },
    {
        id: 'employerAddress_address2',
        labelText: `${text['contactAddress.labelText']} 2`,
    },
    {
        id: 'employerAddress_city',
        labelText: text['label.city'],
        parentId: 'employerAddress',
    },
    {
        id: 'employerAddress_state',
        labelText: text['label.state'],
        value: 'WA',
        parentId: 'employerAddress',
    },
    {
        id: 'employerAddress_zip',
        labelText: text['label.zip'],
        parentId: 'employerAddress',
    },
    {
        id: 'jobTitle',
        labelText: text['jobTitle.labelText'],
    },
    {
        id: 'dateHired',
        labelText: text['dateHired.labelText'],
    },
    {
        id: 'isTerminated',
        labelText: text['isTerminated.labelText'],
        options: [
            {
                text: text['isTerminated.options.true'],
                value: 'Yes',
            },
            {
                text: text['isTerminated.options.false'],
                value: 'No',
            },
        ],
    },
    {
        id: 'dateTerminated',
        labelText: text['dateTerminated.labelText'],
    },
    {
        id: 'isResigned',
        labelText: text['isResigned.labelText'],
        options: [
            {
                text: text['isResigned.options.true'],
                value: 'Yes',
            },
            {
                text: text['isResigned.options.false'],
                value: 'No',
            },
        ],
    },
    {
        id: 'dateResigned',
        labelText: text['dateResigned.labelText'],
    },
    {
        id: 'retaliationDate',
        labelText: text['retaliationDate.labelText'],
    },
    {
        id: 'retaliationDescription',
        labelText: text['retaliationDescription.labelText'],
        helperText: text['retaliationDescription.helperText'],
        required: true,
    },
    {
        id: 'retaliationDescription_input-text',
        parentId: 'retaliationDescription',
    },
    {
        id: 'retaliationReason',
        labelText: text['retaliationReason.labelText'],
        hide: true,
    },
    {
        id: 'retaliationReason_input-text',
        parentId: 'retaliationReason',
    },
    {
        id: 'hasContactedOtherAgency',
        labelText: text['hasContactedOtherAgency.labelText'],
        options: [
            {
                text: text['hasContactedOtherAgency.options.true'],
                value: 'Yes',
            },
            {
                text: text['hasContactedOtherAgency.options.false'],
                value: 'No',
            },
        ],
    },
    {
        id: 'otherAgency',
        labelText: text['otherAgency.labelText'],
    },
    {
        id: 'otherAgencyContactDate',
        labelText: text['otherAgencyContactDate.labelText'],
    },
    {
        id: 'otherAgencyResults',
        labelText: text['otherAgencyResults.labelText'],
        hide: true,
    },
    {
        id: 'otherAgencyResults_input-text',
        parentId: 'otherAgencyResults',
    },
    {
        id: 'attestation',
        labelText: text['attestation.labelText'],
        required: true,
        hide: true,
    },
];