import filter from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-find-rules/actions/filterRules.js';
import showCoreRulesExplainer from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-find-rules/actions/showCoreRulesExplainer.js';
import search from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-find-rules/actions/search.js';
import setParam from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-find-rules/actions/setParam.js';
import {
    isNumberSearch,
    rankByNumber,
    rankByText,
    sortRankedResults,
} from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-find-rules/actions/searchUtils.js';

const coreChapter = 800;

export default () => ({
    state: {
        rules: null,
        chapters: null,
        isLoaded: false,
        filter: 'all',
        filters: {
            all: rules => rules,
            chapters: rules => rules.filter(rule => !rule.section),
            core: rules => rules.filter(rule => rule.chapter === coreChapter),
        },
        filterOptions: [
            {
                text: 'All Safety Rules',
                value: 'all',
            },
            {
                text: 'Chapters only',
                value: 'chapters',
            },
            {
                text: 'Core Rules',
                value: 'core',
            },
        ],
        currentPage: 1,
        itemsPerPage: 10,
        showCoreRulesExplainer: false,
        query: '',
        index: 'Safety_Rules',
    },
    getters: {
        filteredRules: state => {
            if ( !state.rules ) {
                return [];
            }
            return state.filters[state.filter](state.rules) || [];
        },
        searchedAndFilteredRules: (state, getters) => {
            const minChars = 2;
            if (!state.query || state.query.length < minChars ) {
                return getters.filteredRules;
            }

            let ranked;
            if (isNumberSearch(state.query)) {
                ranked = rankByNumber(getters.filteredRules);
            } else {
                ranked = rankByText(state.query, getters.filteredRules);
            }

            return sortRankedResults(ranked);
        },
        resultCount: (state, getters) => getters.searchedAndFilteredRules.length,
        urlParams: (state, getters) => {
            const params = getters.params;
            return Object.keys(params)
                //encode
                .map(key => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
        },
        params: state => ({
            query: state.query,
            filter: state.filter,
            page: state.currentPage,
            index: state.index,
        }),
    },
    actions: {
        filter,
        showCoreRulesExplainer,
        search,
        setParam,
    },
    mutations: {
        SET_FILTER( state, payload ) {
            state.filter = payload;
        },
        SET_PAGE( state, payload ) {
            state.currentPage = payload;
        },
        SET_CORE_RULES_EXPLAINER_VISIBILITY( state, payload ) {
            state.showCoreRulesExplainer = payload;
        },
        SET_SEARCH_STRING( state, payload ) {
            state.query = payload;
        },
    },
});