// adapted from https://stackoverflow.com/a/469362

const events = [
    'input',
    'keydown',
    'keyup',
    'mousedown',
    'mouseup',
    'select',
    'contextmenu',
    'drop',
];

export default {
    bind(el, binding) {
        const regEx = binding.value;
        const input = el.nodeName === 'INPUT' ? el : el.querySelector('input');
        input.filterHandler = () => {
            if (regEx.test(input.value)) {
                //passes test
                input.oldValue = input.value;
                input.oldSelectionStart = input.selectionStart;
                input.oldSelectionEnd = input.selectionEnd;
            } else if (Object.prototype.hasOwnProperty.call(input, 'oldValue')) {
                //doesn't pass, so keep oldvalue
                input.value = input.oldValue;
                input.setSelectionRange(input.oldSelectionStart, input.oldSelectionEnd);
            } else {
                // no oldvalue
                input.value = '';
            }
        };
        events.forEach(event => {
            input.addEventListener(event, input.filterHandler);
        });
    },
    unbind(el) {
        const input = el.nodeName === 'INPUT' ? el : el.querySelector('input');
        events.forEach(event => {
            input.removeEventListener(event, input.filterHandler);
        });
    },
};