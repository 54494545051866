<template>
    <div class="lni-c-rtw-form">
        <div class="lni-u-mt3">
            <h3 class="lni-u-heading--3">
                Prospective Employee Information
            </h3>
            <div class="--row">
                <lni-input-text
                    :id="`${id}_first-name`"
                    class="lni-u-mr1"
                    labelText="First Name"
                    :filled="true"
                    :required="true"
                    :labelBefore="true"
                    :fullWidth="true"
                    :hasInlineMessages="false"
                    :value="firstName"
                />
                <lni-input-text
                    :id="`${id}_last-name`"
                    labelText="Last Name"
                    :filled="true"
                    :required="true"
                    :labelBefore="true"
                    :fullWidth="true"
                    :hasInlineMessages="false"
                    :value="lastName"
                />
            </div>
            <lni-input-address
                :id="`${id}_address`"
                class="lni-c-rtw-form__address"
                labelText="Mailing Address"
                :isFieldset="false"
                :filled="true"
                :labelBefore="true"
                :hasInlineMessages="false"
                :address1="address.address1"
                :address2="address.address2"
                :city="address.city"
                :state="address.state"
                :zip="address.zip"
            />
            <lni-input-text
                :id="`${id}_claim-number`"
                labelText="Claim Number"
                :pattern="'^[a-zA-Z0-9]+$'"
                :customWidth="7"
                :maxlength="7"
                :filled="true"
                :required="true"
                :labelBefore="true"
                :value="claimNumber"
            >
                <span slot="helperText">{{ helperText.claimNumber }}</span>
            </lni-input-text>
        </div>
        <div class="lni-c-rtw-form__job-offer-section lni-u-mt2">
            <h3 class="lni-u-heading--3">
                Job Offer Information
            </h3>
            <lni-input-text
                :id="`${id}_job-address`"
                labelText="Location Address"
                :filled="true"
                :required="true"
                :labelBefore="true"
                :fullWidth="true"
                :hasInlineMessages="false"
                :value="jobAddress"
            />
            <div class="--row">
                <lni-input-date
                    :id="`${id}_report-date`"
                    class="lni-c-rtw-form___report-date lni-u-pt1"
                    labelText="Start Date"
                    helperText=""
                    :isFieldset="false"
                    :filled="true"
                    :dense="false"
                    :required="true"
                    :labelBefore="true"
                    :hasInlineMessages="false"
                    :value="reportDate"
                    :minDate="today"
                />
                <div class="lni-c-rtw-form__report-time lni-u-full-width">
                    <div class="lni-u-flex">
                        <lni-input-text
                            :id="`${id}_report-time`"
                            class="lni-u-mt2"
                            :customWidth="6"
                            labelText="Start Time"
                            :pattern="'^(1[0-2]|0?[1-9]|2[0-3]):[0-5][0-9]$'"
                            :filled="true"
                            :required="true"
                            :labelBefore="true"
                            :hasInlineMessages="false"
                            :value="reportTime"
                        />
                        <lni-select
                            :id="`${id}_report-time-type`"
                            class="lni-u-ml1"
                            labelText="am/pm"
                            :labelBefore="true"
                            :options="[
                                {text: 'am', value: 'am'},
                                {text:'pm', value: 'pm'}
                            ]"
                            customWidth="2"
                            :required="true"
                            :value="reportTimeType"
                        />
                    </div>
                </div>
            </div>
            <div class="--row">
                <lni-input-text
                    :id="`${id}_scheduled-days`"
                    class="lni-u-mr1"
                    labelText="Scheduled Days"
                    :filled="true"
                    :fullWidth="true"
                    :required="true"
                    :labelBefore="true"
                    :value="scheduledDays"
                >
                    <span slot="helperText">{{ helperText.scheduledDays }}</span>
                </lni-input-text>
                <lni-input-text
                    :id="`${id}_scheduled-hours`"
                    class="lni-c-rtw-form__scheduled-hours"
                    labelText="Scheduled Hours"
                    :filled="true"
                    :fullWidth="true"
                    :required="true"
                    :labelBefore="true"
                    :value="scheduledHours"
                >
                    <span slot="helperText">{{ helperText.scheduledHours }}</span>
                </lni-input-text>
            </div>
            <div class="--row">
                <lni-input-text
                    :id="`${id}_pay-rate`"
                    class="--inline-msg-mt0 lni-u-mr2"
                    type="currency"
                    pattern="[0-9\.]+"
                    labelText="Pay Rate"
                    :filled="true"
                    :fullWidth="true"
                    :labelBefore="true"
                    :required="true"
                    :value="pay.rate"
                >
                    <span slot="prefix">
                        $
                    </span>
                    <span slot="helperText">{{ helperText.payRate }}</span>
                </lni-input-text>
                <div class="lni-c-rtw-form__pay-type lni-u-full-width">
                    <lni-radios
                        :id="`${id}_pay-type`"
                        labelText="Salary"
                        :required="true"
                        :options="[
                            {text: 'Monthly', value: 'monthly'},
                            {text: 'Hourly', value: 'per hour'}
                        ]"
                        :value="pay.type"
                        :hasInlineMessages="false"
                        :isColumn="false"
                    />
                </div>
            </div>
            <lni-radios
                :id="`${id}_position-offer`"
                class="lni-u-mt1"
                labelText="Is this a temporary position or permanent job offer?"
                :required="true"
                :options="positionOptions"
                :changeAction="`${id}/checkForMessage`"
                :value="positionOffer"
                :hasInlineMessages="false"
            />
            <lni-radios
                :id="`${id}_health-benefits`"
                class="lni-u-mt2"
                labelText="What are the health care benefits being offered?"
                :required="true"
                :options="[
                    {text: 'Health care benefits will resume at a level from the date of injury', value: 1},
                    {text: 'No health care benefits provided at the time of injury', value: -1},
                    {text: 'Unable to offer the same health care benefits', value: 0}
                ]"
                :changeAction="`${id}/checkForMessage`"
                :value="healthBenefits"
                :hasInlineMessages="false"
            />
            <div>
                <lni-notification
                    :id="`${id}_notification`"
                    type="info"
                >
                    <p v-if="displayMessage">
                        If health and welfare benefits are not continued this may not be considered a valid light duty offer.
                    </p>
                </lni-notification>
            </div>
        </div>
        <div class="lni-u-mt3">
            <h3 class="lni-u-heading--3">
                Employer Information
            </h3>
            <lni-input-text
                :id="`${id}_company-name`"
                labelText="Company Name"
                :filled="true"
                :required="true"
                :labelBefore="true"
                :fullWidth="true"
                :hasInlineMessages="false"
                :value="companyName"
            />
            <div class="--row">
                <lni-input-text
                    :id="`${id}_supervisor-name`"
                    class="lni-c-rtw-form__sup-name lni-u-mr1"
                    labelText="Reporting Supervisor Name"
                    :filled="true"
                    :fullWidth="true"
                    :required="true"
                    :labelBefore="true"
                    :hasInlineMessages="false"
                    :value="supervisorName"
                />
                <lni-input-text
                    :id="`${id}_supervisor-phone`"
                    v-mask="{pattern: '###-###-####', separator: '-'}"
                    class="lni-c-rtw-form__sup-phone"
                    labelText="Reporting Supervisor Phone"
                    pattern="^[0-9]{3}-[0-9]{3}-[0-9]{4}$"
                    maxlength="12"
                    :filled="true"
                    :fullWidth="true"
                    :required="true"
                    :labelBefore="true"
                    :value="supervisorPhone"
                />
            </div>
            <lni-input-text
                :id="`${id}_contact-phone`"
                v-mask="{pattern: '###-###-####', separator: '-'}"
                class="--full-width-sm --inline-msg-mt0"
                labelText="Contact Phone"
                pattern="^[0-9]{3}-[0-9]{3}-[0-9]{4}$"
                maxlength="12"
                :filled="true"
                :required="true"
                :labelBefore="true"
                :value="contactPhone"
            >
                <span slot="helperText">{{ helperText.contactPhone }}</span>
            </lni-input-text>
        </div>
        <lni-button
            :id="`${id}_submit`"
            class="lni-u-block lni-u-mv3"
            text="Preview Letter"
            type="submit"
            :errorContainerId="`${id}_errors`"
            :clickAction="['validateForm', `${id}/submit`]"
        />
        <lni-error-container
            :id="`${id}_errors`"
            heading="Before we submit this form:"
            :errorsCollectedFrom="[
                `${id}_first-name`,
                `${id}_last-name`,
                `${id}_address`,
                `${id}_claim-number`,
                `${id}_pay-rate`,
                `${id}_pay-type`,
                `${id}_job-address`,
                `${id}_report-date`,
                `${id}_report-time`,
                `${id}_scheduled-days`,
                `${id}_scheduled-hours`,
                `${id}_position-offer`,
                `${id}_health-benefits`,
                `${id}_company-name`,
                `${id}_supervisor-name`,
                `${id}_supervisor-phone`,
                `${id}_contact-phone`]"
        />
    </div>
</template>

<script>
import mask from '../../../../../framework/one-lni.directives/source/mask';

export default {
    name: 'LniRtwPrintableForm',
    directives: {
        mask,
    },
    data() {
        return {
            helperText: {
                claimNumber: 'Ex: A123456',
                payRate: 'Ex: 40000 or 17.50',
                scheduledDays: 'Ex: Monday thru Friday',
                scheduledHours: 'Ex: 8 a.m. to 5 p.m.',
                contactPhone: 'Contact person for questions',
            },
        };
    },
    computed: {
        today() {
            const today = new Date();
            return `${today.getMonth() + 1}/${today.getDate()}/${today.getFullYear()}`;
        },
    },
    created() {
        if (history.state) {
            history.pushState(history.state, null, null); //Forward button
        }
    },
    mounted() {
        if (history.state) {
            this.$store.dispatch(`${this.id}/initialize`, history.state);
            this.$store.dispatch(`${this.id}/checkForMessage`);
        }

        this.addCustomMessages();
    },
    methods: {
        addCustomMessages() {
            // Removes 'Please fill out this field.' message on fields with helper text.
            // Keeps helper text on valueMissing and patternMismatch error instead except phone inputs.
            this.$store.commit(`${this.id}_claim-number/customizeValidationMessage`, {
                validationTest: 'valueMissing',
                global: state => `Please enter ${state.labelText}, it is required.`,
                inline: () => this.helperText.claimNumber,
            });

            this.$store.commit(`${this.id}_pay-rate/customizeValidationMessage`, {
                validationTest: 'valueMissing',
                global: state => `Please enter ${state.labelText}, it is required.`,
                inline: () => this.helperText.payRate,
            });
            this.$store.commit(`${this.id}_pay-rate/customizeValidationMessage`, {
                validationTest: 'patternMismatch',
                global: state => `Please use the correct format for ${state.labelText}.`,
                inline: () => this.helperText.payRate,
            });

            this.$store.commit(`${this.id}_scheduled-days/customizeValidationMessage`, {
                validationTest: 'valueMissing',
                global: state => `Please enter ${state.labelText}, it is required.`,
                inline: () => this.helperText.scheduledDays,
            });

            this.$store.commit(`${this.id}_scheduled-hours/customizeValidationMessage`, {
                validationTest: 'valueMissing',
                global: state => `Please enter ${state.labelText}, it is required.`,
                inline: () => this.helperText.scheduledHours,
            });

            this.$store.commit(`${this.id}_supervisor-phone/customizeValidationMessage`, {
                validationTest: 'valueMissing',
                global: state => `Please enter ${state.labelText}, it is required.`,
                inline: () => ' ',
            });
            this.$store.commit(`${this.id}_supervisor-phone/customizeValidationMessage`, {
                validationTest: 'patternMismatch',
                global: state => `Please enter the correct format for ${state.labelText}.`,
                inline: () => 'Format: XXX-XXX-XXXX',
            });

            this.$store.commit(`${this.id}_contact-phone/customizeValidationMessage`, {
                validationTest: 'valueMissing',
                global: state => `Please enter ${state.labelText}, it is required.`,
                inline: () => this.helperText.contactPhone,
            });
            this.$store.commit(`${this.id}_contact-phone/customizeValidationMessage`, {
                validationTest: 'patternMismatch',
                global: state => `Please enter the correct format for ${state.labelText}.`,
                inline: () => 'Format: XXX-XXX-XXXX',
            });
        },
    },
};
</script>

<style lang="scss">
@import '../../../../../framework/one-lni/source/styles/settings';
@import 'lni-rtw-printable-form';
</style>