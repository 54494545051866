import resetForm from '@gov.wa.lni/application.public-website/source/components/lni-site-feedback/actions/resetForm.js';
import encodeDotcmsCookie from
    '@gov.wa.lni/application.public-website-custom-components/source/utilities/storage/encodeDotcmsCookie.js';

export default (context, payload) => {
    if (!context.rootGetters['site-feedback-error-container/isValid']) {
        return;
    }

    const moduleId = context.getters.moduleId;
    const moduleState = context.rootState[moduleId];
    const commentType = context.rootState[`${moduleId}_comment-type`].value;
    const customerType = context.rootState[`${moduleId}_customer-type`].value;
    const name = context.rootState[`${moduleId}_name`].value;
    const email = context.rootState[`${moduleId}_email`].value;
    const phone = context.rootState[`${moduleId}_phone_phone`].value;
    const ext = context.rootState[`${moduleId}_phone_ext`].value;
    const comment = context.rootState[`${moduleId}_comment`].value;

    let phoneNumber = phone;
    if (ext.length > 0) {
        phoneNumber += ` x${ext}`;
    }
    const requestBody = {
        Type: commentType,
        CustomerType: customerType,
        FullName: name,
        Email: email,
        Phone: phoneNumber,
        Comments: comment,
    };
    payload.oneLni.storage.cookie.set(
        moduleState.cookieName,
        encodeDotcmsCookie(requestBody),
    );

    const submitUrl = `${payload.oneLni.environment.current.apiLocation2}/site-feedback-submit`;
    context.commit('setAttribute', {
        id: moduleId,
        attribute: 'showModalSpinner',
        value: true,
    }, {
        root: true,
    });

    fetch(submitUrl).then(response => response.json())
        .then(data => {
            payload.oneLni.storage.cookie.remove(moduleState.cookieName);
            context.commit('setAttribute', {
                id: moduleId,
                attribute: 'showModalSpinner',
                value: false,
            }, {
                root: true,
            });

            context.commit('setAttribute', {
                id: `${moduleId}_result-messages`,
                attribute: 'items',
                value: [
                    data.results,
                ],
            }, {
                root: true,
            });

            if (data.results.level !== 'Error') {
                context.commit('setAttribute', {
                    id: moduleId,
                    attribute: 'hasBeenSubmitted',
                    value: true,
                }, {
                    root: true,
                });

                resetForm(context);
            }
        });
};