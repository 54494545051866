import isNullOrEmptyString from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/actions/helpers/isNullOrEmptyString';

export default (value:string|number, defaultValueIfEmpty:number|null) => {

    if (isNullOrEmptyString(value.toString())) {
        return defaultValueIfEmpty;
    }

    return parseInt(value.toString());

};