const SITE_FEEDBACK_CONTENT_ENDPOINT = '/site-feedback/';


export default (resultContainerId, context, oneLni) => {
    const language = oneLni.getLanguage();
    const langIds = {
        en: '1',
        es: '2',
    };
    const languageParam = language === 'es' ? `&language_id=${langIds[language]}` : '';
    const apiLocation = oneLni.environment.current.apiLocation2;
    const endpoint = `${apiLocation}${SITE_FEEDBACK_CONTENT_ENDPOINT}?location=${location}${languageParam}`;

    return fetch(endpoint, {
        mode: 'cors',
    })
        .then(response => {
            if (response.ok) {
                return response.json();
            }

            const error = {
                level: 'Error',
                message: 'There was an error setting up this page.',
            };

            context.commit('setAttribute', {
                id: resultContainerId,
                attribute: 'items',
                value: [
                    error,
                ],
            }, {
                root: true,
            });

            return null;
        });
};