import setAttribute from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-simbic-calculator/actions/setAttribute.js';

export default (context, field) => {
    setAttribute(
        'value',
        context,   {
            sourceId: field,
        },
        '',
    );
};