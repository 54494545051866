export default (context, payload) => {
    const moduleId = context.getters.moduleId;
    const moduleState = context.rootState[moduleId];

    context.commit('SET_VALUES', payload);

    context.commit('setAttribute', {
        id: `${moduleId}_first-name`,
        attribute: 'value',
        value: moduleState.firstName,
    }, {
        root: true,
    });

    context.commit('setAttribute', {
        id: `${moduleId}_last-name`,
        attribute: 'value',
        value: moduleState.lastName,
    }, {
        root: true,
    });

    Object.keys(moduleState.address).forEach(key => {
        context.commit('setAttribute', {
            id: `${moduleId}_address`,
            attribute: key,
            value: moduleState.address[key],
        }, {
            root: true,
        });

        context.commit('setAttribute', {
            id: `${moduleId}_address_address1`,
            attribute: 'value',
            value: moduleState.address.address1,
        }, {
            root: true,
        });

        context.commit('setAttribute', {
            id: `${moduleId}_address_address2`,
            attribute: 'value',
            value: moduleState.address.address2,
        }, {
            root: true,
        });

        context.commit('setAttribute', {
            id: `${moduleId}_address_city`,
            attribute: 'value',
            value: moduleState.address.city,
        }, {
            root: true,
        });

        context.commit('setAttribute', {
            id: `${moduleId}_address_state`,
            attribute: 'value',
            value: moduleState.address.state,
        }, {
            root: true,
        });

        context.commit('setAttribute', {
            id: `${moduleId}_address_zip`,
            attribute: 'value',
            value: moduleState.address.zip,
        }, {
            root: true,
        });
    });

    context.commit('setAttribute', {
        id: `${moduleId}_claim-number`,
        attribute: 'value',
        value: moduleState.claimNumber,
    }, {
        root: true,
    });

    context.commit('setAttribute', {
        id: `${moduleId}_job-address`,
        attribute: 'value',
        value: moduleState.jobAddress,
    }, {
        root: true,
    });

    context.commit('setAttribute', {
        id: `${moduleId}_report-date`,
        attribute: 'value',
        value: moduleState.reportDate,
    }, {
        root: true,
    });

    context.commit('setAttribute', {
        id: `${moduleId}_report-time`,
        attribute: 'value',
        value: moduleState.reportTime,
    }, {
        root: true,
    });

    context.commit('setAttribute', {
        id: `${moduleId}_report-time-type`,
        attribute: 'value',
        value: moduleState.reportTimeType,
    }, {
        root: true,
    });

    context.commit('setAttribute', {
        id: `${moduleId}_scheduled-days`,
        attribute: 'value',
        value: moduleState.scheduledDays,
    }, {
        root: true,
    });

    context.commit('setAttribute', {
        id: `${moduleId}_scheduled-hours`,
        attribute: 'value',
        value: moduleState.scheduledHours,
    }, {
        root: true,
    });

    context.commit('setAttribute', {
        id: `${moduleId}_pay-rate`,
        attribute: 'value',
        value: moduleState.pay.rate,
    }, {
        root: true,
    });

    context.commit('setAttribute', {
        id: `${moduleId}_pay-type`,
        attribute: 'value',
        value: moduleState.pay.type,
    }, {
        root: true,
    });

    context.commit('setAttribute', {
        id: `${moduleId}_company-name`,
        attribute: 'value',
        value: moduleState.companyName,
    }, {
        root: true,
    });

    context.commit('setAttribute', {
        id: `${moduleId}_supervisor-name`,
        attribute: 'value',
        value: moduleState.supervisorName,
    }, {
        root: true,
    });

    context.commit('setAttribute', {
        id: `${moduleId}_supervisor-phone`,
        attribute: 'value',
        value: moduleState.supervisorPhone,
    }, {
        root: true,
    });

    context.commit('setAttribute', {
        id: `${moduleId}_contact-phone`,
        attribute: 'value',
        value: moduleState.contactPhone,
    }, {
        root: true,
    });

    context.commit('setAttribute', {
        id: `${moduleId}_position-offer`,
        attribute: 'value',
        value: moduleState.positionOffer,
    }, {
        root: true,
    });

    context.commit('setAttribute', {
        id: `${moduleId}_health-benefits`,
        attribute: 'value',
        value: moduleState.healthBenefits,
    }, {
        root: true,
    });
};