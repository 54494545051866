import {
    fetchResults,
} from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-forms-and-pubs-search/api/forms-and-pubs.api.js';

const buildQueryString = context => {
    const params = context.state.params;
    let urlQuery = '?';
    Object.keys(params).forEach(key => {
        if ( params[key] && params[key] !== ' ' ) {
            let value = params[key].toString();
            urlQuery += `${key}=${encodeURIComponent(value)}&`;
        }
    });
    return urlQuery;
};
export default (context, payload) => {
    if (!payload.customData) {
        payload.customData = {};
    }

    if ( !payload.customData.keepPage ) {
        // Note - if this is called by a dispatchEvent payload is predetermined and won't have
        // keepPage so page will reset to 1
        context.commit('SET_PARAM', {
            pg: 1,
        });
    }


    const start = new Date();
    let resultsPromise = fetchResults(buildQueryString(context), payload.oneLni);

    context.dispatch('toggleLoadingState', true);

    Promise.resolve(resultsPromise).then( data => {
        const finish = new Date();
        context.commit('SET_RESULTS', data.results);
        context.commit('SET_COUNT', data.count );
        context.commit('SET_TOTAL_PAGES', data.totalPages);
        context.commit('SET_LANGUAGE_OPTIONS', data.languageOptions);
        context.commit('SET_CATEGORY_OPTIONS', data.categoryOptions);
        context.dispatch('updateControlOptions');
        context.commit('SET_SEARCHED_ONCE', true);
        context.dispatch('updatePagination');
        context.dispatch('setUrlParams');

        if ( finish - start > context.state.minLoadingTime ) {
            context.commit('SET_LOADING_STATE', false);
        } else {
            context.dispatch('toggleLoadingState', false);
        }
    });
};