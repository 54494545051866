import actions from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-self-insured-employers/actions';

export default () => ({
    state: {
        items: [],
        pageSize: 10,
        totalItems: 0,
        page: 1,
    },
    actions: {
        ...actions,
    },
});