import actions from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-news-and-data-center/actions';

export default () => ({
    state: {
        archiveRange: 3,
        currentYear: {
            text: new Date().getFullYear(),
            value: '0',
        },
        previousYear: {},
        archiveYear: '',
        selectedYearValue: '0',
        newsReleaseYears: [],
        newsReleases: [],
        latestRelease: {},
        displayedReleases: [],
        showPageSpinner: true,
        showListSpinner: true,
        publicRecords: '',
        factsAndFigures: '',
        communications: '',
        text: {
            latestNewsHeading: 'Latest News',
            readMoreLink: 'Read More',
            findMediaContact: 'Find a Media Contact by Subject or Program',
            newsReleases: 'News Releases',
            contactForArchived: 'Contact us for news releases from {archiveYear} or earlier',
            checkBackLaterOr: 'Check back later or',
            clickHereForLastYear: 'click here for last year\'s news releases',
            stayConnected: 'Stay Connected',
            subscribe: 'Subscribe to news releases',
            selectYear: 'Select a year',
            mediaContacts: 'Media Contacts',
        },
    },
    actions: {
        ...actions,
    },
    mutations: {
        SET_ATTRIBUTE(state, payload) {
            state[payload.attribute] = payload.value;
        },
    },
});