import helpers from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-ship-grant-projects/actions/helpers';

export default context => {
    const moduleId = context.getters.moduleId;
    const module = context.rootState[moduleId];

    context.commit('setAttribute', {
        id: `${moduleId}`,
        attribute: 'filterStatus',
        value: context.rootState[`${moduleId}_project-status-option`].value,
    }, {
        root: true,
    });
    context.commit('setAttribute', {
        id: `${moduleId}`,
        attribute: 'filterType',
        value: context.rootState[`${moduleId}_project-type-option`].value,
    }, {
        root: true,
    });
    context.commit('setAttribute', {
        id: `${moduleId}`,
        attribute: 'filterIndustry',
        value: context.rootState[`${moduleId}_industry-select`].value,
    }, {
        root: true,
    });
    context.commit('setAttribute', {
        id: `${moduleId}`,
        attribute: 'filterKeywords',
        value: context.rootState[`${moduleId}_keyword-text`].value,
    }, {
        root: true,
    });

    let result = [];
    if (module.filterKeywords && (/[0-9]{4}[a-zA-Z]{2}[0-9]{5}/).test(module.filterKeywords)) {
        module.projects.forEach( project => {
            if (project.projectId.toUpperCase() === module.filterKeywords.toUpperCase().trim()) {
                result.push(project);
            }
        });
    } else {
        for (let i = 0; i < module.projects.length; i++) {
            let project = module.projects[i];
            let projectStatusResult = module.filterStatus === 'all' ? true
                : project.projectStatus === module.filterStatus;

            let projectTypeResult = false;
            for (let j = 0; j < module.filterType.length; j++) {
                if (module.filterType[j] === project.projectType) {
                    projectTypeResult = true;
                }
            }

            let industryResult = module.filterIndustry === 'allIndustries' ? true
                : project.industries.includes(module.filterIndustry);

            /*
             * Filter by keyword optional field will return exact string matches to the project
             * name, grantee, summary and tags (if entered) applied to the content item.
             * Keyword search is subtractive, and must match the exact string, not additive when 2
             * or more words are entered (e.g. "carpenters and framers" will not search for either
             * carpenters or framers but in a combination).
             *
             * "Stop words" standard list will be used to eliminate any words like "and" in the
             * search string
             */
            let keywordsResult = true;
            if (module.filterKeywords.length > 0) {
                const keywords = helpers.getCleanString(context, module.filterKeywords);
                const titleResult = helpers.getCleanString(context, project.displayedProjectTitle)
                    .includes(keywords);
                const granteesResult = helpers.getCleanString(context, project.grantees)
                    .includes(keywords);
                const summaryResult = helpers.getCleanString(context, project.projectSummary)
                    .includes(keywords);

                let tagsResult = false;
                for (let i = 0; i < project.frontendKeywordTags.length; i++) {
                    if (keywords === project.frontendKeywordTags[i]) {
                        tagsResult = true;
                    }
                }

                keywordsResult = titleResult || granteesResult || summaryResult || tagsResult;
            }

            if (projectStatusResult === true && projectTypeResult === true
                && industryResult === true
                && keywordsResult === true) {
                result.push(project);
            }
        }
    }

    context.commit('SET_FILTERED_PROJECTS', result);

    context.commit('setAttribute', {
        id: `${moduleId}`,
        attribute: 'totalItems',
        value: result.length,
    }, {
        root: true,
    });

    context.commit('setAttribute', {
        id: `${moduleId}`,
        attribute: 'page',
        value: 1,
    }, {
        root: true,
    });
    setTimeout(() => {
        context.commit('setAttribute', {
            id: `${moduleId}_pager`,
            attribute: 'totalItems',
            value: result.length,
        }, {
            root: true,
        });

        context.commit('setAttribute', {
            id: `${moduleId}_pager`,
            attribute: 'page',
            value: 1,
        }, {
            root: true,
        });

    });
};