export const setSender = function setSender(context, payload) {
    if (payload.businessName) {
        context.commit('SET_SENDER', {
            rootObj: context.rootState[`${context.getters.moduleId}_business-name`],
            value: payload.businessName,
        });

    }

    if (payload.contactPerson) {
        context.commit('SET_SENDER', {
            rootObj: context.rootState[`${context.getters.moduleId}_contact-person`],
            value: payload.contactPerson,
        });
    }

    if (payload.contactEmail) {
        context.commit('SET_SENDER', {
            rootObj: context.rootState[`${context.getters.moduleId}_contact-email_input-text`],
            value: payload.contactEmail,
        });
    }

    if (payload.address1) {
        context.commit('SET_SENDER', {
            rootObj: context.rootState[`${context.getters.moduleId}_address_address1`],
            value: payload.address1,
        });
    }

    if (payload.address2) {
        context.commit('SET_SENDER', {
            rootObj: context.rootState[`${context.getters.moduleId}_address_address2`],
            value: payload.address2,
        });
    }

    if (payload.city) {
        context.commit('SET_SENDER', {
            rootObj: context.rootState[`${context.getters.moduleId}_address_city`],
            value: payload.city,
        });
    }

    if (payload.state) {
        context.commit('SET_SENDER', {
            rootObj: context.rootState[`${context.getters.moduleId}_address_state`],
            value: payload.state,
        });
    }

    if (payload.zip) {
        context.commit('SET_SENDER', {
            rootObj: context.rootState[`${context.getters.moduleId}_address_zip`],
            value: payload.zip,
        });
    }

    if (payload.comments) {
        context.commit('SET_SENDER', {
            rootObj: context.rootState[`${context.getters.moduleId}_comments_text-input`],
            value: payload.comments,
        });
    }
};