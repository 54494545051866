export default (context, payload) => {
    const error = {
        level: 'Error',
        message: 'There was an error calculating your result.',
    };

    context.commit('setAttribute', {
        id: `${payload.sourceId}_informational-messages`,
        attribute: 'items',
        value: [
            error,
        ],
    }, {
        root: true,
    });
};