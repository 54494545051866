import fileForm from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-quickfile-login/actions/fileForm.js';
import updateValue from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-quickfile-login/actions/updateValue.js';

export default () => ({
    state: {
        action: '',
        accountNumber: '',
        ubi: '',
    },
    actions: {
        fileForm,
        updateValue,
    },
});