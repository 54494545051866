<template>
    <div>
        <lni-sync-url-params
            :id="`${id}_urlSync`"
        />

        <div class="lni-u-full-width lni-u-mt2">
            <lni-input-search
                :id="`${id}_input`"
                ref="searchQueryInput"
                name="query"
                placeholder="Enter keyword(s) or choose a filter below"
                :query="query"
                :clickAction="`${id}/updateQuery`"
                :searchAction="`${id}/updateQuery`"
                :clearAction="`${id}/updateQuery`"
                :emptySearchAction="`${id}/updateQuery`"
            />
        </div>

        <div class="lni-u-flex-wrap lni-u-flex lni-u-justify-between lni-u-mv3 lni-c-search-sharp__button-bar">
            <button
                type="button"
                class="lni-c-button --text lni-u-text--all-caps lni-u-type--bold lni-u-mt2"
                :aria-expanded="`${advancedIsOpen}`"
                :aria-controls="`${id}_collapse`"
                @click="advancedIsOpen = !advancedIsOpen"
            >
                Filter Results
                <span
                    aria-hidden="true"
                    :class="`lnicon--caret--${advancedIsOpen? 'up':'down'}`"
                />
            </button>
            <lni-button
                :id="`${id}_button-clear`"
                class="lni-u-mt2"
                text="Clear Filters"
                :clickAction="[`${id}/clearFilters`]"
                type="text"
            />
        </div>

        <div
            :id="`${id}_collapse`"
            v-slide="advancedIsOpen"
        >
            <div class="lni-u-flex-wrap lni-u-flex lni-u-full-width lni-u-justify-between">
                <div class="lni-c-search-sharp__select">
                    <lni-select
                        :id="`${id}_hazards`"
                        :dense="true"
                        :inline="false"
                        labelText="Hazards"
                        placeholder="Any Hazard"
                        :options="filterOptions.hazards"
                        :changeAction="`${id}/search`"
                    />
                </div>
                <div class="lni-c-search-sharp__select">
                    <lni-select
                        :id="`${id}_industries`"
                        :dense="true"
                        :inline="false"
                        labelText="Industries"
                        placeholder="Any Industry"
                        :options="filterOptions.industries"
                        :changeAction="`${id}/search`"
                    />
                </div>
                <div class="lni-c-search-sharp__select">
                    <lni-select
                        :id="`${id}_projects`"
                        :dense="true"
                        :inline="false"
                        labelText="Project"
                        placeholder="Any Project"
                        :options="filterOptions.projects"
                        :changeAction="`${id}/search`"
                    />
                </div>
            </div>
            <div class="lni-u-flex-wrap lni-u-flex lni-u-full-width">
                <div class="lni-c-search-sharp__multi-select--year">
                    <lni-multiselect
                        :id="`${id}_publicationYear`"
                        class="lni-u-mt3"
                        :dense="true"
                        labelText="Year"
                        placeholder="Any Year"
                        :options="filterOptions.publicationYear"
                        :changeAction="`${id}/search`"
                    />
                </div>
                <div class="lni-c-search-sharp__multi-select">
                    <lni-multiselect
                        :id="`${id}_publicationType`"
                        class="lni-u-mt3"
                        :dense="true"
                        labelText="Publication Type"
                        placeholder="Any Publication"
                        :options="filterOptions.publicationType"
                        :changeAction="`${id}/search`"
                    />
                </div>
            </div>
        </div>

        <!-- Results -->
        <div
            ref="resultsContainer"
        >
            <div class="lni-u-full-width lni-u-mt3 lni-u-bb">
                {{ loading ? 'searching for' : filteredResults.length }} results
            </div>
            <div v-show="filteredResults.length">
                <lni-search-sharp-card
                    v-for="card in currentPage"
                    :id="`sharp-pub_${card.publication}`"
                    :key="`sharp-pub_${card.publication}${card.title}${card.publicationType.value}`"
                    :card="card"
                    class="lni-u-mt3"
                />
            </div>
            <lni-spinner
                v-show="loading"
                :id="`${id}_spinner`"
            />
            <div v-show="!loading && !filteredResults.length">
                <p class="lni-u-text--center lni-u-mv4 lni-u-mhauto">
                    We couldn't find any publications with this criteria. Adjust the search criteria and try again.
                </p>
            </div>
            <lni-pager
                v-show="filteredResults.length && filteredResults.length > pageSize"
                :id="`${id}_pager`"
                :page="page"
                :pageSize="pageSize"
                :showPager="false"
                :totalItems="filteredResults.length"
                @change-page="onChangePage"
            />
        </div>
    </div>
</template>

<script>
import slide from '@gov.wa.lni/framework.one-lni.directives/source/slide.js';
import LniSearchSharpCard from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-search-sharp/lni-search-sharp-card.vue';

export default {
    name: 'LniSearchSharp',
    directives: {
        slide,
    },
    components: {
        LniSearchSharpCard,
    },
    data() {
        return {
            primaryMultiSelect: null,
            advancedIsOpen: true,
            previousSearch: null,
            pageSize: 10,
        };
    },
    computed: {
        filteredResults() {
            return this.$store.getters[`${this.id}/filteredResults`];
        },
        currentPage() {
            const start = (this.page - 1) * this.pageSize;
            if (this.filteredResults.length) {
                const results = [...this.filteredResults];
                return results.slice(start, start + this.pageSize);
            }
            return [];
        },
    },
    mounted() {
        this.initialize();
    },
    methods: {
        clear() {
            this.$refs.searchQueryInput.value = '';
        },
        initialize() {
            if ( this.getUrlParams() ) {
                this.$store.dispatch(`${this.id}/updateStateFromUrl`, this.getUrlParams());
            }
            this.getData();
        },
        getUrlParams() {
            const index = window.location.href.indexOf('?');
            if (index > -1 ) {
                return window.location.href.substring(index);
            }
            return '';
        },
        onChangePage(data) {
            this.page = data.page;

            this.$store.dispatch(`${this.id}/updatePage`, {
                sourceId: this.id,
                pageNumber: this.page,
                totalItems: this.results.length,
            });
            this.$scrollTo(this.$refs.resultsContainer);

        },

        //Get results
        async getData() {
            await this.$store.dispatch(`${this.id}/search`, this.$oneLni);
        },
    },
}; </script>
<style lang="scss">
@import 'lni-search-sharp';
</style>