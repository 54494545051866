export default (context, payload) => {
    if (!payload.targetId) {
        return;
    }

    const idPrefixLength = `${payload.sourceId}_`.length;
    const filterId = payload.targetId.substring(idPrefixLength); // trainingFrequency
    const value = context.rootState[payload.targetId].value; // annual training

    if (Object.keys(context.state.filterValues).includes(filterId)) {
        context.commit('UPDATE_FILTER_VALUES', {
            filter: filterId,
            value,
        });
    }
};