export const getFees = function(oneLni, alternateApiLocation) {
    const apiLocation = alternateApiLocation
        ? alternateApiLocation : oneLni.environment.current.apiLocation2;
    const endpoint = `${apiLocation}/elevator-fees?calculator=valuation`;

    return fetch(endpoint)
        .then(res => res.json())
        .then(body => body.data)
        .then(data => ({
            alterationAddFee: data.alterationAddFee,
            alterationBaseFee: data.alterationBaseFee,
            installationAddFee: data.installationAddFee,
            installationBaseFee: data.installationBaseFee,
            marker1: data.marker1,
            marker1Fee: data.marker1Fee,
            marker2: data.marker2,
            marker2Fee: data.marker2Fee,
            marker3: data.marker3,
            marker3Fee: data.marker3Fee,
            marker4: data.marker4,
            marker4Fee: data.marker4Fee,
            marker5: data.marker5,
            marker5Fee: data.marker5Fee,
            marker6: data.marker6,
        }));
};