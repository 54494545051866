export default string => {
    const dotcmsDecoded = string
        .replace(/(%28)/g, '(')
        .replace(/(%29)/g, ')')
        .replace(/(%2D)/g, '-') // Hyphen-Minus
        .replace(/(%E2%80%94)/g, '—') // Em-dash
        .replace(/(%5F)/g, '_') // Underscore
        .replace(/(%2E)/g, '.')
        .replace(/%7E/g, '~')
        .replace(/%2A/g, '*');
    return JSON.parse(decodeURIComponent(dotcmsDecoded));
};