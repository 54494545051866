import LniCarousel from '@gov.wa.lni/application.public-website/source/components/lni-carousel';
import LniHeroTile from '@gov.wa.lni/application.public-website/source/components/lni-hero-tile';
import LniLanguageAlert from '@gov.wa.lni/application.public-website/source/components/lni-language-alert';
import LniLogin from '@gov.wa.lni/application.public-website/source/components/lni-login';
import LniQuestion from '@gov.wa.lni/application.public-website/source/components/lni-question';
import LniSafetyTopicList from '@gov.wa.lni/application.public-website/source/components/lni-safety-topic-list';
import LniSiteFeedback from '@gov.wa.lni/application.public-website/source/components/lni-site-feedback';
import LniTout from '@gov.wa.lni/application.public-website/source/components/lni-tout';
import LniYoutube from '@gov.wa.lni/application.public-website/source/components/lni-youtube';
import LniLinkList from '@gov.wa.lni/application.public-website/source/components/lni-link-list';

export default [
    LniCarousel,
    LniHeroTile,
    LniLanguageAlert,
    LniLogin,
    LniQuestion,
    LniSafetyTopicList,
    LniSiteFeedback,
    LniTout,
    LniYoutube,
    LniLinkList,
];