<template>
    <g fill="none">
        <g clip-path="url(#a)">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 3h24v18H0V3Z"
                fill="#2E2F38"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M21.75 11.922c-.03-3.146-.15-4.517-.894-5.486-.134-.209-.388-.328-.596-.477-.73-.537-4.173-.73-8.167-.73-3.994 0-7.6.193-8.315.73-.224.149-.477.268-.626.477-.73.969-.835 2.34-.88 5.486.045 3.145.15 4.517.88 5.485.149.224.402.328.626.477.715.552 4.321.73 8.315.76 3.994-.03 7.436-.208 8.167-.76.208-.149.462-.253.596-.477.745-.968.864-2.34.894-5.485Z"
                fill="#fff"
            />
            <path
                d="M21.75 11.922c-.03-3.146-.15-4.517-.894-5.486-.134-.209-.388-.328-.596-.477-.73-.537-4.173-.73-8.167-.73-3.994 0-7.6.193-8.315.73-.224.149-.477.268-.626.477-.73.969-.835 2.34-.88 5.486.045 3.145.15 4.517.88 5.485.149.224.402.328.626.477.715.552 4.321.73 8.315.76 3.994-.03 7.436-.208 8.167-.76.208-.149.462-.253.596-.477.745-.968.864-2.34.894-5.485Z"
                stroke="#2E2F38"
                stroke-width=".151"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.567 11.107c-.202-2.461-1.51-3.911-3.852-3.911-2.138 0-3.82 1.929-3.82 5.029 0 3.113 1.533 5.055 4.065 5.055 2.022 0 3.437-1.49 3.66-3.965H9.205c-.096.919-.479 1.624-1.287 1.624-1.277 0-1.511-1.264-1.511-2.608 0-1.85.532-2.794 1.415-2.794.787 0 1.287.572 1.362 1.57h2.383ZM19.909 11.107c-.203-2.461-1.511-3.911-3.852-3.911-2.138 0-3.82 1.929-3.82 5.029 0 3.113 1.533 5.055 4.065 5.055 2.021 0 3.436-1.49 3.66-3.965h-2.415c-.096.919-.48 1.624-1.288 1.624-1.277 0-1.51-1.264-1.51-2.608 0-1.85.531-2.794 1.414-2.794.788 0 1.288.572 1.362 1.57h2.384Z"
                fill="#2E2F38"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path
                    fill="#fff"
                    transform="translate(0 3)"
                    d="M0 0h24v18H0z"
                />
            </clipPath>
        </defs>
    </g>
</template>
<script>
export default {
    name: 'CcInfo',
};
</script>