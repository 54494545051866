export default () => ({
    state: {
        filterOptions: [],
        events: [],
        minYears: 2,
        maxYears: 1,
    },
    getters: {
        isMinDate: () => this.$store.state[`${this.id}_calendar`].isMinDate,
        isMaxDate: () => this.$store.state[`${this.id}_calendar`].isMaxDate,
    },
});