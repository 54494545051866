import getTableState from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-vendor-services-lookup/actions/getTableState.js';
let previousFilterState = {};

export default (context, payload) => {
    const moduleId = context.getters.moduleId;
    const state = context.rootState[`${moduleId}_state`].value;
    const city = context.rootState[`${moduleId}_city`].value;
    const county = context.rootState[`${moduleId}_county`].value;
    const service = context.rootState[`${moduleId}_service`].value;

    const currentFilterState = {
        state,
        city,
        county,
        specialty: service,
    };

    let filterStateChanged = false;
    if (!previousFilterState
        || previousFilterState.city !== currentFilterState.city
        || previousFilterState.county !== currentFilterState.county
        || previousFilterState.state !== currentFilterState.state
        || previousFilterState.specialty !== currentFilterState.specialty) {
        filterStateChanged = true;
    }

    previousFilterState = currentFilterState;

    const tableState = getTableState(context, `${moduleId}_table`);

    if (filterStateChanged) {
        tableState.PageNumber = 1;
    }

    const requestBody = {
        ...currentFilterState,
        ...tableState,
    };

    const currentEnvironment = payload.oneLni.environment.current;
    const searchUrl = `${currentEnvironment.publicApiLocation}/VendorLookup/Search`;
    fetch(searchUrl, {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
        },
        body: JSON.stringify(requestBody),
    })
        .then(response => {
            if (response.ok) {
                return response.json();
            }

            context.commit('setAttribute', {
                id: `${moduleId}_table`,
                attribute: 'loadState',
                value: 'error',
            }, {
                root: true,
            });

            return null;
        })
        .then(response => {
            context.commit('setAttribute', {
                id: `${moduleId}_table`,
                attribute: 'items',
                value: response.items,
            }, {
                root: true,
            });
            context.commit('setAttribute', {
                id: `${moduleId}_table`,
                attribute: 'totalItems',
                value: response.totalItems,
            }, {
                root: true,
            });

            if (filterStateChanged) {
                context.commit('setAttribute', {
                    id: `${moduleId}_table`,
                    attribute: 'page',
                    value: 1,
                }, {
                    root: true,
                });
            }
        });
};