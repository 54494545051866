export default (context, payload) => {
    const moduleId = context.getters.moduleId;
    const moduleState = context.rootState[moduleId];

    let searchState = {
        industry: parseInt(context.rootState[`${moduleId}_industry-select`].value),
        incidentType: parseInt(context.rootState[`${moduleId}_incident-type-select`].value),
    };

    if (context.rootState[`${moduleId}_display-select`].value === 'all') {
        const years = context.getters.summaryYears;
        searchState.startDate = `01/01/${years[years.length - 1].value}`;
        searchState.endDate = `12/31/${years[0].value}`;
    } else if (context.rootState[`${moduleId}_display-select`].value === 'default') {
        const endOfMonth = `${moduleState.currentMonth}/${context.getters.numDaysInMonth(moduleState.currentMonth)}/${moduleState.currentYear}`;
        const twelveMonthsAgo = `${moduleState.currentMonth}/01/${moduleState.currentYear - 1}`;
        searchState.startDate = twelveMonthsAgo;
        searchState.endDate = endOfMonth;
    } else {
        searchState.startDate = `01/01/${context.rootState[`${moduleId}_display-select`].value}`;
        searchState.endDate = `12/31/${context.rootState[`${moduleId}_display-select`].value}`;
    }
    context.commit('UPDATE_SEARCH_STATE', searchState);

    context.commit('setAttribute', {
        id: `${moduleId}_table`,
        attribute: 'page',
        value: 1,
    }, {
        root: true,
    });

    if (context.rootState[`${moduleId}_pager`]) {
        context.commit('setAttribute', {
            id: `${moduleId}_table_pager`,
            attribute: 'page',
            value: 1,
        }, {
            root: true,
        });
    }

    context.dispatch('setTableData', {
        oneLni: payload.oneLni,
    });
};