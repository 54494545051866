/* elevator installation fees */
/* eslint-disable no-magic-numbers */

const setAttribute = (attribute, context, payload, value) => {
    context.commit('setAttribute', {
        id: payload.sourceId,
        attribute,
        value,
    }, {
        root: true,
    });
};

const currencyConversion = val => {
    const floatDig = 2;

    return '$' + parseFloat(val).toFixed(floatDig)
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,');
};

export const resetCalc = (context, payload) => {
    setAttribute('totalFee', context, payload, 0.00);
    setAttribute('valuation', context, payload, 0.00);
    setAttribute('value', context, {
        sourceId: `${context.getters.moduleId}_input`,
    }, '');
    setAttribute('value', context, {
        sourceId: `${context.getters.moduleId}_result`,
    }, '');
};

export const calculateTotalFee = (context, payload) => {
    const moduleId = context.getters.moduleId;

    if (!context.rootGetters[`${moduleId}_error-container/isValid`]) {
        setAttribute('totalFee', context, payload, 0.00);
        setAttribute('valuation', context, payload, 0.00);
        setAttribute('value', context, {
            sourceId: `${context.getters.moduleId}_result`,
        }, '');
        return;
    }

    let input = context.rootState[`${moduleId}_input`];
    let result = parseFloat(input.value);
    let totalFee = parseFloat(input.value);
    const thousands = 1000.00;
    switch (true) {
        case result > 0 && result <= context.state.marker1:
            totalFee = context.state.marker1Fee;
            break;
        case result <= context.state.marker2:
            totalFee =  context.state.marker2Fee;
            break;
        case result <= context.state.marker3:
            totalFee = context.state.marker3Fee;
            break;
        case result <= context.state.marker4:
            totalFee = context.state.marker4Fee;
            break;
        case result <= context.state.marker5:
            totalFee = context.state.marker5Fee;
            break;
        case result <= context.state.marker6:
            totalFee = context.state.baseFee + context.state.addFee;
            break;
        case result > context.state.marker6:
            totalFee = context.state.baseFee
                + ((Math.ceil((result - context.state.marker6) / thousands) * context.state.addFee)
                + context.state.addFee);
            break;
        default:
            totalFee = 0;
            break;
    }

    setAttribute('totalFee', context, payload, currencyConversion(totalFee));
    setAttribute('valuation', context, payload, input.value);
    setAttribute('value', context, {
        sourceId: `${context.getters.moduleId}_result`,
    }, currencyConversion(totalFee));
};