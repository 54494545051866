<template>
    <div
        :class="['lni-u-mv2 lni-language-alert lni-u-pa2', {'lni-u-border-radius--small lni-u-bg--white lni-c-box--content lni-u-shadow--sm':contentType=='content'}]"
        role="alert"
    >
        <div class="lni-u-flex">
            <div class="lni-language-alert__icon">
                <span
                    aria-hidden="true"
                    class="lnicon--earth lni-u-type--lg"
                />
            </div>
            <div class="lni-u-ml2 lni-u-full-width">
                <div class="lni-u-flex lni-u-justify-between lni-language-alert__header lni-u-flex-wrap">
                    <span class="lni-u-heading--4">{{ title }}</span>
                    <a
                        :href="englishContentLink.route"
                        @click.prevent="onEnglishContentClick"
                    >{{ englishContentLink.label }} &rarr;</a>
                </div>
                <p class="lni-language-alert__content">
                    {{ message }}
                </p>
                <a :href="resourceLink.route">{{ resourceLink.label }}</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LniLanguageAlert',
    methods: {
        onEnglishContentClick() {
            this.$oneLni.setLanguage('en');
            window.location.href = this.englishContentLink.route;
        },
    },
};
</script>

<style lang="scss">
@import '@gov.wa.lni/application.public-website/source/components/lni-language-alert/lni-language-alert.scss';
</style>