
import setAttribute from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-lep-calculator/actions/setAttribute.js';

export default context => {
    const moduleId = context.getters.moduleId;
    setAttribute('preparedBy', context, moduleId, context.rootState[`${moduleId}_prepared-by`].value);
    setAttribute('worker', context, moduleId, context.rootState[`${moduleId}_worker`].value);
    setAttribute('claimNumber', context, moduleId, context.rootState[`${moduleId}_claim-number`].value);
    setAttribute('fromDate', context, moduleId, context.rootState[`${moduleId}_from-date`].value);
    setAttribute('toDate', context, moduleId, context.rootState[`${moduleId}_to-date`].value);
    setAttribute('wagesAtDoi', context, moduleId, context.rootState[`${moduleId}_wages-at-doi`].value);
    setAttribute('currentWages', context, moduleId, context.rootState[`${moduleId}_current-wages`].value);
    setAttribute('timeLossRate', context, moduleId, context.rootState[`${moduleId}_time-loss-rate`].value);
};