export default (context, payload) => {
    const utils = payload.oneLni.storeUtils;
    const params = {};
    params.query = utils.getAttribute(
        context,
        'query',
        'query');

    params.ruleTopic = utils.getValue(
        context,
        'ruleTopic');

    params.ruleStatus = utils.getValue(
        context,
        'ruleStatus');

    context.dispatch('urlSync/setParams', params, {
        root: true,
    });
};