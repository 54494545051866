export const lookup = function lookup(context, payload) {
    const moduleId = context.getters.moduleId;
    const moduleState = context.rootState[moduleId];

    /* If user clicks suggestion from auto-suggestion list then target has value
     * Otherwise source has value */
    let title = context.rootState[payload.sourceId].value;
    if (!title) {
        title = context.rootState[payload.targetId].value;
    }

    if (title) {
        const selectedOption = moduleState.options.find(
            opt => opt.text.toLowerCase() === title.toLowerCase(),
        );
        if (selectedOption) {
            window.location.href = `${moduleState.urlPath}${selectedOption.value}`;
        } else {
            context.commit('setAttribute', {
                id: moduleId,
                attribute: 'valid',
                value: false,
            }, {
                root: true,
            });
        }
    } else {
        context.commit('setAttribute', {
            id: moduleId,
            attribute: 'valid',
            value: false,
        }, {
            root: true,
        });
    }
};