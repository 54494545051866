const removeList = [
    '_PrePrinted',
    '_Email',
];

export default (context, payload) => {
    let inputId = payload.targetId;
    removeList.forEach(item => {
        if (inputId.includes(item)) {
            inputId = inputId.replace(item, '');
        }
    });

    const prefixLength = payload.sourceId.length;
    const modelId = inputId.substring(prefixLength + 1);
    const value = context.rootState[inputId].value;
    context.commit('UPDATE_FORM_ITEM', {
        id: modelId,
        value,
    });
    context.dispatch('persist', payload);
};