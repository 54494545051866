import actions from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-elevator-opening/actions';

export default () => ({
    state: {
        pricePer: 0,
        minOpenings: 0,
        openingsFee: '',
        baseFee: 0,
        openingsFixed: 0,
        cable: true,
        otherFeesLink: '',
    },
    getters: {
        isCable(state) {
            return state.cable;
        },
    },
    actions: {
        ...actions,
    },
});