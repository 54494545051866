export default function( context, payload ) {
    const sortString = context.rootState[payload.targetId].value;

    context.commit('SET_SORT', sortString);
    context.commit('SET_PAGE', 1);
    context.dispatch('setParam', {
        param: 'page',
        value: 1,
    });
    context.dispatch('setParam', {
        param: 'sort',
        value: sortString,
    });
}