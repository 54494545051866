import actions from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-order-self-insurer-roa-form/actions';
import decodeDotcmsCookie from '@gov.wa.lni/application.public-website-custom-components/source/utilities/storage/decodeDotcmsCookie.js';

import {
    formFields,
} from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-order-self-insurer-roa-form/formModel.js';

export default () => ({
    state: {
        showSif2Spinner: false,
        formData: formFields,
        sendEmailEndpoint: '',
        senderEmail: '',
        quantityOptions: '',
        trackedErrors: [],
        hasExistingData: false,
    },
    actions: {
        ...actions,
    },
    mutations: {
        INITIALIZE(state, payload) {
            const cookie
                = payload.oneLni.storage.cookie.get('order-self-insurer-roa-form-request');
            if (cookie && typeof decodeDotcmsCookie(cookie) === 'object'  ) {
                const data = decodeDotcmsCookie(cookie);
                state.formData = data.formData;
                state.hasExistingData = true;
            }
        },
        UPDATE_FORM_ITEM(state, payload) {
            const itemIndex = state.formData.findIndex(item => item.id === payload.id);
            const newItem = {
                ...state.formData[itemIndex],
                ...payload,
            };
            state.formData.splice(itemIndex, 1, newItem);
        },
        ADD_TRACKED_ERRORS(state, payload) {
            for (let i = 0; i < payload.length; i++) {
                if (state.trackedErrors.indexOf(payload[i]) === -1) {
                    state.trackedErrors.push(payload[i]);
                }
            }
        },
        REMOVE_TRACKED_ERRORS(state, payload) {
            for (let i = 0; i < payload.length; i++) {
                let index = state.trackedErrors.indexOf(payload[i]);
                if (index > -1) {
                    state.trackedErrors.splice(index, 1);
                }
            }
        },
    },
});