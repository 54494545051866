const setAttribute = function(context, id, attribute, value) {
    context.commit('setAttribute', {
        id,
        attribute,
        value: Number(value),
    }, {
        root: true,
    });
};

export default (context, payload) => {
    const moduleId = context.getters.moduleId;
    const isCable = context.rootGetters[`${moduleId}/isCable`];
    if (isCable) {
        setAttribute(context, moduleId, 'baseFee', payload.baseCableFee);
    } else {
        setAttribute(context, moduleId, 'baseFee', payload.baseFee);
    }

    setAttribute(context, moduleId, 'pricePer', payload.openingPricePer);
    setAttribute(context, moduleId, 'minOpenings', payload.minimumOpenings);
};