import getApiData from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-search-sharp/api/sharp.api.js';

let haveSearched = false;
export default async (context, payload) => {
    const oneLni = payload.oneLni || payload;

    const isFrontEndSearch = payload.targetId && [
        `${context.getters.moduleId}_publicationYear`,
        `${context.getters.moduleId}_publicationType`].includes(payload.targetId);

    // let ui know we are loading
    context.dispatch('updateLoadingState', true);

    // update state
    context.dispatch('updateFilterValues', payload);

    if (context.state.lastSearchParams
        && context.getters.paramString === context.state.lastSearchParams) {

        // we already did this search
        context.dispatch('updateLoadingState', false);
        return;
    }


    //save the url search string for easy comparison to prevent duplicate searches
    context.commit('SAVE_LAST_SEARCH_PARAMS', context.getters.paramString);

    // use lni-sync-url-params to set client url
    context.dispatch(`${context.getters.moduleId}_urlSync/setParams`, context.getters.params, {
        root: true,
    });

    // we don't use api for publicationYear or publicationType
    if ( !isFrontEndSearch ) {
        const response = await getApiData(context.getters.paramString, oneLni);

        if (response) {

            context.commit('UPDATE_RESULTS', response.results);

            if (!haveSearched) {
                // set initial filter options to state
                Object.keys(response.filterOptions).forEach(key => {
                    context.commit('UPDATE_FILTER_OPTIONS', {
                        filter: key,
                        options: Object.values(response.filterOptions[key]),
                    });

                });
                haveSearched = true;
            }
        }
    }

    context.dispatch('updateFilterOptions', payload).then(() => {
        // set filter controls options and values
        context.dispatch('syncFilterControls');
    });

    // go back to page 1 and update pager
    // QUESTION: would this be better in the search action?
    context.dispatch(`updatePage`, {
        pageNumber: 1,
    });

    context.dispatch('updateLoadingState', false);

};