import * as calculate from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-elevator-valuation/actions/calculate.js';
import * as updateFees from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-elevator-valuation/actions/updateFees.js';

export default () => ({
    state: {
        totalFee: '',
        valuation: 0.00,
        installation: true,
        baseFee: 0,
        addFee: 0,
        otherFeesLink: '',
        marker1: 0,
        marker1Fee: 0,
        marker2: 0,
        marker2Fee: 0,
        marker3: 0,
        marker3Fee: 0,
        marker4: 0,
        marker4Fee: 0,
        marker5: 0,
        marker5Fee: 0,
        marker6: 0,
    },
    actions: {
        ...calculate,
        ...updateFees,
    },
    getters: {
        isInstallation(state) {
            return state.installation;
        },
    },
});