// We only collect county information for WA state requests
// If the address has any other state, we automatically set county to outOfState
export default (context, payload) => {
    const countyId = `${context.getters.moduleId}_county`;
    const selectedState = context.rootState[payload.targetId].value;
    if (selectedState !== 'WA') {
        context.commit('setAttribute', {
            id: countyId,
            attribute: 'value',
            value: 'outOfState',
        }, {
            root: true,
        });
        context.commit('setAttribute', {
            id: countyId,
            attribute: 'disabled',
            value: true,
        }, {
            root: true,
        });
    } else if (context.rootState[countyId].value === 'outOfState') {
        // reset and enable
        context.commit('setAttribute', {
            id: countyId,
            attribute: 'value',
            value: '',
        }, {
            root: true,
        });
        context.commit('setAttribute', {
            id: countyId,
            attribute: 'disabled',
            value: false,
        }, {
            root: true,
        });
    }
    // trigger update of store because all other updates are triggered by input or change
    context.dispatch('syncStore', {
        targetId: countyId,
        sourceId: payload.sourceId,
    });
};