import resetForm from '@gov.wa.lni/application.public-website/source/components/lni-site-feedback/actions/resetForm.js';

const handleError = (context, id) => {
    context.commit('setAttribute', {
        id,
        attribute: 'hasBeenSubmitted',
        value: false,
    }, {
        root: true,
    });

    context.commit('setAttribute', {
        id: `${id}_result-messages`,
        attribute: 'items',
        value: [
            {
                level: 'Error',
                message: 'There was an error submitting your feedback.',
            },
        ],
    }, {
        root: true,
    });
};

export default (context, payload) => {
    if (!context.rootGetters['site-feedback-error-container/isValid']) {
        return;
    }

    const moduleId = context.getters.moduleId;
    const commentType = context.rootState[`${moduleId}_comment-type`].value;
    const customerType = context.rootState[`${moduleId}_customer-type`].value;
    const name = context.rootState[`${moduleId}_name`].value;
    const email = context.rootState[`${moduleId}_email`].value;
    const phone = context.rootState[`${moduleId}_phone`].value;
    const comment = context.rootState[`${moduleId}_comment`].value;

    const requestBody = {
        Type: commentType,
        CustomerType: customerType,
        FullName: name,
        Email: email,
        Phone: phone,
        Comments: comment,
        Referer: window.document.referrer,
    };

    const currentEnvironment = payload.oneLni.environment.current;
    const submitUrl = `${currentEnvironment.publicApiLocation}/SiteFeedback/Submit`;
    context.commit('setAttribute', {
        id: moduleId,
        attribute: 'showModalSpinner',
        value: true,
    }, {
        root: true,
    });

    fetch(submitUrl, {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
        },
        body: JSON.stringify(requestBody),
    })
        .then(response => {
            context.commit('setAttribute', {
                id: moduleId,
                attribute: 'showModalSpinner',
                value: false,
            }, {
                root: true,
            });

            if (response.ok) {
                context.commit('setAttribute', {
                    id: `${moduleId}_result-messages`,
                    attribute: 'items',
                    value: [
                        {
                            level: 'Information',
                            message: 'Thank you for your feedback.',
                        },
                    ],
                }, {
                    root: true,
                });

                context.commit('setAttribute', {
                    id: moduleId,
                    attribute: 'hasBeenSubmitted',
                    value: true,
                }, {
                    root: true,
                });

                resetForm(context);
            } else {
                handleError(context, moduleId);
            }

            return null;
        })
        .catch(() => {
            handleError(context, moduleId);
        });
};