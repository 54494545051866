export default (context, payload) => {
    const moduleId = context.getters.moduleId;
    const moduleState = context.rootState[moduleId];

    const years = [moduleState.currentYear];
    for (let i = 1; i < moduleState.archiveRange; i++) {
        years.push({
            text: (moduleState.currentYear.text - i).toString(),
            value: i,
        });
    }
    context.commit('SET_ATTRIBUTE', {
        attribute: 'newsReleaseYears',
        value: years,
    });
    context.commit('setAttribute', {
        id: 'news-release-year',
        attribute: 'options',
        value: moduleState.newsReleaseYears,
    }, {
        root: true,
    });

    context.commit('SET_ATTRIBUTE', {
        attribute: 'archiveYear',
        value: (moduleState.currentYear.text - moduleState.archiveRange).toString(),
    });

    context.dispatch('changeYear', {
        oneLni: payload.oneLni,
    });

    context.commit('SET_ATTRIBUTE', {
        attribute: 'showPageSpinner',
        value: false,
    });
};