export default (context, tableId) => {
    const pageNumber = context.rootState[tableId].page;
    const pageSize = context.rootState[tableId].pageSize;
    const sort = context.rootState[tableId].sort;
    const sortField = Object.keys(sort)[0];
    const sortDirection = sort[sortField];
    return {
        PageNumber: pageNumber,
        PageSize: pageSize,
        SortField: sortField,
        SortDirection: sortDirection,
    };
};