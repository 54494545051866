import actions from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-search-sharp/actions';

const paramStringToObject = string => {
    const pairs = string.split('&');
    const params = {};
    if (!string || !pairs.length ) {
        return null;
    }
    pairs.forEach(pair => {
        const [key, value] = pair.split('=');
        params[key] = decodeURIComponent(value.replace(/\+/g, '%20'));
    });
    return params;
};

export default () => ({
    state: {
        page: 1,
        loading: false,
        results: [],
        query: '',
        filterOptions: {
            hazards: [],
            industries: [],
            projects: [],
            publicationType: [],
            publicationYear: [],
        },
        filterValues: {
            hazards: '',
            industries: '',
            projects: '',
            publicationYear: [],
            publicationType: [],
        },
        lastSearch: {},
        lastSearchParams: '',
        minLoadingTime: 600,

    },
    actions: {
        ...actions,
    },
    mutations: {
        UPDATE_RESULTS: (state, payload) => {
            state.results = [...payload];
        },
        UPDATE_FILTER_OPTIONS: (state, payload) => {
            state.filterOptions = {
                ...state.filterOptions,
                [payload.filter]: payload.options,
            };
        },
        UPDATE_FILTER_VALUES: (state, payload) => {
            state.filterValues = {
                ...state.filterValues,
                [payload.filter]: payload.value,
            };
        },
        UPDATE_SEARCH_PARAMS: (state, paramString) => {
            const paramObject = paramStringToObject(paramString);
            Object.entries(paramObject).forEach(([key, value]) => {
                if (Object.keys(state.filterValues).includes(key)) {
                    if (
                        ['publicationType', 'publicationYear'].includes(key)
                        && !Array.isArray(value)
                    ) {
                        // handle comma separated strings
                        state.filterValues[key] = value.split(',');
                    } else {
                        state.filterValues[key] = value;
                    }
                }
                if (key === 'query') {
                    state.query = value;
                }
                if (key === 'pg') {
                    state.page = value;
                }
            });
        },
        UPDATE_QUERY: (state, payload) => {
            state.query = payload;
        },
        SAVE_LAST_SEARCH_PARAMS: (state, payload) => {
            state.lastSearchParams = payload;
        },
        CLEAR_FILTERS: state => {
            state.filterValues =  {
                hazards: null,
                industries: null,
                projects: null,
                publicationYear: [],
                publicationType: [],
            };
        },
        UPDATE_LOADING_STATE: (state, isLoading) => {
            state.loading = isLoading;
        },
    },
    getters: {
        params: state => {
            const filters = state.filterValues;
            const activeFilters = Object.keys(filters).filter(key => {
                const val = filters[key];
                return val && val.length; //non empty string or array with length
            }).reduce((obj, key) => ({
                ...obj,
                [key]: filters[key],
            }), {});
            return {
                query: state.query,
                pg: state.page.toString(),
                index: 'SHARP',
                ...activeFilters,
            };
        },
        paramString: (state, getters) => {
            const params = getters.params;
            return Object.keys(params)
                .map(key => `${key}=${encodeURIComponent(params[key])}`).join('&');
        },
        filteredResults: state => {
            const typeFilters = state.filterValues.publicationType;
            const yearFilters = state.filterValues.publicationYear;
            const test = (filtered, val) => !filtered.length || filtered.includes(val);
            return [...state.results]
                .filter(result => test(yearFilters, result.publicationYear.value.toString()))
                .filter(result => test(typeFilters, result.publicationType.value));
        },
        isFiltering: state => !!Object.values(state.filterValues)
            .filter(val => val && val.length).length,
    },
});