const defaultStateValue = 'WA';
const defaultFilterValue = '0';

export default context => {
    const moduleId = context.getters.moduleId;

    context.commit('setAttribute', {
        id: `${moduleId}_state`,
        attribute: 'value',
        value: defaultStateValue,
    }, {
        root: true,
    });

    context.commit('setAttribute', {
        id: `${moduleId}_city`,
        attribute: 'value',
        value: defaultFilterValue,
    }, {
        root: true,
    });

    context.commit('setAttribute', {
        id: `${moduleId}_county`,
        attribute: 'value',
        value: defaultFilterValue,
    }, {
        root: true,
    });

    context.commit('setAttribute', {
        id: `${moduleId}_service`,
        attribute: 'value',
        value: defaultFilterValue,
    }, {
        root: true,
    });

};