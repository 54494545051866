<template>
    <div class="lni-u-pb3">
        <div v-if="filteredItems.length">
            <lni-rulemaking-card
                v-for="result in filteredItems"
                :id="result.id + '_card'"
                :key="result.id"
                class="lni-u-mt3 lni-u-mhauto"
                :card="result"></lni-rulemaking-card>
        </div>
        <div aria-live="polite">
            <div
                v-if="!filteredItems.length"
                class="lni-u-text--center lni-u-mv4 lni-c-box--content lni-u-mhauto">
                <p>
                    We couldn't find any results for -
                    <span class="lni-u-type--bold">{{ query }}</span>
                    <span v-if="filterTopic !== 'All'"> in <span class="lni-u-type--bold">{{ filterTopic }}</span></span>
                    <span v-if="filterStatus !== 'All'"> with a status of <span class="lni-u-type--bold">{{ filterStatus }}</span></span>
                    - Try searching using some of the following tips:
                </p>
                <ul class="lni-u-mhauto lni-u-inline-block lni-u-ph3 lni-u-text--left lni-u-mt3">
                    <li>Make sure all words are spelled correctly</li>
                    <li>Try different keywords</li>
                    <li>Try more general keywords</li>
                    <li>Try fewer keywords</li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'lni-rulemaking-results',
    computed: {
        filterStatus() {
            return this.$store.state.ruleStatus.value;
        },
        filterTopic() {
            return this.$store.state.ruleTopic.value;
        },
        filteredItems() {
            let topic = this.filterTopic;
            let status = this.filterStatus;

            if (topic === 'All' && status === 'All') {
                return this.results;
            }

            //Emergency Rulemaking
            const today = new Date();
            let filteredResults = [];
            const expirationDateStr = 'EXPIRATION DATE';
            if (status === 'Emergency' || status === 'Active' || status === 'Inactive') {
                this.results.forEach(card => {
                    card.activities.forEach(activity => {
                        const isEmergency = (topic === 'All' || card.categories.includes(topic))
                            && activity.step === 'Emergency Rulemaking';
                        const isActive = activity.title.toUpperCase() === expirationDateStr
                            && new Date(activity.activityEndDate) > today;
                        const isInactive = activity.title.toUpperCase() === expirationDateStr
                            && new Date(activity.activityEndDate) <= today;
                        if ((status === 'Emergency' && isEmergency)
                            || (status === 'Active' && isEmergency && isActive)
                            || (status === 'Inactive' && isEmergency && isInactive)) {
                            filteredResults.push(card);
                        }
                    });
                });
            }
            if (filteredResults.length > 0) {
                const filteredSet = new Set(filteredResults);
                let filteredArr = [...filteredSet];
                filteredArr.sort((a, b) => { //Sort by expiration date (newest to oldest)
                    let sortResult = 0;
                    a.activities.forEach(aActivity => {
                        b.activities.forEach(bActivity => {
                            if (aActivity.title.toUpperCase() === expirationDateStr && bActivity.title.toUpperCase() === expirationDateStr) {
                                sortResult = new Date(bActivity.activityEndDate) - new Date(aActivity.activityEndDate);
                            }
                        });
                    });

                    return sortResult;
                });

                return filteredArr;
            }

            return this.results.filter(card =>
                (topic === 'All' || card.categories.includes(topic))
                && (status === 'All'  || card.status === status));
        },
    },
}; </script>