<!-- eslint-disable vue/no-v-html -->
<template>
    <div class="lni-c-news-and-data-center">
        <script
            type="application/javascript"
            async
            src="https://platform.twitter.com/widgets.js"
            charset="utf-8"
        />
        <div
            v-show="showPageSpinner"
            class="lni-u-flex lni-u-justify-center"
        >
            <lni-spinner
                id="page-spinner"
                :inline="false"
            />
        </div>
        <div
            v-show="!showPageSpinner"
            class="lni-o-type-context--content"
        >
            <div
                v-if="Object.keys(latestRelease).length > 0"
                class="lni-u-pb3"
            >
                <h2>{{ text.latestNewsHeading }}</h2>
                <div class="lni-c-news-and-data-center__latest lni-u-pv3">
                    <h3>{{ latestRelease.headline }}</h3>
                    <div class="lni-u-mt2">
                        <span class="lni-c-news-and-data-center__date">
                            {{ latestRelease.date }}</span>
                        <lni-html-element
                            :id="`${id}_latest-release__lead`"
                            class="lni-u-mt2 lni-u-mv3 lni-u-font-color--light lni-u-type--md"
                            :htmlString="latestRelease.lead"
                        />
                        <a
                            class="lni-c-news-and-data-center__link lni-u-type--extra-bold"
                            :href="`/news-events/article/${latestRelease.id}`"
                        >
                            {{ text.readMoreLink }}
                        </a>
                    </div>
                </div>
            </div>
            <div class="lni-u-pv3 lni-u-b--subtle lni-u-bt lni-u-bb">
                <h2>
                    {{ text.findMediaContact }}
                </h2>
                <div class="lni-u-pv1">
                    <a
                        class="lni-u-type--bold lni-u-mt2 "
                        href="/news-events/media-contacts"
                    >
                        {{ text.mediaContacts }}</a>
                </div>
            </div>
            <div class="lni-c-news-and-data-center__grid lni-u-mt3">
                <div class="lni-c-news-and-data-center__block">
                    <h2>
                        {{ text.newsReleases }}
                    </h2>
                    <ul class="lni-u-list-reset lni-u-mt2">
                        <li>
                            <a href="mailto:PublicAffairs@Lni.wa.gov">
                                {{ interpolate(text.contactForArchived, { archiveYear }) }}
                            </a>
                        </li>
                    </ul>
                    <lni-select
                        id="news-release-year"
                        :labelText="text.selectYear"
                        class="lni-u-mv3"
                        :value="selectedYearValue"
                        :options="newsReleaseYears"
                        :changeAction="`${id}/changeYear`"
                    />
                    <div
                        v-if="showListSpinner"
                        class="lni-u-flex lni-u-justify-center"
                    >
                        <lni-spinner
                            id="list-spinner"
                            :inline="true"
                        />
                    </div>
                    <div v-else>
                        <div v-if="displayedReleases.length > 0">
                            <div
                                v-for="(release, index) in displayedReleases"
                                :key="index"
                                class="lni-u-pb3"
                            >
                                <span class="lni-c-news-and-data-center__date">
                                    {{ release.date }}
                                </span>
                                <h3 class="lni-u-mt1">
                                    <a
                                        :href="`/news-events/article/${release.id}`"
                                        class="lni-c-news-and-data-center__article-headline"
                                    >
                                        {{ release.headline }}</a>
                                </h3>
                            </div>
                        </div>
                        <div v-else>
                            {{ text.checkBackLaterOr }}
                            <a
                                v-if="lastYearIsInOptions"
                                href="#"
                                @click="changeToPreviousYear()"
                            >{{ text.clickHereForLastYear }}</a>
                            <a
                                v-else
                                href="mailto:PublicAffairs@Lni.wa.gov"
                            >
                                {{ text.contactForArchived }}</a>.
                        </div>
                    </div>
                </div>
                <div class="lni-c-news-and-data-center__block">
                    <h2>{{ text.stayConnected }}</h2>
                    <div class="lni-u-mt2 lni-u-mb3">
                        <a
                            class="lni-c-news-and-data-center__link lni-u-type--extra-bold"
                            href="https://public.govdelivery.com/accounts/WADLI/subscriber/new"
                            target="_blank"
                        >
                            <span
                                aria-hidden="true"
                                class="lnicon--envelope lni-u-inline-block lni-u-pr1"
                            />
                            {{ text.subscribe }}
                        </a>
                    </div>
                    <span class="lni-c-news-and-data-center__twitter lni-u-mv3">
                        <a
                            class="twitter-timeline"
                            data-height="400"
                            href="https://twitter.com/lniwa?ref_src=twsrc%5Etfw"
                        />
                    </span>
                    <lni-html-element
                        v-if="publicRecords.length > 0"
                        :id="`${id}_public-records`"
                        classString="lni-u-mv3"
                        :htmlString="publicRecords"
                    />
                    <lni-html-element
                        v-if="factsAndFigures.length > 0"
                        :id="`${id}_facts-figures`"
                        :classString="['lni-u-mv3',
                                       {'lni-u-pt2': publicRecords.length > 0},
                        ]"
                        :htmlString="factsAndFigures"
                    />
                    <lni-html-element
                        v-if="communications.length > 0"
                        :id="`${id}_communications`"
                        :classString="['lni-u-mv3',
                                       {'lni-u-pt2': publicRecords.length > 0 || factsAndFigures.length > 0},
                        ]"
                        :htmlString="communications"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import interpolate from '@gov.wa.lni/framework.one-lni.core/source/lib/interpolate.js';

export default {
    name: 'LniNewsAndDataCenter',
    computed: {
        displayedReleases() {
            if (this.newsReleases.length > 0) {
                const selectedYear = this.newsReleaseYears[this.selectedYearValue].text;
                //Exclude the first news release if the selected year is the same as the current year
                //because first news release will be the latest news release displayed at the top of the page
                //api returns news releases in chronological order (date desc)
                return selectedYear === this.currentYear.text ? this.newsReleases.slice(1, this.newsReleases.length) : this.newsReleases;
            }

            return [];
        },
        selectedYear() {
            return this.newsReleaseYears[this.selectedYearValue].text;
        },
        previousYear() {
            const prev = (Number(this.selectedYear) - 1).toString();
            return this.newsReleaseYears.find(year => year.text === prev);
        },
        lastYearIsInOptions() {
            return this.previousYear ? this.newsReleaseYears.some(year => year.text === this.previousYear.text) : false;
        },
    },
    mounted() {
        this.dispatchEvent(`${this.id}/initialize`, {}, {
            root: true,
        });
    },
    methods: {
        changeToPreviousYear() {
            this.$store.commit('setAttribute', {
                id: 'news-release-year',
                attribute: 'value',
                value: (Number(this.selectedYearValue) + 1).toString(),
            }, {
                root: true,
            });

            this.dispatchEvent(`${this.id}/changeYear`);
        },
        interpolate,
    },
};
</script>

<style lang="scss">
@import 'lni-news-and-data-center';
</style>