import lookup from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-glossary/actions/lookup.js';

export default () => ({
    state: {
        // Example: [{ heading: 'A', data: [{ value: 'aka', text: 'Also Known As' }, ...] }]
        definitions: [],
        // id of the abbr that is highlighted by lookup
        highlightTargetId: '',
    },
    actions: {
        lookup,
    },
});