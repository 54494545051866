import addDays from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-simbic-calculator/actions/addDays.js';

const daysUntilAccrual = 61;
const monthlyAccrual = 30;
/* If interest is less than $1 then add $1 instead of the interest
 * (the minimum interest per month should be $1) */
const interestRate = 0.01;

export default calculationState => {
    let runningBalanceDue = calculationState.balanceDue;
    calculationState.accrualDate = addDays(
        new Date(calculationState.billingDate), daysUntilAccrual,
    );
    const today = new Date();
    today.setHours(0, 0, 0, 0); // For date comparisons
    let accrualDate = calculationState.accrualDate;

    const accrualDates = [accrualDate];
    while (accrualDate <= today) {
        accrualDate = addDays(accrualDate, monthlyAccrual);
        if (accrualDate <= today) {
            accrualDates.push(accrualDate);
        }
    }

    let lastPaymentDate = calculationState.billingDate;
    const assignedAccrualDates = [];
    calculationState.payments.forEach(payment => {
        payment.interests = [];
        accrualDates.forEach(accrualDate => {
            // Comparing dates as dates partially not working
            if (accrualDate > lastPaymentDate
                && (accrualDate < payment.date
                || accrualDate.toString() === payment.date.toString())) {
                payment.interests.push({
                    date: accrualDate,
                    amount: 0,
                    balance: 0,
                });
                assignedAccrualDates.push(accrualDate);
            }
        });
        lastPaymentDate = payment.date;
    });

    //Get remaining accrual dates not assigned to any payments
    const remainingAccrualDates = accrualDates.filter(x => !assignedAccrualDates.includes(x));

    calculationState.payments.forEach(payment => {
        let paymentProcessed = false;
        let interestAmount = 0;
        payment.interests.forEach(interest => {
            if (payment.date > interest.date) {
                interest.amount = (runningBalanceDue * interestRate) <= 1
                    ? 1 : runningBalanceDue * interestRate;
                interest.balance = runningBalanceDue + interest.amount;
                runningBalanceDue = interest.balance;
            } else { // Process payment first and then compound interest
                runningBalanceDue -= payment.amount;
                paymentProcessed = true;

                interest.amount = (runningBalanceDue * interestRate) <= 1
                    ? 1 : runningBalanceDue * interestRate;
                interest.balance = runningBalanceDue + interest.amount;
                runningBalanceDue = interest.balance;
                payment.balance = runningBalanceDue;
            }
            interestAmount += interest.amount;
        });

        if (!paymentProcessed) {
            runningBalanceDue -= payment.amount;
            payment.balance = runningBalanceDue;
        }

        payment.interest = interestAmount;
        payment.principal = payment.balance;
    });

    const finalPayment = calculationState.payments[calculationState.payments.length - 1];
    calculationState.remainingPrincipal = finalPayment.principal;
    if (finalPayment.principal < 0) {
        calculationState.remainingPrincipal = 0;
        calculationState.remainingBalance += finalPayment.principal;
    } else {
        calculationState.remainingPrincipal = finalPayment.principal;
    }

    calculationState.balanceOwed = finalPayment.balance;
    for (let i = 0; i < remainingAccrualDates.length; i++) {
        if (new Date() >= remainingAccrualDates[i]) {
            let interest = (calculationState.balanceOwed * interestRate) <= 1
                ? 1 : calculationState.balanceOwed * interestRate;
            calculationState.balanceOwed += interest;
        }
    }

    const intMultiplier = 100;
    calculationState.remainingPrincipal = Math.round(
        calculationState.remainingPrincipal * intMultiplier) / intMultiplier;
    calculationState.balanceOwed = Math.round(
        calculationState.balanceOwed * intMultiplier) / intMultiplier;
    return calculationState;
};