import lookupEnum from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-vendor-services-lookup/actions/lookupEnum.js';

export default (lookupName, state, tableId, context, oneLni) => {
    const intLookup = lookupEnum[lookupName];
    const currentEnvironment = oneLni.environment.current;
    const lookupUrl = `${currentEnvironment.publicApiLocation}/VendorLookup/GetLookup?lookupType=${intLookup}&stateString=${state}`;
    return fetch(lookupUrl, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
        },
    })
        .then(response => {
            if (response.ok) {
                return response.json();
            }

            context.commit('setAttribute', {
                id: tableId,
                attribute: 'loadState',
                value: 'error',
            }, {
                root: true,
            });

            return null;
        })
        .then(response => response);
};