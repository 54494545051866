<template>
    <div></div>
</template>
<script>
export default {
    name: 'lni-cookie-monster',

    mounted() {
        this.cookies.forEach(cookie => {
            this.$oneLni.storage.cookie.remove(cookie);
        });
    },
}; </script>