<template>
    <div>
        <button
            v-if="isMobile"
            :id="id + '_button--toggle'"
            class="lni-u-flex lni-u-justify-between lni-c-login-header --mobile lni-u-full-width"
            :aria-expanded="!!expanded"
            aria-controls="collapsible"
            @click="toggleVisibility"
        >
            <div>
                <span
                    class="lni-u-mr2 lnicon--headerLogo"
                    :aria-hidden="true"
                />
                <span> {{ headline }} </span>
            </div>
            <span
                :aria-hidden="true"
                :class="`lnicon--${(expanded) ? 'caret--up': 'caret--down'}`"
            />
        </button>
        <div
            v-else
            class="lni-c-login-header lni-u-full-width"
        >
            <span
                class="lni-u-mr2 lnicon--headerLogo"
                :aria-hidden="true"
            />
            <span> {{ headline }} </span>
        </div>
        <lni-collapse
            :id="id + '_collapse'"
            :visible="expanded"
            class="lni-u-mt3"
        >
            <form
                ref="loginForm"
                :action="action"
                method="POST"
                novalidate
                class="lni-c-login-form lni-u-mhauto"
                @keydown.enter.prevent="handleSubmit"
            >
                <lni-input-text
                    :id="id + '_username'"
                    :labelText="loginLabel"
                    :filled="true"
                    :fullWidth="true"
                    :name="loginName"
                    :required="true"
                    autocomplete="username"
                />

                <lni-input-text
                    :id="id + '_password'"
                    type="password"
                    :labelText="passLabel"
                    :name="passName"
                    :filled="true"
                    :fullWidth="true"
                    :required="true"
                    autocomplete="current-password"
                />
                <div class="lni-u-flex lni-u-mt1">
                    <lni-button
                        :id="id + '_button--submit'"
                        class="lni-u-mr0 lni-u-mlauto lni-c-button"
                        variant="cta"
                        :text="buttonLabel"
                        @click="handleSubmit"
                    >
                        {{ buttonLabel }}
                    </lni-button>
                </div>
                <slot name="hiddenInput" />
                <div class="lni-u-flex">
                    <slot name="loginFooter" />
                </div>
            </form>
        </lni-collapse>
    </div>
</template>
<script>
/* eslint-disable vue/no-side-effects-in-computed-properties */
const mobileBreakPoint = 430;

export default {
    name: 'LniLogin',

    data() {
        return {
            expanded: false,
            windowWidth: window.innerWidth,
        };
    },
    computed: {
        isMobile() {
            if (this.windowWidth <= mobileBreakPoint) {
                return true;
            }
            return false;
        },
        fieldsAreValid() {
            const username = `${this.id}_username`;
            const password = `${this.id}_password`;

            if (this.$store.getters) {
                return this.$store.getters[`${username}/isValid`]
                && this.$store.getters[`${password}/isValid`];
            }
            return false;
        },
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
        if (!this.expanded) {
            this.expanded = true;
            this.$store.commit('setAttribute', {
                id: `${this.id}_collapse`,
                attribute: 'visible',
                value: true,
            });
        }
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
        toggleVisibility() {
            this.expanded = !this.expanded;
            this.$store.commit('setAttribute', {
                id: `${this.id}_collapse`,
                attribute: 'visible',
                value: this.expanded,
            });
        },
        handleSubmit() {
            this.$refs.loginForm.submit();
        },
    },
}; </script>
<style lang="scss">
@import '@gov.wa.lni/application.public-website/source/components/lni-login/lni-login';
</style>