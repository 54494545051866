import encodeDotcmsCookie from '@gov.wa.lni/application.public-website-custom-components/source/utilities/storage/encodeDotcmsCookie.js';

const buildSender = (context, payload) => {
    const senderObj = {
        businessName: context.rootState[context.getters.moduleId + '_business-name'].value,
        contactPerson: context.rootState[context.getters.moduleId + '_contact-person'].value,
        contactEmail: context.rootState[context.getters.moduleId + '_contact-email'].value,
        address1: context.rootState[context.getters.moduleId + '_address'].address1,
        address2: context.rootState[context.getters.moduleId + '_address'].address2,
        city: context.rootState[context.getters.moduleId + '_address'].city,
        state: context.rootState[context.getters.moduleId + '_address'].state,
        zip: context.rootState[context.getters.moduleId + '_address'].zip,
        comments: context.rootState[context.getters.moduleId + '_comments'].value,
    };

    payload.oneLni.storage.cookie.set(
        'sender',
        encodeDotcmsCookie(senderObj),
        {
            expires: 7,
        },
    );
    const sendEmailEndpoint = '/agency/order-paper-forms/send-order';
    window.location.href = sendEmailEndpoint;
};

export default (context, payload) => {
    if (context.rootState['order-paper-form-errors'].errors < 1) {
        context.commit('setAttribute', {
            id: context.getters.moduleId,
            attribute: 'showModalSpinner',
            value: true,
        }, {
            root: true,
        });
        buildSender(context, payload);
    }
};