export default context => {
    const moduleId = context.getters.moduleId;
    const moduleState = context.rootState[moduleId];

    const r = moduleState.rateVariables;
    const ef = moduleState.experienceFactor;
    const trailingDecimals = 2;

    //Total undiscounted rate amount is the composite rate
    const totalUndiscounted = (((r.afRate + r.mafnoc + r.mafsaw) * ef) + r.spfRate)
                                * moduleState.quarterlyHours;
    //Worker amount is the payroll deduction
    const workerUndiscounted = ((((r.mafnoc + r.mafsaw) * ef) + r.spfRate) * moduleState.multiplier)
                                * moduleState.quarterlyHours;
    //Employer amount is the composite rate minus worker amount
    const employerUndiscounted = totalUndiscounted - workerUndiscounted;

    //Discounted rates for preferred worker status
    const employerDiscounted = (r.spfRate * moduleState.quarterlyHours) * moduleState.multiplier;
    const workerDiscounted = (r.spfRate * moduleState.quarterlyHours) * moduleState.multiplier;
    const totalDiscounted = employerDiscounted + workerDiscounted;

    //Savings equals undiscounted minus discounted
    const employerSavings = employerUndiscounted - employerDiscounted;
    const workerSavings = workerUndiscounted - workerDiscounted;
    const totalSavings = employerSavings + workerSavings;

    const calculatedResult = {
        regular: {
            employerRate: employerUndiscounted.toFixed(trailingDecimals),
            deduction: workerUndiscounted.toFixed(trailingDecimals),
            total: totalUndiscounted.toFixed(trailingDecimals),
        },
        discounted: {
            employerRate: employerDiscounted.toFixed(trailingDecimals),
            deduction: workerDiscounted.toFixed(trailingDecimals),
            total: totalDiscounted.toFixed(trailingDecimals),
        },
        savings: {
            employerRate: employerSavings.toFixed(trailingDecimals),
            deduction: workerSavings.toFixed(trailingDecimals),
            total: totalSavings.toFixed(trailingDecimals),
        },
    };

    context.commit('SET_ATTRIBUTE', {
        attribute: 'calculatedResult',
        value: calculatedResult,
    });
    context.commit('SET_ATTRIBUTE', {
        attribute: 'calculated',
        value: true,
    });
};