import resetPaymentInputs from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-simbic-calculator/actions/resetPaymentInputs.js';
import clearField from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-simbic-calculator/actions/clearField.js';

export default context => {
    const id = context.getters.moduleId;
    const moduleState = context.rootState[id];

    clearField(context, `${id}_payment-amount-1`);
    clearField(context, `${id}_payment-date-1`);
    clearField(context, `${id}_balance-due`);
    clearField(context, `${id}_billing-date`);

    for (let i = moduleState.paymentFields.length; i > 1; i--) {
        context.commit('REMOVE_PAYMENT_ROW');
    }

    resetPaymentInputs(context);

    context.dispatch('resetCalculatedValues').then(() => {
        context.dispatch('resetFormValidationState', {
            targetId: `${id}_reset-button`,
        }, {
            root: true,
        });
    });
};