export default function postToService(options: any, oneLni: any) {

    const url = '/GatewayProxy/UiProxy.aspx?op=UiGatewayRestfulOperation';

    options.ServiceName = 'SafetyHealthResourceLibraryAPI';

    return oneLni.fetch(url, options).then((response: { ok: any; json: () => any }) => {
        if (!response.ok) {
            return response;
        }
        return response.json();
    });

}