<template>
    <a
        :href="route"
        class="lni-c-tile lni-u-pv2 lni-u-flex lni-u-justify-between lni-u-items-center lni-u-line-height--tight"
    >
        <span class="lni-c-tile-label lni-u-ml3">{{ label }}</span>
        <span
            :aria-hidden="true"
            class="lni-c-tile-icon lni-u-mr2 lni-u-ml1 lnicon--triangle--right"
        />
    </a>
</template>
<script>
export default {
    name: 'LniHeroTile',

}; </script>
<style lang="scss">
@import '@gov.wa.lni/application.public-website/source/components/lni-hero-tile/lni-hero-tile.scss';
</style>