
const LOGGER_ENDPOINT = '/logger-safety-initiative/';
let loggerPromise = null;

export default oneLni => {
    const endpoint = `${oneLni.environment.current.apiLocation2}${LOGGER_ENDPOINT}`;
    loggerPromise = fetch(endpoint, {
        mode: 'cors',
    }).then(response => response.json())
        .then(data => data);
    return loggerPromise;
};