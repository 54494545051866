import sharedConstants from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/actions/helpers/constants';

export default (context:any, payload:any) => {

    const utils = payload.oneLni.storeUtils;

    utils.setAttribute(context, sharedConstants.FIELDS.videoFormatsList,
        sharedConstants.ATTRIBUTES.value, []);

    context.commit(sharedConstants.MUTATIONS.setUnfilteredCatalogSearchResults);


};