
export default (context, payload) => {
    const currentEnvironment = payload.oneLni.environment.current;

    fetch(`${currentEnvironment.publicApiLocation}/preferredworker`, {
        mode: 'cors',
    }).then(response => response.json())
        .then(data => {
            context.commit('SET_ATTRIBUTE', {
                attribute: 'preferredData',
                value: data.items,
            });

            context.commit('SET_ATTRIBUTE', {
                attribute: 'loadState',
                value: 'loaded',
            });
        }).catch(error => {
            context.commit('SET_ATTRIBUTE', {
                attribute: 'errorDetails',
                value: error,
            });

            context.commit('SET_ATTRIBUTE', {
                attribute: 'loadState',
                value: 'error',
            });
        });
};