export default () => ({
    state: {
        contentType: 'content',
        title: 'Page Unavailable',
        message: `We're sorry. The page you are trying to view is unavailable in your selected
         language. If you need additional information regarding this content or additional language
         support please contact one of the resources listed on the page linked below.`,
        englishContentLink: {
            route: '/',
            label: 'View page in English',
        },
        resourceLink: {
            route: '/agency/contact/by-subject',
            label: 'Contact Information By Subject',
        },
    },
});