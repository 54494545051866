export default context => {
    const paramState = context.state.params;

    // Update options
    setTimeout(() => {
        //Update values
        Object.keys(paramState).forEach(key => {
            if ( paramState[key] && context.rootState[`${context.getters.moduleId}_${key}`]) {
                context.commit('setAttribute', {
                    id: `${context.getters.moduleId}_${key}`,
                    attribute: 'value',
                    value: paramState[key].toString(),
                }, {
                    root: true,
                });
            }
        });
    });
};