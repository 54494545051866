export default (context, payload) => {
    const pageSizeValue = context.rootState[payload.targetId].value;
    const componentId = payload.targetId.replace('_PageSize', '');
    context.commit('setAttribute', {
        id: `${componentId}_pager`,
        attribute: 'pageSize',
        value: parseInt(pageSizeValue),
    }, {
        root: true,
    });

    context.commit('setAttribute', {
        id: `${componentId}_pager`,
        attribute: 'page',
        value: 1,
    }, {
        root: true,
    });

    context.commit('setAttribute', {
        id: componentId,
        attribute: 'pageSize',
        value: parseInt(pageSizeValue),
    }, {
        root: true,
    });

    context.commit('setAttribute', {
        id: componentId,
        attribute: 'page',
        value: 1,
    }, {
        root: true,
    });
};