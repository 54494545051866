const hasDigit = /([0-9])/;
const isNumberSearch = text => hasDigit.test(text);

const rankByText = (text, rules) => rules.map(rule => {
    rule.rank = 0;
    const term = text.toLowerCase();
    if ( rule.title.toLowerCase().includes(term) ) {

        rule.rank += 0.5;
        // const textIndex = rule.title.indexOf(text);
        if (rule.title.toLowerCase().startsWith(term)) {
            //the term is first in the title
            rule.rank += 0.5;
        }
    }
    if (rule.shortDescription && rule.shortDescription.toLowerCase().includes(term) ) {
        rule.rank += 0.25;
    }
    return rule;
});
const rankByNumber = rules => rules.map(rule => {
    rule.rank = 0;
    if (!rule.section) {
        // It is a chapter
        rule.rank += 1;
    }
    return rule;
});

const sortRankedResults = rules => rules.sort( (a, b) =>  b.rank - a.rank );

export {
    isNumberSearch,
    rankByNumber,
    rankByText,
    sortRankedResults,
};