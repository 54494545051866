<template>
    <div class="lni-c-simbic-calculator__container">
        <fieldset class="lni-c-simbib-calculator__received-bill">
            <legend class="lni-u-type--lg">
                Date Bill Received
            </legend>
            <lni-input-date
                :id="id + '_billing-date'"
                class="lni-c-simbic-calculator__billing-input lni-c-simbic-calculator__input  lni-u-block lni-u-mt0 lni-u-mr2"
                pattern="[0-9]"
                :customWidth="11"
                :filled="true"
                labelText="Enter the mailing date + 5 days or the Claim Allowance Date"
                :required="true"
                :labelBefore="true"
                :changeAction="`${id}/resetCalculatedValues`"
                helperText=""
                :hasInlineMessages="false"
            />
            <lni-input-text
                :id="id + '_balance-due'"
                class="lni-c-simbic-calculator__balance-due-input lni-c-simbic-calculator__input lni-u-mr2 lni-u-mt1 "
                type="currency"
                pattern="[0-9\.]+"
                :maxlength="20"
                labelText="Balance Due"
                :customWidth="17"
                :filled="true"
                :labelBefore="true"
                :required="true"
                :changeAction="`${id}/resetCalculatedValues`"
                :hasInlineMessages="false"
            >
                <span slot="prefix">
                    $
                </span>
            </lni-input-text>
            <lni-input-display
                :id="id + '_accrual-date'"
                class="lni-c-simbic-calculator__accrual-date lni-u-mt lni-u-mb0"
                labelText="Interest Accrual Date"
            />
        </fieldset>

        <fieldset
            v-for="(paymentField, index) in paymentFields"
            :key="`payment${index + 1}`"
            class="lni-c-simbic-calculator__payment-fieldset lni-u-block"
        >
            <legend class="lni-u-type--lg lni-u-mt3">
                Payments
            </legend>
            <lni-input-date
                :id="`${id}_payment-date-${index + 1}`"
                class="lni-c-simbic-calculator__input  lni-u-block lni-u-mt0 lni-u-mr2 lni-u-mt3"
                pattern="[0-9]"
                :customWidth="11"
                :filled="true"
                :labelText="`Date of Payment ${index + 1}`"
                :required="index + 1 === 1"
                :labelBefore="true"
                :changeAction="`${id}/resetCalculatedValues`"
                helperText=""
                :hasInlineMessages="false"
            />
            <lni-input-text
                :id="`${id}_payment-amount-${index + 1}`"
                class="lni-c-simbic-calculator__input lni-u-mr2 lni-u-mt1"
                type="currency"
                pattern="[0-9\.]+"
                :maxlength="20"
                :labelText="`Payment ${index + 1} Amount`"
                :customWidth="11"
                :filled="true"
                :labelBefore="true"
                :required="index + 1 === 1"
                :changeAction="`${id}/resetCalculatedValues`"
                :hasInlineMessages="false"
            >
                <span slot="prefix">
                    $
                </span>
            </lni-input-text>
            <lni-input-display
                :id="`${id}_interest-${index + 1}`"
                class="lni-u-mb0 lni-u-mt1 "
                labelText="Interest Accrued"
            />
        </fieldset>
        <div class="lni-c-simbic-calculator__add-row-controls lni-u-mt2">
            <lni-button
                id="addPaymentRowButton"
                text="Add Another Payment"
                :clickAction="`${id}/addPaymentRow`"
            />
            <lni-button
                v-if="paymentFields.length > 1"
                id="deletePaymentRowButton"
                class="lni-u-ml1"
                text="Delete"
                type="alternate"
                :clickAction="`${id}/removePaymentRow`"
            />
        </div>


        <div class="lni-u-flex-column lni-u-flex-wrap lni-u-mt3 lni-u-pt3 lni-u-bt lni-u-b--subtle">
            <lni-input-display
                :id="id + '_remaining-principal'"
                labelText="Remaining Principal Owed"
                class="lni-u-text-center lni-u-mb1 lni-u-mr2"
            />
            <lni-input-display
                :id="id + '_balance-owed'"
                labelText="Remaining Balance Owed"
                class="lni-u-text-center lni-u-mb2"
            />
        </div>

        <div class="lni-c-simbic-calculator__actions-wrapper lni-u-flex">
            <lni-button
                :id="id + '_calculate-button'"
                errorContainerId="simbic-calculator-error-container"
                text="Calculate"
                class="lni-c-simbic-calculator__calculate-button lni-c-button"
                @click.native="calculateClick"
            />
            <lni-button
                :id="id + '_reset-button'"
                text="Reset"
                type="alternate"
                errorContainerId="simbic-calculator-error-container"
                :clickAction="`${id}/reset`"
                class="lni-u-ml1"
            />
        </div>

        <lni-error-container
            id="simbic-calculator-error-container"
            class="lni-u-mt3"
            heading="Before we submit this form:"
        />
    </div>
</template>

<script>
//TODO: computed errors collected from

export default {
    name: 'LniSimbicCalculator',
    mounted() {
        this.$store.commit('setAttribute', {
            id: `simbic-calculator-error-container`,
            attribute: 'errorsCollectedFrom',
            value: [
                `${this.id}_billing-date`,
                `${this.id}_balance-due`,
                ...this.$store.getters[`${this.id}/paymentFieldIds`],

            ],
        });
        this.$store.dispatch(`${this.id}/reset`, {
            sourceId: this.id,
        });
    },
    methods: {
        calculateClick() {
            const payload = {
                sourceId: this.id,
                targetId: `${this.id}_calculate-button`,
                validation: {
                    errorsCollectedBy: this.$store.state[this.id].errorsCollectedBy,
                },
            };
            const self = this;
            this.$store.dispatch('validateForm', payload)
                .then(() => {
                    if (self.$store.getters['simbic-calculator-error-container/isValid']) {
                        this.$store.dispatch(`${this.id}/calculate`, payload);
                    } else {
                        this.$store.dispatch(`${this.id}/resetCalculatedValues`, payload);
                        setTimeout(() => {
                            self.$scrollTo(`#simbic-calculator-error-container`);
                        }, 0);
                    }
                });
        },
    },
}; </script>
<style lang="scss">
@import 'lni-simbic-calculator';
</style>