import sendEmail from '@gov.wa.lni/application.public-website/source/components/lni-site-feedback/actions/sendEmail.js';
import submit  from '@gov.wa.lni/application.public-website/source/components/lni-site-feedback/actions/submit.js';
import initializeContent  from '@gov.wa.lni/application.public-website/source/components/lni-site-feedback/actions/initializeContent.js';
import resetForm from '@gov.wa.lni/application.public-website/source/components/lni-site-feedback/actions/resetForm.js';

export default {
    sendEmail,
    submit,
    initializeContent,
    resetForm,
};