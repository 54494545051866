<template>
    <div class="lni-c-preferred-worker-calculator lni-u-mt3">
        <span
            v-if="loadState === 'error'"
            role="alert"
            class="lni-c-alert lni-u-mb3 --error">
            <span
                aria-hidden="true"
                class="lnicon--exclamation"></span>
            There was a problem with the network. Please try again. (Details: {{ errorDetails }})
        </span>
        <form action="/">
            <lni-input-text
                :id="`${id}_risk-class`"
                class="lni-u-block"
                labelText="Risk Class"
                :aria-required="true"
                :required="true"
                :maxlength="4"
                :customValidityAction="`${id}/riskClassCustomValidation`">
                <p slot="helperText">
                    Example: 0103 or 4903
                </p>
            </lni-input-text>
            <lni-input-text
                :id="`${id}_experience-factor`"
                class="lni-u-block"
                labelText="Experience Factor"
                :maxlength="6"
                :aria-required="true"
                :required="true"
                :customValidityAction="`${id}/experienceCustomValidation`">
                <p slot="helperText">
                    Example: 1 or .8992
                </p>
            </lni-input-text>
            <lni-button
                :id="`${id}_calculate-btn`"
                class="lni-u-block"
                text="Calculate"
                type="submit"
                :errorContainerId="`${id}_calculation-errors`"
                @click.native="submit">
            </lni-button>
            <lni-error-container
                :id="`${id}_calculation-errors`"
                class="lni-u-mv3"
                heading="Before we submit this form:"
                :errorsCollectedFrom="[
                    `${id}_risk-class`,
                    `${id}_experience-factor`,
                ]">
            </lni-error-container>
        </form>
        <div
            v-if="calculated"
            class="lni-u-mv3 lni-u-pt3 lni-u-b--subtle lni-u-bt">
            <h3>Quarterly Premium Calculations</h3>
            <p class="lni-c-preferred-worker-calculator__note lni-u-line-height--tight lni-u-mt1">
                Example only.  Actual savings may vary.
            </p>
            <ul class="lni-u-list-reset lni-u-mv3 lni-u-type--md lni-u-font-color--light">
                <li>Risk Class: <strong>{{ riskClass }}</strong></li>
                <li>Risk Class Description: <strong>{{ riskClassDescription }}</strong></li>
                <li>Experience Factor: <strong>{{ experienceFactor }}</strong></li>
            </ul>
            <div class="lni-c-preferred-worker-calculator__grid">
                <div class="lni-c-preferred-worker-calculator__card lni-c-preferred-worker-calculator__block">
                    <p class="lni-c-preferred-worker-calculator__card--header lni-u-mb2">
                        REGULAR EMPLOYEE
                    </p>
                    <p class="lni-c-preferred-worker-calculator__note lni-u-line-height--tight lni-u-mt0">
                        Estimated quarterly premium ({{ quarterlyHours }} hours/quarter)
                    </p>
                    <div class="lni-u-pt3">
                        <label class="lni-u-type--xs">Employer Premium</label>
                        <div class="lni-c-preferred-worker-calculator__card--currency lni-u-pt1">
                            {{ currencyConversion(calculatedResult.regular.employerRate) }}
                        </div>
                    </div>
                    <div class="lni-u-pt2">
                        <label class="lni-u-type--xs">Employee Withholding</label>
                        <div class="lni-c-preferred-worker-calculator__card--currency lni-u-pt1">
                            {{ currencyConversion(calculatedResult.regular.deduction) }}
                        </div>
                    </div>
                    <div class="lni-u-pt2">
                        <label class="lni-u-type--xs">Total</label>
                        <div class="lni-c-preferred-worker-calculator__card--currency lni-u-pt1">
                            {{ currencyConversion(calculatedResult.regular.total) }}
                        </div>
                    </div>
                </div>
                <div class="lni-c-preferred-worker-calculator__block">
                    <div class="lni-u-mv3">
                        <svg
                            width="32"
                            height="8">
                            <path d="M1 0a1 1 0 00-1 1v6a1 1 0 001 1h30a1 1 0 001-1V1a1 1 0 00-1-1H1z" />
                        </svg>
                    </div>
                </div>
                <div class="lni-c-preferred-worker-calculator__card lni-c-preferred-worker-calculator__block">
                    <p class="lni-c-preferred-worker-calculator__card--header lni-u-mb2">
                        PREFERRED WORKER
                    </p>
                    <p class="lni-c-preferred-worker-calculator__note lni-u-line-height--tight lni-u-mt0">
                        Estimated quarterly premium ({{ quarterlyHours }} hours/quarter)
                    </p>
                    <div class="lni-u-pt3">
                        <label class="lni-u-type--xs">Employer Premium</label>
                        <div class="lni-c-preferred-worker-calculator__card--currency lni-u-pt1">
                            {{ currencyConversion(calculatedResult.discounted.employerRate) }}
                        </div>
                    </div>
                    <div class="lni-u-pt2">
                        <label class="lni-u-type--xs">Employee Withholding</label>
                        <div class="lni-c-preferred-worker-calculator__card--currency lni-u-pt1">
                            {{ currencyConversion(calculatedResult.discounted.deduction) }}
                        </div>
                    </div>
                    <div class="lni-u-pt2">
                        <label class="lni-u-type--xs">Total</label>
                        <div class="lni-c-preferred-worker-calculator__card--currency lni-u-pt1">
                            {{ currencyConversion(calculatedResult.discounted.total) }}
                        </div>
                    </div>
                </div>
                <div class="lni-c-preferred-worker-calculator__block">
                    <div class="lni-u-mv3">
                        <svg
                            width="32"
                            height="8"
                            class="lni-u-mb1">
                            <path d="M1 0a1 1 0 00-1 1v6a1 1 0 001 1h30a1 1 0 001-1V1a1 1 0 00-1-1H1z" />
                        </svg>
                        <svg
                            width="32"
                            height="8">
                            <path d="M1 0a1 1 0 00-1 1v6a1 1 0 001 1h30a1 1 0 001-1V1a1 1 0 00-1-1H1z" />
                        </svg>
                    </div>
                </div>
                <div class="lni-c-preferred-worker-calculator__card lni-c-preferred-worker-calculator__block">
                    <p class="lni-c-preferred-worker-calculator__card--header lni-u-mb2">
                        QUARTERLY SAVINGS
                    </p>
                    <p class="lni-c-preferred-worker-calculator__note lni-u-line-height--tight lni-u-mt0">
                        Estimated quarterly premium discount for hiring a preferred worker ({{ quarterlyHours }} hours/quarter)
                    </p>
                    <div class="lni-u-pt2">
                        <label class="lni-u-type--xs">Employer Premium Savings</label>
                        <div class="lni-c-preferred-worker-calculator__card--currency lni-u-pt1">
                            {{ currencyConversion(calculatedResult.savings.employerRate) }}
                        </div>
                    </div>
                    <div class="lni-u-pt2">
                        <label class="lni-u-type--xs">Employee Withholding Savings</label>
                        <div class="lni-c-preferred-worker-calculator__card--currency lni-u-pt1">
                            {{ currencyConversion(calculatedResult.savings.deduction) }}
                        </div>
                    </div>
                    <div class="lni-u-pt2">
                        <label class="lni-u-type--xs">Combined Savings Total</label>
                        <div class="lni-c-preferred-worker-calculator__card--currency lni-u-pt1">
                            {{ currencyConversion(calculatedResult.savings.total) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'lni-preferred-worker-calculator',

    computed: {
        riskClassDescription() {
            return this.$store.getters[`${this.id}/rateVariables`](this.riskClass).description;
        },
    },
    mounted() {
        this.addCustomValidations();
        this.dispatchEvent(`${this.id}/initialize`, {}, {
            root: true,
        });
    },
    methods: {
        submit() {
            const self = this;

            const payload = {
                sourceId: this.id,
                targetId: `${this.id}_calculate-btn`,
                validation: {
                    errorsCollectedBy: this.$store.state[this.id].errorsCollectedBy,
                },
            };
            this.$store.dispatch('validateForm', payload).then(() => {
                if (self.$store.getters[`${self.id}_calculation-errors/isValid`]) {
                    self.$store.commit(`${self.id}/SET_ATTRIBUTE`, {
                        attribute: 'riskClass',
                        value: self.$store.state[`${self.id}_risk-class`].value,
                    });

                    self.$store.commit(`${self.id}/SET_ATTRIBUTE`, {
                        attribute: 'rateVariables',
                        value: self.$store.getters[`${self.id}/rateVariables`](self.riskClass),
                    });

                    self.$store.commit(`${self.id}/SET_ATTRIBUTE`, {
                        attribute: 'experienceFactor',
                        value: self.$store.state[`${self.id}_experience-factor`].value,
                    });

                    self.dispatchEvent(`${self.id}/calculate`);
                }
            });
        },
        currencyConversion(val) {
            const floatDig = 2;
            return '$' + parseFloat(val).toFixed(floatDig).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,');
        },
        addCustomValidations() {
            this.$store.commit(`${this.id}_risk-class/addValidityTest`, {
                validityTest: 'riskClassExcluded',
                global: state => `Risk Class ${state.value} is calculated per square foot and cannot be used in this calculator`,
                inline: state => `Risk Class ${state.value} cannot be used in this calculator`,
            });
            this.$store.commit(`${this.id}_risk-class/addValidityTest`, {
                validityTest: 'riskClassInvalid',
                global: () => 'The Risk Class must be a 4-digit number (Example: 0103 or 4903)',
                inline: () => 'The Risk Class must be a 4-digit number',
            });
            this.$store.commit(`${this.id}_risk-class/addValidityTest`, {
                validityTest: 'riskClassNotFound',
                global: state => `${state.value} is not a valid Risk Class (not found)`,
                inline: state => `${state.value} is not a valid Risk Class (not found)`,
            });

            this.$store.commit(`${this.id}_experience-factor/addValidityTest`, {
                validityTest: 'experienceInvalid',
                global: () => 'The Experience Factor must be a number between .0001 and 9.9999 (Example: 1 or .8992)',
                inline: () => 'The Experience Factor must be a number between .0001 and 9.9999',
            });
        },
    },
};
</script>
<style lang="scss">
@import 'lni-preferred-worker-calculator';
</style>