<template>
    <div class="lni-c-forms-and-pubs">
        <!-- top container -->
        <div class="lni-c-box--content lni-u-mhauto">
            <div class="lni-c-search__container">
                <!-- search controls -->
                <lni-input-search
                    :id="`${id}_query`"
                    :placeholder="searchLabel"
                    :query="params['query']"
                    :searchAction="`${id}/search`"
                    :emptySearchAction="`${id}/search`"
                    :inputAction="`${id}/updateSearchTerm`"
                    :clearAction="`${id}/updateSearchTerm`"
                />
                <p class="lni-u-flex lni-u-mt2">
                    <a
                        :id="`${id}_site-search-link`"
                        :href="$store.getters[`${id}/fullSiteHref`]"
                        class="lni-u-mlauto lni-u-mr0 lni-c-anchor"
                    >Search full site &rarr;</a>
                </p>

                <!-- ordering info -->
                <div
                    v-show="!showLoader && results.length"
                    class="lni-u-mt3"
                >
                    <button
                        class="lni-c-button"
                        :aria-controls="`${id}_order-copies-info`"
                        :aria-expanded="showOrderControls ? 'true' : 'false'"
                        @click="showOrderControls = !showOrderControls"
                    >
                        <span v-if="!showOrderControls">Order free copies by mail</span>
                        <span v-else>Hide ordering information</span>
                    </button>
                    <transition name="slide-from-top">
                        <div
                            v-show="showOrderControls"
                            :id="`${id}_order-copies-info`"
                            class="lni-u-pv3 lni-o-type-context--content"
                        >
                            <h2 class="lni-u-mt2">
                                Order free copies by mail
                            </h2>
                            <p>L&amp;I offers free copies of many forms, publications, posters, and other items.</p>
                            <ul>
                                <li v-show="canBeOrderedOnline">
                                    <p>
                                        Some of the items in this search can be ordered online.
                                        Press "Add to order" on the items below to add to your cart.
                                    </p>
                                    <lni-forms-and-pubs-cart :id="`${id}_cart`" />
                                </li>
                                <li v-if="canBeOrdered">
                                    <p>Some of the items in this search have special instructions for ordering.</p>
                                </li>
                            </ul>
                            <div v-if="!canBeOrdered && !canBeOrderedOnline">
                                <p>Unfortunately, the items in this search are only available online.</p>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
            <!-- end ordering info -->

            <!-- loader -->
            <div
                v-show="showLoader"
                class="lni-u-text--center lni-u-full-height"
            >
                <lni-spinner
                    id="formAndPubTopSpinner"
                    :inline="true"
                />
            </div>
        </div>
        <!--end top container -->

        <div class="lni-c-box--content lni-u-mhauto lni-u-mt3">
            <!-- loader -->
            <div
                v-show="showLoader"
                class="lni-u-text--center lni-u-full-height"
            >
                <lni-spinner
                    id="formAndPubSpinner"
                    :inline="true"
                />
            </div>
            <div
                v-show="!showLoader"
                class="lni-c-search__container lni-u-mhauto"
            >
                <template v-show="count">
                    <!-- result count -->
                    <p class="lni-u-text--xs">
                        {{ $store.getters[`${id}/firstResult`] }} &dash; {{ $store.getters[`${id}/lastResult`] }} of {{ count }} Results
                    </p>

                    <!-- result list -->
                    <ul class="lni-u-list-reset lni-c-search__results">
                        <lni-forms-and-pubs-search-result
                            v-for="result in results"
                            :key="result.formId"
                            :groupTranslations="true"
                            v-bind="{ ...result }"
                            :showOrderControls="showOrderControls"
                            :idsInOrder="$store.getters[`${id}_cart/idsInCart`]"
                            @selection-made="handleSelection($event)"
                        />
                    </ul>
                    <lni-pager
                        :id="`${id}_pager`"
                        class="lni-u-pt3"
                        :collectionId="id"
                        :page="params.pg"
                        :totalItems="count"
                        :pageSize="params.limit"
                        :showPager="false"
                        selectLabel="Items per Page"
                        @change-page="onPageChange"
                    />
                </template>

                <!-- error messages -->
                <div aria-live="polite">
                    <div v-if="results.length === 0 && userHasSearched && !showLoader">
                        <p>
                            <span>We could not find any items</span>
                            <span v-if="params['query']">containing "{{ params['query'] }}"</span>
                            <span v-if="params.type || params.subject || params.language">with:</span>
                            <span v-else>.</span>
                        </p>
                        <ul v-if="params.type || params.subject || params.language">
                            <li v-if="params.type">
                                type: {{ params.type }}
                            </li>
                            <li v-if="params.subject">
                                subject: {{ params.subject }}
                            </li>
                            <li v-if="params.language">
                                language: {{ params.language }}
                            </li>
                        </ul>
                        <p>Try changing your search to start again.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import lniFormsAndPubsSearchResult from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-forms-and-pubs-search/lni-forms-and-pubs-search-result.vue';

export default {
    name: 'LniFormsAndPubs',
    components: {
        lniFormsAndPubsSearchResult,
    },

    data() {
        return {
            hasAdvancedFilters: false,
            searchLabel: 'Search forms & publications',
        };
    },
    computed: {
        // Are any of these results orderable?
        canBeOrdered() {
            return this.results.filter(result => result.availableFrom).length > 0;
        },
        // Are any results orderable via the warehouse form
        canBeOrderedOnline() {
            return this.results.filter(
                result => result.copies.includes('warehouse'),
            ).length > 0;
        },
    },
    mounted() {
        const params = this.getUrlParams();
        if ( params ) {
            this.$store.commit(`${this.id}/SET_PARAM`, params);
            this.updateControls(params);
        }
        this.$nextTick(() => this.dispatchEvent(`${this.id}/search`));

        if ( this.$store.state[`${this.id}_cart`].cart.length > 0 && !this.showOrderControls) {
            this.$store.commit(`${this.id}/TOGGLE_ORDER_CONTROLS`);
        }
    },
    methods: {
        handleSelection(payload) {
            if (payload.shouldAdd) {
                this.$store.commit(`${this.id}_cart/ADD_ITEM`, {
                    formId: payload.formId,
                    title: payload.title,
                    language: payload.language,
                });
            } else {
                this.$store.dispatch(`${this.id}_cart/removeItemById`, payload.formId);
            }
        },
        // Convert params to object
        //TODO: normalize with search-form (no replaces)
        getUrlParams() {
            const query = window.location.search.substring(1);
            const pairs = query.split('&');
            const params = {};
            if (!query || !pairs.length ) {
                return null;
            }
            pairs.forEach(pair => {
                const keyValue = pair.split('=');
                params[keyValue[0]] = decodeURIComponent(keyValue[1].replace(/\+/g, '%20'));
            });
            return params;
        },
        // Loop through params and update ui control values
        updateControls(params) {
            Object.keys(params).forEach(key => {
                if (key === 'query') {
                    // search-input's value is "query" not "value"
                    this.$store.commit('setAttribute', {
                        id: `${this.id}_${key}`,
                        attribute: 'query',
                        value: params[key],
                    });

                } else {
                    this.$store.commit('setAttribute', {
                        id: `${this.id}_${key}`,
                        attribute: 'value',
                        value: params[key],
                    });
                }
            });
        },
        onPageChange(payload) {
            this.$store.commit(`${this.id}/SET_PARAM`, {
                pg: payload.page,
            }, {
                root: true,
            });
            this.dispatchEvent(`${this.id}/search`, {
                keepPage: true,
            });
        },
    },
}; </script>