<template>
    <div class="lni-c-site-feedback">
        <div
            v-show="showModalSpinner"
            class="lni-u-text--center lni-u-full-height">
            <lni-spinner
                :id="`${id}_spinner`"
                :inline="false"></lni-spinner>
        </div>
        <lni-informational-messages
            :id="`${id}_result-messages`"
            class="lni-u-mv3"></lni-informational-messages>
        <div v-if="labels && !hasBeenSubmitted">
            <form
                class="lni-u-form-width--base lni-u-flex lni-u-flex-column">
                <lni-select
                    :id="`${id}_comment-type`"
                    :labelText="labels.commentType"
                    class="lni-c-site-feedback__comment-type"
                    :required="true"
                    :options="[]"></lni-select>
                <lni-input-textarea
                    :id="`${id}_comment`"
                    class=""
                    :labelText="labels.description"
                    :required="true"
                    :limit="500"
                    :maxlength="500"
                    :fullWidth="true"></lni-input-textarea>
                <lni-input-text
                    :id="`${id}_name`"
                    class=""
                    :labelText="labels.name"
                    :maxlength="100"
                    :fullWidth="true"></lni-input-text>
                <lni-select
                    :id="`${id}_customer-type`"
                    :labelText="labels.userType"
                    class="lni-c-site-feedback__customer-type"
                    :options="[]"></lni-select>
                <lni-input-email
                    :id="`${id}_email`"
                    :labelText="labels.email">
                    <span slot="helperText">{{ helperText.email }}</span>
                </lni-input-email>
                <lni-input-phone
                    :id="`${id}_phone`"
                    class=""
                    :isFieldset="false"
                    :labelText="labels.phone"
                    :extensionLabelText="labels.phoneExt"
                    :fullWidth="true">
                    <span slot="helperText">{{ helperText.phone }}</span>
                </lni-input-phone>
                <lni-button
                    :id="`${id}_submit-button`"
                    :clickAction="[`validateForm`, isEnglish ? `${id}/submit` : `${id}/sendEmail`]"
                    :text="labels.submit"
                    errorContainerId="site-feedback-error-container"
                    class="lni-c-button --primary lni-u-mb3 lni-u-ml6 lni-u-self-end"></lni-button>
                <lni-error-container
                    :id="`site-feedback-error-container`"
                    :heading="labels.errorHeading"
                    :message="labels.errorMessage"
                    :errorsCollectedFrom="[
                        `${id}_comment-type`,
                        `${id}_comment`,
                        `${id}_email`,
                        `${id}_phone`,
                    ]"></lni-error-container>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    name: 'lni-site-feedback',
    computed: {
        isEnglish() {
            return this.$oneLni.getLanguage() === 'en';
        },
    },
    mounted() {
        this.initialize();
    },
    methods: {
        initialize() {
            this.dispatchEvent(`${this.id}/initializeContent`).then(() => {
                setTimeout(() => {
                    this.addCustomErrorMessages();
                }, 0);
            });
        },
        addCustomErrorMessages() {
            const commentTypeMessages = {
                valueMissing: {
                    inline: () => this.validation.generic.required.inline,
                    global: () => this.validation.commentType.required.global,
                },
            };
            this.$store.commit('setAttribute', {
                id: `${this.id}_comment-type`,
                attribute: 'messages',
                value: commentTypeMessages,
            });

            const commentMessages = {
                valueMissing: {
                    inline: () => this.validation.generic.required.inline,
                    global: () => this.validation.description.required.global,
                },
            };
            this.$store.commit('setAttribute', {
                id: `${this.id}_comment_input-text`,
                attribute: 'messages',
                value: commentMessages,
            });

            const existingEmailMessages = this.$store.state[`${this.id}_email_input-text`].messages;
            const emailMessages = {
                patternMismatch: {
                    inline: () => this.validation.email.valid.inline,
                    global: () => this.validation.email.Valid.global,
                },
                typeMismatch: {
                    inline: () => this.validation.email.valid.inline,
                    global: () => this.validation.email.valid.global,
                },
            };
            const newMessages = {
                ...existingEmailMessages,
                ...emailMessages,
            };

            this.$store.commit('setAttribute', {
                id: `${this.id}_email_input-text`,
                attribute: 'messages',
                value: newMessages,
            });

            this.$store.commit(`${this.id}_phone_phone/customizeValidationMessage`, {
                validationTest: 'patternMismatch',
                global: () => this.validation.phone.valid.global,
                inline: () => this.validation.phone.valid.inline,
            });
        },
    },
};
</script>