<template>
    <div class="lni-u-flex lni-u-gap2 lni-u-mt3">
        <div>
            <div><b>Language(s):</b> {{ $props.languages }}</div>
            <div><b>Copyright Date:</b> {{ $props.productionDate }} </div>
            <div><b>Length:</b> {{ $props.videoLength }} minutes</div>
        </div>
        <div :class="metaAlignment">
            <div><b>Title ID:</b> {{ $props.titleId }}</div>
            <div class="lni-u-flex lni-u-items-center lni-u-flex-wrap lni-u-gap1">
                <div><b>Format(s):</b></div>
                <div class="lni-u-flex lni-u-items-center lni-u-gap1">
                    <div
                        v-for="formatType in $props.titleFormats"
                        :key="formatType"
                    >
                        <lni-svg-icon
                            v-if="formatType.toUpperCase() === 'CC'"
                            :id="`${titleId}_cc`"
                            class="lni-u-block"
                            aria-label="Close Captioning"
                        >
                            <cc />
                        </lni-svg-icon>
                        <div v-if="formatType.toUpperCase() === 'ONLINE'">
                            <span class="lni-u-visually-hidden">Online</span>
                            <span
                                class="lnicon--youtube lni-u-type--lg lni-u-block"
                                aria-hidden="false"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-show="showBackToTopDetails">
            <button
                type="button"
                class="lni-c-button lni-u-text--all-caps lni-u-type--bold"
                @click="scrollToAnchorPoint"
            >
                Back to Top
                <span
                    aria-hidden="true"
                    class="lnicon--caret--up"
                />
            </button>
        </div>
    </div>
</template>
<script>
import cc from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/cc-info/cc-info.vue';

export default {
    name: 'LniSafetyVideoMetaData',
    components: {
        cc,
    },
    props: {
        showBackToTopDetails: {
            type: Boolean,
            default: true,
        },
        titleName: {
            type: String,
            default: '',
        },
        titleId: {
            type: String,
            default: '',
        },
        videoLength: {
            type: String,
            default: '',
        },
        productionDate: {
            type: String,
            default: '',
        },
        languages: {
            type: String,
            default: '',
        },
        titleFormats: {
            type: Array,
            default: () => []
        },
    },
    computed: {
        metaAlignment() {
            const searchResultView = 'lni-u-mr3 lni-u-mlauto';
            const detailOnlyView = 'lni-u-mr3 lni-u-ml6';
            if(this.$props.showBackToTopDetails) {
                return searchResultView;
            } else {
                return detailOnlyView;
            }
        },
    },
    methods: {
        scrollToAnchorPoint() {
            this.$nextTick(() => { window.scrollTo(0, 0); });
        },
    },
}
</script>