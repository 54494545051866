import postToService from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/actions/postToService';
import helper from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/actions/helpers';
import sharedConstants from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/actions/helpers/constants';
import VideoSearch from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/classes/videoSearch';

export default function searchForVideos(context: any, payload: any) {

    const oneLni = payload.oneLni;
    context.commit(sharedConstants.MUTATIONS.updateLoadingState, true);
    const utils = payload.oneLni.storeUtils;
    utils.setAttribute(context, 'alertMsg', 'items', []);

    let videoSearch = new VideoSearch();

    context.commit(sharedConstants.MUTATIONS.setSearchOption,
        context.rootState.search_searchType.value,
    );

    if (context.getters.getSearchOption === sharedConstants.keywordSearch) {
        videoSearch.optionData.keywordTerm = context.rootState.search_input.query;
    } else if (context.getters.getSearchOption === sharedConstants.subjectSearch) {
        context.commit(sharedConstants.MUTATIONS.setSubjectValue,
            context.rootState.search_subjectOrHazards.value,
        );
        videoSearch.optionData.keywordTerm = context.getters.getSubjectText;
    }

    context.commit(sharedConstants.MUTATIONS.setSearchTermSpecified,
        videoSearch.optionData.keywordTerm);

    if (helper.isNullOrEmptyString(context.getters.getSearchTermSpecified)) {
        let missingSearchTerm = [{
            message: 'Please enter a keyword/phrase or subject.',
            level: 'Error',
        }];
        utils.setAttribute(context, 'alertMsg', 'items', missingSearchTerm);
        context.commit(sharedConstants.MUTATIONS.updateLoadingState, false);
        return;
    }

    videoSearch.getLibraryCatalogOptions.body = videoSearch.getRequestData();

    postToService(videoSearch.getLibraryCatalogOptions,
        oneLni).then((response:
            {
                ok: any;
                data: any;
            }) => {

        if (!response.ok) {
            context.commit(sharedConstants.MUTATIONS.updateLoadingState, false);
            //helper.showDialog(context, 'dialogException', true, true);
            return;
        }

        context.commit(sharedConstants.MUTATIONS.setVideoCatalogSearchResults, response.data);

        if (context.getters.getVideoFormatValue !== sharedConstants.allValue) {
            context.commit(sharedConstants.MUTATIONS.setFilterCatalogSearchResultsByFormat,
                context.getters.getVideoFormatText);
        }

        context.commit(sharedConstants.MUTATIONS.setSearchInvoked, true);
        context.commit(sharedConstants.MUTATIONS.updateLoadingState, false);
    });

    context.commit(sharedConstants.MUTATIONS.setShowQuicklinks, false);
    context.commit(sharedConstants.MUTATIONS.setShowNewSearchOption, true);


}