const minSearchStringLength = 3;
let previousSearchString = '';

export default (context, payload) => {
    const moduleId = context.getters.moduleId;
    const searchString = context.rootState[`${moduleId}_search-text`].query;
    const pageSize = context.rootState[`${moduleId}`].pageSize;
    const pageNumber = context.rootState[`${moduleId}`].page;

    if (searchString && searchString.length < minSearchStringLength) {
        return;
    }

    const requestBody = {
        searchString,
        pageNumber,
        pageSize,
    };

    let searchStringChanged = false;
    if (previousSearchString !== searchString) {
        searchStringChanged = true;
        requestBody.pageNumber = 1;
    }
    previousSearchString = searchString;

    const currentEnvironment = payload.oneLni.environment.current;
    let searchUrl = `${currentEnvironment.publicApiLocation}/SelfInsuredEmployers/Search`;
    fetch(searchUrl, {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
        },
        body: JSON.stringify(requestBody),
    })
        .then(response => {
            if (response.ok) {
                return response.json();
            }

            return null;
        })
        .then(response => {
            let items = response.items;
            let totalItems = response.totalItems;

            context.commit('setAttribute', {
                id: `${moduleId}`,
                attribute: 'items',
                value: items,
            }, {
                root: true,
            });

            context.commit('setAttribute', {
                id: `${moduleId}`,
                attribute: 'totalItems',
                value: totalItems,
            }, {
                root: true,
            });

            setTimeout(() => {
                context.commit('setAttribute', {
                    id: `${moduleId}_pager`,
                    attribute: 'totalItems',
                    value: totalItems,
                }, {
                    root: true,
                });
            });

            if (searchStringChanged) {
                context.commit('setAttribute', {
                    id: `${moduleId}`,
                    attribute: 'page',
                    value: 1,
                }, {
                    root: true,
                });

                setTimeout(() => {
                    context.commit('setAttribute', {
                        id: `${moduleId}_pager`,
                        attribute: 'page',
                        value: 1,
                    }, {
                        root: true,
                    });
                });
            }

            searchStringChanged = false;
        });
};