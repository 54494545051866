<template>
    <form
        class="lni-c-order-self-insurer-roa-form lni-u-mt3 lni-u-form-width--base"
        @change="onFormChange($event)"
    >
        <h2 class="lni-u-heading--2">
            Order SIF-2 Forms
        </h2>
        <fieldset class="lni-u-mt3">
            <legend class="lni-u-heading--3">
                Contact information
            </legend>
            <div class="lni-u-flex lni-u-flex-column">
                <lni-input-text
                    :id="`${id}_company-name`"
                    ref="company-name"
                    labelText="Self-Insured Company Name"
                    :filled="true"
                    :required="true"
                    :labelBefore="true"
                    :fullWidth="true"
                />
                <lni-input-text
                    :id="`${id}_account-id`"
                    ref="account-id"
                    pattern="7[0-9]{7}"
                    maxlength="8"
                    labelText="Self-Insured Account ID"
                    :filled="true"
                    :labelBefore="true"
                    :required="true"
                    class="lni-u-mt3"
                    :fullWidth="true"
                />
                <lni-input-text
                    :id="`${id}_contact-name`"
                    ref="contact-name"
                    labelText="Contact Name"
                    :filled="true"
                    :labelBefore="true"
                    :required="true"
                    class="lni-u-mt3"
                    :fullWidth="true"
                />
                <lni-input-phone
                    :id="`${id}_contact-phone`"
                    ref="contact-phone"
                    labelText="Contact Phone Number"
                    :isFieldset="false"
                    :filled="true"
                    class="lni-u-mt3"
                    :labelBefore="true"
                    :fullWidth="true"
                />
                <lni-input-email
                    :id="`${id}_contact-email`"
                    ref="contact-email"
                    labelText="Contact Email"
                    :filled="true"
                    :required="true"
                    :labelBefore="true"
                    :fullWidth="true"
                />
            </div>
        </fieldset>

        <fieldset class="lni-u-mt3">
            <legend class="lni-u-heading--3">
                Order information
            </legend>

            <lni-radios
                :id="`${id}_receive-type`"
                class="lni-u-mt3"
                labelText="How would you like to receive your claim numbers? Choose one."
                :isColumn="true"
                :required="true"
                :changeAction="`${id}/toggleAddress`"
                :options="[
                    {text: 'Emailed Claim Numbers', description: 'Receive a set of claim numbers. You are responsible for tracking the assigned claim numbers and entering the claim number on the SIF-2 or approved substantially similar form.', value: 'Email'},
                    {text: 'Pre-printed Forms', description: 'We will mail you pre-printed forms with the claim numbers already filled out.', value: 'PrePrinted'},
                ]"
            />
            <lni-collapse
                id="address-collapse"
                :visible="false"
            >
                <lni-input-address
                    :id="`${id}_address`"
                    ref="address"
                    class="lni-u-mt3"
                    labelText="Mailing Address"
                    state=" "
                    :filled="true"
                />
            </lni-collapse>
            <lni-select
                :id="`${id}_quantity`"
                labelText="Quantity"
                labelBefore="true"
                :required="true"
                :options="selectQuantityOptions"
                class="lni-u-mt3"
                :fullWidth="true"
            />
        </fieldset>
        <lni-error-container
            id="order-self-insurer-roa-form-request-errors"
            class="lni-u-mt2"
            heading="Before we submit your request"
            :errorsCollectedFrom="trackedErrors"
        />
        <lni-button
            :id="`${id}_submit`"
            ref="button"
            text="Submit Order"
            type="button"
            class="lni-u-mt3"
            errorContainerId="order-self-insurer-roa-form-request-errors"
            @click="onSubmit"
        />
        <lni-recaptcha
            :id="`${id}_recaptcha`"
            ref="invisible-recaptcha"
            class="lni-u-mt3"
            :verifyAction="`${id}/submit`"
            size="invisible"
            :loadRecaptchaScript="true"
            badge="inline"
        />
        <div
            v-show="showSif2Spinner"
            class="lni-u-text--center lni-u-full-height"
        >
            <lni-spinner
                :id="`${id}_spinner`"
                :inline="false"
            />
        </div>
    </form>
</template>
<script>

export default {
    name: 'LniOrderSelfInsurerRoaForm',
    computed: {
        selectQuantityOptions() {
            const options = [];
            const data = this.quantityOptions.split(',');
            for (let i = 0; i < data.length; i++) {
                let d = data[i].replace(' ', ''); //Make sure white space is removed (e.g. 100, 200, 300)
                options.push({
                    text: d.toString(),
                    value: Number(d),
                });
            }

            return options;
        },
    },
    mounted() {
        // Back button
        window.addEventListener('pagehide', () => {
            if (this.showSif2Spinner) {
                this.$store.commit('setAttribute', {
                    id: this.id,
                    attribute: 'showSif2Spinner',
                    value: false,
                }, {
                    root: true,
                });
            }
        });

        this.$store.commit(`${this.id}/ADD_TRACKED_ERRORS`, [
            `${this.id}_company-name`,
            `${this.id}_account-id`,
            `${this.id}_contact-name`,
            `${this.id}_contact-phone`,
            `${this.id}_contact-email`,
            `${this.id}_receive-type`,
            `${this.id}_quantity`,
        ]);
        this.$store.commit(
            `setAttribute`, {
                id: 'order-self-insurer-roa-form-request-errors',
                attribute: 'errorsCollectedFrom',
                value: this.trackedErrors,
            });
        this.$store.commit(`${this.id}/INITIALIZE`, {
            oneLni: this.$oneLni,
        });
        if (!this.hasExistingData) {
            document.querySelectorAll('input[type="radio"]').forEach(item => {
                item.checked = false;
            });
        }
        this.$store.dispatch(`${this.id}/syncUi`);
        this.addCustomMessages();
    },
    methods: {
        onFormChange($event) {
            this.$nextTick(() => {
                this.$store.dispatch(`${this.id}/syncStore`, {
                    targetId: $event.target.id,
                    sourceId: this.id,
                    oneLni: this.$oneLni,
                });
            });
        },
        addCustomMessages() {
            this.$store.commit(`${this.id}_account-id/customizeValidationMessage`, {
                validationTest: 'patternMismatch',
                global: state =>
                    `The ${state.labelText} must be 8 digits and begin with a "7".`,
                inline: () =>
                    `Please enter a valid Self-Insurer Account ID.  It must be 8 digits and begin with a "7".`,
            });
        },
        async validate() {
            await this.$store.dispatch('validateForm', {
                targetId: `${this.id}_submit`,
                validation: {
                    errorsCollectedBy: undefined
                }
            })
        },
        async onSubmit() {
            const recaptcha = this.$refs['invisible-recaptcha'].$refs.recaptcha;

            await this.validate()

            const errors = this.$store.state['order-self-insurer-roa-form-request-errors'].errors;
            if (errors && !errors.length) {
                recaptcha.execute();
            }

        },
    },
}; </script>