import encodeDotcmsCookie from '@gov.wa.lni/application.public-website-custom-components/source/utilities/storage/encodeDotcmsCookie.js';

export default (context, payload) => {
    const moduleId = context.getters.moduleId;
    const moduleState = context.rootState[moduleId];

    if (context.rootState[`${moduleId}_errors`].errors < 1) {
        // Show spinner
        context.commit('setAttribute', {
            id: moduleId,
            attribute: 'showModalSpinner',
            value: true,
        }, {
            root: true,
        });

        const sender = {
            companyName: context.rootState[`${moduleId}_company-name`].value,
            contactName: context.rootState[`${moduleId}_contact-name`].value,
            contactPhone: context.rootState[`${moduleId}_contact-phone`].phoneNumber,
            contactPhoneExt: context.rootState[`${moduleId}_contact-phone`].phoneExtension,
            contactEmail: context.rootState[`${moduleId}_contact-email`].value,
        };
        context.commit('SET_SENDER', sender);

        payload.oneLni.storage.cookie.set(
            moduleState.cookie,
            encodeDotcmsCookie(sender),
        );

        window.location.href = moduleState.sendEmailEndpoint;
    }
};