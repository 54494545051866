<template>
    <div class="lni-c-third-party-admins-card lni-c-box--content lni-u-flex lni-u-pa2 lni-u-flex-column">
        <div class="lni-u-text--all-caps lni-u-line-height--tight lni-u-type--lg">
            <div>
                <span>
                    {{ item.claimsContact }}
                </span>
            </div>
        </div>
        <div>
            <div class="lni-c-third-party-admins-card__contact lni-u-type--xxs">
                <div class="lni-c-third-party-admins-card__columns lni-u-flex lni-u-justify-start">
                    <div class="lni-c-third-party-admins-card__address">
                        <div>{{ item.claimsAddressLine1 }}</div>
                        <div>{{ item.claimsAddressLine2 }}</div>
                        <div v-if="item.claimsAddressLine3">
                            {{ item.claimsAddressLine3 }}
                        </div>
                    </div>
                    <div class="lni-c-third-party-admins-card__phones">
                        <div>Ph: {{ item.claimsPhone }}</div>
                        <div>Fax: {{ item.claimsFax }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'lni-third-party-admins-card',

}; </script>