import actions from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-logger-training-lookup/actions';

export default () => ({
    state: {
        page: 1,
        quarterlyTrainings: [],
        annualTrainings: [],
        filterOptions: {
            trainingFrequency: [],
            category: [],
            topic: [],
            type: [],
        },
        filterValues: {
            trainingFrequency: [],
            topic: [],
            category: [],
            type: [],
        },
        searchTerm: '',
        currentFilter: '',
    },
    actions: {
        ...actions,
    },
    mutations: {
        UPDATE_SEARCH_TERM(state, payload) {
            state.searchTerm = payload;
        },
        SET_TRAININGS(state, payload) {
            state.totalCount = payload.results.totalCount;
            state.quarterlyTrainings = payload.results.quarterlyResults;
            state.annualTrainings = payload.results.annualResults;
        },
        UPDATE_FILTER_VALUES(state, payload) {
            state.currentFilter = payload.filter;

            state.filterValues = {
                ...state.filterValues,
                [payload.filter]: payload.value,
            };
        },
        UPDATE_FILTER_OPTIONS(state, payload) {
            state.filterOptions = {
                trainingFrequency: Object.values(payload.trainingFrequency),
                category: Object.values(payload.category),
                topic: Object.values(payload.topic),
                type: Object.values(payload.type),
            };
        },
        CLEAR_FILTER_VALUES(state, payload) {
            state.filterValues = {
                trainingFrequency: payload,
                topic: [],
                category: [],
                type: [],
            };
        },
    },
    getters: {
        filteredResults: (state, getters) => {
            if (getters.searchTerm) {
                return getters.searchTermFilter;
            }

            return [
                ...getters.filteredQuarterlyResults.sort((a, b) => {
                    if (a.title < b.title) {
                        return -1;
                    }
                    if (a.title > b.title) {
                        return 1;
                    }
                    return 0;
                }),
                ...getters.filteredAnnualResults];
        },
        searchTerm: state => state.searchTerm,
        // Provides list of options by name (i.e. topic, type, or category)
        filterList: state => name => {
            const values = state.filterValues[name];
            if (!values.length) {
                return state.filterOptions[name];
            }

            return values.map(value => ({
                text: value,
                value,
            }));
        },
        filteredQuarterlyResults: (state, getters) => {
            let results = [];
            if (state.filterValues.trainingFrequency.includes('Quarterly Training')) {
                getters.filterList('type').forEach(type => {
                    results = [
                        ...results,
                        ...state.quarterlyTrainings.filter(res => res.type === type.value),
                    ];
                });

                let categoryResults = [];
                getters.filterList('category').forEach(category => {
                    categoryResults = [
                        ...categoryResults,
                        ...results.filter(res => res.category.includes(category.value)),
                    ];
                });
                results = results.filter(res => categoryResults.includes(res));

                let topicResults = [];
                getters.filterList('topic').forEach(topic => {
                    topicResults = [
                        ...topicResults,
                        ...results.filter(res => res.topic.includes(topic.value)),
                    ];
                });
                results = results.filter(res => topicResults.includes(res));
            }

            return results;
        },
        filteredAnnualResults: state => {
            if (state.filterValues.trainingFrequency.includes('Annual Training')) {
                return state.annualTrainings;
            }
            return [];
        },
        typeFilterOptions: (state, getters) => {
            const countMap = {};
            getters.filteredResults.forEach(res => {
                state.filterOptions.type.forEach(type => {
                    if (res.type === type.value) {
                        countMap[res.type] = (countMap[res.type] ?? 0) + 1;
                    }
                });
            });

            return state.filterOptions.type.map(opt => ({
                ...opt,
                disabled: !countMap[opt.value],
                count: countMap[opt.value] ?? 0,
            }));
        },
        categoryFilterOptions: (state, getters) => {
            const countMap = {};
            getters.filteredResults.forEach(res => {
                res.category?.forEach(category => {
                    countMap[category] = (countMap[category] ?? 0) + 1;
                });
            });

            return state.filterOptions.category.map(opt => ({
                ...opt,
                disabled: !countMap[opt.value],
                count: countMap[opt.value] ?? 0,
            }));
        },
        topicFilterOptions: (state, getters) => {
            const countMap = {};
            getters.filteredResults.forEach(res => {
                res.topic?.forEach(topic => {
                    countMap[topic] = (countMap[topic] ?? 0) + 1;
                });
            });

            return state.filterOptions.topic.map(opt => ({
                ...opt,
                disabled: !countMap[opt.value],
                count: countMap[opt.value] ?? 0,
            }));
        },
        trainingFrequencyOptions: state => state.filterOptions.trainingFrequency,
        annualTrainings: state => state.annualTrainings,
        quarterlyTrainings: state => state.quarterlyTrainings,
        searchTermFilter: (state, getters) => {
            const trainings = [
                ...getters.filteredAnnualResults,
                ...getters.filteredQuarterlyResults];

            const results = [];
            const deepSearch = training => {
                for (let prop in training) {
                    if (training.hasOwnProperty(prop)
                        && training[prop] !== null
                        && training[prop] !== undefined) {
                        if (typeof training[prop] === 'object') {
                            if (deepSearch(training[prop])) {
                                return true;
                            }
                        } else if (training[prop].toString()
                            .toLowerCase()
                            .includes(getters.searchTerm)) {
                            return true;
                        }
                    }
                }
                return false;
            };

            for (let training of trainings) {
                if (deepSearch(training)) {
                    results.push(training);
                }
            }

            return results;
        },
        currentFilter: state => state.currentFilter,
    },
});