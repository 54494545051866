<template>
    <div
        :class="['lni-c-tout-child',
                 'lni-u-shadow--sm',
                 'lni-u-pa2']"
    >
        <div class="lni-c-tout__container">
            <div class="lni-u-text--all-caps lni-c-tout__title lni-u-mb2">
                {{ touts.title }}
            </div>
            <div class="lni-u-flex lni-u-flex-column lni-u-full-height">
                <div
                    v-for="item in touts.tout"
                    :key="item.title"
                    class="lni-u-mb2"
                >
                    <a
                        class="lni-u-block lni-u-mb1 lni-c-tout__link lni-u-type--bold"
                        :href="item.route"
                    >
                        {{ item.title }}
                    </a>
                    <p class="lni-u-text--sm">
                        {{ item.description | maxLength }}
                    </p>
                </div>
            </div>
            <div>
                <a
                    class="lni-c-touts__link lni-u-type--extra-bold lni-u-text--all-caps lni-u-block lni-u-text--right"
                    :href="seeMoreLink"
                >
                    {{ touts.linkLabel }}
                </a>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: 'LniTout',
    filters: {
        maxLength(val) {
            const max = 165;
            if (val > max) {
                return val.substring(0, max);
            }
            return val;
        },
    },

    computed: {
        seeMoreLink() {
            if (this.touts.index === 'All_Content') {
                return `/agency/site-search?index=${this.touts.index}&query=${this.touts.query}&pg=1&group=10`;
            }

            return `/forms-publications/search?group-translations=true&index=${this.touts.index}&query=${this.touts.query}&pg=1&limit=20`;
        },
    },

}; </script>
<style lang="scss">
@import '@gov.wa.lni/application.public-website/source/components/lni-tout/lni-tout.scss';
</style>