<template>
    <li
        class="lni-c-box--content --card lni-u-mt3">
        <div class="lni-u-flex lni-u-flex-column lni-u-mb2">
            <h3 class="lni-c-search-result__info lni-u-ma0 lni-u-type--normal">
                <a
                    v-if="result.filename"
                    class="lni-c-search-result__link"
                    :href="result.route"
                    target="_blank">
                    {{ result.title }}
                </a>
                <a
                    v-else
                    class="lni-c-search-result__link"
                    :href="result.route">
                    {{ result.title }}
                </a>
            </h3>
            <div class="lni-c-search-result__desc lni-u-mt1">
                {{ result.description }}
            </div>
            <span
                v-if="result.filename"
                :aria-hidden="true"
                :class="['lni-c-search-result__icon', `lnicon--${getIcon}`]">
            </span>
            <!-- Search Result Breadcrumb -->
            <div
                v-if="result.breadcrumbTrail && result.breadcrumbTrail.length > 0"
                class="lni-c-search-result__breadcrumb">
                <div
                    v-for="(crumb, index) in result.breadcrumbTrail"
                    :key="index"
                    class="lni-u-inline-block lni-u-mt1 lni-u-text--all-caps">
                    <span>{{ crumb.label }}</span>
                    <span
                        v-if="index < result.breadcrumbTrail.length - 1"
                        class="lnicon--caret--right lni-u-ph1"></span>
                </div>
            </div>
        </div>
    </li>
</template>
<script>
export default {
    name: 'lni-search-result',
    props: {
        result: {
            type: Object,
            required: true,
        },
    },
}; </script>