<template>
    <div class="lni-c-logger-training-lookup--card lni-c-box--content lni-u-flex-column lni-u-mb2">
        <div
            v-if="card.category"
            class="--category lni-u-mb2"
        >
            {{ category }}
        </div>
        <div class="lni-u-flex lni-u-justify-between">
            <a
                target="_blank"
                class="--title lni-u-text--no-underline"
                :href="card.type === 'Video' ? card.videoUrl : card.fileUri"
            >
                {{ card.title }}
            </a>
            <!-- TODO: Annual trainings and Excel worksheets do not have icons (CMS fix?) -->
            <div
                v-if="card.type === 'Video'"
                class="--format"
            >
                <span
                    class="lnicon--youtube"
                    aria-hidden="true"
                />
                <span class="lni-u-visually-hidden">Video</span>
            </div>
            <div
                v-if="card.type === 'Report'"
                class="--format"
            >
                <span
                    class="lnicon--pdf"
                    aria-hidden="true"
                />
                <span class="lni-u-visually-hidden">PDF</span>
            </div>
            <div
                v-if="card.type?.value === 'PowerPoint'"
                class="--format"
            >
                <span
                    class="lnicon--powerpoint"
                    aria-hidden="true"
                />
                <span class="lni-u-visually-hidden">PDF</span>
            </div>
        </div>
        <div class="lni-u-pt1 lni-u-line-height--tight --desc">
            {{ card.description }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'LniLoggerTrainingCard',
    props: {
        card: {
            type: Object,
            default: () => ({})
        },
    },
    computed: {
        category() {
            return this.card.category.toString().toUpperCase().replace(/,/g, ', ');
        },
    },
};
</script>