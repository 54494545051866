<!-- eslint-disable vue/no-v-html -->
<template>
    <div class="lni-c-site-search">
        <!-- top container -->
        <div class="lni-c-box--content lni-u-mhauto">
            <div class="lni-c-search__container">
                <!-- search controls -->
                <lni-input-search
                    :id="`${id}_query`"
                    :placeholder="content.searchLabel"
                    :query="params['query']"
                    :searchAction="`${id}/search`"
                    :emptySearchAction="`${id}/search`"
                    :inputAction="`${id}/updateSearchTerm`"
                    :clearAction="`${id}/updateSearchTerm`"
                />
                <p class="lni-u-flex lni-u-mt2">
                    <a
                        :id="`${id}_forms-pubs-search-link`"
                        :href="$store.getters[`${id}/formsPubsHref`]"
                        class="lni-u-mlauto lni-u-mr0 lni-c-anchor"
                    >{{ content.formsLinkLabel }} &rarr;</a>
                </p>
            </div>
        </div>
        <div class="lni-c-box--content lni-u-mhauto lni-u-mt3">
            <!-- loader -->
            <div
                v-show="showLoader"
                class="lni-u-text--center lni-u-full-height"
            >
                <lni-spinner
                    id="siteSearchSpinner"
                    :inline="true"
                />
            </div>

            <div
                v-show="!showLoader"
                class="lni-u-mt3 lni-c-search__container"
            >
                <div>
                    <div class="lni-u-flex lni-u-justify-between">
                        <span class="lni-flex-auto">{{ resultsText }}</span>
                    </div>

                    <ul
                        v-if="count > 0"
                        class="lni-u-pa0 lni-c-search__results"
                    >
                        <lni-search-result
                            v-for="(result, index) in results"
                            :id="`result${index}`"
                            :key="index"
                            :result="result"
                        />
                    </ul>
                    <div
                        v-else
                        class="lni-u-text--center lni-u-mv4"
                    >
                        <p>
                            {{ content.resultsErrorPreFix }} -
                            <span class="lni-u-type--bold">{{ params.query }}</span>
                            - {{ content.resultsErrorSuffix }}
                        </p>
                        <lni-html-element
                            :id="`${id}_message`"
                            classString="lni-u-mhauto lni-u-inline-block lni-u-ph3 lni-u-text--left lni-u-mt3"
                            :htmlString="content.resultsHTMLMessage"
                        />
                    </div>
                </div>
                <lni-pager
                    v-show="count > 10"
                    :id="`${id}_pager`"
                    class="lni-u-pt3"
                    :collectionId="id"
                    :page="params.pg"
                    :totalItems="count"
                    :pageSize="10"
                    :showPager="false"
                    @change-page="onPageChange"
                />
            </div>
        </div>
    </div>
</template>

<script>
import LniSearchResult from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-site-search/lni-search-result.vue';

export default {
    name: 'LniSiteSearch',
    components: {
        LniSearchResult,
    },
    props: {
        content: {
            type: Object,
        },
    },
    computed: {
        resultsText() {
            let result = '';
            const firstResult = this.$store.getters[`${this.id}/firstResult`];
            const lastResult = this.$store.getters[`${this.id}/lastResult`];
            if (this.count > 1) {
                result = `${firstResult} - ${this.count >= lastResult ? lastResult : this.count} ${this.content.resultsCountOfLabel} ${this.count} ${this.content.resultsCountLabel}`;
            } else {
                result = `${this.count} ${this.count === 0 ? this.content.resultsCountLabel : this.content.resultsCountLabel.slice(0, -1)}`;
            }

            return result;
        },
    },
    mounted() {
        const params = this.getUrlParams();
        if ( params ) {
            this.$store.commit(`${this.id}/SET_PARAM`, params);
            this.updateControls(params);
        }

        this.$nextTick(() => this.dispatchEvent(`${this.id}/search`));
    },
    methods: {
        // Convert params to object
        //TODO: normalize with search-form (no replaces)
        getUrlParams() {
            const query = window.location.search.substring(1);
            const pairs = query.split('&');
            const params = {};
            if (!query || !pairs.length ) {
                return null;
            }
            pairs.forEach(pair => {
                const keyValue = pair.split('=');
                params[keyValue[0]] = decodeURIComponent(keyValue[1].replace(/\+/g, '%20'));
            });
            return params;
        },
        // Loop through params and update ui control values
        updateControls(params) {
            Object.keys(params).forEach(key => {
                if (key === 'query') {
                    // search-input's value is "query" not "value"
                    this.$store.commit('setAttribute', {
                        id: `${this.id}_${key}`,
                        attribute: 'query',
                        value: params[key],
                    });

                } else {
                    this.$store.commit('setAttribute', {
                        id: `${this.id}_${key}`,
                        attribute: 'value',
                        value: params[key],
                    });
                }
            });
        },
        onPageChange(payload) {
            this.$store.commit(`${this.id}/SET_PARAM`, {
                pg: payload.page,
            }, {
                root: true,
            });
            this.dispatchEvent(`${this.id}/search`, {
                keepPage: true,
            });
        },
    },
}; </script>