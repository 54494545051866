<template>
    <div class="lni-c-self-insured-employers-card lni-c-box--content --card lni-u-pa2 lni-u-mhauto lni-u-flex lni-u-justify-between lni-u-flex-column">
        <div class="lni-u-text--all-caps lni-u-line-height--tight lni-u-type--lg lni-c-self-insured-employers-card__title">
            <div>
                <span>
                    {{ item.businessName }}
                </span>
            </div>
        </div>
        <div class="lni-c-self-insured-employers-card__header lni-u-type--xxs lni-u-mv1">
            <div class="lni-c-self-insured-employers-card__dates lni-u-line-height--tight ">
                Active Self-Insured Employer: <span class="lni-u-nowrap">{{ item.fromDate }} to {{ item.toDate }}</span>
            </div>
        </div>
        <div>
            <div class="lni-c-self-insured-employers-card__contact lni-u-mt1">
                <span class="lni-u-text--all-caps lni-u-type--bold">Claim Contact</span>
                <div class="lni-c-self-insured-employers-card__columns lni-u-flex lni-u-justify-start">
                    <div class="lni-c-self-insured-employers-card__address">
                        <div>{{ item.claimsContact }}</div>
                        <div>{{ item.claimsAddressLine1 }}</div>
                        <div>{{ item.claimsAddressLine2 }}</div>
                        <div>{{ item.claimsAddressLine3 }}</div>
                    </div>
                    <div class="lni-c-self-insured-employers-card__phones">
                        <div>Ph: {{ item.claimsPhone }}</div>
                        <div>Fax: {{ item.claimsFax }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'lni-self-insured-employers-card',

}; </script>