<template>
    <div class="lni-c-self-insured-employers">
        <div>
            <lni-input-search
                :id="`${id}_search-text`"
                class="lni-u-mv2"
                type="search"
                placeholder="Enter an employer name"
                :inputAction="`${id}/search`"
                :clearAction="`${id}/search`"
                :searchAction="`${id}/search`"
                :maxlength="50">
            </lni-input-search>
        </div>
        <div>
            <div class="lni-u-full-width lni-u-mt0 lni-u-mb3 lni-u-bb">
                {{ totalItems }} results
            </div>
            <div v-if="items.length > 0">
                <lni-self-insured-employers-card
                    v-for="(item, index) in items"
                    :id="`self-insured-employers_${item.businessName}_${index}`"
                    :key="`self-insured-employers_${item.businessName}_${item.toDate}_${item.fromDate}_${index}`"
                    :item="item"></lni-self-insured-employers-card>
                <lni-pager
                    :id="`${id}_pager`"
                    :collectionId="id"
                    :pageSize="pageSize"
                    :totalItems="totalItems"
                    :page="page"
                    :columnCount="1"
                    scrollTarget=".lni-c-self-insured-employers"
                    @change-page="onChangePage"
                    @change-page-size="onChangePageSize"></lni-pager>
            </div>
            <div v-else>
                <p class="lni-u-text--center lni-u-mv4 lni-u-mhauto">
                    We couldn't find any employers with this search string. Adjust the search string and try again.
                </p>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'lni-self-insured-employers',

    mounted() {
        this.$watch('pageSize', () => {
            this.updateList();
        });
        this.$watch('page', () => {
            this.updateList();
        });

        this.dispatchEvent(`${this.id}/search`);
    },
    methods: {
        updateList() {
            this.dispatchEvent(`${this.id}/search`);
        },
        onChangePage(data) {
            this.page = data.page;
        },
        onChangePageSize(data) {
            this.pageSize = data.pageSize;
        },
        clearInput() {
            this.page = 1;
            this.totalItems = 0;
            this.$store.commit('setAttribute', {
                id: `${this.id}_search-text`,
                attribute: 'value',
                value: '',
            }, {
                root: true,
            });
            this.updateList();
        },
    },
}; </script>
<style lang="scss">
@import 'lni-self-insured-employers';
</style>