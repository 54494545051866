import setAttribute from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-elevator-opening/actions/setAttribute.js';

export default (context, payload) => {
    setAttribute('openingsFee', context, payload, 0);
    setAttribute('openingsFixed', context, payload, 0);
    setAttribute('value', context, {
        sourceId: `${context.getters.moduleId}_input`,
    }, '');
    setAttribute('value', context, {
        sourceId: `${context.getters.moduleId}_result`,
    }, '');
};