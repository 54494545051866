
export default (context, payload) => {
    let parentId;

    if (payload.targetId.includes('from-date')) {
        parentId = payload.targetId.replace('_from-date', '');
    } else {
        parentId = payload.targetId.replace('_to-date', '');
    }
    const id = payload.targetId.replace('from-date', 'to-date');
    const moduleState = context.rootState[id];
    if (!moduleState.flags.dirty) {
        return;
    }

    context.dispatch(`${id}/customValidate`, {
        targetId: id,
    }, {
        root: true,
    });

    context.dispatch(`${parentId}/handleInputChange`, {
        targetId: id,
    }, {
        root: true,
    });
};