import helpers from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-ergonomic-ideas/helpers';

export default () => ({
    state: {
        results: [],
        filteredResults: null,
        linkedResults: [],
        totalItems: 0,
        filterKeywords: '',
        industryOptions: [],
        hazardOptions: [],
        contentTypeOptions: [],
        contentTypeOptionsCounts: [],
        industryValue: 'all',
        hazardValue: 'all',
        contentTypeValue: [],
        page: 1,
        pageSize: 10,
        stopWords: [],
    },
    getters: {
        resultsIndustryHazardFiltered(state) {
            let ideas = [];
            state.results.forEach(idea => {
                const industryResult = state.industryValue === 'all' ? true
                    : idea.industries.includes(state.industryValue);
                const hazardResult = state.hazardValue === 'all' ? true
                    : idea.hazards.includes(state.hazardValue);

                if (industryResult === true && hazardResult === true) {
                    ideas.push(idea);
                }
            });

            return ideas;
        },
        activeIndustryOptions(state) {
            const activeIndustries = ['all'];
            state.results.forEach(idea => {
                idea.industries.forEach(industry => {
                    if (activeIndustries.indexOf(industry) === -1) {
                        activeIndustries.push(industry);
                    }
                });
            });

            return activeIndustries;
        },
        activeHazardOptions(state) {
            const activeHazards = ['all'];
            state.results.forEach(idea => {
                idea.hazards.forEach(hazard => {
                    if (activeHazards.indexOf(hazard) === -1) {
                        activeHazards.push(hazard);
                    }
                });
            });

            return activeHazards;
        },
    },
    actions: {
        onSuccess(context) {
            const data = context.rootState['ergonomics-api'].responseData.results;
            const moduleId = context.getters.moduleId;
            const moduleState = context.rootState[moduleId];

            //Create 'all' industry option (not available in Category)
            const industryAllOption = {
                text: 'All Industries',
                value: 'all',
            };
            const industries = [...data.industries];
            industries.unshift(industryAllOption);

            //Create 'all' hazard option (not available in Category)
            const hazardAllOption = {
                text: 'All Hazards',
                value: 'all',
            };
            let hazards = [...data.hazards];
            hazards.unshift(hazardAllOption);

            const controlValues = {
                results: data.ideas,
                filteredResults: data.ideas,
                industryOptions: industries,
                hazardOptions: hazards,
                contentTypeOptions: data.types,
                stopWords: data.stopWords,
            };
            context.commit('SET_VALUES', controlValues);

            //Set disabled industry options
            const activeIndustries = context.getters.activeIndustryOptions;
            moduleState.industryOptions.forEach(option => {
                if (activeIndustries.indexOf(option.value) === -1) {
                    option.disabled = true;
                }
            });
            context.commit('setAttribute', {
                id: 'industrySelect',
                attribute: 'options',
                value: moduleState.industryOptions,
            }, {
                root: true,
            });

            //Set disabled hazard options
            const activeHazards = context.getters.activeHazardOptions;
            moduleState.hazardOptions.forEach(option => {
                if (activeHazards.indexOf(option.value) === -1) {
                    option.disabled = true;
                }
            });
            context.commit('setAttribute', {
                id: 'hazardSelect',
                attribute: 'options',
                value: moduleState.hazardOptions,
            }, {
                root: true,
            });

            //Counts are added at filter
            let typeValues = data.types.map(a => a.value);
            context.commit('setAttribute', {
                id: 'typeSelect',
                attribute: 'options',
                value: moduleState.contentTypeOptions,
            }, {
                root: true,
            });
            context.commit('setAttribute', {
                id: 'typeSelect',
                attribute: 'value',
                value: typeValues,
            }, {
                root: true,
            });

            context.dispatch('updateData');
            if (moduleState.linkedResults.length > 0) {
                context.commit('SET_FILTERED_RESULTS', moduleState.linkedResults);

                context.commit('setAttribute', {
                    id: 'keywordInput',
                    attribute: 'value',
                    value: helpers.getCleanString(context, moduleState.filteredResults[0].title),
                }, {
                    root: true,
                });

                //Set content type counts
                context.commit('SET_CONTENT_TYPE_OPTIONS_COUNTS', moduleState.filteredResults);
                context.commit('setAttribute', {
                    id: 'typeSelect',
                    attribute: 'options',
                    value: moduleState.contentTypeOptionsCounts,
                }, {
                    root: true,
                });
            }
        },
        onInitialize(context, payload) {
            context.dispatch('ergonomics-api/sendRequest', payload, {
                root: true,
            });
        },
        onFilterChange(context) {
            context.dispatch('updateData');
        },
        updateData(context) {
            let controlValues =  {
                hazardValue: 'all',
                industryValue: 'all',
                contentTypeValue: [],
                filterKeywords: '',
            };

            if (context.rootState.hazardSelect) {
                const tempControlValues = {
                    hazardValue: context.rootState.hazardSelect.value,
                    industryValue: context.rootState.industrySelect.value,
                    contentTypeValue: context.rootState.typeSelect.value,
                    filterKeywords: context.rootState.keywordInput.value,
                };

                Object.assign(controlValues, tempControlValues);
            }

            context.commit('SET_VALUES', controlValues);
            context.dispatch('filter');
        },
        filter(context) {
            const moduleId = context.getters.moduleId;
            const moduleState = context.rootState[moduleId];

            const ideas = [];

            context.getters.resultsIndustryHazardFiltered.forEach(idea => {
                let typeResult = false;
                moduleState.contentTypeValue.forEach(type => {
                    if (idea.type === type) {
                        typeResult = true;
                    }
                });

                let keywordsResult = true;
                if (moduleState.filterKeywords.length > 0) {
                    const keywords = helpers.getCleanString(context,
                        moduleState.filterKeywords);
                    const titleResult = helpers.getCleanString(context, idea.title)
                        .includes(keywords);
                    const descriptionResult = helpers.getCleanString(context, idea.description)
                        .includes(keywords);

                    let tagsResult = false;
                    idea.frontSideSearchTags.forEach(tag => {
                        if (keywords === tag) {
                            tagsResult = true;
                        }
                    });

                    keywordsResult = titleResult || descriptionResult || tagsResult;

                }

                if (typeResult === true && keywordsResult === true) {
                    ideas.push(idea);
                }
            });

            context.commit('SET_FILTERED_RESULTS', ideas);

            context.commit('setAttribute', {
                id: moduleId,
                attribute: 'page',
                value: 1,
            }, {
                root: true,
            });
            setTimeout(() => {
                context.commit('setAttribute', {
                    id: 'pager',
                    attribute: 'page',
                    value: 1,
                }, {
                    root: true,
                });
                context.commit('setAttribute', {
                    id: 'pager',
                    attribute: 'totalItems',
                    value: moduleState.totalItems,
                }, {
                    root: true,
                });
            });
            //Set content type counts
            context.commit('SET_CONTENT_TYPE_OPTIONS_COUNTS',
                moduleState.filteredResults);
            context.commit('setAttribute', {
                id: 'typeSelect',
                attribute: 'options',
                value: moduleState.contentTypeOptionsCounts,
            }, {
                root: true,
            });
        },
        changePageSizeValue(context, payload) {
            const pageSize = context.rootState[payload.targetId].value;
            const pagerId = payload.sourceId;

            context.commit(`${pagerId}/SET_PAGE_SIZE`, pageSize, {
                root: true,
            });

            context.commit('setAttribute', {
                id: context.getters.moduleId,
                attribute: 'pageSize',
                value: context.rootState.pager.pageSize,
            }, {
                root: true,
            });
        },
    },
    mutations: {
        SET_VALUES(state, payload) {
            Object.keys(payload).forEach(key => {
                state[key] = payload[key];
            });
        },
        SET_FILTERED_RESULTS(state, payload) {
            state.filteredResults = payload.sort((a, b) => {
                if (a.title < b.title) {
                    return -1;
                }

                if (a.title > b.title) {
                    return 1;
                }

                return 0;
            });
            state.totalItems = state.filteredResults.length;
        },
        SET_CONTENT_TYPE_OPTIONS_COUNTS(state, payload) {
            let result = [];
            if (state.results) {
                state.contentTypeOptions.forEach(option => {
                    const count = payload.filter(idea => idea.type === option.value).length;

                    result.push({
                        text: option.text + ' (' + count + ')',
                        value: option.value,
                    });
                });
            }

            state.contentTypeOptionsCounts = result;
        },
    },
});