<template>
    <div class="lni-u-mt2">
        <div class="lni-u-shadow--xs lni-u-pa2 lni-u-pb3">
            <div class="lni-u-flex lni-u-type--xxs">
                <span>{{ policy.policyNumber }}</span>
                <span class="lni-u-mr0 lni-u-mlauto">Issued: {{ policy.dateIssued }}</span>
            </div>
            <h2 class="lni-u-type--md lni-u-type--bold lni-u-line-height--tight lni-u-mt0">
                <a
                    class="lni-u-text--no-underline"
                    :href="policy.shortyUrl">
                    {{ policy.title }}
                </a>
            </h2>
            <p>{{ policy.description }}</p>
            <div
                v-if="policy.related && policy.related.length">
                <h3 class="lni-u-type--sm">
                    This policy references the following WACs:
                </h3>
                <ul class="lni-u-mt0 lni-u-ml3">
                    <li
                        v-for="item in policy.related"
                        :key="item.title">
                        <a
                            class="lni-u-text--no-underline"
                            :href="relatedLink(item)">
                            {{ item.title }}<span class="lni-c-link__meta"> ({{ relatedWac(item) }})</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'lni-enforcement-policy',
    props: {
        policy: {
            type: Object,
            default: () => ({}),
        },
    },
    methods: {
        relatedLink(item) {
            let link = `/safety-health/safety-rules/rules-by-chapter/?chapter=${item.chapter}`;
            if (item.section) {
                link = `${link}#${item.section}`;
            }
            return link;
        },
        relatedWac(item) {
            if (!item.section) {
                return `296-${item.chapter}`;
            }
            return `296-${item.chapter}-${item.section}`;
        },
    },
}; </script>