import calculate from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-simbic-calculator/actions/calculate.js';
import reset from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-simbic-calculator/actions/reset.js';
import resetCalculatedValues from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-simbic-calculator/actions/resetCalculatedValues.js';
import setAttribute from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-simbic-calculator/actions/setAttribute.js';
import formatDate from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-simbic-calculator/actions/formatDate.js';
import formatConversion from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-simbic-calculator/actions/formatConversion.js';
import addPaymentRow from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-simbic-calculator/actions/addPaymentRow.js';
import removePaymentRow from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-simbic-calculator/actions/removePaymentRow.js';

export default {
    calculate,
    reset,
    resetCalculatedValues,
    setAttribute,
    formatDate,
    formatConversion,
    addPaymentRow,
    removePaymentRow,
};