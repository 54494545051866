import actions from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-equal-pay-consultation/actions';

export default () => ({
    state: {
        cookie: 'equalpayconsultation_sender',
        sendEmailEndpoint: '',
        sender: {
            companyName: '',
            contactName: '',
            contactPhone: '',
            contactPhoneExt: '',
            contactEmail: '',
        },
        text: {
            companyName: 'Company/Organization Name',
            contactName: 'Employer Representative Name',
            contactPhone: 'Employer Representative Phone',
            contactEmail: 'Employer Representative Email',
            // eslint-disable-next-line max-len
            contactMessage: 'We will contact you within five business days from when this request is submitted',
            submit: 'Submit',
            errorsHeading: 'Before we submit your request',
        },
        showModalSpinner: false,
    },
    actions: {
        ...actions,
    },
    mutations: {
        SET_SENDER(state, payload) {
            Object.keys(payload).forEach(key => {
                state.sender[key] = payload[key];
            });
        },
    },
});