export default (context, payload) => {
    const targetState = context.rootState[payload.targetId];

    const salary = targetState.value;
    let validity = {};
    if (targetState.validity) {
        validity = {
            ...targetState.validity,
        };
    }

    if (salary.indexOf(',') > -1) {
        const regex = /^(\d{1,3}(,\d{3})?)$/;
        const salaryIsValid = salary.match(regex);

        validity.salaryInvalid = !salaryIsValid;
    } else {
        validity.salaryInvalid = isNaN(salary) || Number(salary) <= 0;
    }

    return validity;
};