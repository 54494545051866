export default context => {
    const parentId = context.getters.moduleId;
    context.state.formData.forEach(field => {
        if (field.value) {
            context.commit('setAttribute', {
                id: `${parentId}_${field.id}`,
                attribute: 'value',
                value: field.value,
            }, {
                root: true,
            });
        }
    });
};