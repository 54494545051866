/* eslint-disable no-negated-condition */

export default (type, val) => {
    const fixNum = 2,
        fixnumPercent = 2,
        toPercent = 100;
    let newVal = '';

    if (val !== undefined && val !== '') {
        switch (type) {
            case 'Currency':
                if (val !== null) {
                    newVal = '$' + parseFloat(val)
                        .toFixed(fixNum)
                        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,');
                } else {
                    newVal = '$0.00';
                }
                break;
            case 'Integer':
                if (val !== null) {
                    newVal = parseInt(val, 10).toString();
                } else {
                    newVal = '';
                }
                break;
            case 'Percent':
                newVal = parseFloat(val * toPercent).toFixed(fixnumPercent) + '%';
                break;
            default:
                newVal = val.toString();
        }
    } else {
        newVal = val.toString();
    }
    return newVal;
};