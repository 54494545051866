export default (context, { error, step }) => {
    context.commit('SET_ERROR', {
        type: step,
        error,
    });
    context.commit('SET_STATUS', {
        type: step,
        pending: false,
    });
    context.dispatch('handleModal', true);

};