<template>
    <div class="lni-c-consultation-request lni-u-mt3">
        <form
            class="lni-u-form-width--base"
            @input="onFormChange($event)"
            @change="onFormChange($event)"
        >
            <lni-spinner
                v-if="showModalSpinner"
                :id="`${id}_spinner`"
            />
            <fieldset class="lni-u-mt3">
                <legend class="lni-u-heading--3">
                    Contact Information
                </legend>
                <lni-input-text
                    :id="`${id}_contact-person`"
                    class="lni-u-mt2"
                    :filled="true"
                    :fullWidth="true"
                    :required="true"
                />
                <lni-input-text
                    :id="`${id}_job-title`"
                    class="lni-u-mt2"
                    :filled="true"
                    :fullWidth="true"
                />
                <lni-input-email
                    :id="`${id}_contact-email`"
                    class="lni-u-mt1"
                    :filled="true"
                    :fullWidth="true"
                    :required="true"
                />
                <lni-input-phone
                    :id="`${id}_contact-phone`"
                    class="lni-u-mt2"
                    :filled="true"
                    :required="true"
                    :isFieldset="false"
                />
                <lni-input-textarea
                    :id="`${id}_contact-preference`"
                    class="lni-u-mt2"
                    :filled="true"
                    :fullWidth="true"
                    :limit="255"
                />
                <lni-select
                    :id="`${id}_preferred-language`"
                    class="lni-u-full-width"
                    :options="preferredLanguages"
                    :changeAction="`${id}/onChange`"
                />
                <lni-collapse
                    :id="`${id}_other-language__collapse`"
                    class="lni-u-mt2"
                    :visible="false"
                >
                    <lni-input-text
                        :id="`${id}_other-language`"
                        :filled="true"
                        :fullWidth="true"
                    />
                </lni-collapse>
            </fieldset>
            <fieldset class="lni-u-mt3">
                <legend class="lni-u-heading--3">
                    Company Information
                </legend>
                <lni-input-text
                    :id="`${id}_company-name`"
                    class="lni-u-mt2"
                    :filled="true"
                    :fullWidth="true"
                    :required="true"
                />
                <lni-input-text
                    :id="`${id}_ubi`"
                    v-constrain="upTo9digitNumber"
                    class="lni-u-mt2"
                    :filled="true"
                    :fullWidth="true"
                    maxlength="9"
                    pattern="[0-9]{9}"
                >
                    <template #helperText>
                        <span>Numbers only, no special characters</span>
                    </template>
                </lni-input-text>
                <div class="lni-u-flex lni-u-gap2">
                    <lni-input-text
                        :id="`${id}_address_zip`"
                        class="lni-u-mt0"
                        :customWidth="8"
                    />
                    <lni-select
                        :id="`${id}_county`"
                        class="lni-u-mt0"
                        :fullWidth="true"
                        :required="true"
                        :options="counties"
                    >
                        <template #helperText>
                            <span>County is not needed if you are outside Washington State</span>
                        </template>
                    </lni-select>
                </div>
            </fieldset>
            <lni-button
                :id="`${id}_submit`"
                text="Submit Request"
                type="button"
                class="lni-u-mt2"
                :errorContainerId="`${id}_errors`"
                :clickAction="['validateForm',`${id}/submit`]"
            />
        </form>
        <div class="lni-u-mt2 lni-u-reading-width--max">
            <lni-error-container
                :id="`${id}_errors`"
                class="lni-u-mt3"
                heading="Before we submit your request"
            />
        </div>
    </div>
</template>

<script>
import constrain from '@gov.wa.lni/framework.one-lni.directives/source/constrain.js';

export default {
    name: 'LniSafetyConsultationRequest',
    directives: {
        constrain,
    },
    data() {
        return {
            upTo8digitNumber: /^[0-9]{0,8}$/,
            upTo9digitNumber: /^[0-9]{0,9}$/,
        };
    },
    async mounted() {
        await this.$store.dispatch(`${this.id}/initialize`, {
            oneLni: this.$oneLni,
        });
        this.dispatchEvent(`${this.id}/syncUi`);
        this.addCustomMessages();
    },
    methods: {
        getLabel(id) {
            return this.$store.state[this.getFullId(id)].labelText;
        },
        getFullId(shortId) {
            return `${this.id}_${shortId}`;
        },
        onFormChange($event) {
            this.$store.dispatch(`${this.id}/syncStore`, {
                targetId: $event.target.id,
                sourceId: this.id,
                oneLni: this.$oneLni,
            });
        },
        addCustomMessages() {
            this.$store.commit(`${this.id}_ubi/customizeValidationMessage`, {
                validationTest: 'patternMismatch',
                global: state =>
                    `The ${state.labelText} must be 9 digits.`,
                inline: () =>
                    `Please enter a valid 9 digit UBI number`,
            });
        },
    },
};
</script>