const counties = [
    {
        region: 0,
        value: 'outOfState',
        text: 'Out of State / Other',
    },
    {
        region: 5,
        value: 'Adams',
        text: 'Adams',
    },
    {
        region: 6,
        value: 'Asotin',
        text: 'Asotin',
    },
    {
        region: 5,
        value: 'Benton',
        text: 'Benton',
    },
    {
        region: 5,
        value: 'Chelan',
        text: 'Chelan',
    },
    {
        region: 3,
        value: 'Clallam',
        text: 'Clallam',
    },
    {
        region: 4,
        value: 'Clark',
        text: 'Clark',
    },
    {
        region: 5,
        value: 'Columbia',
        text: 'Columbia',
    },
    {
        region: 4,
        value: 'Cowlitz',
        text: 'Cowlitz',
    },
    {
        region: 5,
        value: 'Douglas',
        text: 'Douglas',
    },
    {
        region: 6,
        value: 'Ferry',
        text: 'Ferry',
    },
    {
        region: 5,
        value: 'Franklin',
        text: 'Franklin',
    },
    {
        region: 6,
        value: 'Garfield',
        text: 'Garfield',
    },
    {
        region: 5,
        value: 'Grant',
        text: 'Grant',
    },
    {
        region: 4,
        value: 'Grays Harbor',
        text: 'Grays Harbor',
    },
    {
        region: 1,
        value: 'Island',
        text: 'Island',
    },
    {
        region: 3,
        value: 'Jefferson',
        text: 'Jefferson',
    },
    {
        region: 2,
        value: 'King',
        text: 'King',
    },
    {
        region: 3,
        value: 'Kitsap',
        text: 'Kitsap',
    },
    {
        region: 5,
        value: 'Kittitas',
        text: 'Kittitas',
    },
    {
        region: 4,
        value: 'Klickitat',
        text: 'Klickitat',
    },
    {
        region: 4,
        value: 'Lewis',
        text: 'Lewis',
    },
    {
        region: 6,
        value: 'Lincoln',
        text: 'Lincoln',
    },
    {
        region: 4,
        value: 'Mason',
        text: 'Mason',
    },
    {
        region: 5,
        value: 'Okanogan',
        text: 'Okanogan',
    },
    {
        region: 4,
        value: 'Pacific',
        text: 'Pacific',
    },
    {
        region: 6,
        value: 'Pend Oreille',
        text: 'Pend Oreille',
    },
    {
        region: 3,
        value: 'Pierce',
        text: 'Pierce',
    },
    {
        region: 1,
        value: 'San Juan',
        text: 'San Juan',
    },
    {
        region: 1,
        value: 'Skagit',
        text: 'Skagit',
    },
    {
        region: 4,
        value: 'Skamania',
        text: 'Skamania',
    },
    {
        region: 1,
        value: 'Snohomish',
        text: 'Snohomish',
    },
    {
        region: 6,
        value: 'Spokane',
        text: 'Spokane',
    },
    {
        region: 6,
        value: 'Stevens',
        text: 'Stevens',
    },
    {
        region: 4,
        value: 'Thurston',
        text: 'Thurston',
    },
    {
        region: 4,
        value: 'Wahkiakum',
        text: 'Wahkiakum',
    },
    {
        region: 5,
        value: 'Walla Walla',
        text: 'Walla Walla',
    },
    {
        region: 1,
        value: 'Whatcom',
        text: 'Whatcom',
    },
    {
        region: 6,
        value: 'Whitman',
        text: 'Whitman',
    },
    {
        region: 5,
        value: 'Yakima',
        text: 'Yakima',
    },
];
export default counties;