// Single and multiple value selectors differ
// The "specialCases" are the multi value filters, handled on the ui by the multiselects
// The option.value of the multiselect corresponds to the string value of result[filterId].value

// The other single value filters (hazards, projects, industries) are single selects in the ui
// The option.text of the select corresponds to
// one of the multiple string values in the array result[filterId]

const specialCases = ['publicationYear', 'publicationType'];

const updateOptionCounts = (filterId, context) => {
    const newOptions = [...context.state.filterOptions[filterId].map(
        opt => ({
            ...opt,
            count: 0,
            disabled: specialCases.includes(filterId),
        }))];


    // test to see if an option has a value that corresponds to the attribute in a result
    let hasValue = (filterId, result, option) => {
        if (specialCases.includes(filterId)) {
            if (!result[filterId].value) {
                //the result has a null value for some reason TODO: fix api/content
                return false;
            }
            return result[filterId].value === option.value
            || result[filterId].value.toString() === option.value;
        }

        return result[filterId].includes(option.text);
    };
    return context.getters.filteredResults.reduce((options, result) => {

        let index = options.findIndex(option => hasValue(filterId, result, option));
        if (index > -1) {
            options[index].count++;
            options[index].disabled = false;
        }

        return options;
    }, newOptions);
};

export default (context, payload) => {
    if (payload.targetId && payload.targetId !== `${context.getters.moduleId}_button-clear`) {
        //ui is calling search
        // payload.targetId === `${sourceId}_${filterName}`
        const idPrefixLength = `${payload.sourceId}_`.length;
        const currentFilter = payload.targetId.substring(idPrefixLength);
        const isSpecial = filter => specialCases.includes(filter);
        Object.keys(context.state.filterOptions)
            .filter(filter => {
                if (!context.getters.isFiltering) {
                    // reset to initial state (no filters)
                    return true;
                } else if (!isSpecial(filter)) {
                    // reset simple selects
                    return true;
                } else if (filter !== currentFilter) {
                    // reset other thant the current filter
                    return true;
                }
                // don't reset current filter if there are filters in place
                return false;
            })
            .forEach(filter => {
                context.commit('UPDATE_FILTER_OPTIONS', {
                    filter,
                    options: updateOptionCounts(filter, context),
                });
            });
    } else {
        // calling on mounted or from clear button
        // update all filters
        Object.keys(context.state.filterOptions).forEach(filter => {
            context.commit('UPDATE_FILTER_OPTIONS', {
                filter,
                options: updateOptionCounts(filter, context),
            });
        });
    }
};