<template>
    <div>
        <div class="lni-c-lep-calculator__form">
            <lni-informational-messages
                id="lep-calculator-informational-messages"
                :items="[]"
            />

            <lni-error-container
                id="lep-calculator-error-container"
                class="lni-u-mt3"
                :errorsCollectedFrom="[
                    `${id}_prepared-by`,
                    `${id}_worker`,
                    `${id}_claim-number`,
                    `${id}_from-date`,
                    `${id}_to-date`,
                    `${id}_wages-at-doi`,
                    `${id}_current-wages`,
                    `${id}_time-loss-rate`,
                ]"
            />
            <div class="lni-c-lep-calculator_print-inputs-container lni-u-mr5 lni-u-mb2">
                <div class="lni-c-lep-calculator_print-inputs-line">
                    <div class="lni-c-lep-calculator_print-inputs-left-column">
                        <span class="lni-u-type--bold lni-u-mr1">Today's Date:</span><span>{{ currentDate }}</span>
                    </div>
                    <div class="lni-c-lep-calculator_print-inputs-right-column">
                        <span class="lni-u-type--bold lni-u-mr1">Prepared By:</span><span> {{ preparedBy }}</span>
                    </div>
                </div>
                <div class="lni-c-lep-calculator_print-inputs-line">
                    <div class="lni-c-lep-calculator_print-inputs-left-column">
                        <span class="lni-u-type--bold lni-u-mr1">Injured Worker:</span><span> {{ worker }}</span>
                    </div>
                    <div class="lni-c-lep-calculator_print-inputs-right-column">
                        <span class="lni-u-type--bold lni-u-mr1">Claim Number:</span><span> {{ claimNumber }}</span>
                    </div>
                </div>
                <div class="lni-c-lep-calculator_print-inputs-line">
                    <div class="lni-c-lep-calculator_print-inputs-left-column">
                        <span class="lni-u-type--bold lni-u-mr1">From:</span><span> {{ fromDate }}</span>
                    </div>
                    <div class="lni-c-lep-calculator_print-inputs-right-column">
                        <span class="lni-u-type--bold lni-u-mr1">Through:</span><span> {{ toDate }}</span>
                    </div>
                </div>
                <div class="lni-c-lep-calculator_print-inputs-wages-line">
                    <div>
                        <span class="lni-u-type--bold lni-u-mr1">Wages at DOI:</span><span> {{ wagesAtDoi }}</span>
                    </div>
                    <div>
                        <span class="lni-u-type--bold lni-u-mr1">Current Wages:</span><span> {{ currentWages }}</span>
                    </div>
                    <div>
                        <span class="lni-u-type--bold lni-u-mr1">Time Loss Rate:</span><span> {{ timeLossRate }}</span>
                    </div>
                </div>
            </div>
            <div class="lni-c-lep-calculator__inputs-container lni-u-mb3">
                <div class="lni-c-lep-calculator__input-container lni-u-flex lni-u-flex-wrap">
                    <div
                        class="lni-u-relative lni-u-mb3 lni-u-pt2 lni-c-calculation-display__card_width lni-c-lep-calculator__today-date lni-u-mr2 lni-u-mt1"
                    >
                        <label>Today's Date</label>:
                        <span class="lni-u-pl1 lni-u-mr2 lni-u-type--bold">{{ currentDate }}</span>
                    </div>

                    <lni-input-text
                        :id="`${id}_prepared-by`"
                        class="lni-c-lep-calculator__input"
                        labelText="Prepared By"
                        :aria-required="true"
                        :required="true"
                        :dense="true"
                        :filled="true"
                        :changeAction="`${id}/handleInputChange`"
                    />
                </div>
                <div class="lni-c-lep-calculator__input-container lni-u-flex lni-u-flex-wrap">
                    <lni-input-text
                        :id="`${id}_worker`"
                        class="lni-c-lep-calculator__input lni-u-mr2"
                        labelText="Injured Worker"
                        :dense="true"
                        :required="true"
                        :filled="true"
                        :changeAction="`${id}/handleInputChange`"
                    />
                    <lni-input-text
                        :id="`${id}_claim-number`"
                        class="lni-c-lep-calculator__input"
                        labelText="Claim Number"
                        :maxlength="7"
                        type="text"
                        :dense="true"
                        :required="true"
                        :filled="true"
                        :pattern="'^[a-zA-Z]{1}[a-zA-Z0-9]{1}[0-9]{5}$'"
                        :changeAction="`${id}/handleInputChange`"
                    />
                </div>
                <lni-fieldset
                    :id="`${id}_date-fieldset`"
                    legend="Payment Period"
                >
                    <div class="lni-c-lep-calculator__input-container lni-u-flex lni-u-flex-wrap">
                        <lni-input-date
                            :id="id + '_from-date'"
                            class="lni-c-lep-calculator__input lni-u-mr2 lni-u-mt0"
                            labelText="From Date"
                            :required="true"
                            :dense="true"
                            minDate="5/6/1993"
                            helperText=""
                            :changeAction="`${id}/validateFromDate`"
                            :customValidityAction="`${id}/fromDateCustomValidity`"
                        />
                        <lni-input-date
                            :id="id + '_to-date'"
                            class="lni-c-lep-calculator__input lni-u-mt0"
                            pattern="[0-9]"
                            labelText="Through Date"
                            :required="true"
                            :dense="true"
                            minDate="5/6/1993"
                            helperText=""
                            :changeAction="`${id}/validateFromDate`"
                            :customValidityAction="`${id}/toDateCustomValidity`"
                        />
                    </div>
                </lni-fieldset>
                <div class="lni-c-lep-calculator__input-container lni-u-flex lni-u-flex-wrap">
                    <lni-input-text
                        :id="id + '_wages-at-doi'"
                        class="lni-c-lep-calculator__input lni-u-mr2"
                        step=".01"
                        type="number"
                        labelText="Updated Wages at DOI"
                        :required="true"
                        :dense="true"
                        :filled="true"
                        :changeAction="`${id}/handleInputChange`"
                    >
                        <template #helperText>
                            <span>Updated Wages at the Date of Injury (earning capacity).</span>
                        </template>
                    </lni-input-text>
                    <lni-input-text
                        :id="id + '_current-wages'"
                        class="lni-c-lep-calculator__input"
                        step=".01"
                        type="number"
                        :required="true"
                        labelText="Current Wages"
                        :dense="true"
                        :filled="true"
                        :changeAction="`${id}/handleInputChange`"
                    >
                        <template #helperText>
                            <span>Light-duty wages for the payment period.</span>
                        </template>
                    </lni-input-text>
                </div>
                <div class="lni-c-lep-calculator__input-container lni-u-flex lni-u-flex-wrap">
                    <lni-input-text
                        :id="id + '_time-loss-rate'"
                        class="lni-c-lep-calculator__input lni-u-mr2"
                        type="number"
                        step="1"
                        labelText="Time-Loss Rate"
                        :dense="true"
                        :required="true"
                        :filled="true"
                        :changeAction="`${id}/handleInputChange`"
                    >
                        <template #helperText>
                            <span>Time-loss rate multiplied by number of calendar days in the period.</span>
                        </template>
                    </lni-input-text>
                </div>
            </div>

            <div class="lni-c-lep-calculator__buttons-wrapper lni-u-mb3 lni-u-flex lni-u-flex-wrap lni-u-justify-around">
                <lni-button
                    :id="id + '_calculate-button'"
                    ref="calculateWage"
                    errorContainerId="lep-calculator-error-container"
                    text="Calculate"
                    class="lni-c-lep-calculator__submit lni-c-button --primary lni-u-mt2"
                    @click.native="calculateClick"
                />
                <lni-button
                    :id="id + '_reset-button'"
                    text="Reset"
                    errorContainerId="lep-calculator-error-container"
                    type="alternate"
                    :clickAction="`${id}/reset`"
                    classString="lni-c-button --primary lni-u-ml2 lni-u-mt2"
                />
                <lni-button
                    :id="id + '_print-button'"
                    ref="printButton"
                    errorContainerId="lep-calculator-error-container"
                    text="Print Worksheet"
                    classString="lni-c-button --alternate lni-u-ml2 lni-u-mt2"
                    @click.native="printClick"
                />
            </div>
        </div>
        <lni-calculation-display
            :id="id + '_calc_display1'"
            :results="results"
        />
    </div>
</template>

<script>
import * as api from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-lep-calculator/rates.api.js';

export default {
    name: 'LniLepCalculator',

    computed: {
        currentDate() {
            const options = {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
            };
            return new Date().toLocaleDateString('en', options);
        },
    },
    beforeUnmount() {
        this.reset();
    },
    mounted() {
        this.addCustomValidations();
        api.getRates(null, this.$oneLni).then(data => {
            this.$store.commit('setAttribute', {
                id: this.id,
                attribute: 'rates',
                value: data.data,
            });
        });
    },
    methods: {
        printClick() {
            window.print();
        },
        calculateClick() {
            const payload = {
                sourceId: this.id,
                targetId: `${this.id}_calculate-button`,
                validation: {
                    errorsCollectedBy: this.$store.state[this.id].errorsCollectedBy,
                },
            };
            const self = this;
            this.$store.dispatch('validateForm', payload)
                .then(() => {
                    this.$store.dispatch(`${this.id}/calculate`, payload);
                })
                .then(() => {
                    if (self.$store.getters['lep-calculator-error-container/isValid']) {
                        setTimeout(() => {
                            self.$scrollTo(`#${self.id}_calc_display1_calc_display`);
                        }, 0);
                    }
                });
        },
        addCustomValidations() {
            this.$store.commit(`${this.id}_from-date/addValidityTest`, {
                validityTest: 'fromDateAfterToDate',
                global: () => 'The From Date cannot be after the Through Date',
                inline: () => 'The From Date cannot be after the Through Date',
            });
            this.$store.commit(`${this.id}_to-date/addValidityTest`, {
                validityTest: 'toDateBeforeFromDate',
                global: () => 'The Through Date cannot be before the From Date',
                inline: () => 'The Through Date cannot be before the From Date',
            });
        },
    },
}; </script>
<style lang="scss">
@import 'lni-lep-calculator';
</style>