<template>
    <div>
        <lni-input-text
            :id="id + '_input'"
            class="lni-c-elevator__amount-input"
            type="number"
            step=".01"
            pattern="[0-9]*"
            min="2"
            labelText="Total # of openings"
            :changeAction="['validateForm', `${id}/calculate`]"
            :dense="true"
            required
            :customValidityAction="`${id}/numOpeningsCustomValidity`"
            :filled="false"
        />
        <lni-error-container
            :id="`${id}_error-container`"
            heading=""
            :errorsCollectedFrom="[
                id + '_input',
            ]"
        />
        <div class="lni-c-elevator__inputs-wrapper lni-u-mb3">
            <lni-button
                :id="id + '_button1'"
                text="Reset"
                type="alternate"
                :clickAction="`${id}/reset`"
                classString="lni-c-button --primary"
            />
            <lni-button
                :id="id + '_button2'"
                :clickAction="['validateForm', `${id}/calculate`]"
                text="Calculate"
                :errorContainerId="`${id}_error-container`"
                class="lni-c-elevator__submit lni-c-button --primary"
            />
        </div>
        <div
            aria-live="polite"
        >
            <h4 class="lni-c-elevator__result lni-u-type--normal lni-u-mb1">
                Result
            </h4>
            <lni-input-display
                :id="id + '_result'"
                labelText="Elevator Operating Fee"
                :value="openingsFee"
            />
            <div v-if="openingsFee !== ''">
                <!-- TODO: Setup Link to Other and/or Additional Fees -->
                <p>The annual {{ cable ? '' : 'alteration' }} elevator operating certificate fee based on the <strong>{{ openingsFixed }}</strong> elevator openings you entered is <strong>{{ openingsFee }}</strong>. See <a :href="otherFeesLink">"Other and/or Additional Fees"</a> page for other and/or additional fees.</p>
            </div>
        </div>
    </div>
</template>

<script>
import * as api from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-elevator-opening/fees.api.js';

export default {
    name: 'LniElevatorOpening',
    mounted() {
        this.getOpeningFees();
        this.addCustomMessages();
        this.addCustomValidations();
    },
    methods: {
        getOpeningFees() {
            api.getFees(this.$oneLni).then(data => {
                this.$store.dispatch(`${this.id}/updateFee`, data);
            });
        },
        addCustomMessages() {
            this.$store.commit(`${this.id}_input/customizeValidationMessage`, {
                validationTest: 'valueMissing',
                global: () =>
                    `Please enter total # of openings.`,
                inline: () => 'Please fill out this field',
            });
        },
        addCustomValidations() {
            this.$store.commit(`${this.id}_input/addValidityTest`, {
                validityTest: 'numOpeningsLessThan2',
                global: () => 'The number of openings must be 2 or greater.',
                inline: () => 'The number of openings must be 2 or greater.',
            });
        },
    },
}; </script>