import actions from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-dosh-hospitalizations/actions';

export default () => ({
    state: {
        loadState: 'initial',
        errorDetails: null,
        searchState: {
            industry: 999,
            incidentType: 999,
            startDate: null, //startDate <= endDate (filter for 2020 then startDate = 01/01/2020)
            endDate: null, //startDate <= endDate (filter for 2020 then endDate = 12/31/2020)
        },
        emptyIndustry: 'No industry given',
        industries: [],
        industryOtherValue: 81,
        industryDefaultValue: 999, //All
        incidentTypes: [],
        incidentTypeOtherValue: 99,
        incidentTypeDefaultValue: 999, //All
        items: [],
        totalItems: 0,
        currentYear: new Date().getFullYear().toString(),
        currentMonth: (new Date().getMonth() + 1).toString(),
        years: [],
        numOfSummaryYears: null, //null to display all years
        displayDefaultValue: 'default',
    },
    getters: {
        industryOptions: state => {
            const sortedIndustries = [...state.industries].sort((a, b) => {
                if (a.text === b.text) {
                    return 0;
                }

                return a.text < b.text ? -1 : 1;
            });

            return [
                {
                    text: 'All Industries',
                    value: 999,
                },
                ...sortedIndustries,
                {
                    text: 'No Industry Given',
                    value: -1,
                },
            ];
        },
        incidentTypeOptions: state => {
            const sortedIncidentTypes = [...state.incidentTypes].sort((a, b) => {
                if (a.text === b.text) {
                    return 0;
                }

                return a.text < b.text ? -1 : 1;
            });

            return [
                {
                    text: 'All Incident Types',
                    value: 999,
                },
                ...sortedIncidentTypes,
            ];
        },
        displayOptions: (state, getters) => [
            {
                text: 'All events',
                value: 'all',
            },
            {
                text: 'Last 12 months',
                value: 'default',
            },
            ...getters.summaryYears,
        ],
        summaryYears: state => {
            //Make sure current year is included
            let result = [...state.years];
            if (result.some(year => year.text === state.currentYear) === false) {
                result.unshift({
                    text: state.currentYear,
                    value: state.currentYear,
                });
            }
            result = result.map(r => ({
                text: `${r.text} Summary`,
                value: r.value,
            })).sort((a, b) => {
                if (a.text === b.text) {
                    return 0;
                }

                return a.text < b.text ? -1 : 1;
            }).reverse();

            if (state.numOfSummaryYears) {
                result = result.splice(0, state.numOfSummaryYears);
            }

            return result;
        },
        numDaysInMonth: state => month => new Date(state.currentYear, month, 0).getDate(),
    },
    actions: {
        ...actions,
    },
    mutations: {
        UPDATE_SEARCH_STATE(state, payload) {
            Object.keys(payload).forEach(key => {
                state.searchState[key] = payload[key];
            });
        },
    },
});