import encodeDotcmsCookie from '@gov.wa.lni/application.public-website-custom-components/source/utilities/storage/encodeDotcmsCookie.js';

export default (context, payload) => {
    if (context.state.formData.length) {
        payload.oneLni.storage.cookie.set(
            'order-self-insurer-roa-form-request',
            encodeDotcmsCookie({
                formData: context.state.formData,
            }),
            {
                expires: 1,
            },
        );
    } else {
        payload.oneLni.storage.cookie.remove('order-self-insurer-roa-form-request');
    }
};