export default (context, payload) => {
    const minLength = 10;
    const moduleState = context.rootState[payload.targetId];
    const validity = payload.validity;
    validity.fromDateAfterToDate = false;

    if (!moduleState.value || moduleState.value.length < minLength) {
        return validity;
    }

    const fromDate = Date.parse(moduleState.value);

    if (isNaN(fromDate)) {
        return validity;
    }

    const toDateId = payload.targetId.replace('from-date', 'to-date');
    const toDateString = context.rootState[toDateId].value;
    if (!toDateString) {
        return validity;
    }

    const toDate = Date.parse(toDateString);

    if (isNaN(toDate)) {
        return validity;
    }

    validity.fromDateAfterToDate = fromDate > toDate;

    return validity;
};