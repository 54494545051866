export default context => {
    const moduleId = context.getters.moduleId;
    const moduleState = context.rootState[moduleId];

    let addr = {
        ...moduleState.address,
    };
    Object.keys(addr).forEach(key => {
        addr[key] = context.rootState[`${moduleId}_address`][key];
    });

    const controlValues = {
        firstName: context.rootState[`${moduleId}_first-name`].value,
        lastName: context.rootState[`${moduleId}_last-name`].value,
        address: addr,
        claimNumber: context.rootState[`${moduleId}_claim-number`].value,
        jobAddress: context.rootState[`${moduleId}_job-address`].value,
        reportDate: context.rootState[`${moduleId}_report-date`].value,
        reportTime: context.rootState[`${moduleId}_report-time`].value,
        reportTimeType: context.rootState[`${moduleId}_report-time-type`].value,
        scheduledDays: context.rootState[`${moduleId}_scheduled-days`].value,
        scheduledHours: context.rootState[`${moduleId}_scheduled-hours`].value,
        pay: {
            rate: context.rootState[`${moduleId}_pay-rate`].value,
            type: context.rootState[`${moduleId}_pay-type`].value,
        },
        positionOffer: JSON.parse(context.rootState[`${moduleId}_position-offer`].value),
        healthBenefits: JSON.parse(context.rootState[`${moduleId}_health-benefits`].value),
        companyName: context.rootState[`${moduleId}_company-name`].value,
        supervisorName: context.rootState[`${moduleId}_supervisor-name`].value,
        supervisorPhone: context.rootState[`${moduleId}_supervisor-phone`].value,
        contactPhone: context.rootState[`${moduleId}_contact-phone`].value,
    };

    context.commit('SET_VALUES', controlValues);
};