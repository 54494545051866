import getApiData from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-ship-grant-projects/api/ship-grant-projects.api.js';

export default (context, payload) => {
    const moduleId = context.getters.moduleId;
    const module = context.rootState[moduleId];

    getApiData(payload.oneLni).then(data => {
        const projectStatuses = [
            {
                text: 'All Projects',
                value: 'all',
            },
            ...data.projectStatuses];
        context.commit('SET_PROJECT_STATUSES', projectStatuses);
        context.commit('setAttribute', {
            id: `${moduleId}_project-status-option`,
            attribute: 'options',
            value: module.projectStatuses,
        }, {
            root: true,
        });

        context.commit('SET_PROJECT_TYPES', data.projectTypes);
        context.commit('setAttribute', {
            id: `${moduleId}_project-type-option`,
            attribute: 'options',
            value: module.projectTypes,
        }, {
            root: true,
        });

        const industries = [
            {
                text: 'All Industries',
                value: 'allIndustries',
            },
            ...data.industries,
        ];
        context.commit('SET_INDUSTRIES', industries);
        context.commit('setAttribute', {
            id: `${moduleId}_industry-select`,
            attribute: 'options',
            value: module.industries,
        }, {
            root: true,
        });

        context.commit('SET_PROJECTS', data.projects);

        if (context.rootState[`${moduleId}_pager`]) {
            context.commit('setAttribute', {
                id: `${moduleId}_pager`,
                attribute: 'totalItems',
                value: data.projects.length,
            }, {
                root: true,
            });
        }
        context.commit('SET_FILTERED_PROJECTS', data.projects);

        context.commit('SET_STOP_WORDS', data.stopWords);
        context.commit('setAttribute', {
            id: moduleId,
            attribute: 'stopWords',
            value: data.stopWords,
        }, {
            root: true,
        });

        context.commit('setAttribute', {
            id: `${moduleId}_project-status-option`,
            attribute: 'value',
            value: module.projectStatuses[0].value,
        }, {
            root: true,
        });

        let typeValues = [];
        for (let i = 0; i < module.projectTypes.length; i++) {
            typeValues.push(module.projectTypes[i].value);
        }
        if (module.filterKeywords.length === 0 && module.filterType.length > 0) {
            context.commit('setAttribute', {
                id: `${moduleId}_project-type-option`,
                attribute: 'value',
                value: [module.filterType],
            }, {
                root: true,
            });
        } else {
            context.commit('setAttribute', {
                id: `${moduleId}_project-type-option`,
                attribute: 'value',
                value: typeValues,
            }, {
                root: true,
            });
        }

        context.commit('setAttribute', {
            id: `${moduleId}_industry-select`,
            attribute: 'value',
            value: module.industries[0].value,
        }, {
            root: true,
        });
    }).then(() => context.dispatch(`filter`))
        .then(() => context.commit('setAttribute', {
            id: `${moduleId}`,
            attribute: 'hasInitialized',
            value: true,
        }, {
            root: true,
        }));
};