import removeItemById from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-forms-and-pubs-cart/actions/removeItemById.js';
import actions from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-forms-and-pubs-cart/actions';

export default () => ({
    state: {
        cart: [],
        isOpen: false,
    },
    actions: {
        removeItemById,
        ...actions,
    },
    getters: {
        idsInCart(state) {
            return state.cart.length ? state.cart.map(item => item.formId) : [];
        },
    },
    mutations: {
        INITIALIZE_CART(state, payload) {
            state.cart = payload;
        },
        ADD_ITEM(state, payload) {
            state.cart.push({
                ...payload,
                quantity: 1,
            });
        },
        UPDATE_ITEM(state, payload) {
            const indexOfItem = state.cart.findIndex(item => item.formId === payload.formId);
            state.cart.splice(indexOfItem, 1, payload);
        },
        REMOVE_ITEM(state, payload) {
            state.cart.splice(state.cart.indexOf(payload), 1);
        },
    },
});