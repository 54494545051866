<template>
    <div class="lni-c-late-payment lni-u-mt3">
        <div class="lni-u-flex lni-u-flex-wrap">
            <lni-input-text
                :id="`${id}_num-premiums`"
                class="lni-u-mt2 lni-u-mr2"
                type="number"
                required
                step=".01"
                pattern="[0-9]*"
                labelText="Total of Premiums"
                :labelBefore="true"
                :filled="false"
            >
                <span slot="prefix">
                    $
                </span>
            </lni-input-text>
            <lni-input-text
                :id="`${id}_num-months`"
                class="lni-u-mt2"
                type="number"
                required
                step=".01"
                pattern="[0-9]*"
                labelText="Number of Months Late"
                :labelBefore="true"
                :filled="false"
            />
        </div>
        <div class="lni-u-mb3 lni-u-mt2">
            <lni-button
                :id="id + '_calculate'"
                :clickAction="['validateForm', `${id}/calculate`]"
                text="Calculate"
                class="lni-c-button --primary"
                :errorContainerId="`${id}_error-container`"
            />
        </div>
        <div class="lni-u-form-width--base">
            <lni-error-container
                :id="`${id}_error-container`"
                heading=""
                :errorsCollectedFrom="[
                    `${id}_num-premiums`,
                    `${id}_num-months`,
                ]"
            />
        </div>
        <div
            class="lni-u-pt3 lni-u-bt lni-u-b--subtle"
            aria-live="polite"
        >
            <span class="lni-c-late-payment__result lni-u-type--bold lni-u-text--all-caps">
                Result
            </span>
            <lni-input-display
                :id="id + '_result'"
                class="lni-u-mt1 lni-u-mb2"
                labelText="Penalty for Late Reporting"
            />
            <lni-input-display
                :id="id + '_result1'"
                class="lni-u-mb2"
                labelText="Interest"
            />
            <lni-input-display
                :id="id + '_result2'"
                class="lni-u-mb2"
                labelText="Amount Due for This Quarter"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'LniLatePayment',
}; </script>

<style lang="scss">
@import 'lni-late-payment';
</style>