import getContentConfiguration from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-retaliation-complaint/api/getContentConfiguration';
const tokenMap = {
    'Local Development': 'devToken',
    'Public Development': 'devToken',
    'Public Staging': 'demoToken',
    'Public Production': 'prodToken',
};

export const initialize = async (context, { id, oneLni }) => {
    context.commit('SET_STATUS', {
        type: 'initialize',
        pending: true,
    });

    const env = oneLni.environment.current.name;
    const token = tokenMap[env];
    const config = await getContentConfiguration(context, { id,
        oneLni });
    if (!config || config instanceof Error) {
        const error = config ? config.message : `Couldn't get content configuration.`;
        return context.dispatch(`handleError`, {
            step: 'initialize',
            error,
        });
    }

    context.commit('setAttribute', {
        id,
        attribute: 'token',
        value: config[token],
    }, {
        root: true,
    });

    Object.keys(config).forEach(key => {
        if (config[key] !== 'null' && Object.keys(context.state).includes(key)) {
            context.commit('setAttribute', {
                id,
                attribute: key,
                value: config[key],
            }, {
                root: true,
            });
        }
    });

    return context.dispatch('getUiContent', {
        oneLni,
        id,
    });
};

export const getUiContent = async (context, { oneLni }) => {
    const contentMap = context.getters.uiContentMap;
    const contentlets = await context.dispatch('getContentByIds', {
        ids: contentMap.map(({ contentIdentifier }) => contentIdentifier),
        oneLni,
    });
    if (!contentlets || contentlets instanceof Error || contentlets === undefined) {
        const error = contentlets ? contentlets.message : `Couldn't get content configuration.`;
        return context.dispatch(`handleError`, {
            step: 'initialize',
            error,
        });
    }
    contentlets.forEach(contentlet => {
        const { textKey, contentField } = contentMap
            .find(({ contentIdentifier }) => contentIdentifier === contentlet.identifier);
        context.commit(`UPDATE_TEXT_VALUE`, {
            key: textKey,
            value: contentlet[contentField],
        });
    });
    // handle one-lni components
    context.commit('setAttribute', {
        id: 'more-information',
        attribute: 'htmlString',
        value: context.state.text.legalInformation,
    }, { root: true });

    return context.dispatch('handleSuccess', { step: 'initialize' });

};