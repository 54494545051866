export default context => {
    context.commit('setAttribute', {
        id: 'relevance_select',
        attribute: 'value',
        value: '',
    }, {
        root: true,
    });
    context.commit('setAttribute', {
        id: 'channelOptions_multi-select',
        attribute: 'value',
        value: [],
    }, {
        root: true,
    });
};