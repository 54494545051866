const SEARCH_ENDPOINT = '/safety-topics/';

export default (query, oneLni) => {
    const endpoint = `${oneLni.environment.current.apiLocation2}${SEARCH_ENDPOINT}?${query}`;

    return fetch(endpoint, {
        mode: 'cors',
    }).then(response => response.json())
        .then(({
            results, totalResults, filteredResults, aggrs,
        }) => ({
            results,
            totalResults,
            filteredResults,
            aggrs,
        }));
};