import actions from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-ship-grant-projects/actions';

export default () => ({
    state: {
        filterStatus: '',
        filterType: [],
        filterIndustry: '',
        filterKeywords: '',
        projectStatuses: [],
        projectTypes: [],
        industries: [],
        projects: [],
        filteredProjects: [],
        page: 1,
        pageSize: 10,
        stopWords: [],
        hasInitialized: false,
    },
    mutations: {
        SET_PROJECT_STATUSES(state, data) {
            state.projectStatuses = data;
        },
        SET_PROJECT_TYPES(state, data) {
            state.projectTypes = data;
        },
        SET_INDUSTRIES(state, data) {
            state.industries = data;
        },
        SET_PROJECTS(state, data) {
            state.projects = data;
        },
        SET_FILTERED_PROJECTS(state, data) {
            state.filteredProjects = data.sort((a, b) => {
                if (a.displayedProjectTitle < b.displayedProjectTitle) {
                    return -1;
                }
                if (a.displayedProjectTitle > b.displayedProjectTitle) {
                    return 1;
                }
                return 0;
            });
        },
        SET_STOP_WORDS(state, data) {
            state.stopWords = data.stopWords;
        },
    },
    actions: {
        ...actions,
    },
});