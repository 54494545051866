import {
    login,
} from '@gov.wa.lni/application.public-website/source/components/lni-login/actions/login.js';

export default () => ({
    state: {
        headline: '',
        headerLogo: '',
        loginLabel: 'Username',
        loginName: 'username',
        passLabel: 'Password',
        passName: 'password',
        buttonLabel: 'Sign In',
        action: '',
    },
    actions: {
        login,
    },
});