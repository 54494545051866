import getBaseUrl from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-retaliation-complaint/api/getBaseUrl';
const putEmailContent = async (context, {
    oneLni, data,
}) => {
    const API_TOKEN = context.state.token;
    const endpoint = `${getBaseUrl(oneLni)}/api/v1/workflow/actions/0b479965-de10-4768-be6b-12ad472519ad/fire`;
    const body = {
        contentlet: {
            contentType: 'EmailFormSubmission',
            ...data,
        },
    };
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${API_TOKEN}`);
    headers.append('Cookie', `access_token=${API_TOKEN}`);

    const requestOptions = {
        method: 'PUT',
        headers,
        body: JSON.stringify(body),
        redirect: 'follow',
    };
    const response = await fetch(endpoint, requestOptions);
    if (response.headers.get('content-type') === 'text/plain') {
        const textResult = await response.text();
        return textResult;
    }
    const result = await response.json();

    return result;
};


export default putEmailContent;