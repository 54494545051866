export default context => {
    const parentId = context.getters.moduleId;
    let receiveType;
    context.state.formData.forEach(field => {
        if (field.value) {
            context.commit('setAttribute', {
                id: `${parentId}_${field.id}`,
                value: field.value,
                attribute: 'value',
            }, {
                root: true,
            });
        }
        if (field.id === 'receive-type' && field.value === 'PrePrinted') {
            receiveType = 'PrePrinted';
            context.commit('setAttribute', {
                id: 'address-collapse',
                value: true,
                attribute: 'visible',
            }, {
                root: true,
            });

            context.commit('ADD_TRACKED_ERRORS', [`${parentId}_address`]);
        } else if (field.id === 'receive-type' && field.value === 'Email') {
            receiveType = 'Email';
        }
    });

    if (receiveType === 'Email') {
        context.commit('setAttribute', {
            id: `${parentId}_address_address1`,
            attribute: 'value',
            value: '',
        }, {
            root: true,
        });

        context.commit('setAttribute', {
            id: `${parentId}_address_address2`,
            attribute: 'value',
            value: '',
        }, {
            root: true,
        });

        context.commit('setAttribute', {
            id: `${parentId}_address_city`,
            attribute: 'value',
            value: '',
        }, {
            root: true,
        });

        context.commit('setAttribute', {
            id: `${parentId}_address_state`,
            attribute: 'value',
            value: 'WA',
        }, {
            root: true,
        });

        context.commit('setAttribute', {
            id: `${parentId}_address_zip`,
            attribute: 'value',
            value: '',
        }, {
            root: true,
        });
    }
};