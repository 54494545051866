const submitForm = context => {
    window.location.href = context.state.sendEmailEndpoint;
};

export default context => {
    const moduleId = context.getters.moduleId;
    if (context.rootState[`${moduleId}_errors`].errors < 1) {
        context.commit('setAttribute', {
            id: context.getters.moduleId,
            attribute: 'showModalSpinner',
            value: true,
        }, {
            root: true,
        });
        submitForm(context);
    }
};