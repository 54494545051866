// import mockApi from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-enforcement-policies/api/mockApi.json';

const ENFORCEMENT_POLICIES_ENDPOINT = '/enforcement-policies/';
let enforcementPoliciesPromise = null;

export const getPolicies = function(wac, oneLni) {
    let endpoint = `${oneLni.environment.current.apiLocation2}${ENFORCEMENT_POLICIES_ENDPOINT}`;
    if (wac) {
        endpoint = `${endpoint}?wac=${wac}`;
    }

    if (oneLni.environment.current.name === 'Local Development') {

        console.warn(`Using mock enforcement policies data
            - uncomment code in lni-enforcement-policies/api/enforcementPolicies.api.js`);
        // return mockApi;
    }

    if (!enforcementPoliciesPromise) {
        enforcementPoliciesPromise = fetch(endpoint, {
            mode: 'cors',
        }).then(response => response.json())
            .then(data => data.results);
    }
    return enforcementPoliciesPromise;
};