export const SESSION_KEY = 'retaliationComplaint';

export const persist = (context,  {
    oneLni,
}) => {
    oneLni.storage.session.save(SESSION_KEY, context.state.formData);
};

export const loadStateFromSession = (context, payload) => {
    // eslint-disable-next-line no-underscore-dangle
    const session = payload.oneLni.storage.session._backingStore;
    if (session?.length) {
        const sessionData = JSON.parse(session[SESSION_KEY]).data;

        context.commit('setAttribute', {
            id: payload.id,
            attribute: 'formData',
            value: sessionData,
        }, {
            root: true,
        });
    }
};

export const syncStore = (context, payload) => {
    const inputId = payload.targetId;
    const prefixLength = payload.sourceId.length;
    const modelId = inputId.substring(prefixLength + 1);
    const value = context.rootState[inputId].value;
    const label = context.rootState[inputId].labelText;
    context.commit('UPDATE_FORM_ITEM', {
        id: modelId,
        value,
        label,
    });
    context.dispatch('persist', payload);
};

export const syncUi = context => {
    const parentId = context.getters.moduleId;
    context.state.formData.forEach(field => {
        if (field.value) {
            context.commit('setAttribute', {
                id: `${parentId}_${field.id}`,
                attribute: 'value',
                value: field.value,
            }, {
                root: true,
            });
        }
    });
};