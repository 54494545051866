import calculate from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-elevator-opening/actions/calculate.js';
import reset from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-elevator-opening/actions/reset.js';
import numOpeningsCustomValidity from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-elevator-opening/actions/numOpeningsCustomValidity.js';
import updateFee from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-elevator-opening/actions/updateFee.js';

export default {
    updateFee,
    calculate,
    reset,
    numOpeningsCustomValidity,
};