export const formFields = [
    {
        id: 'company-name',
        label: 'Self-Insured Company Name',
    },
    {
        id: 'account-id',
        label: 'Self-Insured Account ID',
    },
    {
        id: 'contact-name',
        label: 'Contact Name',
    },
    {
        id: 'contact-phone_phone',
        label: 'Phone',
    },
    {
        id: 'contact-phone_ext',
        label: 'Ext.',
    },
    {
        id: 'contact-email_input-text',
        label: 'Contact Email',
    },
    {
        id: 'receive-type',
        label: 'How would you like to receive your claim numbers?',
    },
    {
        id: 'quantity',
        label: 'Quantity',
    },
    {
        id: 'address_address1',
        label: 'Address 1',
    },
    {
        id: 'address_address2',
        label: 'Address 2',
    },
    {
        id: 'address_city',
        label: 'City',
    },
    {
        id: 'address_state',
        label: 'State',
        value: 'WA',
    },
    {
        id: 'address_zip',
        label: 'Zip',
    },
];