import Mask from '@gov.wa.lni/framework.one-lni.directives/source/maskUtils.js';

/**
 * Automatically format an input as a user types
 * Currently the only options set the string used as a seperator and position it
 * Only numbers are allowed.
 * TODO: add more robust options.
 * @example `v-mask="{pattern: '###-###-####', separator: '-'}"`
 * @param {HTMLElement} el the html root element of component
 * @param {Object} binding the paramaters set on component
 * @returns {null} nothing
 */

export default {
    bind(el, binding, vnode) {
        el.mask = new Mask(el, vnode, {
            pattern: binding.value.pattern,
            separator: binding.value.separator,
        });
        el.mask.init();

        vnode.context.$on('mask:update', el => {
            vnode.context.$store.commit('setAttribute', {
                id: el.id,
                attribute: 'value',
                value: el.value,
            });
        });
    },
};