import {
    lookup,
} from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-condition-treatment/actions/lookup.js';

export default () => ({
    state: {
        options: [],
        urlPath: '',
        condition: null,
        valid: true,
    },
    actions: {
        lookup,
    },
});