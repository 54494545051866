import calculate from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-lep-calculator/actions/calculate.js';
import reset from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-lep-calculator/actions/reset.js';
import fromDateCustomValidity from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-lep-calculator/actions/fromDateCustomValidity.js';
import toDateCustomValidity from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-lep-calculator/actions/toDateCustomValidity.js';
import validateFromDate from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-lep-calculator/actions/validateFromDate.js';
import validateToDate from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-lep-calculator/actions/validateToDate.js';
import handleInputChange from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-lep-calculator/actions/handleInputChange.js';

export default {
    calculate,
    reset,
    fromDateCustomValidity,
    toDateCustomValidity,
    validateFromDate,
    validateToDate,
    handleInputChange,
};