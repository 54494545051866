export default (context, payload) => {
    const minLength = 10;
    const moduleState = context.rootState[payload.targetId];
    const validity = payload.validity;
    validity.toDateBeforeFromDate = false;

    if (!moduleState.value || moduleState.value.length < minLength) {
        return validity;
    }

    const toDate = Date.parse(moduleState.value);

    if (isNaN(toDate)) {
        return validity;
    }

    const fromDateId = payload.targetId.replace('to-date', 'from-date');

    const fromDateString = context.rootState[fromDateId].value;
    if (!fromDateString) {
        return validity;
    }

    const fromDate = Date.parse(fromDateString);

    if (isNaN(fromDate)) {
        return validity;
    }

    validity.toDateBeforeFromDate = toDate < fromDate;

    return validity;
};