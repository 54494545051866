export default (val, type) => {
    const   fixNum = 2,
        toPercent = 100;
    let     newVal = '';

    switch (type) {
        case 'Currency':
            newVal = '$' + (Math.round(parseFloat(val * toPercent)) / toPercent).toFixed(fixNum)
                .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,');
            break;
        case 'Integer':
            newVal = parseInt(val).toString();
            break;
        case 'Percent':
            newVal = parseFloat(val * toPercent).toFixed(fixNum) + '%';
            break;
        case 'Float':
            newVal = parseFloat(val.replace(/[$,]+/g, ''));
            break;
        default:
            newVal = val.toString();
    }
    return newVal;

};