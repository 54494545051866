<template>
    <lni-intersection-observer
        id="lniTwtEmbeds"
        classNames="lni-c-news-and-data-center__twitter lni-c-box--content"
        rootMargin="500px"
        @intersectionObserverChange="onChange">
        <script
            v-if="twitterEmbeddedLink"
            type="application/javascript"
            defer
            async
            :src="twitterEmbeddedLink"
            charset="utf-8">
        </script>
        <a
            class="twitter-timeline"
            data-height="360"
            href="https://twitter.com/lniwa?ref_src=twsrc%5Etfw"
            data-dnt="true">Tweets from @lniwa</a>
    </lni-intersection-observer>
</template>

<script>

export default {
    name: 'lni-twitter-embeds',
    methods: {
        onChange(entry, unobserve) {
            // After loading Cancel monitoring, optimize performance
            if (entry.isIntersecting) {
                unobserve();
                this.twitterEmbeddedLink = 'https://platform.twitter.com/widgets.js';
            }
        },
    },
};

</script>