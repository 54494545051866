import sharedConstants from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/actions/helpers/constants';
import helper from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/actions/helpers';

export default (context:any) => {

    context.commit(sharedConstants.MUTATIONS.setVideoFormatValue,
        context.rootState.search_videoFormats.value,
    );

    let selectedVideoFormat:string = context.rootState.search_videoFormats.value;
    let videoFormatId:number|null = helper.convertToNumber(selectedVideoFormat,
        sharedConstants.allValue,
    );

    context.commit(sharedConstants.MUTATIONS.setVideoFormatValue,
        videoFormatId,
    );

    if (context.getters.getVideoFormatValue === sharedConstants.allValue) {
        context.commit(sharedConstants.MUTATIONS.setUnfilteredCatalogSearchResults);
    } else {
        context.commit(sharedConstants.MUTATIONS.setFilterCatalogSearchResultsByFormat,
            context.getters.getVideoFormatText);
    }
};