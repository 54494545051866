import * as actions from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-offices/actions/actions.js';

export default () => ({
    state: {
        locations: [],
        officeName: null,
        office: {},
        staticData: {
            TTYLabel: 'TTY (hearing impaired):',
            TTYPhone: '1-800-833-6388',
            mapLabel: 'Get turn-by-turn directions (via Google® Maps)8',
            // eslint-disable-next-line max-len
            mapDisclaimer: '*A free mapping service available from Google® provided as a courtesy. Links from this page do not represent or imply the endorsement of commercial products by the state of Washington, Labor & Industries, or by department staff.',
            hoursOfOperation: '8 a.m. to 5 p.m. Weekdays (except state holidays)',
            hintSelect: 'Select the nearest office to you.',
        },
        callToSchedule: '',
        skipTheLine: {},
    },
    actions: {
        ...actions,
    },
    mutations: {
        ITEM_SELECTED: (state, payload) => {
            state.officeName = payload.location;
            state.office = payload.office;
        },
    },
});