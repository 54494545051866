import formatConversion from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-lep-calculator/actions/formatConversion.js';
import setAttribute from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-lep-calculator/actions/setAttribute.js';

export default (key, context, payload, results) => {
    for (let prop in results) {
        if (results[prop].format !== undefined && results[prop].value !== undefined) {
            results[prop].value = formatConversion(results[prop].format, results[prop].value);
        }
    }
    setAttribute('results', context, payload.sourceId, results);
};