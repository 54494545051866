<template>
    <div>
        <lni-input-text
            id="accountNumber"
            v-mask="{pattern: '###,###-##', separator: [',', '-']}"
            class="lni-u-block"
            :required="true"
            labelText="L&I Account ID"
            pattern="[0-9]{3},[0-9]{3}-[0-9]{2}"
            maxlength="10"
            @value-set="onValueSet('accountNumber')"
            @paste="onPaste('accountNumber')"
        >
            <template #helperText>
                <p>
                    {{ accountNumberExample }}
                </p>
            </template>
        </lni-input-text>
        <lni-input-text
            id="ubi"
            v-mask="{pattern: '### ### ###', separator: ' '}"
            class="lni-u-block"
            :required="true"
            labelText="WA UBI No."
            pattern="[0-9]{3} [0-9]{3} [0-9]{3}"
            maxlength="11"
            @value-set="onValueSet('ubi')"
            @paste="onPaste('ubi')"
        >
            <template #helperText>
                <p>
                    {{ ubiExample }}
                </p>
            </template>
        </lni-input-text>
        <lni-button
            id="quickfile_submit-button"
            :clickAction="['validateForm', `quickfile/fileForm`]"
            text="File Report"
            class="lni-c-button --primary lni-u-mt3 lni-u-mb2 lni-u-self-center"
            errorContainerId="quickfile-form-error-container"
        />
        <lni-error-container
            id="quickfile-form-error-container"
            heading="Before we submit this form:"
            :errorsCollectedFrom="[
                'accountNumber',
                'ubi',
            ]"
        />
        <a
            class="lni-u-mt2 lni-u-block"
            href="https://secure.lni.wa.gov/verify/"
            target="_blank"
            title="Find your account or WA UBI No."
        >Look up my Account ID or UBI number
        </a>
        <div id="errorWrapper">
            <lni-informational-message
                v-if="errorMessage"
                id="fileForm_reason"
                message="File Submission Response"
                level="Error"
                class="lni-u-pa2"
            >
                <p>{{ errorMessage }}</p>
            </lni-informational-message>
        </div>
    </div>
</template>

<script>
import mask from '@gov.wa.lni/framework.one-lni.directives/source/mask.js';

export default {
    name: 'LniQuickfileLogin',
    directives: {
        mask,
    },
    data() {
        return {
            errorMessage: '',
            params: {},
            accountNumberExample: 'Example: 123,456-78',
            ubiExample: 'Example: 123 456 789',
        };
    },
    mounted() {
        this.params = this.getUrlParams();
        this.errorMessage = this.params.Reason;

        if (this.params.Reason) {
            this.$scrollTo(`#errorWrapper`);
        }

        this.addCustomMessages();
    },
    methods: {
        getUrlParams() {
            //TODO: use this when IE gets dropped
            //  = url.searchParams.get('Reason');
            const query = window.location.search.substring(1);
            const pairs = query.split('&');
            const params = {};
            pairs.forEach(pair => {
                const keyValue = pair.split('=');
                params[keyValue[0]] = decodeURI(keyValue[1]);
            });

            return params;
        },
        addCustomMessages() {
            const self = this;
            this.$store.commit(`ubi/customizeValidationMessage`, {
                validationTest: 'patternMismatch',
                global: state =>
                    `Please use the correct format for ${state.labelText} (${self.ubiExample})`,
                inline: () =>
                    `Please use the correct format (${self.ubiExample})`,
            });
            this.$store.commit(`accountNumber/customizeValidationMessage`, {
                validationTest: 'patternMismatch',
                global: state =>
                    `Please use the correct format for ${state.labelText} (${self.accountNumberExample})`,
                inline: () =>
                    `Please use the correct format (${self.accountNumberExample})`,
            });
        },
        onPaste(id) {
            const waitTime = 0;
            const self = this;
            setTimeout(() => {
                // HACK - The masked value isn't being recorded in the store module
                //      so I'm manually forcing it.
                let pastedValue = document.getElementById(id).value;
                self.$store.commit('setAttribute', {
                    id,
                    attribute: 'value',
                    value: pastedValue,
                });
            }, waitTime);
        },
        onValueSet(id) {
            this.$store.dispatch(`${this.id}/updateValue`, {
                sourceId: this.id,
                attribute: id,
                targetId: id,
            });
        },
    },
}; </script>