<template>
    <div>
        <div class="lni-c-youtube__container">
            <iframe
                :id="id"
                class="lni-c-youtube"
                :title="title"
                width="640"
                height="360"
                :src="videoPath"
                frameborder="0"
                allowfullscreen
            />
        </div>
        <a
            v-if="audioDescriptionLink"
            :href="audioDescriptionLink"
            target="_blank"
        >
            Link to video with audio description
        </a>
    </div>
</template>

<script>
export default {
    name: 'LniYoutube',

    computed: {
        // TODO: video: this is a required field
        // TODO: title: this is a required field
        videoPath() {
            return `https://www.youtube.com/embed/${this.video}`;
        },
    },
}; </script>
<style lang="scss">
@import '@gov.wa.lni/application.public-website/source/components/lni-youtube/lni-youtube.scss';
</style>