export default (context, payload) => {
    const moduleId = context.getters.moduleId;

    context.commit('setAttribute', {
        id: `${moduleId}_industry-select`,
        attribute: 'value',
        value: parseInt(context.getters.industryOptions[0].value),
    }, {
        root: true,
    });

    context.commit('setAttribute', {
        id: `${moduleId}_incident-type-select`,
        attribute: 'value',
        value: parseInt(context.getters.incidentTypeOptions[0].value),
    }, {
        root: true,
    });

    context.commit('setAttribute', {
        id: `${moduleId}_display-select`,
        attribute: 'value',
        value: context.getters.displayOptions[1].value,
    }, {
        root: true,
    });

    context.dispatch('filter', {
        oneLni: payload.oneLni,
    });
};