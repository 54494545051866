import ApiOptionsInterface from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/interfaces/apiOptionsInterface';

export default class loadCatalog {

    public listTypeId:string|number = '';

    public getCatalogOptions: ApiOptionsInterface = {
        operation: '',
        httpMethod: 'GET',
        body: '',
    };

    constructor(id:string|number)   {
        this.listTypeId = id;
        this.getCatalogOptions.operation = `/SafetyVideos/Catalog/${this.listTypeId}`;
    };

}