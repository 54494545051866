<script>
import slide from '@gov.wa.lni/framework.one-lni.directives/source/slide.js';

export default {
    name: 'LniQuestion',
    directives: {
        slide,
    },
    mounted() {
        if (this.isAnchored()) {
            this.isExpanded = true;
            const el = document.getElementById(this.id);
            this.$scrollTo(el);
        }
    },
    methods: {
        isAnchored() {
            return this.id === window.location.hash.replace(/#/g, '').replace(/\//g, '');
        },
        buildTrigger(createElement, node, id) {
            let triggerData = {
                attrs: {
                    type: 'button',
                    'aria-controls': `${id}`,
                    'aria-expanded': this.isExpanded.toString(),
                    id,
                },
                class: [
                    node.data.staticClass,
                    'lni-c-questions__toggle',
                    'lni-u-flex',
                    'lni-u-items-center',
                    'lni-u-text--left',
                    'lni-u-pv1',
                    {
                        '--expanded': this.isExpanded,
                    },
                ],
                on: {
                    click: () => {
                        this.isExpanded = !this.isExpanded;
                    },
                    focus: () => {
                        if (this.isAnchored()) {
                            this.isExpanded = true;
                        }
                    },
                },
            };

            let letterQ = createElement(
                'span',
                {
                    class: ['lni-c-questions__letter',
                        'lni-u-block',
                        'lni-u-text--center', 'lni-u-type--xl'],
                    attrs: {
                        'aria-hidden': 'true',
                    },
                },
                [`${this.questionAbbr.toUpperCase()}.`],
            );

            let wrappedTrigger = createElement(
                'span',
                {
                    class: ['lni-c-questions__trigger',
                        'lni-u-mt0'],
                },
                node.children,
            );

            let caret = createElement('span', {
                attrs: {
                    'aria-hidden': 'true',
                },
                class: ['lni-c-questions__indicator',
                    'lni-u-ma0',
                    'lni-u-text--center',
                    this.isExpanded ? 'lnicon--caret--up' : 'lnicon--caret--down'],
            }, [] );

            return createElement(
                'dt',
                {},
                [createElement('button', triggerData, [letterQ, wrappedTrigger, caret])],
            );
        },
        buildTarget(createElement, node) {
            let letterA = createElement(
                'span',
                {
                    class: ['lni-c-questions__letter',
                        'lni-u-block',
                        'lni-u-text--center', 'lni-u-type--xl'],
                    attrs: {
                        'aria-hidden': true,
                    },
                },
                [`${this.answerAbbr.toUpperCase()}.`],
            );

            node.data.class = [
                node.data.staticClass,
                ['lni-c-questions__content'],
            ];

            let answer = createElement('div', {
                class: ['lni-u-flex'],
            }, [letterA, node]);
            return createElement('dd', {
                class: [
                    'lni-c-questions__content-wrapper',
                    'lni-u-overflow-hidden',
                    'lni-u-ma0',
                ],
                directives: [
                    {
                        name: 'slide',
                        class: 'lni-c-questions__content-wrapper',
                        value: this.isExpanded,
                    },
                ],
            }, [answer]);
        },
    },
    render(createElement) {
        let triggers = this.$slots.trigger;
        let targets = this.$slots.target;

        //This map isn't necessary with the current build.  However, if we choose to expand
        //this to create multiple Q&A's this is the path.
        const groupedItems = triggers.map((node, index) => {
            let target = targets[index];
            let id = `${this.id}`;

            return {
                //wrap the inside of the trigger with a button
                //merge the data
                trigger: this.buildTrigger(createElement, node, id),
                target: this.buildTarget(createElement, target, id),
            };
        });

        // Each group is first wrapped in a definition list and then a list item
        // This is because dl's aren't valid if they contain anything but dd or dt
        // https://stackoverflow.com/questions/10875064/is-there-a-valid-way-to-wrap-a-dt-and-a-dd-with-an-html-element

        let item = groupedItems.map(group => createElement('dl', {
            class: [{
                '--expanded': this.isExpanded,
            }],
        }, [group.trigger, group.target]));
        return createElement('li', {
            class: ['lni-c-questions__group'],
        }, [item]);
    },
}; </script>

<style lang="scss">
@import '@gov.wa.lni/application.public-website/source/components/lni-question/lni-question.scss';
</style>