import encodeDotcmsCookie from '@gov.wa.lni/application.public-website-custom-components/source/utilities/storage/encodeDotcmsCookie.js';

export default (context, payload) => {
    // NOTE: extra encoding passing a cookie to inflexible dotCMS velocity viewtool
    const cart = context.state.cart;
    if (cart.length) {
        payload.oneLni.storage.cookie.set(
            'cart',
            encodeDotcmsCookie(cart),
        );
    } else {
        payload.oneLni.storage.cookie.remove('cart');
    }
};