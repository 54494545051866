const FORMS_AND_PUBS_ENDPOINT = '/forms-and-pubs';

export const fetchResults = function(urlParams, oneLni) {
    const apiLocation = oneLni.environment.current.apiLocation2;
    const endpoint = `${apiLocation}${FORMS_AND_PUBS_ENDPOINT}/${urlParams}`;
    let resultsPromise = fetch(endpoint, {
        mode: 'cors',
    }).then(response => response.json())
        .then(data => data);

    return resultsPromise;
};