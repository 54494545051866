const CHANNELS_ENDPOINT = '/safety-topics-channel-options/';
const RELEVANCE_ENDPOINT = '/safety-topics-relevance-options/';
const CATEGORIES_ENDPOINT = '/safety-topics-categories/';

export default oneLni => {
    const channelsEndpoint = `${oneLni.environment.current.apiLocation2}${CHANNELS_ENDPOINT}`;
    const relevanceEndpoint = `${oneLni.environment.current.apiLocation2}${RELEVANCE_ENDPOINT}`;
    const categoriesEndpoint = `${oneLni.environment.current.apiLocation2}${CATEGORIES_ENDPOINT}`;

    const lookupEndpoints = [
        channelsEndpoint,
        relevanceEndpoint,
        categoriesEndpoint,
    ];
    const promises = lookupEndpoints.map(endpoint => fetch(endpoint, {
        mode: 'cors',
    }).then(response => response.json())
        .then(data => data.results));

    return Promise.all(promises);
};