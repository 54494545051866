
import sharedConstants from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/actions/helpers/constants';

export default (context:any) => {

    let selectedVideoType = context.rootState.search_searchType.value;

    if (selectedVideoType === '1') {
        context.commit(sharedConstants.MUTATIONS.setSearchOption, '1');
    } else if (selectedVideoType === '2') {
        context.commit(sharedConstants.MUTATIONS.setSearchOption, '2');
    }

};