import clearFilters from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-dosh-hospitalizations/actions/clearFilters.js';
import filter from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-dosh-hospitalizations/actions/filter.js';
import getTableState from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-dosh-hospitalizations/actions/getTableState.js';
import initialize from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-dosh-hospitalizations/actions/initialize.js';
import setTableData from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-dosh-hospitalizations/actions/setTableData.js';

export default {
    clearFilters,
    filter,
    getTableState,
    initialize,
    setTableData,
};