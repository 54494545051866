<template>
    <div>
        <p>{{ staticData.hintSelect }}</p>
        <lni-select
            :id="`${id}_select`"
            class="lni-u-mb2"
            :changeAction="`${id}/itemSelected`"
            :labelText="staticData.officeSelectLabel"
            :options="options"
            :inline="false"
            :hasInlineMessages="false"></lni-select>
        <div
            v-show="Object.keys(office).length > 0">
            <h2 class="lni-u-heading--2 lni-u-mt3">
                {{ office.title }}, {{ staticData.officeLocationLabel }}
            </h2>
            <div
                v-if="office.phone && office.local"
                class="lni-c-locations">
                <div class="lni-c-locations-child">
                    <h3 class="lni-u-heading--3 lni-u-mb2">
                        {{ staticData.phoneLabel }}<span v-if="office.phone.email">/{{ staticData.emailLabel }}</span>
                    </h3>
                    <div>
                        <span class="lni-u-type--bold">{{ staticData.telLabel }}:</span>
                        <a
                            :href="`tel:${office.phone.telephone}`"
                            :aria-label="ariaPhone(office.phone.telephone)"
                            class="lni-u-inline-block">{{ office.phone.telephone }}
                        </a>
                    </div>
                    <div v-if="office.phone.tollFree">
                        <span class="lni-u-type--bold">{{ staticData.tollFreeLabel }}:</span>
                        <a
                            :href="`tel:+1${office.phone.tollFree}`"
                            :aria-label="ariaPhone(office.phone.tollFree)"
                            class="lni-u-inline-block">{{ office.phone.tollFree }}
                        </a>
                    </div>
                    <div v-if="office.phone.fax">
                        <span class="lni-u-type--bold">{{ staticData.faxLabel }}:</span>
                        <a
                            :href="`fax:${office.phone.fax}`"
                            :aria-label="ariaPhone(office.phone.fax)"
                            class="lni-u-inline-block">{{ office.phone.fax }}
                        </a>
                    </div>
                    <div v-if="office.phone.altFax">
                        <span class="lni-u-type--bold">{{ office.phone.altFaxLabel }}:</span>
                        <a
                            :href="`fax:${office.phone.altFax}`"
                            :aria-label="ariaPhone(office.phone.fax)"
                            class="lni-u-inline-block">{{ office.phone.altFax }}
                        </a>
                    </div>
                    <div v-if="office.phone.altFax1">
                        <span class="lni-u-type--bold">{{ office.phone.altFax1Label }}:</span>
                        <a
                            :href="`fax:${office.phone.altFax1}`"
                            :aria-label="ariaPhone(office.phone.altFax1)"
                            class="lni-u-inline-block">{{ office.phone.altFax1 }}
                        </a>
                    </div>
                    <div>
                        <span class="lni-u-type--bold">{{ staticData.TTYLabel }}:</span>
                        <a
                            :href="`tel:+1${staticData.TTYPhone}`"
                            :aria-label="ariaPhone(staticData.TTYPhone)"
                            class="lni-u-inline-block">{{ staticData.TTYPhone }}</a>
                    </div>
                    <div v-if="office.phone.email">
                        <span class="lni-u-type--bold">{{ staticData.emailLabel }}:</span>
                        <a
                            :href="`mailto:${office.phone.email}`">{{ office.phone.email }}</a>
                    </div>
                    <div v-if="staticData.emailInfo">
                        <p class="lni-u-type--xs lni-u-line-height--tight lni-u-font-color--light">
                            {{ staticData.emailInfo }}
                        </p>
                    </div>
                    <div class="lni-u-mt3">
                        <h3 class="lni-u-heading--3 lni-u-mb2">
                            {{ staticData.locationLabel }}
                        </h3>
                        <address>{{ office.local.address }}, {{ office.local.city }}, WA {{ office.local.zip }}</address>
                        <a
                            :href="office.local.mapLink"
                            target="_blank"
                            class="lni-u-inline-block">{{ staticData.mapLabel }}</a>
                    </div>
                </div>
                <div class="lni-c-locations-child">
                    <h3 class="lni-u-heading--3 lni-u-mb2">
                        {{ staticData.hoursOfOperationLabel }}
                    </h3>
                    <lni-html-element
                        :id="`${id}_office-hours`"
                        :htmlString="office.hours"></lni-html-element>
                    <div
                        v-if="Object.keys(skipTheLine).length > 0"
                        class="lni-u-mt3">
                        <h3 class="lni-u-heading--3 lni-u-mb2">
                            {{ skipTheLine.title }}
                        </h3>
                        <lni-html-element
                            :id="`${id}_skip-the-line`"
                            :htmlString="skipTheLine.body"></lni-html-element>
                    </div>
                    <p
                        v-if="callToSchedule.length > 0"
                        class="lni-u-mt2 lni-u-type--xs lni-u-line-height--tight lni-u-font-color--light">
                        {{ callToSchedule }} {{ office.phone.telephone }}
                    </p>
                    <a
                        v-if="office.bookonline"
                        class="lni-c-button lni-u-mt2 lni-u-inline-block lni-u-text--all-caps"
                        :href="secureUrl">
                        {{ staticData.ctaButtonLabel }}
                    </a>
                </div>
            </div>
            <div
                v-if="office.mail && office.mail.address"
                class="lni-c-locations lni-u-b--subtle lni-u-bt">
                <div class="lni-c-locations-child">
                    <h3 class="lni-u-heading--3">
                        {{ staticData.mailingAddressLabel }}
                    </h3>
                    <address>
                        {{ office.mail.address }}<br>
                        <div v-if="office.mail.address1">
                            <span>{{ office.mail.address1 }}</span><br>
                        </div>
                        {{ office.mail.city }}, WA {{ office.mail.zip }}
                    </address>
                </div>
                <div
                    v-if="office.mail.stateMail"
                    class="lni-c-locations-child">
                    <h3 class="lni-u-heading--3">
                        {{ staticData.stateMailLabel }}
                    </h3>
                    <p>{{ office.mail.stateMail }}</p>
                </div>
            </div>
            <p class="lni-u-type--xxs lni-u-line-height--tight lni-u-font-color--light">
                {{ staticData.mapDisclaimer }}
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'lni-offices',
    computed: {
        options() {
            let options = [];
            this.locations.forEach(local => {
                let option = {
                    text: local.title,
                    value: local.title,
                };
                options.push(option);
            });

            //Move Olympia-Tumwater to top of list
            const index = options.findIndex(local => local.value === 'Olympia-Tumwater');
            let oly = options.splice(index, 1);
            options.unshift(oly[0]);

            return options;
        },
        secureUrl() {
            const current = this.$oneLni.environment.current.name;
            let url = 'https://secure.lni.wa.gov/ScheduleOfficeVisit';
            if (current === 'Public Development' || current === 'Local Development') {
                url = 'https://dev-secure.lni.wa.gov/ScheduleOfficeVisitInt';
            } else if (current === 'Public Staging') {
                url = 'https://test-secure.lni.wa.gov/ScheduleOfficeVisit';
            }

            let result = `${url}/#/?office=${this.officeName.toLowerCase().replace(' ', '')}`;
            const lang = this.$oneLni.getLanguage();
            if (lang === 'es') {
                result += `&lang=${lang}`;
            }
            return result;
        },
    },
    methods: {
        ariaPhone(number) {
            let num = number.replace(/-/g, '.');
            return num.replace(/([.\d])(?=[^\s.$])/g, '$1 ');
        },
    },
}; </script>

<style lang="scss">
@import 'lni-office-locations';
</style>