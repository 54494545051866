import submitOrder from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-order-paper/actions/submitOrder.js';
import {
    setSender,
} from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-order-paper/actions/setSender.js';

export default () => ({
    state: {
        cart: null,
        showModalSpinner: false,
    },
    getters: {
        orders(state) {
            if (state.cart) {
                return Object.keys(state.cart).map(key => ({
                    quantity: state.cart[key].quantity,
                    title: state.cart[key].title,
                    formId: state.cart[key].formId,
                    language: state.cart[key].language,
                }));
            }
            return [];
        },
    },
    actions: {
        submitOrder,
        setSender,
    },
    mutations: {
        setCart: (state, payload) => {
            state.cart = payload;
        },
        SET_SENDER: (state, payload) => {
            payload.rootObj.value = payload.value;
        },
    },
});