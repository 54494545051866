//Sets the options and values of the filter ui (selects and multiselects)

export default context => {
    const filterOptions = context.state.filterOptions;
    const values = context.state.filterValues;
    const filterIds = Object.keys(filterOptions);

    filterIds.forEach(id => {
        const controlModuleId = `${context.getters.moduleId}_${id}`;
        const options = filterOptions[id];

        context.commit('setAttribute', {
            id: controlModuleId,
            attribute: 'options',
            value: options,
        }, {
            root: true,
        });

        context.commit('setAttribute', {
            id: controlModuleId,
            attribute: 'value',
            value: values[id],
        }, {
            root: true,
        });
    });
};