export const getFees = function(oneLni, alternateApiLocation ) {
    const apiLocation = alternateApiLocation
        ? alternateApiLocation : oneLni.environment.current.apiLocation2;
    const endpoint = `${apiLocation}/elevator-fees?calculator=opening`;

    return fetch(endpoint)
        .then(res => res.json())
        .then(body => body.data)
        .then(data => ({
            openingPricePer: data.openingPricePer,
            baseFee: data.baseFee,
            baseCableFee: data.baseCableFee,
            minimumOpenings: data.minimumOpenings,
        }));
};