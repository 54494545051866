import getApiData from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-news-and-data-center/api/news-and-data-center.api.js';

export default (context, payload) => {
    const moduleId = context.getters.moduleId;
    const moduleState = context.rootState[moduleId];

    context.commit('SET_ATTRIBUTE', {
        attribute: 'showListSpinner',
        value: true,
    });

    context.commit('SET_ATTRIBUTE', {
        attribute: 'selectedYearValue',
        value: context.rootState['news-release-year'].value,
    });

    getApiData({
        key: 'year',
        value: moduleState.newsReleaseYears[moduleState.selectedYearValue].text,
    }, payload.oneLni).then(data => {
        context.commit('SET_ATTRIBUTE', {
            attribute: 'text',
            value: data.text,
        });
        context.commit('setAttribute', {
            id: 'news-release-year',
            attribute: 'labelText',
            value: data.text.selectYear,
        }, {
            root: true,
        });
        context.commit('SET_ATTRIBUTE', {
            attribute: 'newsReleases',
            value: data.newsReleases,
        });

        context.commit('SET_ATTRIBUTE', {
            attribute: 'latestRelease',
            value: data.latestRelease,
        });

        context.commit('SET_ATTRIBUTE', {
            attribute: 'showListSpinner',
            value: false,
        });
    });
};