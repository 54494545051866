<template>
    <div class="lni-u-mhauto lni-u-flex lni-u-justify-between lni-u-flex-column">
        <a
            href
            class="lni-u-type--medium lni-c-shrl-card__column1 lni-u-bold lni-u-type--md lni-u-text--no-underline"
            @click.prevent="$emit('hide-detail')"
        >
            <span
                aria-hidden="true"
                class="lnicon--caret--left"
            />
            Video Library Search
        </a>
        <div>
            <div class="lni-u-mb1">
                <h2 class="lni-u-heading--3 lni-u-mb2 lni-u-mt2">
                    <span class="lni-u-block">{{ $props.videoAdditionalDetails.titleName }}</span>
                    <span
                        v-if="$props.videoAdditionalDetails.foreignTitleName"
                        class="lni-u-block"
                    >
                        {{ $props.videoAdditionalDetails.foreignTitleName }}
                    </span>
                </h2>
            </div>
            <div v-html="formattedDescription" />
            <div
                v-if="enableLegacyFeatures.subjectsCovered"
                class="lni-u-mt2"
            >
                <b>Subjects Covered:</b> {{ $props.videoAdditionalDetails.subjects }}
            </div>
            <LniSafetyVideoMetaData
                :titleName="$props.videoDetails.titleName"
                :titleId="$props.videoDetails.titleId"
                :videoLength="$props.videoDetails.videoLength"
                :productionDate="$props.videoDetails.productionDate"
                :languages="$props.videoDetails.languages"
                :titleFormats="$props.videoDetails.titleFormats"
                :showBackToTopDetails="false"
            />
            <div v-if="enableLegacyFeatures.closedCaptioningSubtitles">
                <b>Closed Captioning/Subtitles:</b> {{ isClosedCaption }}
            </div>
        </div>
        <LniSafetyVideoOnlineDetail
            :videoAdditionalDetails="$props.videoAdditionalDetails"
            class="lni-u-mt2"
        />
    </div>
</template>

<script>
import helper from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/actions/helpers';
import sharedConstants from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/actions/helpers/constants';
import LniSafetyVideoOnlineDetail from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/lni-safety-video-online-detail.vue';
import LniSafetyVideoMetaData from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/lni-safety-video-metadata.vue';

export default {
    name: 'LniSafetyVideoSearchDetail',
    components: {
        LniSafetyVideoOnlineDetail,
        LniSafetyVideoMetaData,
    },
    props: {
         videoDetails: {
            type: Object,
            default: () => ({}),
         },
         videoAdditionalDetails: {
            type: Object,
            default: () => ({}),
         }
    },
    emits: ['hide-detail', 'hideDetail', 'showVideoDetail'],
    data() {
        return {
            enableLegacyFeatures: {
                subjectsCovered: true,
                closedCaptioningSubtitles: true,
                languagesAvailable: true,
            },
            descriptionData: ''
        };
    },
    computed: {
        formattedDescription() {
            return this.$props.videoDetails.description.replace(/\n/g, "<br>");
        },
        parseVideoFormats() {
            return this.$props.videoAdditionalDetails.formats.split(' / ');
        },
        isClosedCaption() {
            let isCc = this.$props.videoAdditionalDetails.hasClosedCaption === 'l';
            if (isCc) {
                return 'Yes';
            }
            return 'No';
        },
        isCc() {
            let formats = helper.safeCasing(this.$props.videoAdditionalDetails.formats,
                sharedConstants.toLower);
            if (helper.isNullOrEmptyString(formats)) {
                return false;
            }
            let includesCc = formats.includes('cc');
            return includesCc;
        },
        refreshDescriptionData() {
            return this.$props.videoDetails.description;
        },
    },
};
</script>