import getContent from '@gov.wa.lni/application.public-website/source/components/lni-site-feedback/actions/getContent.js';

export default (context, payload) => new Promise(resolve => {
    const moduleId = context.getters.moduleId;

    getContent(`${moduleId}_result-messages`, context, payload.oneLni)
        .then(response => {
            context.commit('setAttribute', {
                id: moduleId,
                attribute: 'labels',
                value: response.labels,
            }, {
                root: true,
            });

            context.commit('setAttribute', {
                id: moduleId,
                attribute: 'helperText',
                value: response.helperText,
            }, {
                root: true,
            });

            context.commit('setAttribute', {
                id: moduleId,
                attribute: 'validation',
                value: response.validation,
            }, {
                root: true,
            });

            context.commit('setAttribute', {
                id: moduleId,
                attribute: 'messages',
                value: response.messages,
            }, {
                root: true,
            });

            setTimeout(() => {
                context.commit('setAttribute', {
                    id: `${moduleId}_comment-type`,
                    attribute: 'options',
                    value: response.commentTypes,
                }, {
                    root: true,
                });

                context.commit('setAttribute', {
                    id: `${moduleId}_customer-type`,
                    attribute: 'options',
                    value: response.userTypes,
                }, {
                    root: true,
                });

                resolve();
            }, 0);
        });
});