export default  context => {
    const id = context.getters.moduleId;
    const pagerId = `${id}_pager`;

    context.commit(`${pagerId}/SET_PAGE`, context.state.params.pg, {
        root: true,
    });
    context.commit(`${pagerId}/SET_TOTAL_ITEMS`, context.state.count, {
        root: true,
    });
};