<template>
    <div class="lni-c-find-enforcement-policies lni-u-mt3">
        <lni-input-search
            :id="`${id}_search`"
            type="search"
            placeholder="Search Enforcement Policies"
            :blurAction="`${id}/filterWithUrlUpdate`"
            :searchAction="`${id}/filterWithUrlUpdate`"
            :emptySearchAction="`${id}/filterWithUrlUpdate`"
            :inputAction="`${id}/filter`"
            :clearAction="`${id}/filterWithUrlUpdate`"
            :query="query"
        />
        <lni-select
            :id="`${id}_sort`"
            class="lni-u-mt3"
            :filled="false"
            :options="sortOptions"
            labelText="Sort by:"
            :hideLabel="true"
            :isColumn="false"
            :customWidth="12"
            :changeAction="`${id}/sort`"
            :value="sort"
        />
        <div
            aria-live="polite"
            class="lni-u-mt2"
        >
            <p v-if="policyCount === 0 && !showLoader">
                Sorry, no enforcement policies matched your search of enforcement policies for "{{ query }}".
            </p>
        </div>
        <transition name="lni-o-fade">
            <div v-if="!showLoader">
                <div
                    v-show="policyCount > 0"
                    class="lni-c-find-enforcement-policies__results lni-u-mt2"
                >
                    <p class="lni-u-type--xs">
                        Found {{ policyCount }} enforcement policies
                        <span
                            v-if="wac"
                        >
                            related to Chapter {{ relatedChapter }}
                        </span>
                    </p>
                    <lni-enforcement-policy
                        v-for="policy in currentPagePolicies"
                        :key="policy.policyNumber"
                        :policy="policy"
                    />
                    <lni-pager
                        :id="`${id}_pager`"
                        class="lni-u-pt3"
                        :collectionId="id"
                        :page="currentPage"
                        :totalItems="policyCount"
                        :pageSize="itemsPerPage"
                        :showPager="false"
                        selectLabel="Items per page"
                        @change-page="onPageChange"
                    />
                </div>
            </div>
        </transition>
        <div
            v-show="showLoader"
            class="lni-u-text--center lni-u-full-height"
        >
            <lni-spinner
                id="policySpinner"
                :inline="true"
            />
        </div>
    </div>
</template>

<script>
import * as api from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-enforcement-policies/api/enforcementPolicies.api';
import LniEnforcementPolicy from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-enforcement-policies/lni-enforcement-policy.vue';

export default {
    name: 'LniEnforcementPolicies',
    components: {
        LniEnforcementPolicy,
    },

    data() {
        return {
            currentHash: '',
            showLoader: true,
        };
    },
    computed: {
        relatedChapter() {
            if (!this.wac) {
                return '';
            }

            const wacParts = this.wac.split('-');
            if (wacParts.length <= 1) {
                return '';
            }

            return wacParts[1];
        },
        searchedPolicies() {
            return this.$store.getters[`${this.id}/searchedPolicies`];
        },
        policyCount() {
            return this.$store.getters[`${this.id}/resultCount`];
        },
        lastPage() {
            return Math.ceil(parseInt(this.policyCount, 10) / this.itemsPerPage);
        },
        currentPagePolicies() {
            const start = ( this.currentPage - 1 ) * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            return this.searchedPolicies.slice(start, end);
        },
        urlParams() {
            return this.$store.getters[`${this.id}/urlParams`];
        },
    },
    mounted() {
        const initState = this.getUrlParams();
        //Fetch or get promise
        this.getPolicies(initState);

        this.$watch('isLoaded', isLoaded =>  {
            if ( isLoaded ) {
                this.updateState(initState);
                this.hideLoader();
            } else {
                this.showLoader = true;
            }
        });
    },
    updated() {
        this.updatePageCount();
    },
    methods: {
        hideLoader() {
            const minLoaderTime = 1200;
            setTimeout(self => {
                self.showLoader = false;
            }, minLoaderTime, this);
        },
        onPageChange(payload) {
            this.$store.commit(`${this.id}/SET_PAGE`, payload.page);
            const params = this.$store.getters[`${this.id}/params`];
            this.updateState(params);
        },
        updatePageCount() {
            if (!this.showLoader) {
                this.$store.commit('setAttribute', {
                    id: `${this.id}_pager`,
                    attribute: 'totalItems',
                    value: this.policyCount,
                });
            }
        },
        updateState(state) {
            if ( state.query ) {
                this.$store.commit(`${this.id}/SET_SEARCH_STRING`, state.query);
                this.$store.commit('setAttribute', {
                    id: `${this.id}_search`,
                    attribute: 'query',
                    value: state.query,
                });
            }
            if ( state.wac ) {
                this.$store.commit(`${this.id}/SET_WAC`, state.wac);
            }
            if ( state.sort
                && this.sortOptions.find(option => option.value === state.sort)) {
                this.$store.commit(`${this.id}/SET_SORT`, state.sort);
                this.$store.commit('setAttribute', {
                    id: `${this.id}_sort`,
                    attribute: 'value',
                    value: state.sort,
                });
            }
            if ( state.page && !this.showLoader) {
                const validPage = parseInt(state.page, 10) > this.lastPage ? 1 : parseInt(state.page);
                this.$store.commit(`${this.id}/SET_PAGE`, validPage);
                this.$store.commit('setAttribute', {
                    id: `${this.id}_pager`,
                    attribute: 'page',
                    value: validPage,
                }, {
                    root: true,
                });
            }
            // Because we may not take all the values from hash, we need to set them
            this.setURLParams();
        },
        /* Get all params */
        getUrlParams() {
            const query = window.location.search.substring(1);
            const pairs = query.split('&');
            const params = {};
            if (!query || !pairs.length) {
                return {};
            }
            pairs.forEach(pair => {
                const keyValue = pair.split('=');
                params[keyValue[0]] = decodeURIComponent(keyValue[1].replace(/\+/g, '%20'));
            });
            return params;
        },
        buildParams(options) {
            let url = `query=${encodeURI(options.query)}`;
            if (options.wac) {
                url = `${url}&wac=${options.wac}`;
            }
            url = `${url}&sort=${encodeURI(options.sort)}&page=${encodeURI(options.page)}&index=${encodeURI(options.index)}`;
            return url;
        },
        /* Create params from the state */
        setURLParams() {
            if (this.isLoaded) {
                this.$scrollTo(`#${this.id}_search_search-text`);
            }

            const urlParts = window.location.href.split('?');
            const newURL = `${urlParts[0]}?${this.urlParams}`;
            history.pushState({
                path: newURL,
            }, document.title, newURL);
        },
        /* Parse, sort, enhance, and add policies to store
             Done once
        */
        saveData(data) {
            this.$store.commit('setAttribute', {
                id: this.id,
                attribute: 'policies',
                value: data,
            });
            this.$store.commit('setAttribute', {
                id: this.id,
                attribute: 'isLoaded',
                value: true,
            });
        },
        /* Get all the policies and chapters and process */
        getPolicies(initState) {
            let policyPromise = api.getPolicies(initState.wac, this.$oneLni);
            Promise.resolve(policyPromise).then( data => {
                this.saveData(data);
            });
        },
    },
}; </script>