<template>
    <div class="lni-c-dosh-hospitalizations lni-u-pt3 lni-u-mt3 lni-u-b--subtle lni-u-bt">
        <div
            v-show="showPageSpinner">
            <lni-spinner
                :id="`${id}_page-spinner`"
                :inline="false"></lni-spinner>
        </div>
        <div v-show="!showPageSpinner">
            <div
                v-if="loadState === 'error'">
                <span
                    role="alert"
                    class="lni-c-alert lni-u-mb3 --error">
                    <span
                        aria-hidden="true"
                        class="lnicon--exclamation"></span>
                    There was a problem with the network. Please try again. (Details: {{ errorDetails }})
                </span>
            </div>
            <lni-select
                :id="`${id}_industry-select`"
                class="lni-u-full-width lni-u-mb2"
                labelText="Industry"
                :changeAction="`${id}/filter`"></lni-select>
            <lni-select
                :id="`${id}_incident-type-select`"
                class="lni-u-full-width lni-u-mb2"
                labelText="Incident Type"
                :changeAction="`${id}/filter`"></lni-select>
            <lni-select
                :id="`${id}_display-select`"
                class="lni-u-full-width lni-u-mb3"
                labelText="Display records for"
                :changeAction="`${id}/filter`"></lni-select>
            <lni-button
                :id="`${id}_reset`"
                text="Clear All"
                class="lni-c-button --primary lni-u-mb3"
                :clickAction="`${id}/clearFilters`"></lni-button>
            <div
                v-show="showTableSpinner"
                class="lni-u-flex lni-u-justify-center">
                <lni-spinner
                    :id="`${id}_table-spinner`"
                    :inline="true"></lni-spinner>
            </div>
            <div
                v-show="!showTableSpinner"
                class="lni-c-dosh-hospitalizations__table lni-u-b--subtle lni-u-bt lni-u-pv3">
                <span class="lni-u-type--bold">
                    {{ totalItems }} records shown
                </span>
                <lni-table
                    :id="`${id}_table`"
                    :setDataAction="`${id}/setTableData`"
                    :inPlacePagingAndSorting="false"
                    scrollTarget=".lni-c-dosh-hospitalizations"
                    :items="null"
                    class="lni-u-page-width--max"
                    :pageSize="15"
                    :page="1"
                    :pageSizeOptions="[
                        {
                            text: '15',
                            value: 15,
                        },
                        {
                            text: '30',
                            value: 30,
                        },
                        {
                            text: '50',
                            value: 50,
                        },
                        {
                            text: '100',
                            value: 100
                        },
                    ]"
                    noRowsMessage="Your search returned no results. Please try different search criteria."
                    errorMessage="Error getting data"
                    caption="Hospitalizations"
                    summary="This table shows injuries and illnesses by date, industry, and incident type."
                    :sort="{
                        eventDate: 'desc',
                    }"
                    :columns="[
                        {
                            source: 'eventDate',
                            displayText: 'Event Date',
                            sortable: true,
                            headerClasses: 'lni-u-ph2',
                        },
                        {
                            source: 'description',
                            displayText: 'Description of Injury or Illness',
                            sortable: true,
                            headerClasses: 'lni-u-ph2',
                        },
                        {
                            source: 'naicsIndustryDescription',
                            displayText: 'Industry',
                            sortable: true,
                            headerClasses: 'lni-u-ph2',
                        },
                        {
                            source: 'incidentTypeDescription',
                            displayText: 'Incident Type',
                            sortable: true,
                            headerClasses: 'lni-u-ph2',
                        }
                    ]">
                    <template
                        slot="body"
                        slot-scope="props">
                        <tr
                            :key="props.item.id">
                            <td class="lni-u-ph2 lni-c-dosh-hospitalizations__table-cell">
                                {{ props.item.eventDate }}
                            </td>
                            <td class="lni-u-ph2 lni-c-dosh-hospitalizations__table-cell">
                                {{ props.item.description }}
                            </td>
                            <td class="lni-u-ph2 lni-c-dosh-hospitalizations__table-cell">
                                {{ props.item.naicsIndustryDescription }}
                            </td>
                            <td class="lni-u-ph2 lni-c-dosh-hospitalizations__table-cell">
                                {{ props.item.incidentTypeDescription }}
                            </td>
                        </tr>
                    </template>
                </lni-table>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'lni-dosh-hospitalizations',

    computed: {
        showPageSpinner() {
            return this.loadState === 'initial' || this.loadState === 'loading';
        },
        showTableSpinner() {
            let result = true;

            const table = this.$store.state[`${this.id}_table`];
            if (table) {
                result = !table.itemsAreLoaded;
            }

            return result;
        },
    },
    mounted() {
        this.dispatchEvent(`${this.id}/initialize`, {}, {
            root: true,
        });
    },
};
</script>
<style lang="scss">
@import 'lni-dosh-hospitalizations';
</style>