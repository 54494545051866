import sharedConstants from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/actions/helpers/constants';
import postToService from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/actions/postToService';
import helper from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/actions/helpers';
import LoadCatalog from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/classes/loadCatalog';

export default function loadCatalogData(context:any, payload:any) {
    const oneLni = payload.oneLni;

    const loadCatalog = new LoadCatalog(payload.linkId);
    context.commit(sharedConstants.MUTATIONS.setQuicklinkType, payload.linkId);
    context.commit(sharedConstants.MUTATIONS.updateLoadingState, true);

    postToService(loadCatalog.getCatalogOptions,
        oneLni).then((response:
            {
                ok: any;
                data: any;
            }) => {

        if (!response.ok) {
            context.commit(sharedConstants.MUTATIONS.updateLoadingState, false);
            helper.showDialog(context, sharedConstants.DIALOGS.dialogException, true);
            return;
        }

        const jsonData = response.data.catalogList;

        context.commit(sharedConstants.MUTATIONS.setVideoCatalog, jsonData);
        const alphabetData = context.getters.getCatalogAvailabilityAlphabet;
        context.commit(sharedConstants.MUTATIONS.setVideoCatalogAlphabet,
            alphabetData,
        );
        const groupedData = context.getters.getGroupedCatalogData;
        context.commit(sharedConstants.MUTATIONS.setVideoCatalogGrouped,
            groupedData,
        );
        context.commit(sharedConstants.MUTATIONS.updateLoadingState, false);

    });

}