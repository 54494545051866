import calculate from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-preferred-worker-calculator/actions/calculate.js';
import experienceCustomValidation from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-preferred-worker-calculator/actions/experienceCustomValidation.js';
import initialize from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-preferred-worker-calculator/actions/initialize.js';
import riskClassCustomValidation from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-preferred-worker-calculator/actions/riskClassCustomValidation.js';

export default {
    calculate,
    experienceCustomValidation,
    initialize,
    riskClassCustomValidation,
};