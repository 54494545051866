import getBaseUrl from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-retaliation-complaint/api/getBaseUrl';

export const getContentByIds = async (context, { ids, oneLni }) => {
    const baseUrl = getBaseUrl(oneLni);
    const endpoint = `${baseUrl}/api/content/_search`;
    const body = {
        query: `+identifier:(${ids.join(' ')})`,
    };

    try {
        const response = await fetch(endpoint, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const result = await response.text();
        return JSON.parse(result).entity.jsonObjectView.contentlets;
    } catch (error) {
        return context.dispatch(`handleError`, {
            step: 'initialize',
            error: error.message,
        });
    }
};