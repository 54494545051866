import actions from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/actions';
import helper from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/actions/helpers';
import sharedConstants from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/actions/helpers/constants';

export default () => ({
    state: {
        page: 1,
        loading: false,
        searchOption: '1',
        subjectsOrHazardsOptions: [],
        videoFormatOptions: [],
        videoCatalogSearchResults: [],
        filteredResults: [],
        videoFormatValue: 2,
        subjectValue: 0,
        searchTermSpecified: '',
        isSearchInvoked: false,
        detailInfo: {
            titleId: '',
            legacyId: '',
            titleName: '',
            altTitleName: '',
            videoLength: '',
            description: '',
            languages: '',
            productionDate: '',
            isOnlineOnly: false,
            isLimitedView: false,
            titleFormats: [],
        },
        detailAdditionalInfo: {
            titleId: '',
            titleName: '',
            foreignTitleName: '',
            altTitleName: '',
            languages: '',
            formats: '',
            subjects: '',
            shortDescription: '',
            copyright: '',
            videoLength: '',
            hasClosedCaption: '',
            vendorName: '',
            vendorEmail: '',
            vendorWebsite: '',
            vendorPhone: '',
            vendorStatus: '',
            isLimitedView: false,
            isOnlineOnly: false,
            relatedTitles: [],
            webInfoList: [],
            title: '',
            messages: '',
            success: '',
            status: '',
        },
        customInfo: {
            isViewOnlineorDownloadVideo: false,
            embeddedVideoWidth: '',
            embeddedVideoHeight: '',
            embeddedVideoSource: '',
            embeddedVideoFrameBorder: '',
        },
        quickLinkType: '',
        catalogData: [],
        catalogDataAlphabet: [],
        catalogDataGrouped: [],
        showQuickLinks: true,
        showNewSearchOption: false,
    },
    mutations: {
        SET_SEARCH_OPTION(state, payload) {
            state.searchOption = payload;
        },
        SET_SUBJECTS_OR_HAZARD_OPTIONS(state, payload) {
            state.subjectsOrHazardsOptions = payload;
        },
        SET_SEARCH_TERM_SPECIFIED(state, payload) {
            state.searchTermSpecified = payload;
        },
        SET_SEARCH_INVOKED(state, payload) {
            state.isSearchInvoked = payload;
        },
        SET_VIDEO_FORMAT_OPTIONS(state, payload) {
            state.videoFormatOptions = payload;
        },
        SET_VIDEO_FORMAT_VALUE(state, payload) {
            state.videoFormatValue = payload;
        },
        SET_VIDEO_FORMAT_TEXT(state, payload) {
            state.videoFormatText = payload;
        },
        SET_SUBJECT_VALUE(state, payload) {
            state.subjectValue = payload;
        },
        SET_SUBJECT_TEXT(state, payload) {
            state.subjectText = payload;
        },
        SET_VIDEO_CATALOG_SEARCH_RESULTS(state, payload) {
            state.videoCatalogSearchResults = payload;
            state.filteredResults
            = state.videoCatalogSearchResults;
        },
        SET_FILTER_CATALOG_SEARCH_RESULTS_BY_FORMAT(state, payload) {
            state.filteredResults
            = state.videoCatalogSearchResults.filter(item =>
                    item.titleFormats.includes(payload));
        },
        SET_UNFILTERED_CATALOG_SEARCH_RESULTS(state) {
            state.filteredResults = state.videoCatalogSearchResults;
        },
        UPDATE_LOADING_STATE: (state, isLoading) => {
            state.loading = isLoading;
        },
        SET_VIDEO_DETAIL(state, payload) {
            state.detailInfo.titleId = payload.titleId;
            state.detailInfo.legacyId = payload.legacyId;
            state.detailInfo.titleName = payload.titleName;
            state.detailInfo.altTitleName = payload.altTitleName;
            state.detailInfo.videoLength = payload.videoLength;
            state.detailInfo.description = payload.description;
            state.detailInfo.languages = payload.languages;
            state.detailInfo.productionDate = payload.productionDate;
            state.detailInfo.isOnlineOnly = payload.isOnlineOnly;
            state.detailInfo.isLimitedView = payload.isLimitedView;
            state.detailInfo.titleFormats = [];
            state.detailInfo.titleFormats.push(...payload.titleFormats);
        },
        SET_VIDEO_ADDITIONAL_DETAIL(state, payload) {
            state.detailAdditionalInfo.titleId = payload.titleId;
            state.detailAdditionalInfo.titleName = payload.titleName;
            state.detailAdditionalInfo.foreignTitleName = payload.foreignTitleName;
            state.detailAdditionalInfo.altTitleName = payload.altTitleName;
            state.detailAdditionalInfo.languages = payload.languages;
            state.detailAdditionalInfo.formats = payload.formats;
            state.detailAdditionalInfo.subjects = payload.subjects;
            state.detailAdditionalInfo.shortDescription = payload.shortDescription;
            state.detailAdditionalInfo.copyright = payload.copyright;
            state.detailAdditionalInfo.videoLength = payload.videoLength;
            state.detailAdditionalInfo.hasClosedCaption = payload.hasClosedCaption;
            state.detailAdditionalInfo.vendorName = payload.vendorName;
            state.detailAdditionalInfo.vendorEmail = payload.vendorEmail;
            state.detailAdditionalInfo.vendorWebsite = payload.vendorWebsite;
            state.detailAdditionalInfo.vendorPhone = payload.vendorPhone;
            state.detailAdditionalInfo.vendorStatus = payload.vendorStatus;
            state.detailAdditionalInfo.isLimitedView = payload.isLimitedView;
            state.detailAdditionalInfo.isOnlineOnly = payload.isOnlineOnly;
            state.detailAdditionalInfo.relatedTitles = [];
            state.detailAdditionalInfo.relatedTitles.push(...payload.relatedTitles);
            state.detailAdditionalInfo.webInfoList = [];
            state.detailAdditionalInfo.webInfoList.push(...payload.webInfoList);
            state.detailAdditionalInfo.title = payload.title;
            state.detailAdditionalInfo.messages = payload.messages;
            state.detailAdditionalInfo.success = payload.success;
            state.detailAdditionalInfo.status = payload.status;
        },
        CLEAR_VIDEO_DETAIL(state) {
            state.detailInfo = {};
        },
        CLEAR_VIDEO_ADDITIONAL_DETAIL(state) {
            state.detailAdditionalInfo = {};
        },
        SET_QUICKLINK_TYPE(state, payload) {
            state.quickLinkType = payload;
        },
        SET_VIDEO_CATALOG(state, payload) {
            state.catalogData = payload;
        },
        SET_VIDEO_CATALOG_ALPHABET(state, payload) {
            state.catalogDataAlphabet = payload;
        },
        SET_VIDEO_CATALOG_GROUPED_BY_ALPHABET(state, payload) {
            state.catalogDataGrouped = payload;
        },
        SET_SHOW_QUICKLINKS(state, payload) {
            state.showQuickLinks = payload;
        },
        SET_SHOW_NEW_SEARCH_OPTION(state, payload) {
            state.showNewSearchOption = payload;
        },
    },
    getters: {
        getSearchOption(state) {
            return state.searchOption;
        },
        getSubjectsOrHazards(state) {
            return state.subjectsOrHazardsOptions;
        },
        getVideoFormats(state) {
            return state.videoFormatOptions;
        },
        getSearchTermSpecified(state) {
            return state.searchTermSpecified;
        },
        getSubjects(state) {
            return state.subjectsOrHazardsOptions;
        },
        getVideoFormatValue(state) {
            return state.videoFormatValue;
        },
        getVideoFormatText(state) {
            let convertedSelectionValue = helper.convertToNumber(state.videoFormatValue,
                sharedConstants.all);

            let filter = state.videoFormatOptions.filter(item =>
                item.value === convertedSelectionValue);

            return filter[0].text;
        },
        getSubjectValue(state) {
            return state.subjectValue;
        },
        getSubjectText(state) {

            if (helper.isNullOrEmptyString(state.subjectValue)) {
                return '';
            }

            let convertedSelectValue = helper.convertToNumber(state.subjectValue, null);

            let filter = state.subjectsOrHazardsOptions.filter(item =>
                item.value === convertedSelectValue);

            return filter[0].text;
        },
        getVideoDetail(state) {
            return state.videoDetail;
        },
        getAdditionalDetailShortDescription(state) {
            return state.detailAdditionalInfo.shortDescription;
        },
        getDetailDescription(state) {
            return state.detailInfo.description;
        },
        getCatalogAvailabilityAlphabet(state) {

            const sortedTitles
            = state.catalogData.map(title =>
                title.titleName.replace(/^(The|A|An)\s+/i, '').replace(/^[¡¿]+/i, '')).sort();
            const startingLetters = new Set();

            sortedTitles.forEach(title => {
                let regEx = /^[A-Z]+$/;
                if ( regEx.test(title[0].toUpperCase()) ) {
                    startingLetters.add(title[0].toUpperCase());
                }
            });

            let availableTitleLetters =  Array.from(startingLetters);

            const allLetters = Array.from(sharedConstants.alphabet);
            const remainingLetters = new Set();

            allLetters.forEach(letter => {
                if (!availableTitleLetters.includes(letter)) {
                    remainingLetters.add(letter);
                }
            });

            const mergedSet = new Set();

            availableTitleLetters.forEach(letter => {
                mergedSet.add([letter, true]);
            });

            remainingLetters.forEach(letter => {
                mergedSet.add([letter, false]);
            });

            const sortedArray = Array.from(mergedSet).sort((a, b) => a[0].localeCompare(b[0]));
            return sortedArray;
        },
        getGroupedCatalogData(state) {
            const groupedBooks = {};

            state.catalogData.forEach(video => {
                let title = video.titleName.replace(/^(The|A|An)\s+/i, '');

                const firstLetter = title[0].toUpperCase();

                if (!groupedBooks[firstLetter]) {
                    groupedBooks[firstLetter] = [];
                }

                groupedBooks[firstLetter].push({
                    titleId: video.titleId,
                    titleName: video.titleName,
                    hasLimitedView: video.hasLimitedView,
                });
            });

            return groupedBooks;
        },
        getPreFilteredGroupedCatalogData(state) {
            return state.catalogDataGrouped;
        },
        getPreFilteredAlphabetData(state) {
            return state.catalogDataAlphabet;
        },
        getCatalogTitleCount(state) {
            return state.catalogData.length;
        },
        getSearchResultTotalLabel(state) {
            if (state.quickLinkType === sharedConstants.CATALOGTYPES.inSpanish) {
                return 'Resultados de búsqueda';
            }
            return 'Search results returned';
        },
        getQuickLinkType(state) {
            switch (state.quickLinkType) {
                case sharedConstants.CATALOGTYPES.dvd:
                    return 'Videos in DVD format';
                case sharedConstants.CATALOGTYPES.online:
                    return 'Online Videos';
                case sharedConstants.CATALOGTYPES.inSpanish:
                    return 'Vídeos en español';
                default:
                    return '';
            }
        },
        getShowQuickLinks(state) {
            return state.showQuickLinks;
        },
        getShowNewSearchOption(state) {
            return state.showNewSearchOption;
        },
    },
    actions: {
        ...actions,
    },
});