const setAttribute = function(context, id, attribute, value) {
    context.commit('setAttribute', {
        id,
        attribute,
        value: Number(value),
    }, {
        root: true,
    });
};

export const updateFees = (context, payload) => {
    const moduleId = context.getters.moduleId;
    const isInstallation = context.rootGetters[`${moduleId}/isInstallation`];

    if (isInstallation) {
        setAttribute(context, moduleId, 'addFee', payload.installationAddFee);
        setAttribute(context, moduleId, 'baseFee', payload.installationBaseFee);
    } else {
        setAttribute(context, moduleId, 'addFee', payload.alterationAddFee);
        setAttribute(context, moduleId, 'baseFee', payload.alterationBaseFee);
    }
    setAttribute(context, moduleId, 'marker1', payload.marker1);
    setAttribute(context, moduleId, 'marker1Fee', payload.marker1Fee);
    setAttribute(context, moduleId, 'marker2', payload.marker2);
    setAttribute(context, moduleId, 'marker2Fee', payload.marker2Fee);
    setAttribute(context, moduleId, 'marker3', payload.marker3);
    setAttribute(context, moduleId, 'marker3Fee', payload.marker3Fee);
    setAttribute(context, moduleId, 'marker4', payload.marker4);
    setAttribute(context, moduleId, 'marker4Fee', payload.marker4Fee);
    setAttribute(context, moduleId, 'marker5', payload.marker5);
    setAttribute(context, moduleId, 'marker5Fee', payload.marker5Fee);
    setAttribute(context, moduleId, 'marker6', payload.marker6);
    setAttribute(context, moduleId, 'marker4Fee', payload.marker4Fee);
};