export default function isNullOrEmptyString(stringVal: string): boolean {

    if (stringVal === undefined || stringVal === null) {
        return true;
    }

    if (stringVal.trim() === '') {
        return true;
    }

    return false;
};