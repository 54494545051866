<template>
    <div class="lni-c-logger-training-lookup">
        <div class="lni-u-mt3 lni-u-full-width">
            <lni-input-search
                :id="`${id}_search`"
                placeholder="Enter keyword(s) or choose a filter below"
                :clickAction="`${id}/search`"
                :searchAction="`${id}/search`"
                :clearAction="`${id}/search`"
                :query="searchTerm"
                :emptySearchAction="`${id}/search`"
            />
        </div>
        <div class="lni-u-flex lni-u-justify-between lni-u-mt2 lni-u-mv2">
            <button
                :id="`${id}_filterBtn`"
                type="button"
                class="lni-c-button --text lni-u-text--all-caps lni-u-type--bold"
                :aria-expanded="`${isOpen}`"
                :aria-controls="`${id}_mainForm`"
                text="Filter Results"
                @click="isOpen = !isOpen"
            >
                Filter Results
                <span
                    aria-hidden="true"
                    :class="`lnicon--caret--${isOpen ? 'up' : 'down'}`"
                />
            </button>
            <lni-button
                :id="`${id}_clearFilterBtn`"
                :clickAction="`${id}/clearFilters`"
                type="text"
                text="Clear Filters"
            />
        </div>
        <form
            :id="`${id}_mainForm`"
            v-slide="isOpen"
        >
            <div>
                <lni-checkbox-list
                    :id="`${id}_trainingFrequency`"
                    :hasInlineMessages="false"
                    labelText="Training Frequency"
                    :isColumn="false"
                    :options="trainingFrequencyOptions"
                    :changeAction="`${id}/update`"
                />
            </div>
            <div v-show="isQuarterlyView">
                <div class="lni-u-mt3">
                    <lni-multiselect
                        :id="`${id}_category`"
                        :hasInlineMessages="false"
                        labelText="Training Category"
                        placeholder="All Categories"
                        :changeAction="`${id}/update`"
                        :options="categoryFilterOptions"
                    />
                </div>
                <div class="lni-u-mt3">
                    <lni-multiselect
                        :id="`${id}_topic`"
                        placeholder="All Topics"
                        :hasInlineMessages="false"
                        labelText="Training Topic"
                        :changeAction="`${id}/update`"
                        :options="topicFilterOptions"
                    />
                </div>
                <div class="lni-u-mt3">
                    <lni-multiselect
                        :id="`${id}_type`"
                        placeholder="All Formats"
                        :hasInlineMessages="false"
                        labelText="Training Material Format"
                        :changeAction="`${id}/update`"
                        :options="typeFilterOptions"
                    />
                </div>
            </div>
        </form>
        <div
            ref="resultsContainer"
            class="lni-u-bt lni-u-b--subtle lni-u-mt3 lni-u-pt3"
        >
            <lni-spinner
                v-show="isLoading"
                :id="`${id}_spinner`"
            />
            <span
                v-if="totalCount > 1"
                aria-live="polite"
            >
                {{ pageCountStart === totalCount ? totalCount : `${pageCountStart} – ${pageCountEnd}` }} of {{ `${totalCount} results` }}
            </span>
            <span
                v-else-if="totalCount === 1"
                aria-live="polite"
            >
                {{ `${totalCount} result` }}
            </span>
            <span
                v-else
                aria-live="polite"
            >
                {{ `${totalCount} results` }}
            </span>
            <div
                v-show="!totalCount"
                class="lni-u-mv3 lni-u-text--center"
            >
                <p>We couldn't find any results {{ searchTerm.length ? `for` : '' }} <b>{{ searchTerm }}</b> - Try searching using some of the following tips:</p>
                <ul class="lni-u-mhauto lni-u-inline-block lni-u-ph3 lni-u-text--left lni-u-mt2 lni-u-line-height--tight">
                    <li>Make sure all words are spelled correctly</li>
                    <li>Try different keywords or filters</li>
                    <li>Try more general keywords</li>
                    <li>Try fewer keywords</li>
                </ul>
            </div>
            <div
                v-show="totalCount"
                class="lni-u-mt2"
            >
                <ul class="lni-u-list-reset ">
                    <li
                        v-for="card in currentPage"
                        :key="`${card.trainingFrequency}${card.title}`"
                    >
                        <lni-logger-training-card :card="card" />
                    </li>
                </ul>
                <lni-pager
                    v-show="totalResults.length && totalResults.length > pageSize"
                    :id="`${id}_pager`"
                    :page="page"
                    :pageSize="pageSize"
                    :showPager="false"
                    :totalItems="totalResults.length"
                    @change-page="onChangePage"
                />
            </div>
        </div>
    </div>
</template>

<script>
import LniLoggerTrainingCard from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-logger-training-lookup/lni-logger-training-card.vue';
import slide from '@gov.wa.lni/framework.one-lni.directives/source/slide.js';

export default {
    name: 'LniLoggerTrainingLookup',
    directives: {
        slide,
    },
    components: {
        LniLoggerTrainingCard,
    },
    data() {
        return {
            pageSize: 10,
            isQuarterlyView: true,
            isOpen: true,
            isLoading: true,
        };
    },
    computed: {
        categoryFilterOptions() {
            return this.$store.getters[`${this.id}/categoryFilterOptions`];
        },
        typeFilterOptions() {
            return this.$store.getters[`${this.id}/typeFilterOptions`];
        },
        topicFilterOptions() {
            return this.$store.getters[`${this.id}/topicFilterOptions`];
        },
        trainingFrequencyOptions() {
            return this.$store.getters[`${this.id}/trainingFrequencyOptions`];
        },
        currentPage() {
            const start = (this.page - 1) * this.pageSize;
            if (this.totalResults.length) {
                const results = [...this.totalResults];
                return results.slice(start, start + this.pageSize);
            }
            return [];
        },
        totalResults() {
            return this.$store.getters[`${this.id}/filteredResults`];
        },
        totalCount() {
            return this.totalResults.length;
        },
        pageCountStart() {
            return this.pageSize * this.page - (this.pageSize - 1);
        },
        pageCountEnd() {
            return Math.min(this.pageSize * this.page, this.totalCount);
        },
        currentFilter() {
            return this.$store.getters[`${this.id}/currentFilter`];
        },
    },
    mounted() {
        const self = this;

        this.$store.dispatch(`${this.id}/initialize`, {
            sourceId: `${this.id}`,
            targetId: `${this.id}_trainingFrequency`,
            oneLni: this.$oneLni,
            store: this.$store,
        }).then(() => {
            self.isLoading = false;
        });

        this.$watch('filterValues', filters => {
            self.changeFormView(filters);
        });
    },
    methods: {
        onChangePage(data) {
            this.page = data.page;

            this.$store.dispatch(`${this.id}/updatePage`, {
                sourceId: this.id,
                pageNumber: this.page,
                totalItems: this.totalResults.length,
            });

            this.$scrollTo(this.$refs.resultsContainer);
        },
        changeFormView(filters) {
            const frequencies = filters.trainingFrequency;
            if (frequencies?.length === 1 && frequencies[0] === 'Annual Training') {
                this.isQuarterlyView = !this.isQuarterlyView;
            } else {
                this.isQuarterlyView = true;
            }
        },
    },

};
</script>
<style lang="scss">
@import 'lni-logger-training-lookup';
</style>