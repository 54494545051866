import setAttribute from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-late-payment/actions/setAttribute.js';

export default context => {
    setAttribute('value', context, {
        sourceId: `${context.getters.moduleId}_result`,
    }, '');
    setAttribute('value', context, {
        sourceId: `${context.getters.moduleId}_result1`,
    }, '');
    setAttribute('value', context, {
        sourceId: `${context.getters.moduleId}_result2`,
    }, '');
};