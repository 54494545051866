import { render, staticRenderFns } from "./lni-ship-grant-projects.component.vue?vue&type=template&id=4d1b48eb"
import script from "./lni-ship-grant-projects.component.vue?vue&type=script&lang=js"
export * from "./lni-ship-grant-projects.component.vue?vue&type=script&lang=js"
import style0 from "./lni-ship-grant-projects.component.vue?vue&type=style&index=0&id=4d1b48eb&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports