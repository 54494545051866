export default (date, display = false) => {
    const doubleDig = 10,
        minutes = 60000;

    let offset,
        milli,
        newDate;
    if (!display) {
        newDate = new Date(date);
        milli = newDate.getTime();
        offset = newDate.getTimezoneOffset() * minutes;
        newDate = milli + offset;
        return newDate;
    }

    newDate = new Date(date);

    let day = newDate.getUTCDate(),
        month = newDate.getUTCMonth() + 1,
        year = newDate.getUTCFullYear();

    day = day < doubleDig ? '0' + day : day;
    month = month < doubleDig ? '0' + month : month;

    return month + '/' + day + '/' + year;
};