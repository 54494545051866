<template>
    <div class="lni-c-box-upload">
        <div
            ref="container"
            class="lni-c-box-upload__container"></div>
    </div>
</template>
<script>
const boxUploaderJs = 'https://cdn01.boxcdn.net/platform/elements/17.1.0/en-US/uploader.js';
const boxUploaderCss = 'https://cdn01.boxcdn.net/platform/elements/17.1.0/en-US/uploader.css';
// Temporary UID
const uid = new Date().toString();
export default {
    name: 'lni-box-upload',
    props: {
        folderId: {
            type: String,
            required: true,
        },
        accessToken: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            uid,
        };
    },
    mounted() {
        window.addEventListener('load', this.initializeContentUploader);
        this.initialize();
    },
    methods: {
        initialize() {
            const boxScriptTag = document.createElement('script');
            const boxCssLink = document.createElement('link');
            boxScriptTag.setAttribute('src', boxUploaderJs);
            boxScriptTag.setAttribute('defer', '');
            boxCssLink.setAttribute('href', boxUploaderCss);
            boxCssLink.setAttribute('rel', 'stylesheet');
            document.head.appendChild(boxCssLink);
            document.body.appendChild(boxScriptTag);
        },
        initializeContentUploader() {
            const BoxConstructor = window.Box;
            const uploader = new BoxConstructor.ContentUploader();

            this.showUploader(uploader);
            // Log upload data to console
            uploader.on('complete', data => {
                this.onComplete(data);
            });

            uploader.on('upload', data => {
                this.onUpload(data);
            });

            uploader.on('error', data => {
                this.onError(data);
            });
        },
        showUploader(uploader) {
            uploader.show(this.folderId, this.accessToken, {
                container: this.$refs.container,
                requestInterceptor: this.requestInterceptor,
                responseInterceptor: this.responseInterceptor,
            });
        },
        /* eslint-disable no-console */
        // After an array of files is uploaded
        onComplete(data) {
            console.log(`All files successfully uploaded: ${JSON.stringify(data)}`);
            this.$emit('complete', data);
        },
        // After single file is uploaded
        onUpload(data) {
            console.log(`Successfully uploaded file with name "${data.name}" to Box File ID ${data.id}`);
            this.$emit('upload', data);

        },
        onError(data) {
            console.error(`Error uploading file with name "${data.file.name}". The error was: "${data.error}"`);
            this.$emit('error', data);
        },
        requestInterceptor(request) {
            console.log('request', {
                request,
            });
            if (request.data.attributes) {
                console.log(request.data.attributes);
                const attrs = JSON.parse(request.data.attributes);
                attrs.name = `${attrs.name}_${this.uid}.docx`;
                request.data.attributes = JSON.stringify(attrs);
            }
            return request;
        },
        responseInterceptor(response) {
            console.log({
                response,
            });
            return response;
        },
        /* eslint-enable no-console */
    },
}; </script>