import search from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-site-search/actions/search.js';
import updateSearchTerm from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-site-search/actions/updateSearchTerm.js';
import updatePagination from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-site-search/actions/updatePagination.js';
import clearFilters from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-site-search/actions/clearFilters.js';
import toggleLoadingState from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-site-search/actions/toggleLoadingState.js';
import setUrlParams from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-site-search/actions/setUrlParams.js';

// TODO: consider syncing most params with state when state changes, not just on search

export default () => ({
    state: {
        params: {
            index: 'All_Content', //for analytics only
            pg: 1,
            limit: 10,
            query: '',
        },
        defaultParams: {
            index: 'All_Content',
            pg: 1,
            limit: 10,
            query: '',
        },
        results: [],
        count: 0,
        totalPages: 0,
        showLoader: false,
        showOrderControls: false,
        minLoadingTime: 300,
        userHasSearched: false,
        formsPubsUrl: '/forms-publications/search',
        dirty: false,
    },
    actions: {
        search,
        updateSearchTerm,
        updatePagination,
        clearFilters,
        toggleLoadingState,
        setUrlParams,
    },
    getters: {
        urlParams: state => {
            const params = state.params;
            return Object.keys(params)
                //remove empty keys
                .filter(key => params[key])
                //encode
                .map(key => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
        },
        firstResult: state => {
            const pg = state.params.pg ? parseInt(state.params.pg, 10) : 1;
            const limit = state.params.limit
                ? parseInt(state.params.limit, 10) : state.defaultParams.limit;
            const prevPg = pg - 1;
            if (state.count === 0) {
                return 0;
            }
            if ( prevPg > 0) {
                return (limit * prevPg) + 1;
            }
            return 1;
        },
        lastResult: state => {
            const pg = parseInt(state.params.pg, 10);
            const limit = state.params.limit
                ? parseInt(state.params.limit, 10) : state.defaultParams.limit;
            if ( pg === state.totalPages || state.count === 0) {
                // we are on the last page
                return state.count;
            }
            return limit * pg;

        },
        formsPubsHref: state => `${state.formsPubsUrl}?query=${state.params.query}&pg=1&index=Forms_Pubs`,
    },
    mutations: {
        SET_PARAM(state, payload) {
            state.params = {
                ...state.params,
                ...payload,
            };
        },
        SET_RESULTS(state, results) {
            state.results = results;
        },
        SET_COUNT(state, count) {
            state.count = count;
        },
        SET_TOTAL_PAGES(state, total) {
            state.totalPages = total;
        },
        SET_LOADING_STATE(state, isLoading) {
            state.showLoader = isLoading;
        },
        SET_SEARCHED_ONCE(state) {
            state.userHasSearched = true;
        },
    },
});