
// TODO: Allow one input to specify other inputs that they validate in coordination with
//
// On this form, there are two date inputs.
// Their values validate against each other.  When one is changed
// we need to revalidate the other.
export default (context, payload) => {
    let parentId;

    if (payload.targetId.includes('from-date')) {
        parentId = payload.targetId.replace('_from-date', '');
    } else {
        parentId = payload.targetId.replace('_to-date', '');
    }

    const id = payload.targetId.replace('to-date', 'from-date');
    const moduleState = context.rootState[id];
    if (!moduleState.flags.dirty) {
        return;
    }

    context.dispatch(`${id}/customValidate`, {
        targetId: id,
    }, {
        root: true,
    });

    context.dispatch(`${parentId}/handleInputChange`, {
        targetId: id,
    }, {
        root: true,
    });
};