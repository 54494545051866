export default () => ({
    state: {
        minYears: 1,
        maxYears: 1,
        selectedMonth: null,
        selectedYear: null,
        startMonth: new Date().getMonth(),
        startYear: new Date().getFullYear(),
    },
    mutations: {
        SET_SELECTED_DATE(state, payload) {
            state.selectedMonth = payload.month;
            state.selectedYear = payload.year;
        },
    },
});