const submitForm = context => {
    window.location.href = context.state.sendEmailEndpoint;
};

export default context => {
    if (context.rootState['order-self-insurer-roa-form-request-errors'].errors < 1) {
        // Prevent multiple submissions
        context.commit('setAttribute', {
            id: context.getters.moduleId,
            attribute: 'showSif2Spinner',
            value: true,
        }, {
            root: true,
        });

        submitForm(context);
    }
};