import encodeValue from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-topic-search/actions/encodeValue.js';
import search from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-topic-search/api/safety-topic-search.api.js';

export default (context, payload) => {
    const moduleId = context.getters.moduleId;

    if (!payload.customData || (payload.customData.action && payload.customData.action !== 'PAGINATION')) {
        context.dispatch(`updatePage`, payload);
    }

    //Show spinner
    setTimeout(() => {
        context.commit('setAttribute', {
            id: moduleId,
            attribute: 'searchStatus',
            value: 'pending',
        }, {
            root: true,
        });
    });

    setTimeout(() => {
        const query = context.rootState[`${moduleId}_auto-suggest_text`].query;
        const encodedValue = query ? encodeValue(query) : '';
        const pg = context.state.page;
        const relevance = context.rootState.relevance_select.value;
        const channels = context.rootState['channelOptions_multi-select'].value;
        let searchQuery = ``;
        if (query.length) {
            searchQuery += `&query=${encodedValue}`;
        }
        if (pg) {
            searchQuery += `&pg=${pg}`;
        }
        if (relevance) {
            searchQuery += `&str=${relevance}`;
        }
        if (channels?.length) {
            searchQuery += `&stc=${channels.join(',')}`;
        }

        history.pushState({
            query: encodedValue,
            pg: pg ?? '',
            str: relevance ?? '',
            stc: channels?.length ? channels.join(',') : '',
        }, document.title, `/safety-health/safety-topics/search-by-topic?index=Safety_Topics${searchQuery}`);

        search(searchQuery, payload.oneLni).then(({
            results, totalResults, filteredResults, aggrs,
        }) => {
            context.commit('setAttribute', {
                id: moduleId,
                attribute: 'results',
                value: results,
            }, {
                root: true,
            });

            context.commit('setAttribute', {
                id: moduleId,
                attribute: 'aggrs',
                value: aggrs,
            }, {
                root: true,
            });

            context.commit('setAttribute', {
                id: moduleId,
                attribute: 'totalResults',
                value: totalResults,
            }, {
                root: true,
            });

            context.commit('setAttribute', {
                id: moduleId,
                attribute: 'filteredResults',
                value: filteredResults,
            }, {
                root: true,
            });

            if (payload.targetId !== 'channelOptions_multi-select') {
                context.dispatch('updateMultiselectOptions');
            }

            //Hide spinner
            context.commit('setAttribute', {
                id: moduleId,
                attribute: 'searchStatus',
                value: 'resolved',
            }, {
                root: true,
            });
        });
    });
};