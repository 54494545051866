export default context => {
    const moduleId = context.getters.moduleId;

    const fields = [
        'comment-type',
        'comment',
        'customer-type',
        'name',
        'email',
    ];

    fields.forEach(fieldName => {
        context.commit('setAttribute', {
            id: `${moduleId}_${fieldName}`,
            attribute: 'value',
            value: '',
        }, {
            root: true,
        });
    });

    context.commit('setAttribute', {
        id: `${moduleId}_phone`,
        attribute: 'phoneNumber',
        value: '',
    }, {
        root: true,
    });

};