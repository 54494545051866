import encodeDotcmsCookie from '@gov.wa.lni/application.public-website-custom-components/source/utilities/storage/encodeDotcmsCookie.js';

export default (context, payload) => {
    const moduleId = context.getters.moduleId;
    const moduleState = context.rootState[moduleId];

    const data = {
        name: `${context.rootState[`${moduleId}_first-name`].value} ${context.rootState[`${moduleId}_last-name`].value}`,
        email: context.rootState[`${moduleId}_email`].value,
        phone: context.rootState[`${moduleId}_phone`].phoneNumber,
        city: context.rootState[`${moduleId}_city`].value,
        comments: context.rootState[`${moduleId}_comments`].value,
    };

    payload.oneLni.storage.cookie.set(
        moduleState.cookieName,
        encodeDotcmsCookie(data),
    );

    context.commit('setAttribute', {
        id: moduleId,
        attribute: 'loading',
        value: true,
    }, {
        root: true,
    });

    window.location.href = moduleState.sendEmailEndpoint;
};