const sharedConstants = {
    HASH_LOCATIONS: {
        search: 'search',
        member: 'member',
        quicklinks: 'quicklinks',
    },
    MUTATIONS: {
        setSearchOption: 'SET_SEARCH_OPTION',
        setSubjectsOrHazardsOptions: 'SET_SUBJECTS_OR_HAZARD_OPTIONS',
        setVideoFormatOptions: 'SET_VIDEO_FORMAT_OPTIONS',
        setVideoCatalogSearchResults: 'SET_VIDEO_CATALOG_SEARCH_RESULTS',
        setSearchTermSpecified: 'SET_SEARCH_TERM_SPECIFIED',
        setSearchInvoked: 'SET_SEARCH_INVOKED',
        setFilterCatalogSearchResultsByFormat: 'SET_FILTER_CATALOG_SEARCH_RESULTS_BY_FORMAT',
        setUnfilteredCatalogSearchResults: 'SET_UNFILTERED_CATALOG_SEARCH_RESULTS',
        updateLoadingState: 'UPDATE_LOADING_STATE',
        setVideoFormatValue: 'SET_VIDEO_FORMAT_VALUE',
        setVideoFormatText: 'SET_VIDEO_FORMAT_TEXT',
        setSubjectValue: 'SET_SUBJECT_VALUE',
        setSubjectText: 'SET_SUBJECT_TEXT',
        setVideoDetail: 'SET_VIDEO_DETAIL',
        setAdditionalVideoDetail: 'SET_VIDEO_ADDITIONAL_DETAIL',
        clearVideoDetail: 'CLEAR_VIDEO_DETAIL',
        clearVideoAdditionalDetail: 'CLEAR_VIDEO_ADDITIONAL_DETAIL',
        setQuicklinkType: 'SET_QUICKLINK_TYPE',
        setVideoCatalog: 'SET_VIDEO_CATALOG',
        setVideoCatalogAlphabet: 'SET_VIDEO_CATALOG_ALPHABET',
        setVideoCatalogGrouped: 'SET_VIDEO_CATALOG_GROUPED_BY_ALPHABET',
        setShowQuicklinks: 'SET_SHOW_QUICKLINKS',
        setShowNewSearchOption: 'SET_SHOW_NEW_SEARCH_OPTION',
        setAttribute: 'setAttribute',
    },
    DIALOGS: {
        dialogException: 'dialogException',
    },
    ACTIONS: {
        setFilteredCatalogSearchResultsByFormat: 'setFilteredCatalogSearchResultsByFormat',
    },
    GETTERS: {
        getSearchOption: 'getSearchOption',
        getSubjectsOrHazards: 'getSubjectsOrHazards',
        getVideoFormats: 'getVideoFormats',
        getVideoFormatType: 'getVideoFormatType',
        getSelectedVideoFormatTypeText: 'getSelectedVideoFormatTypeText',
        getSelectedSubjectText: 'getSelectedSubjectText',
        getShowNewSearchOption: 'getShowNewSearchOption',
        getDetailDescription: 'getDetailDescription',
    },
    FIELDS: {
        subjectHazardsList: 'search_subjectOrHazards',
        videoFormatsList: 'search_videoFormats',
    },
    ATTRIBUTES: {
        value: 'value',
    },
    TITLETYPES: {
        SearchVideoCatalog: '1',
        OnlineVideos: '2',
        VideoDetails: '3',
    },
    CATALOGTYPES: {
        recentlyAdded: '1',
        dvd: '2',
        inSpanish: '3',
        online: '4',
    },
    ROUTES: {
        newSearch: '/search/#search',
    },
    allValue: 2,
    allText: 'All',
    keywordSearch: '1',
    subjectSearch: '2',
    alphabet: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
    toUpper: 'u',
    toLower: 'l',
};

export default sharedConstants;