<template>
    <div>
        <div class="lni-c-order-paper__cart lni-u-mt3">
            <h2 class="lni-u-heading--3 lni-u-type--normal">
                Your Order
            </h2>
            <ul
                v-if="orders.length"
                class="lni-u-list-reset lni-u-line-height--tight"
            >
                <li
                    v-for="(order, index) in orders"
                    :key="index"
                    class="lni-u-flex lni-u-items-stretch lni-u-mt2"
                >
                    <span
                        class="
                        lni-c-order-paper__cart-item-quantity
                        lni-u-pr1 lni-u-mr1
                        lni-u-line-height--tight
                        lni-u-text--center
                        lni-u-br
                        lni-u-b--gray-base"
                    >
                        <span class="lni-u-type--bold">{{ order.quantity }}</span>
                        <small class="lni-u-type--xxs">copies</small>
                    </span>
                    <div class="lni-u-flex lni-u-flex-column">
                        <span>{{ order.title }}</span>
                        <div class="lni-u-type--xs">
                            <span class="">{{ order.formId }}</span> - <span class="">{{ order.language }}</span>
                        </div>
                    </div>
                </li>
            </ul>
            <!-- TODO: determine how to handle editing of the order -->
            <!-- <a
                href="#"
                class="lni-u-inline-block lni-u-mt3 lni-u-pl0"
            ><span class="lnicon--arrow--left" aria-hidden="true"></span> Search or change order</a> -->
        </div>
        <form class="lni-c-order-paper">
            <div class="lni-u-flex lni-u-flex-column lni-u-mt3">
                <fieldset>
                    <legend class="lni-u-heading--4">
                        Your Information
                    </legend>
                    <div>
                        <lni-input-text
                            :id="`${id}_business-name`"
                            ref="business-name"
                            labelText="Business Name"
                            :filled="true"
                            :required="true"
                        />
                    </div>
                    <div>
                        <lni-input-text
                            :id="`${id}_contact-person`"
                            ref="contact-person"
                            labelText="Contact Person"
                            :filled="true"
                            :required="true"
                        />
                    </div>
                    <div>
                        <lni-input-email
                            :id="`${id}_contact-email`"
                            ref="contact-email"
                            labelText="Contact Email"
                            :filled="true"
                            :required="true"
                        />
                    </div>
                </fieldset>
                <lni-input-address
                    :id="`${id}_address`"
                    ref="address"
                    labelText="Mailing Address"
                    state=" "
                    :filled="true"
                    :required="true"
                >
                    <template #helperText>
                        <span>Note: FedEx will not deliver to a P.O. Box.</span>
                    </template>
                </lni-input-address>
                <lni-input-textarea
                    :id="`${id}_comments`"
                    ref="comments"
                    class="lni-u-full-width"
                    labelText="Additional Comments"
                    :filled="true"
                >
                    <template #helperText>
                        <span>Optional</span>
                    </template>
                </lni-input-textarea>
                <lni-button
                    :id="`${id}_submit`"
                    ref="button"
                    text="Submit Order"
                    class="lni-u-mr0 lni-u-mlauto"
                    errorContainerId="order-paper-form-errors"
                    :clickAction="['validateForm',`${id}/submitOrder`]"
                />
                <lni-error-container
                    id="order-paper-form-errors"
                    :errorsCollectedFrom="[
                        `${id}_contact-email`,
                        `${id}_address`,
                        `${id}_business-name`,
                        `${id}_contact-person`,
                    ]"
                />
            </div>
        </form>
        <p class="lni-u-mt3">
            Questions? Send an email to our Warehouse at whsemail@Lni.wa.gov.
        </p>
        <div v-if="showModalSpinner">
            <lni-spinner
                :id="`${id}_spinner`"
            />
        </div>
    </div>
</template>

<script>
import decodeDotcmsCookie from '@gov.wa.lni/application.public-website-custom-components/source/utilities/storage/decodeDotcmsCookie.js';

export default {
    name: 'LniOrderPaper',
    computed: {
        orders() {
            return this.$store.getters['orderPaperForm/orders'];
        },
    },
    mounted() {
        this.$store.commit(`${this.id}/setCart`, this.getCartCookie());
        this.$store.dispatch(`${this.id}/setSender`, this.resetData());

    },
    methods: {
        getCartCookie() {
            //Note: In order to read a cookie (due to VTL) you have to decode and parse JSON Object
            if (this.$oneLni.storage.cookie.get('cart')) {
                return decodeDotcmsCookie(this.$oneLni.storage.cookie.get('cart'));
            }
            return false;
        },
        resetData() {
            const senderCookie = this.$oneLni.storage.cookie.get('sender');
            if (senderCookie) {

                let senderData = decodeDotcmsCookie(senderCookie);
                senderData.sourceId = this.id;
                return senderData;
            }
            return false;
        },
    },
}; </script>
<style lang="scss">
@import 'lni-order-paper-form';
</style>