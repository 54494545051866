import actions from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-preferred-worker-calculator/actions';

export default () => ({
    state: {
        loadState: 'initial',
        errorDetails: '',
        riskClass: '',
        rateVariables: {},
        riskClassExclusions: [],
        experienceFactor: 0,
        calculated: false,
        preferredData: [],
        calculatedResult: {},
        quarterlyHours: 480,
        multiplier: 0.5, //Supplemental Pension Fund only, employer and worker each pay half
    },
    getters: {
        rateVariables: state => riskClass => state.preferredData.find(
            pd => pd.riskClass === riskClass,
        ),
    },
    actions: {
        ...actions,
    },
    mutations: {
        SET_ATTRIBUTE(state, payload) {
            state[payload.attribute] = payload.value;
        },
    },
});