export default function( context, payload ) {
    const searchString = context.rootState[payload.targetId].query;
    context.dispatch('filter', payload);
    context.dispatch('setParam', {
        param: 'page',
        value: 1,
    });
    context.dispatch('setParam', {
        param: 'query',
        value: searchString,
    });
}