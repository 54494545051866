import actions from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-rtw-printable-form/actions';

export default () => ({
    state: {
        firstName: '',
        lastName: '',
        address: {
            address1: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
        },
        claimNumber: '',
        jobAddress: '',
        reportDate: '',
        reportTime: '',
        reportTimeType: 'am',
        scheduledDays: '',
        scheduledHours: '',
        pay: {
            rate: '',
            type: '',
        },
        positionOptions: [
            {
                text: 'Light duty/temporary/transitional position',
                value: false,
                // eslint-disable-next-line max-len
                description: 'Light, temporary, or transitional work offered while employee is recovering from workplace injury or occupational condition. Use the permanent job offer when moving from transitional position to a permanent offer.',
            },
            {
                text: 'Permanent job offer/Reasonably continuous',
                value: true,
                // eslint-disable-next-line max-len
                description: 'Work that is meaningful, gainful, and is reasonably continuous and matches worker’s employment pattern at the time of injury.',
            },
        ],
        positionOffer: null,
        healthBenefits: null,
        companyName: '',
        supervisorName: '',
        supervisorPhone: '',
        contactPhone: '',
        letterEndpoint: '',
        displayMessage: false,
    },
    actions: {
        ...actions,
    },
    mutations: {
        SET_VALUES(state, payload) {

            Object.keys(payload).forEach(key => {
                state[key] = payload[key];
            });
        },
    },
});