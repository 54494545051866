let loadStart;
export default function(context, isLoading) {
    const minLoadingTime = context.state.minLoadingTime;
    const now = new Date();
    if (isLoading) {
        context.commit('UPDATE_LOADING_STATE', true);
        loadStart = now;
    } else if (loadStart - now + minLoadingTime > 0) {
        setTimeout(() => {
            context.commit('UPDATE_LOADING_STATE', false);
        }, loadStart - now + minLoadingTime);
    } else {
        context.commit('UPDATE_LOADING_STATE', false);
    }
}