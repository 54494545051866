<template>
    <div class="lni-c-explanation-of-benefits-lookup">
        <div>
            <lni-input-text
                :id="`${id}_search-text`"
                class="lni-u-mr2 lni-u-top"
                type="text"
                labelText="EOB Code or Text"
                :dense="true"
                :filled="false"
                :enterAction="`${id}/search`"
                :maxlength="8"></lni-input-text>
            <lni-button
                :id="`${id}_search-button`"
                :clickAction="`${id}/search`"
                text="Search"
                class="lni-c-button --primary lni-u-top lni-u-mt1"></lni-button>
        </div>

        <div class="table-data">
            <lni-table
                :id="`${id}_table`"
                :set-data-action="`${id}/search`"
                :inPlacePagingAndSorting="false"
                scrollTarget=".lni-c-explanation-of-benefits-lookup"
                :items="null"
                :sort="{
                    chrEOBCode: 'asc'
                }"
                class="lni-u-page-width--max"
                :pageSize="10"
                :page="1"
                noRowsMessage="Your search returned no results, please check your spelling and try again."
                errorMessage="Error Getting Data"
                caption="Explanation of Benefits"
                summary="This table shows explanation of benefits by procedure code."
                :columns="[{
                               source:'chrEOBCode',
                               displayText: 'EOB Code',
                               sortable: true,
                               headerClasses: 'lni-c-explanation-of-benefits-lookup_table-cell'
                           },
                           {
                               source:'chrDescription',
                               displayText: 'Description',
                               sortable: true,
                               headerClasses: 'lni-c-explanation-of-benefits-lookup_table-cell lni-c-explanation-of-benefits-lookup_description lni-u-text--center'
                           },
                           {
                               source:'chrRejectionCode',
                               displayText: 'Rejection Code',
                               sortable: true,
                               headerClasses: 'lni-c-explanation-of-benefits-lookup_table-cell'
                           },
                           {
                               source:'chrGroupCode',
                               displayText: 'Group Code',
                               sortable: true,
                               headerClasses: 'lni-c-explanation-of-benefits-lookup_table-cell'
                           },
                           {
                               source:'chrReasonCode',
                               displayText: 'Reason Code',
                               sortable: true,
                               headerClasses: 'lni-c-explanation-of-benefits-lookup_table-cell'
                           },
                           {
                               source:'chrRemarkCode',
                               displayText: 'Remark Code',
                               sortable: true,
                               headerClasses: 'lni-c-explanation-of-benefits-lookup_table-cell'
                           },
                ]">
                <template
                    slot="body"
                    slot-scope="props">
                    <tr>
                        <th
                            scope="row"
                            class="lni-u-text--center lni-c-explanation-of-benefits-lookup_table-cell">
                            {{ props.item.chrEOBCode }}
                        </th>
                        <td class="lni-u-text--left lni-c-explanation-of-benefits-lookup_table-cell lni-c-explanation-of-benefits-lookup_description">
                            {{ props.item.chrDescription }}
                        </td>
                        <td class="lni-u-text--center lni-c-explanation-of-benefits-lookup_table-cell">
                            {{ props.item.chrRejectionCode }}
                        </td>
                        <td class="lni-u-text--center lni-c-explanation-of-benefits-lookup_table-cell">
                            {{ props.item.chrGroupCode }}
                        </td>
                        <td class="lni-u-text--center lni-c-explanation-of-benefits-lookup_table-cell">
                            <a
                                href="http://www.x12.org/codes/claim-adjustment-reason-codes/"
                                target="_blank">{{ props.item.chrReasonCode }}</a>
                        </td>
                        <td class="lni-u-text--center lni-c-explanation-of-benefits-lookup_table-cell">
                            <a
                                href="http://www.wpc-edi.com/reference/codelists/healthcare/remittance-advice-remark-codes/"
                                target="_blank">{{ props.item.chrRemarkCode }}</a>
                        </td>
                    </tr>
                </template>
            </lni-table>
        </div>
    </div>
</template>

<script>
export default {
    name: 'lni-explanation-of-benefits-lookup',
};
</script>
<style lang="scss">
@import 'lni-explanation-of-benefits-lookup';
</style>