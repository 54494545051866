export default (context, payload) => {
    const moduleState = context.rootState[payload.targetId];
    const validity = payload.validity;
    validity.numOpeningsLessThan2 = false;

    if (!moduleState.value) {
        return validity;
    }

    validity.numOpeningsLessThan2 = parseInt(moduleState.value) <= 1;

    return validity;
};