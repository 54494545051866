<template>
    <div class="lni-c-early-rtw-consultation">
        <lni-spinner
            v-show="loading"
            :id="`${id}_spinner`"
            :inline="false"
        />
        <h2
            :id="`${id}_heading`"
            class="lni-u-mt0"
        >
            {{ text.heading }}
        </h2>
        <form class="lni-u-full-width lni-u-mt2">
            <div class="lni-u-flex lni-u-flex-wrap">
                <lni-input-text
                    :id="`${id}_first-name`"
                    class="lni-u-mr1 lni-u-mb1"
                    :labelText="text.firstName"
                    :filled="true"
                    :required="true"
                    :hasInlineMessages="false"
                />
                <lni-input-text
                    :id="`${id}_last-name`"
                    class="lni-u-mb1"
                    :labelText="text.lastName"
                    :filled="true"
                    :required="true"
                    :hasInlineMessages="false"
                />
            </div>
            <lni-input-email
                :id="`${id}_email`"
                class="lni-u-mb2"
                :labelText="text.email"
                :filled="true"
                :required="true"
                :hasInlineMessages="false"
            />
            <lni-input-phone
                :id="`${id}_phone`"
                class="lni-u-mb1"
                :filled="true"
                :labelText="text.phone"
                :required="true"
                :hasExtension="false"
                :isFieldset="false"
                :hasInlineMessages="false"
            />
            <div class="lni-u-form-width--base">
                <lni-input-text
                    :id="`${id}_city`"
                    class="lni-u-mb1"
                    :filled="true"
                    :required="true"
                    :labelText="text.city"
                    :fullWidth="true"
                    :hasInlineMessages="false"
                />
                <lni-input-textarea
                    :id="`${id}_comments`"
                    class="lni-u-mb2 lni-u-block"
                    :labelText="text.comments"
                    :required="true"
                    :limit="100"
                    :filled="true"
                    :fullWidth="true"
                    :hasInlineMessages="false"
                />
            </div>
            <lni-error-container
                :id="`${id}_errors`"
                class="lni-u-mt3"
                :errorsCollectedFrom="[
                    `${id}_first-name`,
                    `${id}_last-name`,
                    `${id}_email`,
                    `${id}_phone`,
                    `${id}_city`,
                    `${id}_comments`,
                ]"
            />
            <lni-button
                :id="`${id}_submit`"
                class="lni-u-mb2"
                :text="text.submit"
                type="button"
                :errorContainerId="`${id}_errors`"
                @click="submit"
            />
            <lni-recaptcha
                :id="`${id}_recaptcha`"
                ref="invisible-recaptcha"
                :verifyAction="`${id}/onVerify`"
                size="invisible"
                :loadRecaptchaScript="true"
                badge="inline"
            />
        </form>
    </div>
</template>

<script>
export default {
    name: 'LniEarlyRtwConsultation',
    mounted() {
        if (this.sendEmailEndpoint.length < 1) {
            console.warn('sendEmailEndpoint is not set.');
        }

        this.$store.commit('setAttribute', {
            id: `${this.id}_recaptcha`,
            attribute: 'forceReset',
            value: true,
        }, {
            root: true,
        });

        this.$store.commit('setAttribute', {
            id: this.id,
            attribute: 'loading',
            value: false,
        }, {
            root: true,
        });
    },
    methods: {
        submit() {
            const self = this;

            this.$store.dispatch('validateForm', {
                sourceId: this.id,
                targetId: `${this.id}_submit`,
                validation: {
                    errorsCollectedBy: null,
                },
            }).then(() => {
                const errorsCollectedFrom = self.$store.state[`${self.id}_errors`].errorsCollectedFrom;
                let valid = true;
                errorsCollectedFrom.forEach(id => {
                    if (self.$store.state[id].errors.length > 0) {
                        valid = false;
                    }
                });

                if (valid) {
                    self.$refs['invisible-recaptcha'].$refs.recaptcha.execute();
                }
            });
        },
    },
};
</script>