<template>
    <div class="lni-c-rule-card lni-c-box--content --card">
        <span
            v-for="(cat, index) in card.categories"
            :key="'category' + index"
            class="lni-c-rule-card__category lni-u-text--all-caps">
            {{ cat }}<span v-if="index !== card.categories.length - 1">, </span>
        </span>
        <h2
            :id="'cardTitleId' + id"
            class="lni-c-rule-card__title lni-u-type--medium lni-u-mt2">
            {{ card.title }}
        </h2>
        <p class="lni-c-rule-card__details lni-u-line-height--tight lni-u-mt1">
            {{ card.chapterDetails }}
        </p>
        <button
            :id="'togglePurposeId' + id"
            class="lni-u-mt3 lni-c-button --text lni-c-rule-card__purpose-toggle lni-u-type--xs"
            :aria-expanded="`${showPurpose}`"
            :aria-controls="'purpose_' + id"
            :aria-describedby="'cardTitleId' + id"
            @click="showPurpose = !showPurpose">
            Rulemaking Purpose
            <span
                :class="['lni-c-rule-card__purpose-icon',
                         'lni-u-ml1',
                         `lnicon--triangle--${showPurpose? 'up': 'down'}`]"
                aria-hidden="true"></span>
        </button>
        <!-- TODO: Add Slide functionality -->
        <div
            v-if="showPurpose"
            :id="'purpose_' + id"
            class="lni-c-rule-card__purpose">
            {{ card.purpose }}
        </div>
        <div class="lni-u-flex lni-justify-between lni-u-mt3 lni-u-flex-wrap">
            <div class="lni-c-rule-card__column lni-u-flex lni-u-flex-column lni-u-items-center">
                <h3 class="lni-u-type--bold">
                    Status
                </h3>
                <div class="lni-u-mt2">
                    {{ card.status }}
                </div>
                <div
                    v-if="activeItem && card.status === 'Open'"
                    class="lni-u-mt3 lni-u-text--center">
                    {{ activeItem.step }}
                </div>
                <div
                    v-else
                    class="lni-u-mt3 lni-u-text--center">
                    {{ card.closedDate }}
                </div>
                <div class="lni-u-mt3">
                    Stage:
                </div>
                <div
                    v-if="activeItem"
                    class="lni-u-text--center">
                    {{ activeItem.title }}
                </div>
                <div
                    v-if="activeItem"
                    class="lni-u-text--center">
                    {{ activeItem.activityEndDate }}
                </div>
            </div>
            <div class="lni-c-rule-card__column lni-u-flex lni-u-flex-column">
                <h3 class="lni-u-type--bold">
                    Rulemaking Activity
                </h3>
                <div v-if="steps">
                    <lni-rulemaking-activity
                        v-for="(step, index) in steps"
                        :id="'stepId' + id + index"
                        :key="`step${index}`"
                        :titleId="'cardTitle_' + id"
                        :step="step"
                        :activities="activityByStep(step)"></lni-rulemaking-activity>
                </div>
            </div>
            <div class="lni-c-rule-card__column lni-u-flex lni-u-flex-column lni-u-items-center">
                <div class="lni-u-text--center lni-u-full-width">
                    <h3 class="lni-u-type--bold">
                        Comments
                    </h3>
                    <div v-if="card.commentDate">
                        <p class="lni-u-mt2 lni-c-rule-card__comments">
                            Proposed Rule
                            Comments Due
                        </p>
                        <span>{{ card.commentDate }}</span>
                    </div>
                </div>
                <div class="lni-u-text--center lni-c-rule-card__information lni-u-mt2">
                    <p class="lni-u-type--bold lni-u-type--small">
                        Contact
                    </p>
                    <p class="lni-u-type--s">
                        {{ card.rulemakingContact }}
                    </p>
                </div>
            </div>
        </div>
        <a
            v-if="card.stakeholderRoute"
            :href="card.stakeholderRoute"
            class="lni-c-rule-card__link lni-u-text--right lni-u-block lni-u-text--all-caps lni-u-type--bold lni-u-mt2">See Stakeholder Information for {{ card.title }}</a>
    </div>
</template>
<script>

export default {
    name: 'lni-rulemaking-card',

    data() {
        return {
            showPurpose: false,
            steps: [],
        };
    },
    computed: {
        activeItem() {
            return this.card.activities.filter( e => e.active)[0];
        },
    },
    mounted() {
        this.card.activities.forEach(activity => {
            if (!this.steps.includes(activity.step)) {
                this.steps.push(activity.step);
            }
        });
    },
    methods: {
        activityByStep(step) {
            return this.card.activities.filter(activity => activity.step === step);
        },
    },
}; </script>