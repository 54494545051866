<template>
    <div>
        <ul class="lni-u-list-reset">
            <li
                v-for="topic in topicList"
                :key="topic.topic"
                class="lni-u-flex lni-u-pv2 lni-u-b--subtle lni-u-space-between lni-u-flex-wrap lni-c-safety-topic__index lni-u-bb --dashed">
                <component
                    :is="topic.reference?'div': 'a'"
                    :href="!topic.reference ? `/safety-health/safety-topics/search-by-topic?query=${topic.link}`: null"
                    :class="[{ 'lni-u-text--no-underline':!topic.reference},'lni-u-mr2']">
                    {{ topic.topic }}
                </component>
                <component
                    :is="topic.reference?'a':'div'"
                    class="lni-u-text--no-underline"
                    :href="`/safety-health/safety-topics/search-by-topic?query=${topic.link}`">
                    <span v-if="topic.reference">See: </span>{{ topic.reference }}
                </component>
            </li>
        </ul>
        <div class="lni-u-flex lni-u-justify-between lni-u-text--all-caps lni-u-type--bold lni-u-mt2">
            <a
                v-if="backLink"
                :href="backLink.route"
                class="lni-u-text--no-underline">
                <span
                    class="lnicon--caret--left"
                    :aria-hidden="true"></span>
                {{ backLink.title }}
            </a>
            <a
                v-if="forwardLink"
                :href="forwardLink.route"
                class="lni-u-text--no-underline">{{ forwardLink.title }}
                <span
                    class="lnicon--caret--right"
                    :aria-hidden="true"></span>
            </a>
        </div>
    </div>
</template>
<script>

export default {
    name: 'lni-safety-topic-list',

}; </script>