export default (context, payload) => {
    const id = context.getters.moduleId;
    const pagerId = `${id}_pager`;
    context.commit('setAttribute', {
        id,
        attribute: 'page',
        value: payload.pageNumber,
    }, {
        root: true,
    });

    context.commit('setAttribute', {
        id: pagerId,
        attribute: 'page',
        value: payload.pageNumber,
    }, {
        root: true,
    });

    context.commit('setAttribute', {
        id: pagerId,
        attribute: 'totalItems',
        value: context.getters.filteredResults.length,
    }, {
        root: true,
    });
};