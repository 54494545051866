<template>
    <div>
        <div class="lni-u-mt3 lni-u-mb2 lni-u-pb3 lni-u-bb lni-u-b--subtle">
            <lni-auto-suggestion
                :id="`${id}_auto-suggest`"
                :options="allDefinitions"
                :enterAction="`${id}/lookup`"
            />
        </div>
        <div
            v-for="(group, index) in definitions"
            :key="index"
        >
            <div class="lni-u-mt3 lni-u-mb1">
                <div
                    class="lni-c-glossary__heading lni-u-text--center lni-u-pv1 lni-u-mr2"
                >
                    <h2 class="lni-u-heading--2">
                        <b>{{ group.heading }}</b>
                    </h2>
                </div>
            </div>
            <dl>
                <lni-glossary-definition
                    v-for="(definition, i) in group.data"
                    :id="`${group.heading}-${i}`"
                    :key="`${group.heading}-${i}`"
                    :term="definition.value"
                    :definition="definition.text"
                    :highlightTargetId="highlightTargetId"
                />
            </dl>
        </div>
    </div>
</template>

<script>
import LniGlossaryDefinition from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-glossary/lni-glossary-definition.vue';

export default {
    name: 'LniGlossary',
    components: {
        LniGlossaryDefinition,
    },
    computed: {
        allDefinitions() {
            const results = [];
            this.definitions.forEach(grp => {
                grp.data.forEach(def => {
                    results.push({
                        text: `${def.value} - ${def.text}`,
                        value: def.value,
                    });
                });
            });

            return results;
        },
    },
};
</script>

<style lang="scss">
@import 'lni-glossary';
</style>