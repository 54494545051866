export default object => {
    const encoded = encodeURIComponent(
        JSON.stringify(object),
    );
    const dotcmsEncoded = encoded.replace(/\(/g, '%28')
        .replace(/\)/g, '%29')
        .replace(/-/g, '%2D') // Hyphen-Minus
        .replace(/—/g, '%E2%80%94') // Em-dash?
        .replace(/_/g, '%5F') // Underscore
        .replace(/\./g, '%2E')
        .replace(/~/g, '%7E')
        .replace(/\*/g, '%2A');
    return dotcmsEncoded;
};