import searchApi from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-topic-search/api/safety-topic-search.api.js';
import lookupApi from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-topic-search/api/safety-topic-lookups.api.js';

export default async (context, payload) => {
    const utils = payload.oneLni.storeUtils;
    const id = payload.targetId;

    const lookupResults = await lookupApi(payload.oneLni);
    utils.setAttribute(context, id, 'channelOptions', lookupResults[0]);
    utils.setAttribute(context, id, 'relevanceOptions', lookupResults[1]);
    utils.setAttribute(context, id, 'options', lookupResults[2]);
    utils.setAttribute(context, id, 'isInitialized', true);

    const query = context.getters.queryString;
    const searchResults = await searchApi(query, payload.oneLni);
    utils.setAttribute(context, id, 'results', searchResults.results);
    utils.setAttribute(context, id, 'totalResults', searchResults.totalResults);
    utils.setAttribute(context, id, 'filteredResults', searchResults.filteredResults);
    utils.setAttribute(context, id, 'aggrs', searchResults.aggrs);
    utils.setAttribute(context, id, 'searchStatus', 'resolved');

    await context.dispatch('updateMultiselectOptions');
    // Set multiselect value and selected options
    const params = context.rootState[id].params;
    if (params.str?.length) {
        utils.setAttribute(context, 'relevance_select', 'value', params.str);
    }
    if (params.stc?.length) {
        utils.setAttribute(context, 'channelOptions_multi-select', 'value', params.stc.split(','));
    }
};