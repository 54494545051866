export default (context, payload) => {
    const pageSize = context.rootState[payload.targetId].value;
    const pagerId = payload.sourceId;
    context.commit(`${pagerId}/SET_PAGE_SIZE`, pageSize, {
        root: true,
    });
    context.commit('SET_PARAM', {
        limit: pageSize,
    });
    context.dispatch('search');
};