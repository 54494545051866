import initialize from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-topic-search/actions/initialize.js';
import search from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-topic-search/actions/search.js';
import clearFilters from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-topic-search/actions/clearFilters.js';
import updatePage from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-topic-search/actions/updatePage.js';
import updateMultiselectOptions from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-topic-search/actions/updateMultiselectOptions.js';
import updateSafetyTopic from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-topic-search/actions/updateSafetyTopic.js';

export default {
    initialize,
    search,
    clearFilters,
    updatePage,
    updateMultiselectOptions,
    updateSafetyTopic,
};