import ApiOptionsInterface from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/interfaces/apiOptionsInterface';

export default class VideoDetail {

    public titleId:string = '';

    constructor(id:string)   {
        this.titleId = id;
        this.getVideoDetail.operation = `/SafetyVideos/${this.titleId}`;
    };

    public getVideoDetail: ApiOptionsInterface = {
        operation: '',
        httpMethod: 'GET',
        body: '',
    };
}