<template>
    <ul class="lni-u-list-reset">
        <li
            v-for="{ url, text, friendlySize } in links"
            :key="url">
            <a
                :href="url"
                class="lni-u-text--no-underline --auto-icon">
                {{ text }}
                <span class="lni-c-link__meta">({{ friendlySize }})</span></a>
        </li>
    </ul>
</template>

<script>
export default {
    name: 'link-list',
    props: {
        links: {
            type: Array,
            required: true,
        },
    },
}; </script>