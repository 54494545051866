export default (context, payload) => {
    const targetState = context.rootState[payload.targetId];

    const hours = targetState.value;
    let validity = {};
    if (targetState.validity) {
        validity = {
            ...targetState.validity,
        };
    }

    const minHoursPerWeek = 40;
    validity.hoursInvalid = Number(hours) < minHoursPerWeek;

    return validity;
};