<template>
    <div class="lni-c-box--content --card lni-u-mhauto lni-u-flex lni-u-justify-between lni-u-flex-column">
        <div>
            <div class="lni-u-flex lni-u-justify-between">
                <a
                    :href="card.filePath"
                    target="_blank"
                    class="lni-u-type--medium lni-c-sharp-card__title lni-c-sharp-card__column1">{{ card.title }}</a>
                <a
                    :aria-label="`Link to file named: ${card.title}`"
                    :class="['--auto-icon', 'lni-c-sharp-card__icon']"
                    :href="card.filePath"></a>
            </div>
            <div class="lni-c-sharp-card__info">
                <p class="lni-u-line-height--tight lni-u-type-xs lni-c-sharp-card__column1">
                    {{ card.summary }}
                </p>
                <div class="lni-u-text--all-caps lni-u-type--xxs lni-u-line-height--tight lni-c-sharp-card__column2">
                    <div>
                        <span>
                            {{ card.publicationType.text }}
                        </span>
                    </div>
                    <div>
                        <span
                            v-for="(hazard, index) in card.hazards"
                            :key="'hazard' + index"
                            class="lni-u-block">
                            {{ hazard }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="lni-c-sharp-card__publication">
                <span>{{ card.publicationYear.text }}</span>
                <span class="lni-u-type--xxs lni-u-text--all-caps">
                    Publication:
                    <span>{{ card.publication }}</span>
                </span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'lni-search-sharp-card',
    props: {
        card: {
            type: Object,
            required: true,
        },
    },
}; </script>