<template>
    <div
        :class="colorClass"
        class="lni-c-system-status"
        role="alert"
    >
        <div class="lni-u-flex">
            <span
                aria-hidden="true"
                :class="`lnicon--${iconClass}`"
                class="lni-c-system-status-icon"
            />
            <div>
                <h3 class="lni-u-heading--3">
                    {{ title }}
                </h3>
                <div>
                    <lni-dynamic-template
                        id="message"
                        class="lni-u-mv1"
                        :templateString="message"
                    />
                </div>
                <div class="lni-u-type--xs lni-u-flex lni-u-justify-end lni-u-mt2 lni-u-full-width">
                    Last updated:&nbsp;<time
                        class="lni-u-nowrap"
                        :datetime="dateTime"
                    >{{ dateTime }}</time>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const messageClasses = {
    active: {
        color: '--warn',
        icon: 'warning',
    },
    scheduled: {
        color: '--info',
        icon: 'info',
    },
    resolved: {
        color: '--okay',
        icon: 'checkmark',
    },
};

export default {
    name: 'LniSystemStatus',
    computed: {
        iconClass() {
            return messageClasses[this.level].icon;
        },
        colorClass() {
            return messageClasses[this.level].color;
        },
    },
}; </script>
<style lang="scss">
@import 'lni-system-status';
</style>