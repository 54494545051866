export default (context, payload) => {
    if (!payload.targetId) {
        return;
    }
    // payload.targetId === `${sourceId}_${filterName}`
    const idPrefixLength = `${payload.sourceId}_`.length;
    const filterId = payload.targetId.substring(idPrefixLength);
    const value = context.rootState[payload.targetId].value;
    if (Object.keys(context.state.filterValues).includes(filterId)) {
        context.commit('UPDATE_FILTER_VALUES', {
            filter: filterId,
            value,
        });
    }
};