export const itemSelected = function itemSelected(context, payload) {
    let office = context.rootState[payload.sourceId].locations.filter(e => {
        if ( e.title === context.rootState[payload.targetId].value) {
            return e;
        }
        return false;
    });

    context.commit('ITEM_SELECTED', {
        location: context.rootState[payload.targetId].value,
        office: office[0],
    });

    // Component is undefined without setTimeout() because of update timings
    setTimeout(() => {
        context.commit('setAttribute', {
            id: `${payload.sourceId}_office-hours`,
            attribute: 'htmlString',
            value: office[0].hours,
        }, {
            root: true,
        });
    });
};