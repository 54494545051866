export default (context, payload) => {
    const filterControls = Object.keys(context.state.filterOptions);
    filterControls.forEach(id => {
        if (['publicationYear', 'publicationType'].includes(id)) {
            context.commit('UPDATE_FILTER_VALUES', {
                filter: id,
                value: [],
            });
        } else {
            context.commit('UPDATE_FILTER_VALUES', {
                filter: id,
                value: '',
            });
        }
    });
    context.dispatch('search', payload);
};