<template>
    <div class="lni-u-mt2">
        <ol class="lni-u-list-reset">
            <li>
                <button
                    :id="'toggleStep_' + id"
                    class="lni-c-button --text lni-c-rule__toggle-purpose"
                    :aria-expanded="`${isOpen}`"
                    :aria-controls="'activities_' + id"
                    @click="isOpen = !isOpen">
                    <span class="lni-c-rule-activity__title lni-u-type--bold">{{ step }}</span>
                    <span
                        aria-hidden="true"
                        :class="['lni-c-rule-activity__icon--toggle',
                                 `lnicon--triangle--${isOpen? 'up':'down'}`]"></span>
                </button>
                <ol
                    v-show="isOpen"
                    :id="'activities_' + id"
                    class="lni-u-list-reset">
                    <li
                        v-for="(activity, index) in activities"
                        :key="activity.title + index"
                        :class="{'--active': activity.active}"
                        class="lni-c-rule-activity lni-u-flex">
                        <span
                            class="lni-c-rule-activity__icon lnicon--arrow--right lni-u-ph2"
                            :class="{'--active': activity.active}"></span>
                        <span class="lni-u-pr2">{{ activity.activityEndDate }}</span>
                        <a
                            v-if="activity.route"
                            class="lni-c-rule-activity__link lni-u-inline-block"
                            :href="activity.route"
                            :aria-describedby="'activities_' + id">{{ activity.title }}</a>
                        <span v-else>{{ activity.title }}</span>
                    </li>
                </ol>
            </li>
        </ol>
    </div>
</template>
<script>

export default {
    name: 'lni-rulemaking-activity',

    data() {
        return {
            isOpen: false,
        };
    },
    mounted() {
        if (this.activities.filter( e => e.active).length > 0) {
            this.isOpen = true;
        }
    },
    methods: {
        currentStage(status) {
            if (status) {
                return 'Next Rulemaking Activity Stage';
            }
            return false;
        },
    },
}; </script>