export default (context, payload) => {
    const targetState = context.rootState[payload.targetId];

    const experience = targetState.value;
    let validity = {};
    if (targetState.validity) {
        validity = {
            ...targetState.validity,
        };
    }

    //Whole number or decimal
    const trailingDecimals = 4; //Up to 4 digits after the decimal
    const decimalIndex = experience.indexOf('.');
    const decimalLength = decimalIndex > -1 ? (experience.length - 1) - decimalIndex : 0;
    const endValue = 10; //Not inclusive
    validity.experienceInvalid = isNaN(experience)
                                || (Number(experience) <= 0 || Number(experience) >= endValue)
                                || (decimalLength > 0 ? decimalLength > trailingDecimals : false);

    return validity;
};