export default (context, show) => {
    const id = context.getters.moduleId;
    const errorMessageTitle = context.state.text.errorMessageTitle;
    const successMessageTitle = context.state.text.successMessageTitle;
    const title = context.state.success ? successMessageTitle : errorMessageTitle;

    context.commit('setAttribute', {
        id: `${id}_modal`,
        attribute: 'title',
        value: title,
    }, {
        root: true,
    });
    context.commit('setAttribute', {
        id: `${id}_modal`,
        attribute: 'visible',
        value: show,
    }, {
        root: true,
    });
};