export default () => ({
    state: {
        touts: {
            title: '',
            tout: [{
                title: '',
                description: '',
                filename: '',
                route: '',
            }, {
                title: '',
                description: '',
                filename: '',
                route: '',
            }],
            index: '',
            linkLabel: '',
            query: '',
        },
    },
});