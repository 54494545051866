export default (context, payload) => {
    const moduleId = context.getters.moduleId;
    const senderData = payload.customData;

    context.commit('setAttribute', {
        id: `${moduleId}_company-name`,
        attribute: 'value',
        value: senderData.companyName,
    }, {
        root: true,
    });

    context.commit('setAttribute', {
        id: `${moduleId}_contact-name`,
        attribute: 'value',
        value: senderData.contactName,
    }, {
        root: true,
    });

    context.commit('setAttribute', {
        id: `${moduleId}_contact-phone_phone`,
        attribute: 'value',
        value: senderData.contactPhone,
    }, {
        root: true,
    });

    context.commit('setAttribute', {
        id: `${moduleId}_contact-phone_ext`,
        attribute: 'value',
        value: senderData.contactPhoneExt,
    }, {
        root: true,
    });

    context.commit('setAttribute', {
        id: `${moduleId}_contact-email`,
        attribute: 'value',
        value: senderData.contactEmail,
    }, {
        root: true,
    });

    context.commit('SET_SENDER', senderData);
};