export default (context, payload) => {
    const inputId = payload.targetId;
    const prefixLength = payload.sourceId.length;
    const modelId = inputId.substring(prefixLength + 1);
    const value = context.rootState[inputId].value;

    context.commit('UPDATE_FORM_ITEM', {
        id: modelId,
        value,
    });
    context.dispatch('persist', payload);
};