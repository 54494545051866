export default (context, payload) => {
    const moduleId = context.getters.moduleId;
    const moduleState = context.rootState[moduleId];

    const apiEndpoint = `${payload.oneLni.environment.current.publicApiLocation}/DoshHospitalizations/GetLookups`;
    fetch(apiEndpoint, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
        },
    }).then(response => response.json()).then(data => {
        //Data is a list of key-value pairs and the state has a property associated with each key
        data.forEach(obj => {
            context.commit('setAttribute', {
                id: moduleId,
                attribute: obj.key,
                value: obj.value,
            }, {
                root: true,
            });
        });

        context.commit('setAttribute', {
            id: `${moduleId}_industry-select`,
            attribute: 'options',
            value: context.getters.industryOptions,
        }, {
            root: true,
        });
        context.commit('setAttribute', {
            id: `${moduleId}_industry-select`,
            attribute: 'value',
            value: parseInt(moduleState.industryDefaultValue),
        }, {
            root: true,
        });

        context.commit('setAttribute', {
            id: `${moduleId}_incident-type-select`,
            attribute: 'options',
            value: context.getters.incidentTypeOptions,
        }, {
            root: true,
        });
        context.commit('setAttribute', {
            id: `${moduleId}_incident-type-select`,
            attribute: 'value',
            value: parseInt(moduleState.incidentTypeDefaultValue),
        }, {
            root: true,
        });

        context.commit('setAttribute', {
            id: `${moduleId}_display-select`,
            attribute: 'options',
            value: context.getters.displayOptions,
        }, {
            root: true,
        });
        context.commit('setAttribute', {
            id: `${moduleId}_display-select`,
            attribute: 'value',
            value: moduleState.displayDefaultValue,
        }, {
            root: true,
        });

        context.dispatch('filter', {
            oneLni: payload.oneLni,
        }).then(() => {
            if (moduleState.loadState !== 'error') {
                context.commit('setAttribute', {
                    id: moduleId,
                    attribute: 'loadState',
                    value: 'loaded',
                }, {
                    root: true,
                });
            }
        });
    }).catch(error => {
        context.commit('setAttribute', {
            id: moduleId,
            attribute: 'loadState',
            value: 'error',
        }, {
            root: true,
        });

        context.commit('setAttribute', {
            id: moduleId,
            attribute: 'errorDetails',
            value: error,
        }, {
            root: true,
        });
    });
};