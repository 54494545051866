import actions from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-topic-search/actions';

export default () => ({
    state: {
        isInitialized: false,
        searchStatus: 'pending', // 'resolved' or 'pending'
        cookie: 'safetyTopics_search',
        options: [],
        relevanceOptions: [],
        channelOptions: [],
        results: [],
        filteredResults: [],
        aggrs: [],
        page: 1,
        totalResults: null,
        params: {
            query: '',
            pg: '',
            str: '',
            stc: '',
        },
    },
    actions: {
        ...actions,
    },
    getters: {
        channelOptionsWithCounts(state) {
            const initializedOptions = [...state.channelOptions].map(
                opt => {
                    const hasValue = state.aggrs && (state.aggrs[opt.text.toLowerCase().replace(/ /g, '')] || state.aggrs[opt.value]);
                    return {
                        ...opt,
                        disabled: !hasValue,
                        count: hasValue ?? 0,
                    };
                });

            return initializedOptions;
        },
        queryString(state) {
            const result = [];
            if (state.params.query) {
                result.push(`query=${state.params.query}`);
            }
            if (state.params.pg) {
                result.push(`pg=${state.params.pg}`);
            }
            if (state.params.str) {
                result.push(`str=${state.params.str}`);
            }
            if (state.params.stc) {
                result.push(`stc=${state.params.stc}`);
            }

            return result.join('&');
        },
    },
});