import {
    togglePurpose,
} from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-rulemaking-card/actions/togglePurpose.js';

export default () => ({
    state: {
        card: null,
    },
    actions: {
        togglePurpose,
    },
});