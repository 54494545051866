<template>
    <div>
        <div
            v-show="showQuickLinks"
            class="lni-u-mt3 lni-c-box--content --card lni-u-mhauto lni-u-flex lni-u-justify-between lni-u-flex-column"
        >
            <h2>Quick Search</h2>
            <ul class="lni-u-ml3">
                <li>
                    <a
                        href
                        class="lni-u-type--medium lni-u-bold lni-u-type--md lni-u-text--no-underline"
                        @click.prevent="loadCatalog('4')"
                    > Videos to watch online
                    </a>
                </li>
                <li>
                    <a
                        href
                        class="lni-u-type--medium lni-u-bold lni-u-type--md lni-u-text--no-underline"
                        @click.prevent="loadCatalog('3')"
                    >
                        Vídeos disponibles en español <i>(Videos available in Spanish)</i>
                    </a>
                </li>
            </ul>
        </div>
        <div v-show="showQuickLinkData">
            <div
                class="lni-u-flex lni-u-justify-end"
            >
                <a
                    href
                    class="lni-u-type--medium lni-u-bold lni-u-type--md lni-u-text--no-underline"
                    @click.prevent="navigateToSearchPage()"
                >
                    New Search
                </a>
            </div>
            <hr>
            <div class="lni-u-mb2">
                {{ searchResultLabel }} <b>{{ catalogTitleCount }}</b> <i>{{ catalogTypeLabel }}</i>
            </div>
            <span
                v-for="(group, letter) in filteredGroupedCatalogData"
                :key="letter"
            >
                <div class="lni-u-mt2">
                    <span
                        v-if="isAlpha(letter)"
                    >
                        <span
                            :id="letter"
                            class="lni-c-quicklink-shrl__bigLetter"
                        >
                            {{ letter }}
                        </span>
                    </span>
                    <!--Display alphabet-->
                    <span
                        v-for="item in filteredAlphabetData"
                        :key="item[0]+item[1]"
                    >
                        <span
                            v-if="isAlpha(letter)"
                        >
                            <b
                                v-if="item[1]"
                                class="lni-u-mr1"
                            >
                                <a
                                    :href="`#${item[0]}`"
                                    class="lni-u-type--medium lni-c-shrl-card__column1 lni-u-bold lni-u-type--md lni-u-text--no-underline"
                                >
                                    {{ item[0] }}
                                </a>
                            </b>
                            <span
                                v-else
                                class="lni-u-mr1"
                            >
                                {{ item[0] }}
                            </span>
                        </span>
                    </span>
                </div>
                <table>
                    <tr
                        v-for="video in group"
                        :key="video.titleId"
                    >
                        <td>
                            <a
                                href
                                class="lni-u-type--medium lni-c-shrl-card__column1 lni-u-bold lni-u-type--md lni-u-text--no-underline"
                                @click.prevent="showDetail(video.titleId)"
                            >
                                {{ video.titleName }}
                            </a>
                        </td>
                    </tr>
                </table>
            </span>
        </div>
    </div>
</template>

<script>
import sharedConstants from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/actions/helpers/constants';
import {mapState} from 'vuex';

export default {
    name: 'LniSafetyVideoQuicklinks',
    emits: ['hide-detail', 'hide-main', 'show-title', 'show-detail'],
    data() {
        return {
            showQuickLinks: true,
            showQuickLinkData: false,
            catalogTypeSelected: '',
        };
    },
    computed: {
        ...mapState({
            catalogData: state => state.catalogData,
            catalogDataAlphabet: state => state.catalogDataAlphabet,
            catalogDataGrouped: state => state.catalogDataGrouped,
        }),
        catalogTitleCount() {
            return this.$store.getters['search/getCatalogTitleCount'];
        },
        searchResultLabel() {
            return this.$store.getters['search/getSearchResultTotalLabel'];
        },
        catalogTypeLabel() {
            return this.$store.getters['search/getQuickLinkType'];
        },
        filteredGroupedCatalogData() {
            return this.$store.getters['search/getPreFilteredGroupedCatalogData'];
        },
        filteredAlphabetData() {
            return this.$store.getters['search/getPreFilteredAlphabetData'];
        },
    },
    mounted() {},
    methods: {
        isAlpha(letter) {
            return /^[A-Z]+$/.test(letter);
        },
        navigateToSearchPage() {
            window.location.href = sharedConstants.ROUTES.newSearch;
            window.location.reload();
        },
        loadCatalog(linkId) {
            this.catalogTypeSelected = linkId;
            this.showQuickLinks = false;
            this.showQuickLinkData = true;
            this.$emit('hide-detail');
            this.$emit('hide-main');
            this.$emit('show-title', sharedConstants.TITLETYPES.OnlineVideos);
            this.$store.dispatch('search/loadCatalogData', {
                oneLni: this.$oneLni,
                linkId,
            });
        },
        hideVideoDetail() {
            this.showQuickLinkVideoDetail = false;
        },
        showDetail(titleId) {
            this.showQuickLinks = false;
            this.showQuickLinkData = false;
            this.$emit('show-detail', titleId);
        },
    },
};
</script>
<style lang="scss">
    @import 'lni-safety-video-search'
</style>