import isNullOrEmptyString from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/actions/helpers/isNullOrEmptyString';

export default function safeCasing(stringVal: string, caseOption: string): string {
    if (isNullOrEmptyString(stringVal)) {
        return '';
    }

    if (isNullOrEmptyString(caseOption)) {
        return stringVal;
    }

    if (typeof stringVal === 'string') {
        let formatParam: 'u' | 'l';

        if (caseOption.toLowerCase() === 'touppercase' || caseOption.toLowerCase() === 'u') {
            formatParam = 'u';
        } else if (caseOption.toLowerCase() === 'tolowercase' || caseOption.toLowerCase() === 'l') {
            formatParam = 'l';
        } else {
            return stringVal; // Return original string if caseOption is invalid
        }

        return formatParam === 'u' ? stringVal.toUpperCase() : stringVal.toLowerCase();
    } else {
        return '';
    }
};