import setAttribute from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-simbic-calculator/actions/setAttribute.js';
import formatConversion from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-simbic-calculator/actions/formatConversion.js';

export default (context, results) => {
    const id = context.getters.moduleId;
    results.payments.forEach((payment, index) => {
        setAttribute('value', context, {
            sourceId: `${id}_interest-${index + 1}`,
        }, formatConversion(payment.interest, 'Currency'));
    });

    setAttribute('value', context, {
        sourceId: `${id}_remaining-principal`,
    }, formatConversion(results.remainingPrincipal, 'Currency'));

    setAttribute('value', context, {
        sourceId: `${id}_balance-owed`,
    }, formatConversion(results.balanceOwed, 'Currency'));

    setAttribute('value', context, {
        sourceId: `${id}_accrual-date`,
    }, results.accrualDate.toLocaleDateString());

};