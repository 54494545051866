
import setAttribute from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-lep-calculator/actions/setAttribute.js';

export default (context, payload) => {
    setAttribute('value', context, `${context.getters.moduleId}_wages-at-doi`, '');
    setAttribute('value', context, `${context.getters.moduleId}_to-date`, '');
    setAttribute('value', context, `${context.getters.moduleId}_from-date`, '');
    setAttribute('value', context, `${context.getters.moduleId}_claim-number`, '');
    setAttribute('value', context, `${context.getters.moduleId}_worker`, '');
    setAttribute('value', context, `${context.getters.moduleId}_current-wages`, '');
    setAttribute('value', context, `${context.getters.moduleId}_time-loss-rate`, '');
    setAttribute('value', context, `${context.getters.moduleId}_prepared-by`, '');
    setAttribute('results', context, payload.sourceId, '');

    context.dispatch('resetFormValidationState', {
        targetId: `${context.getters.moduleId}_reset-button`,
    }, {
        root: true,
    });
};