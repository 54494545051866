<template>
    <form
        class="lni-c-equal-pay-consultation lni-u-mt3 lni-u-form-width--base"
    >
        <div class="lni-u-flex lni-u-flex-column">
            <lni-input-text
                :id="`${id}_company-name`"
                :labelText="text.companyName"
                :filled="true"
                :required="true"
                :labelBefore="true"
                :fullWidth="true"
            />
            <lni-input-text
                :id="`${id}_contact-name`"
                class="lni-u-mt3"
                :labelText="text.contactName"
                :filled="true"
                :required="true"
                :labelBefore="true"
                :fullWidth="true"
            />
            <lni-input-phone
                :id="`${id}_contact-phone`"
                :labelText="text.contactPhone"
                :isFieldset="false"
                :filled="true"
                :required="true"
                class="lni-u-mt3 lni-c-equal-pay-consultation_phone"
                :labelBefore="true"
                :fullWidth="true"
            />
            <lni-input-email
                :id="`${id}_contact-email`"
                :labelText="text.contactEmail"
                :filled="true"
                :required="true"
                :labelBefore="true"
                :fullWidth="true"
            />
        </div>
        <div class="lni-u-mt3">
            <p>{{ text.submitMessage }}</p>
            <lni-button
                :id="`${id}_submit`"
                :text="text.submit"
                type="button"
                class="lni-u-mt3"
                :errorContainerId="`${id}_errors`"
                :clickAction="['validateForm',`${id}/submit`]"
            />
            <lni-error-container
                :id="`${id}_errors`"
                class="lni-u-mt3"
                :heading="text.errorsHeading"
                :errorsCollectedFrom="[
                    `${id}_company-name`,
                    `${id}_contact-name`,
                    `${id}_contact-phone`,
                    `${id}_contact-email`,
                ]"
            />
        </div>
        <div v-show="showModalSpinner">
            <lni-spinner
                :id="`${id}_spinner`"
            />
        </div>
    </form>
</template>

<script>
import decodeDotcmsCookie from '@gov.wa.lni/application.public-website-custom-components/source/utilities/storage/decodeDotcmsCookie.js';

export default {
    name: 'LniEqualPayConsultation',
    mounted() {
        if (this.sendEmailEndpoint.length < 1) {
            console.warn('sendEmailEndpoint is not set.');
        }

        const senderCookie = this.$oneLni.storage.cookie.get(this.cookie);
        if (senderCookie) {
            let senderData = decodeDotcmsCookie(senderCookie);
            this.dispatchEvent(`${this.id}/updateSender`, senderData);
        }
    },
};
</script>

<style lang="scss">
@import 'lni-equal-pay-consultation';
</style>