//import mockApi from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-ship-grant-projects/api/mock.api.json'; //For use in demo only

let shipGrantProjectsPromise = null;

export default oneLni => {
    if (oneLni.environment.current.name === 'Local Development') {
        //console.warn('Using mock rules data - uncomment code in lni-ship-grant-projects.api.js');

        // return mockApi; //For use in demo
    }

    const endpoint = `${oneLni.environment.current.apiLocation2}/ship-grant-projects/`;
    shipGrantProjectsPromise = fetch(endpoint, {
        mode: 'cors',
    }).then(response => response.json())
        .then(data => data.results);
    return shipGrantProjectsPromise;
};