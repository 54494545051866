import actions from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-early-rtw-consultation/actions';

export default () => ({
    state: {
        loading: true,
        text: {
            heading: '',
            name: '',
            email: '',
            phone: '',
            city: '',
            comments: '',
            submit: '',
        },
        sendEmailEndpoint: '',
        cookieName: 'early-rtw-consultation',
    },
    actions: {
        ...actions,
    },
});