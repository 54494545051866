export default function(context, payload) {
    const params = new window.URLSearchParams(window.location.hash.substring(1));
    params.set(payload.param, payload.value);

    const urlParts = window.location.href.split('?');
    const newQuery = context.getters.urlParams; //already encoded
    const newURL = `${urlParts[0]}?${newQuery}`;
    history.pushState({
        path: newURL,
    }, document.title, newURL);
}