import checkForMessage from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-rtw-printable-form/actions/checkForMessage.js';
import initialize from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-rtw-printable-form/actions/initialize.js';
import setValues from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-rtw-printable-form/actions/setValues.js';
import submit from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-rtw-printable-form/actions/submit.js';

export default {
    checkForMessage,
    initialize,
    setValues,
    submit,
};