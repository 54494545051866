import stateRate from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-lep-calculator/actions/stateRate.js';

// Months are zero based in javascript(january is the 0th month, june is the 5th month)
const june = 5,
    daysInJune = 30;

const endOfFiscalYearDate = currentDate => {
    const currentMonth = currentDate.getMonth();
    let endYear = currentDate.getFullYear();
    if (currentMonth > june) {
        endYear = endYear + 1;
    }

    return new Date(endYear, june, daysInJune);
};

export default (startDate, endDate, rates) => {
    const wageArray = [];
    let currentDate = startDate;
    let endFiscalYearDate = endOfFiscalYearDate(currentDate);
    while (currentDate <= endDate) {
        let currentWages = {
            days: 0,
            itemDate: new Date(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                currentDate.getDate(),
            ),
        };
        while (currentDate <= endDate && currentDate <= endFiscalYearDate) {
            currentWages.days += 1;

            currentDate.setDate(currentDate.getDate() + 1);
        }
        wageArray.push(currentWages);
        endFiscalYearDate = endOfFiscalYearDate(currentDate);
    }

    let totalRate = 0;
    let totalDays = 0;
    wageArray.forEach(wage => {
        totalDays += wage.days;
        const rate = stateRate(wage.itemDate, rates);
        totalRate += rate * wage.days;
    });

    return [totalRate, totalDays];
};