<template>
    <div>
        <lni-input-text
            :id="id + '_input'"
            ref="valuationInput"
            class="lni-c-elevator__amount-input"
            type="number"
            step=".01"
            labelText="Valuation in USD"
            :dense="true"
            required
            :changeAction="['validateForm', `${id}/calculateTotalFee`]"
            :filled="false"
        />
        <lni-error-container
            :id="`${id}_error-container`"
            heading=""
            :errorsCollectedFrom="[
                id + '_input',
            ]"
        />
        <div class="lni-c-elevator__inputs-wrapper lni-u-mb3">
            <lni-button
                :id="id + '_button1'"
                text="Reset"
                type="alternate"
                :clickAction="`${id}/resetCalc`"
                classString="lni-c-button --primary"
            />
            <lni-button
                :id="id + '_button2'"
                :clickAction="['validateForm', `${id}/calculateTotalFee`]"
                text="Calculate"
                :errorContainerId="`${id}_error-container`"
                class="lni-c-elevator__submit lni-c-button --primary"
            />
        </div>
        <div
            aria-live="polite"
        >
            <h4 class="lni-c-elevator__result lni-u-type--normal lni-u-mb1">
                Result
            </h4>
            <!-- TODO: Fix significant figures -->
            <lni-input-display
                :id="id + '_result'"
                :labelText="`Elevator ${installation ? 'Installation': 'Alteration'} Fee`"
                :value="totalFee"
            />
            <div
                v-if="totalFee"
                class="lni-u-mb"
            >
                <p class="lni-u-mb1">
                    The elevator {{ installation ? 'installation' : 'alteration' }} fee based on the <strong>{{ USD(valuation) }}</strong> contractor valuation you entered is <strong>{{ totalFee }}</strong>.
                </p>
                <div v-if="valuation > 15000">
                    <h4>Valuations $15,001 or Over</h4>
                    <p>Contractor valuations $15,001 or over have a base fee of {{ USD(baseFee) }} plus {{ USD(addFee) }} for each additional $1,000 over $15,001.</p>
                    <p>See <a :href="otherFeesLink">"Other and/or Additional Fees"</a> page for more information.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as api from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-elevator-valuation/fees.api.js';

export default {
    name: 'LniElevatorValuation',

    mounted() {
        this.addCustomMessages();
        this.initializeBaseFees();

    },
    methods: {
        initializeBaseFees() {
            api.getFees(this.$oneLni).then(data => {
                this.$store.dispatch(`${this.id}/updateFees`, data);
            });
        },
        addCustomMessages() {
            this.$store.commit(`${this.id}_input/customizeValidationMessage`, {
                validationTest: 'valueMissing',
                global: () =>
                    `Please enter valuation in US dollars.`,
                inline: () => 'Please fill out this field',
            });
        },
        /**
         * Filter number to display in USD format
         * @param {Number} val to be converted to currency
         * @return {String} value in USD currency
         */
        USD(val) {
            const floatDig = 2;

            return '$' + parseFloat(val).toFixed(floatDig)
                .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,');
        },
    },
}; </script>