import getTableState from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-explanation-of-benefits-lookup/actions/getTableState.js';

let previousSearchString = '';

export default (context, payload) => {
    let moduleId = context.getters.moduleId;
    const searchString = context.rootState[`${moduleId}_search-text`].value;

    const tableState = getTableState(context, `${moduleId}_table`);
    let searchStringChanged = false;
    if (previousSearchString !== searchString) {
        searchStringChanged = true;
        tableState.PageNumber = 1;
    }
    previousSearchString = searchString;

    const requestBody = {
        searchString,
        ...tableState,
    };

    const currentEnvironment = payload.oneLni.environment.current;
    let searchUrl = `${currentEnvironment.publicApiLocation}/Eob/Search`;
    fetch(searchUrl, {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
        },
        body: JSON.stringify(requestBody),
    })
        .then(response => {
            if (response.ok) {
                return response.json();
            }

            context.commit('setAttribute', {
                id: `${moduleId}_table`,
                attribute: 'loadState',
                value: 'error',
            }, {
                root: true,
            });

            return null;
        })
        .then(response => {
            let items = response.items;
            let totalItemCount = response.totalItems;

            context.commit('setAttribute', {
                id: `${moduleId}_table`,
                attribute: 'items',
                value: items,
            }, {
                root: true,
            });
            context.commit('setAttribute', {
                id: `${moduleId}_table`,
                attribute: 'totalItems',
                value: totalItemCount,
            }, {
                root: true,
            });

            if (searchStringChanged) {
                context.commit('setAttribute', {
                    id: `${moduleId}_table`,
                    attribute: 'page',
                    value: 1,
                }, {
                    root: true,
                });
                context.commit('setAttribute', {
                    id: `${moduleId}_table_pager`,
                    attribute: 'page',
                    value: 1,
                }, {
                    root: true,
                });

                context.commit('setAttribute', {
                    id: `${moduleId}_table_pager`,
                    attribute: 'totalItems',
                    value: totalItemCount,
                }, {
                    root: true,
                });
            }
        })
        .catch(() => {
            context.commit('setAttribute', {
                id: `${moduleId}_table`,
                attribute: 'loadState',
                value: 'error',
            }, {
                root: true,
            });
        });
};