export default (context, payload) => {
    const moduleState = context.rootState[context.getters.moduleId];
    const targetState = context.rootState[payload.targetId];

    const riskClass = targetState.value;
    const validLength = 4;
    let validity = {};
    if (targetState.validity) {
        validity = {
            ...targetState.validity,
        };
    }

    validity.riskClassInvalid = isNaN(riskClass) || riskClass.length !== validLength;
    validity.riskClassExcluded = moduleState.riskClassExclusions.some(
        r => riskClass === r,
    );
    validity.riskClassNotFound = context.getters.rateVariables(riskClass) === undefined;

    return validity;
};