export default (context, payload) => {
    const value = context.rootState[payload.targetId].value;
    let collapse = false;
    if (value === 'Email') {
        collapse = true;

        context.commit('setAttribute', {
            id: `${payload.sourceId}_address_address1`,
            attribute: 'value',
            value: '',
        }, {
            root: true,
        });

        context.commit('setAttribute', {
            id: `${payload.sourceId}_address_address2`,
            attribute: 'value',
            value: '',
        }, {
            root: true,
        });

        context.commit('setAttribute', {
            id: `${payload.sourceId}_address_city`,
            attribute: 'value',
            value: '',
        }, {
            root: true,
        });

        context.commit('setAttribute', {
            id: `${payload.sourceId}_address_state`,
            attribute: 'value',
            value: 'WA',
        }, {
            root: true,
        });

        context.commit('setAttribute', {
            id: `${payload.sourceId}_address_zip`,
            attribute: 'value',
            value: '',
        }, {
            root: true,
        });
    }

    context.commit('setAttribute', {
        id: `address-collapse`,
        attribute: 'visible',
        value: !collapse,
    }, {
        root: true,
    });

    if (collapse) {
        context.commit('REMOVE_TRACKED_ERRORS', [`${payload.sourceId}_address`]);
    } else {
        context.commit('ADD_TRACKED_ERRORS', [`${payload.sourceId}_address`]);
    }
};