// import mockApi from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-rule-chapter/api/rules.api.js';
const RULES_ENDPOINT = '/rules/';
let rulesPromise = null;

export const getRules = function(chapter, oneLni) {
    const endpoint = `${oneLni.environment.current.apiLocation2}${RULES_ENDPOINT}?chapter=${chapter}`;
    if (oneLni.environment.current.name === 'Local Development') {
        console.warn('Using mock rules data - uncomment code in lni-find-rules/api/rules.api.js');
        // return mockApi;
    }

    if (!rulesPromise) {
        rulesPromise = fetch(endpoint, {
            mode: 'cors',
        }).then(response => response.json())
            .then(data => data.results);
    }
    return rulesPromise;
};