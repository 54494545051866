<template>
    <div class="lni-c-find-rules">
        <lni-input-search
            :id="`${id}_search`"
            type="search"
            placeholder="Search Rules"
            :searchAction="`${id}/search`"
            :clearAction="`${id}/search`"
            :query="query"
        />
        <lni-radios
            :id="`${id}_filter`"
            class="lni-c-find-rules__filter lni-u-mt3"
            :options="filterOptions"
            labelText="Filter by:"
            :hideLabel="true"
            :isColumn="false"
            :changeAction="[`${id}/filter`,`${id}/showCoreRulesExplainer`]"
            value="chapters"
        />
        <div
            v-if="showCoreRulesExplainer"
            class="lni-u-flex lni-u-mv1 lni-u-items-start"
        >
            <span
                aria-hidden="true"
                class="lni-c-find-rules__info-icon lni-c-icon lnicon--info lni-u-mr1 lni-u-line-height--tight lni-u-font-color--info"
            />
            <p class="lni-u-type--xs lni-u-line-height--tight lni-u-reading-width--max">
                Core rules are the basic safety and health rules all employers need to follow, except for agricultural businesses.
            </p>
        </div>
        <div
            v-if="ruleCount === 0 && !showLoader"
            class="lni-u-mt2"
            aria-live="polite"
        >
            <p>Sorry, no rules matched your search of {{ filterOptions.find(item => item.value === filter).text }} for "{{ query }}".</p>
        </div>
        <transition name="lni-o-fade">
            <div v-if="!showLoader">
                <div
                    v-show="ruleCount > 0"
                    class="lni-c-find-rules__results lni-u-mt2"
                >
                    <p class="lni-u-type--xs">
                        Found {{ ruleCount }} rules
                    </p>
                    <transition-group
                        name="filter-list"
                        tag="div"
                    >
                        <lni-rule
                            v-for="rule in currentPageRules"
                            :key="`${rule.chapter}-${rule.section}`"
                            v-bind="{...rule}"
                        />
                    </transition-group>
                    <lni-pager
                        :id="`${id}_pager`"
                        class="lni-u-pt3"
                        :collectionId="id"
                        :page="currentPage"
                        :totalItems="ruleCount"
                        :pageSize="itemsPerPage"
                        :showPager="false"
                        selectLabel="Items per page"
                        @change-page="onPageChange"
                    />
                </div>
            </div>
        </transition>
        <div
            v-show="showLoader"
            class="lni-u-text--center lni-u-full-height"
        >
            <lni-spinner
                id="rulesSpinner"
                :inline="true"
            />
        </div>
    </div>
</template>

<script>
import LniRule from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-find-rules/lni-rule.vue';

export default {
    name: 'LniFindRules',
    components: {
        LniRule,
    },
    data() {
        return {
            currentHash: '',
            showLoader: true,
        };
    },
    computed: {
        searchedRules() {
            return this.$store.getters[`${this.id}/searchedAndFilteredRules`];
        },
        ruleCount() {
            return this.$store.getters[`${this.id}/resultCount`];
        },
        lastPage() {
            return Math.ceil(parseInt(this.ruleCount, 10) / this.itemsPerPage);
        },
        currentPageRules() {
            const start = ( this.currentPage - 1 ) * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            return this.searchedRules.slice(start, end);
        },
        urlParams() {
            return this.$store.getters[`${this.id}/urlParams`];
        },
    },
    //TODO: Can we do this earlier?
    mounted() {
        this.$store.commit(`${this.id}/SET_FILTER`, 'chapters');

        const initState = this.getUrlParams();
        this.updateState(initState);

        this.$store.dispatch(`${this.id}/search`, {
            oneLni: this.$oneLni,
        });

        this.$watch('isLoaded', isLoaded =>  {
            if (isLoaded) {
                this.hideLoader();
            } else {
                this.showLoader = true;
            }
        });
    },
    updated() {
        this.updatePageCount();
    },
    methods: {
        hideLoader() {
            const minLoaderTime = 1200;
            setTimeout(self => {
                self.showLoader = false;
            }, minLoaderTime, this);
        },
        onPageChange(payload) {
            this.$store.commit(`${this.id}/SET_PAGE`, payload.page);
            const params = this.$store.getters[`${this.id}/params`];
            this.updateState(params);
        },
        updatePageCount() {
            if (!this.showLoader) {
                this.$store.commit('setAttribute', {
                    id: `${this.id}_pager`,
                    attribute: 'totalItems',
                    value: this.ruleCount,
                });
            }
        },
        updateState(state) {
            if (state.query) {
                this.$store.commit(`${this.id}/SET_SEARCH_STRING`, state.query);
                this.$store.commit('setAttribute', {
                    id: `${this.id}_search`,
                    attribute: 'query',
                    value: state.query,
                });
            }
            if (state.filter && Object.keys(this.filters).includes(state.filter)) {
                // Filter value exists and is valid
                this.$store.commit(`${this.id}/SET_FILTER`, state.filter);
                this.$store.commit('setAttribute', {
                    id: `${this.id}_filter`,
                    attribute: 'value',
                    value: state.filter,
                });
            }
            if (state.page && !this.showLoader) {
                const validPage = parseInt(state.page, 10) > this.lastPage ? 1 : parseInt(state.page);
                this.$store.commit(`${this.id}/SET_PAGE`, validPage);
                this.$store.commit('setAttribute', {
                    id: `${this.id}_pager`,
                    attribute: 'page',
                    value: validPage,
                }, {
                    root: true,
                });
            }
            // Because we may not take all the values from hash, we need to set them
            this.setURLParams();
        },
        /* Get all params */
        getUrlParams() {
            const query = window.location.search.substring(1);
            const pairs = query.split('&');
            const params = {};
            if (!query || !pairs.length) {
                return {};
            }
            pairs.forEach(pair => {
                const keyValue = pair.split('=');
                params[keyValue[0]] = decodeURIComponent(keyValue[1].replace(/\+/g, '%20'));
            });
            return params;
        },
        buildParams(options) {
            return `query=${encodeURI(options.query)}&filter=${encodeURI(options.filter)}&page=${encodeURI(options.page)}&index=${encodeURI(options.index)}`;
        },
        /* Create params from the state */
        setURLParams() {
            if (this.isLoaded) {
                this.$scrollTo(`#${this.id}_search_search-text`);
            }

            const urlParts = window.location.href.split('?');
            const newURL = `${urlParts[0]}?${this.urlParams}`;
            history.pushState({
                path: newURL,
            }, document.title, newURL);
        },
    },
}; </script>