import postToService from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/actions/postToService';
import LoadLookup from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/classes/loadLookup';
import sharedConstants from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/actions/helpers/constants';
import helper from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/actions/helpers';

export default function loadLookupData(context: any, payload: any) {
    const moduleId = context.getters.moduleId;
    const moduleState = context.rootState[moduleId];
    const oneLni = payload.oneLni;

    const loadLookupData = new LoadLookup();

    context.commit(sharedConstants.MUTATIONS.updateLoadingState, true);

    postToService(loadLookupData.getLookupOptions,
        oneLni).then((response:
            {
                ok: any;
                data: any;
            }) => {

        if (!response.ok) {
            context.commit(sharedConstants.MUTATIONS.updateLoadingState, false);
            helper.showDialog(context, sharedConstants.DIALOGS.dialogException, true);
            return;
        }

        const jsonData = response.data;

        context.commit(sharedConstants.MUTATIONS.setSubjectsOrHazardsOptions, jsonData.subject);
        context.commit(sharedConstants.MUTATIONS.setAttribute, {
            id: `${moduleId}_subjectOrHazards`,
            attribute: 'options',
            value: moduleState.subjectsOrHazardsOptions,
        }, {
            root: true,
        });

        context.commit(sharedConstants.MUTATIONS.updateLoadingState, false);
    });

}