export default context => {
    const id = context.getters.moduleId;
    const payments = [];
    for (let i = 1; i <= context.state.paymentFields.length; i++) {
        const paymentDate = context.rootState[`${id}_payment-date-${i}`].value;
        const paymentAmount = context.rootState[`${id}_payment-amount-${i}`].value;

        if (!(paymentDate && paymentAmount)) {
            break;
        }

        const parsedPaymentAmount = parseFloat(paymentAmount);
        const parsedDate = new Date(paymentDate);
        payments.push({
            date: parsedDate,
            amount: parsedPaymentAmount,
        });
    }

    return payments.sort((a, b) => a.date - b.date);
};