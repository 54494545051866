<template>
    <div
        class="lni-c-glossary__definition lni-u-flex">
        <dt
            class="lni-u-block"
            :class="isHighlighted ? '--highlight' : ''">
            <abbr
                class="lni-u-type--bold lni-u-text--no-underline"
                :title="definition">{{ term }}</abbr>
        </dt>
        <dd
            class="lni-u-block"
            :class="isHighlighted ? '--highlight' : ''">
            {{ definition }}
        </dd>
    </div>
</template>

<script>
export default {
    name: 'lni-glossary-definition',
    props: {
        definition: {
            type: String,
            required: true,
        },
        term: {
            type: String,
            required: true,
        },
        highlightTargetId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isHighlighted: false,
        };
    },
    watch: {
        highlightTargetId(val) {
            this.isHighlighted = val.length > 0 ? val === this.$attrs.id : false;
            if (this.isHighlighted) {
                document.getElementById(this.$attrs.id).scrollIntoView();
            }
        },
    },
};
</script>