
export default () => ({
    state: {
        firstName: '',
        lastName: '',
        address: {
            address1: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
        },
        claimNumber: '',
        jobAddress: '',
        reportDate: '',
        reportTime: '',
        reportTimeType: '',
        scheduledDays: '',
        scheduledHours: '',
        pay: {
            rate: '',
            type: '',
        },
        companyName: '',
        supervisorName: '',
        supervisorPhone: '',
        contactPhone: '',
        positionOffer: '',
        healthBenefits: '',
        formEndpoint: '/',
    },
    mutations: {
        SET_VALUES(state, payload) {
            Object.keys(payload).forEach(key => {
                state[key] = payload[key];
            });
        },
    },
});