export const formFields = [
    {
        id: 'contact-person',
        label: 'Name',
    },
    {
        id: 'job-title',
        label: 'Job Title',
    },
    {
        id: 'contact-email_input-text',
        label: 'Email',
    },
    {
        id: 'contact-phone_phone',
        label: 'Phone',
    },
    {
        id: 'contact-phone_ext',
        label: 'Ext.',
    },
    {
        id: 'contact-preference_input-text',
        label: 'What can we help you with? Which contact method and time of day do you prefer?',
    },
    {
        id: 'preferred-language',
        label: 'Preferred Language',
    },
    {
        id: 'other-language',
        label: 'Other Language',
    },
    {
        id: 'company-name',
        label: 'Company Name',
    },
    {
        id: 'ubi',
        label: 'WA UBI No.',
    },
    {
        id: 'address_zip',
        label: 'Zip Code',
    },
    {
        id: 'county',
        label: 'County',
    },
];