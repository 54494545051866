const LEP_ENDPOINT = '/lep';

let lepPromise = null;

export const resetPromise = function() {
    lepPromise = null;
};

export const getRates = function(alternateApiLocation, oneLni) {
    const apiLocation = alternateApiLocation
        ? alternateApiLocation : oneLni.environment.current.apiLocation2;
    const endpoint = `${apiLocation}${LEP_ENDPOINT}`;

    if (!lepPromise) {
        lepPromise = fetch(endpoint, {
            mode: 'cors',
        }).then(response => response.json())
            .then(data => data);
    }
    return lepPromise;
};