import persist from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-order-self-insurer-roa-form/actions/persist.js';
import submit from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-order-self-insurer-roa-form/actions/submit.js';
import syncStore from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-order-self-insurer-roa-form/actions/syncStore.js';
import syncUi from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-order-self-insurer-roa-form/actions/syncUi.js';
import toggleAddress from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-order-self-insurer-roa-form/actions/toggleAddress.js';

export default {
    persist,
    submit,
    syncStore,
    syncUi,
    toggleAddress,
};