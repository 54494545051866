import actions from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-rulemaking/actions';

export default () => ({
    state: {
        query: '',
        ruleTopic: 'All',
        ruleStatus: 'All',
        topic: [],
        action: '',
        status: [{
            text: 'All Rules',
            value: 'All',
        }, {
            text: 'Open Rules',
            value: 'Open',
        }, {
            text: 'Closed Rules',
            value: 'Closed',
        }, {
            text: 'All Emergency Rules',
            value: 'Emergency',
        }, {
            text: 'Active Emergency Rules',
            value: 'Active',
        }, {
            text: 'Inactive Emergency Rules',
            value: 'Inactive',
        }],
        params: {
            query: '',
            ruleTopic: 'All',
            ruleStatus: 'All',
        },
    },
    actions: {
        ...actions,
    },
});