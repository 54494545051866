export default (context, payload) => {
    context.commit('setAttribute', {
        id: 'query',
        attribute: 'query',
        value: '',
    }, {
        root: true,
    });

    context.commit('setAttribute', {
        id: 'ruleTopic',
        attribute: 'value',
        value: 'All',
    }, {
        root: true,
    });

    context.commit('setAttribute', {
        id: 'ruleStatus',
        attribute: 'value',
        value: 'All',
    }, {
        root: true,
    });

    context.dispatch('setFormAction', payload);
    context.dispatch('updateUrl', payload).then(() => {
        context.dispatch('search', payload);
    });
};