export default (context: any,
    payload: {
                oneLni:
                    {
                        storeUtils: any;
                    }; }) => {
    const utils = payload.oneLni.storeUtils;

    utils.setAttribute(
        context,
        `dialogException`,
        'visible',
        false,
    );
};