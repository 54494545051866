export default (context, payload) => {
    const moduleId = context.getters.moduleId;
    const moduleState = context.rootState[moduleId];

    context.dispatch('getTableState', `${moduleId}_table`).then(tableData => {
        context.commit('setAttribute', {
            id: `${moduleId}_table`,
            attribute: 'itemsAreLoaded',
            value: false,
        }, {
            root: true,
        });

        const apiEndpoint = `${payload.oneLni.environment.current.publicApiLocation}/DoshHospitalizations/Search`;
        fetch(apiEndpoint, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },

            body: JSON.stringify({
                ...moduleState.searchState,
                ...tableData,
            }),
        }).then(response => {
            context.commit('setAttribute', {
                id: `${moduleId}_table`,
                attribute: 'itemsAreLoaded',
                value: true,
            }, {
                root: true,
            });

            return response.json();
        }).then(data => {
            context.commit('setAttribute', {
                id: moduleId,
                attribute: 'items',
                value: data.items,
            }, {
                root: true,
            });
            context.commit('setAttribute', {
                id: moduleId,
                attribute: 'totalItems',
                value: data.totalItems,
            }, {
                root: true,
            });

            context.commit('setAttribute', {
                id: `${moduleId}_table`,
                attribute: 'items',
                value: moduleState.items,
            }, {
                root: true,
            });
            context.commit('setAttribute', {
                id: `${moduleId}_table`,
                attribute: 'totalItems',
                value: moduleState.totalItems,
            }, {
                root: true,
            });
        }).catch(error => {
            context.commit('setAttribute', {
                id: moduleId,
                attribute: 'loadState',
                value: 'error',
            }, {
                root: true,
            });
            context.commit('setAttribute', {
                id: moduleId,
                attribute: 'error',
                value: error,
            }, {
                root: true,
            });

            context.commit('setAttribute', {
                id: `${moduleId}_table`,
                attribute: 'loadState',
                value: 'error',
            }, {
                root: true,
            });
            context.commit('setAttribute', {
                id: `${moduleId}_table`,
                attribute: 'itemsAreLoaded',
                value: true,
            }, {
                root: true,
            });
        });
    });
};