<template>
    <div>
        <p class="lni-u-ml1 lni-u-type--xs">
            Enter a keyword and choose from the list of available conditions or treatments.
        </p>
        <div class="lni-u-flex lni-u-align-baseline lni-u-pb2">
            <lni-auto-suggestion
                :id="`${id}_auto-suggest`"
                ref="auto-suggest"
                :filled="false"
                :dense="true"
                labelText=""
                :fullWidth="true"
                :hasInlineMessages="false"
                :options="options"
                :enterAction="`${id}/lookup`"
                :searchAction="`${id}/lookup`"></lni-auto-suggestion>
        </div>
        <div
            v-if="!valid">
            <p class="lni-u-text--center lni-u-type--medium lni-u-type--md">
                Please select a condition or treatment from the suggested list.
            </p>
        </div>
        <div
            v-if="hasCondition"
            class="lni-u-pt3 lni-u-b--subtle lni-u-bt lni-u-mt2">
            <h2 class="lni-c-condition-treatment__title lni-u-type--medium lni-u-mb2">
                {{ condition.title }}
            </h2>
            <div class="lni-c-condition-treatment__box">
                <div class="lni-u-flex lni-c-condition-treatment__headers lni-u-mb1 lni-u-text--all-caps">
                    <span>Effective Date: {{ condition.effectiveDate }}</span>
                </div>
                <div class="lni-c-condition-treatment__authorization">
                    {{ condition.coverageDecision }}
                </div>
                <ul
                    v-if="hasChildren"
                    ref="list"
                    class="lni-u-list-reset lni-u-mt0">
                    <li
                        v-if="condition.priorAuth !=='Blank'"
                        class="lni-c-condition-treatment__authorization">
                        {{ condition.priorAuth }}
                    </li>
                    <li
                        v-if="condition.doctorReferral"
                        class="lni-c-condition-treatment__authorization">
                        {{ condition.doctorReferral[0] }}
                    </li>
                    <li
                        v-for="(auth, index) in condition.SFAM"
                        :key="`${auth}-${index}`"
                        class="lni-c-condition-treatment__methods">
                        <span class="lni-u-type--bold">L&amp;I State Fund:</span>
                        <a
                            v-if="auth === 'Use the Secure Message Center of My L&I.'"
                            :href="condition.SMCurl">{{ condition.SMCtitle }}</a>
                        <a
                            v-else-if="auth === 'Utilization Review provided by Comagine.'"
                            :href="condition.Comagineurl">{{ condition.Comagine }}</a>
                        <span v-else>{{ auth }}</span>
                    </li>
                    <li
                        v-for="(customMethod, index) in condition.customMethods"
                        :key="`${customMethod}-${index}`"
                        class="lni-c-condition-treatment__methods">
                        <span class="lni-u-type--bold">L&amp;I State Fund:</span>
                        <a
                            :href="customMethod.link"> {{ customMethod.title }}</a>
                    </li>
                    <li
                        v-if="condition.SIEurl"
                        class="lni-c-condition-treatment__methods lni-u-mb2">
                        Self-Insured:
                        <a
                            :href="condition.SIEurl.link">{{ condition.SIEurl.title }}</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'lni-condition-treatment',
    data() {
        return {
            hasChildren: true,
        };
    },
    computed: {
        hasCondition() {
            return this.condition && Object.keys(this.condition).length > 0;
        },
    },
    mounted() {
        if (this.$refs.length) {
            this.hasChildren = this.$refs.list.childNodes;
        }

        // Set search bar value
        if (this.hasCondition) {
            this.$refs['auto-suggest'].value = this.condition.title;
            this.$refs['auto-suggest'].$refs[`${this.id}_auto-suggest_text`].query = this.condition.title;
        }
    },
};
</script>

<style lang="scss">
@import 'lni-condition-treatment';
</style>