import clearField from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-simbic-calculator/actions/clearField.js';

export default context => {
    const moduleId = context.getters.moduleId;
    clearField(context, `${moduleId}_accrual-date`);
    clearField(context, `${moduleId}_balance-owed`);
    clearField(context, `${moduleId}_remaining-principal`);
    for (let i = 1; i <= context.state.paymentFields.length; i++) {
        clearField(context, `${moduleId}_interest-${i}`);
    }
};