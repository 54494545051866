import components from '@gov.wa.lni/application.public-website-custom-components/source/components';
import publicWebsiteComponents from '@gov.wa.lni/application.public-website/source/components';

const options = {
    template: '',
    enableRouter: false,
    applicationComponents: [
        ...components,
        ...publicWebsiteComponents,
    ],
};

window.lni.oneLniCorePlugin.initialize(
    options,
    configuration => {
        window.lni.Vue.use(window.lni.oneLniCorePlugin);
        window.lni.oneLniCorePlugin.setApplication(new window.lni.Vue(configuration));
    });