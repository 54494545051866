export default (context, payload) => {
    const targetId = `${payload.sourceId}_other-language`;
    const setAttribute = payload.oneLni.storeUtils.setAttribute;
    const isOther = context.getters.isOtherLanguage;

    setAttribute(context, `${targetId}__collapse`, 'visible', isOther);
    setAttribute(context, targetId, 'required', isOther);
    if (isOther) {
        context.commit('ADD_TRACKED_ERRORS', [targetId]);
    } else {
        setAttribute(context, targetId, 'value', '');
        context.dispatch('syncStore', {
            oneLni: payload.oneLni,
            targetId,
            sourceId: payload.sourceId,
        });
        context.commit('REMOVE_TRACKED_ERRORS', [targetId]);
    }
};