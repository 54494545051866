<template>
    <div class="lni-c-box--content --card lni-u-mhauto lni-u-flex lni-u-justify-between lni-u-flex-column">
        <a
            href
            class="lni-u-type--medium lni-c-shrl-card__column1 lni-u-bold lni-u-type--md lni-u-text--no-underline"
            @click.prevent="$emit('show-detail', titleId)"
        > {{ titleName }}
        </a>
        <div class="lni-u-inline lni-u-mt1">
            <b>Description: </b><span v-html="formattedDescription" />
        </div>
        <LniSafetyVideoMetaData
            :titleName="$props.titleName"
            :titleId="$props.titleId"
            :videoLength="$props.videoLength"
            :productionDate="$props.productionDate"
            :languages="$props.languages"
            :titleFormats="$props.titleFormats"
        />
    </div>
</template>

<script>
import LniSafetyVideoMetaData from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/lni-safety-video-metadata.vue';

export default {
    name: 'LniSafetyVideoSearchCard',
    components: {
        LniSafetyVideoMetaData,
    },
    props: {
        titleName: {
            type: String,
            default: '',
        },
        titleId: {
            type: String,
            default: '',
        },
        videoLength: {
            type: String,
            default: '',
        },
        productionDate: {
            type: String,
            default: '',
        },
        languages: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
        titleFormats: {
            type: Array,
            default: () => []
        },
        videoDetails: {
            type: Object,
            default: () => ({}),
         },
    },
    emits: ['show-detail'],
    computed: {
        formattedDescription() {
            return this.$props.description.replace(/\n/g, "<br>");
        },
    },
};
</script>