// import mockApi from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-search-sharp/api/mock.api.json'; //For use in demo only.

const SHARP_ENDPOINT = '/sharp2/';
let sharpPromise = null;


export default (params, oneLni) => {
    const endpoint = `${oneLni.environment.current.apiLocation2}${SHARP_ENDPOINT}?${params}`;

    sharpPromise = fetch(endpoint, {
        mode: 'cors',
    }).then(response => response.json())
        .then(data => data.results);
    return sharpPromise;
};