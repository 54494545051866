<template>
    <div>
        <lni-sync-url-params
            id="urlSync"
            ref="urlSync">
        </lni-sync-url-params>
        <form
            :id="id + '_form'"
            class="lni-c-rule-search__container lni-u-flex lni-u-flex-wrap lni-u-justify-between lni-u-pt1 lni-u-items-center"
            :action="action"
            method="GET">
            <div class="lni-c-rule-search__form">
                <lni-input-search
                    id="query"
                    type="search"
                    placeholder="Title or number (296-XXX)"
                    :sendPagerParameter="false"
                    :clickAction="`${id}/search`"
                    :searchAction="`${id}/search`"
                    :emptySearchAction="`${id}/search`"
                    :inputAction="`${id}/setFormAction`"
                    :query="query"
                    :clearAction="`${id}/setFormAction`"></lni-input-search>
            </div>
            <div class="lni-u-flex lni-u-flex-wrap lni-u-pt1">
                <lni-select
                    id="ruleTopic"
                    name="ruleTopic"
                    class="lni-u-mr1"
                    :dense="true"
                    inline="false"
                    labelText="Topic"
                    :value="ruleTopic"
                    :options="topic"
                    :changeAction="[`${id}/setFormAction`,`${id}/updateUrl`]"></lni-select>
                <lni-select
                    id="ruleStatus"
                    name="ruleStatus"
                    class="lni-u-mr1"
                    :dense="true"
                    :inline="false"
                    labelText="Status"
                    :value="ruleStatus"
                    :options="status"
                    :changeAction="[`${id}/setFormAction`,`${id}/updateUrl`]"></lni-select>
                <div class="lni-c-rule-search__clear">
                    <lni-button
                        :id="`${id}_button-clear`"
                        class="lni-u-mt1"
                        text="Clear Filters"
                        :clickAction="`${id}/clearFilters`"
                        type="text"></lni-button>
                </div>
            </div>
        </form>
    </div>
</template>
<script>

export default {
    name: 'lni-rulemaking',
    mounted() {
        this.updateControls(this.$refs.urlSync.getUrlParams());
    },
    methods: {
        updateControls(params) {
            if (!params) {
                return;
            }

            Object.keys(params).forEach(key => {
                if (key === 'query') {
                    // search-input's value is "query" not "value"
                    this.$store.commit('setAttribute', {
                        id: `query`,
                        attribute: 'query',
                        value: params[key],
                    });
                } else {
                    this.$store.commit('setAttribute', {
                        id: `${key}`,
                        attribute: 'value',
                        value: params[key],
                    });
                }
                this.$store.dispatch(`${this.id}/setFormAction`, {
                    sourceId: this.id,
                    oneLni: this.$oneLni,
                });
            });
        },
    },
}; </script>
<style lang="scss">
@import 'lni-rulemaking';
</style>