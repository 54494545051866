<template>
    <div>
        <div>
            <h1 class="lni-u-mb2">
                {{ title }}
            </h1>
        </div>
        <div
            v-show="showVideoDetails"
            id="anchor"
        >
            <LniSafetyVideoDetail
                :videoDetails="detailInfo"
                :videoAdditionalDetails="detailAdditionalInfo"
                @hide-detail="hideVideoDetail"
            />
        </div>
        <div v-show="showMainSearch">
            <div class="lni-u-mt3 lni-c-box--content --card lni-u-mhauto lni-u-flex lni-u-justify-between lni-u-flex-column">
                <p class="lni-u-mb2">
                    Viewing online videos from the safety library is for Washington state residents
                    only. You must
                    <a
                        href="#"
                        class="lni-u-type--medium lni-c-shrl-card__column1 lni-u-bold lni-u-type--md lni-u-text--no-underline"
                        @click.prevent="navigateToMemberPage()"
                    >
                        become a member</a>
                    to view online videos.
                </p>
                <lni-radios
                    :id="`${id}_searchType`"
                    labelText="I want to find:"
                    :isColumn="true"
                    :hasInlineMessages="false"
                    value="1"
                    :options="[
                        {text: 'Keyword or phrase', value: '1'},
                        {text: 'Subject or hazard', value: '2'},
                    ]"
                    :changeAction="`${id}/toggleSearchType`"
                />
                <div
                    v-show="searchOption==='1'"
                    class="lni-u-full-width lni-u-mt2"
                >
                    <lni-input-search
                        :id="`${id}_input`"
                        ref="searchQueryInput"
                        name="query"
                        placeholder="Enter keyword(s), phrase, or Video ID"
                        :clickAction="`${id}/searchForVideos`"
                        :searchAction="`${id}/searchForVideos`"
                        :clearAction="`${id}/clearSearchResults`"
                        :emptySearchAction="`${id}/searchForVideos`"
                    />
                </div>
                <div
                    v-show="searchOption==='2'"
                    class="lni-u-full-width lni-u-mt2"
                >
                    <lni-select
                        :id="`${id}_subjectOrHazards`"
                        :dense="true"
                        :inline="false"
                        labelText="Subject or Hazards"
                        placeholder="Any Subject or Hazard"
                        :options="subjectsOrHazardsOptions"
                        :changeAction="`${id}/searchForVideos`"
                    />
                </div>
                <div
                    v-show="startNewSearch"
                    class="lni-u-flex lni-u-justify-end"
                >
                    <a
                        href
                        class="lni-u-type--medium lni-u-bold lni-u-type--md lni-u-text--no-underline lni-u-mt1"
                        @click.prevent="restartNewSearch()"
                    >
                        New Search
                    </a>
                </div>
            </div>
            <hr>
            <div
                role="status"
                aria-live="polite"
            >
                <div
                    v-if="isSearchInvoked"
                    class="lni-u-full-width lni-u-mv3"
                >
                    <div v-if="loading">
                        Searching for {{ searchTermSpecified }}
                    </div>
                    <div v-else>
                        We found {{ filteredResults.length }}
                        <span
                            v-if="filteredResults.length === 1"
                        >
                            video
                        </span>
                        <span v-else>
                            videos
                        </span>
                        with the keyword(s) {{ searchTermSpecified }}.
                    </div>
                </div>
            </div>
            <div
                v-if="filteredResults?.length"
                ref="top"
                class="lni-u-flex lni-u-flex-column lni-u-gap2"
            >
                <LniSafetyVideoSearchCard
                    v-for="(item) in filteredResults"
                    :key="item.titleId"
                    v-bind="{...item}"
                    @show-detail="showVideoDetail"
                />
            </div>
        </div>
        <div
            v-show="showQuickLinks"
            class="lni-u-mt2"
        >
            <LniSafetyQuickLinks
                @hide-main="hideMainSearch"
                @hide-detail="hideVideoDetail"
                @show-title="showTitle"
                @show-detail="showVideoDetailViaQuicklinks"
            />
        </div>
        <div>
            <lni-spinner
                v-show="loading"
                :id="`${id}_spinner`"
            />
            <lni-modal
                id="dialogException"
                title="Alert"
                :allowClose="true"
            >
                <template #content>
                    <div class="lni-u-mb2">
                        An error occurred while trying to access information on the Catalog Library.
                        <div class="lni-u-mt2">
                            If the error persists, please contact us to report this issue.
                        </div>
                        <div class="lni-u-mt2">
                            We're sorry for the inconvenience!
                        </div>
                    </div>
                </template>
                <template #actions>
                    <div class="lni-u-flex lni-u-justify-end">
                        <lni-button
                            id="testDialogOkButton"
                            class="lni-c-button lni-u-mr2"
                            clickAction="closeDialog"
                            text="OK"
                        >
                            Ok
                        </lni-button>
                    </div>
                </template>
            </lni-modal>
            <span id="alertContainer">
                <lni-informational-messages id="alertMsg" />
            </span>
        </div>
    </div>
</template>

<script>
import LniSafetyVideoSearchCard from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/lni-safety-video-search-card.vue';
import LniSafetyVideoDetail from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/lni-safety-video-detail.vue';
import LniSafetyQuickLinks from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/lni-safety-video-quicklinks.vue';
import sharedConstants from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-safety-video-search/actions/helpers/constants';

export default {
    name: 'LniSafetyVideoSearch',
    components: {
        LniSafetyVideoSearchCard,
        LniSafetyVideoDetail,
        LniSafetyQuickLinks,
    },
    data() {
        return {
            advancedIsOpen: true,
            pageSize: 10,
            title: 'Search Video Catalog',
            showMainSearch: true,
            showVideoDetails: false,
            showStartNewSearch: false,
        };
    },
    computed: {
        showQuickLinks() {
            return this.$store.getters['search/getShowQuickLinks'];
        },
        startNewSearch() {
            return this.$store.getters['search/getShowNewSearchOption'];
        }
    },
    mounted() {
        this.$store.dispatch(`${this.id}/loadLookupData`, {
            oneLni: this.$oneLni,
        });
    },
    methods: {
        restartNewSearch() {
            window.location.href = sharedConstants.ROUTES.newSearch;
            window.location.reload();
        },
        showVideoDetail(titleId) {
            this.$store.dispatch(`${this.id}/loadVideoDetail`, {
                oneLni: this.$oneLni,
                titleId,
            });
            this.$store.dispatch(`${this.id}/loadAdditionalVideoDetail`, {
                oneLni: this.$oneLni,
                titleId,
            });
            this.showVideoDetails = true;
            this.showOnlineVideoSection = true;
            this.showMainSearch = false;
            this.showTitle(sharedConstants.TITLETYPES.VideoDetails);
        },
        showVideoDetailViaQuicklinks(titleId) {
            this.$store.dispatch(`${this.id}/loadVideoDetail`, {
                oneLni: this.$oneLni,
                titleId,
            });
            this.$store.dispatch(`${this.id}/loadAdditionalVideoDetail`, {
                oneLni: this.$oneLni,
                titleId,
            });
            this.showVideoDetails = true;
            this.showOnlineVideoSection = false;
            this.showMainSearch = false;
            this.showTitle(sharedConstants.TITLETYPES.VideoDetails);
        },
        hideMainSearch() {
            this.showMainSearch = false;
        },
        hideVideoDetail() {
            this.showVideoDetails = false;
            this.showMainSearch = true;
            this.showTitle(sharedConstants.TITLETYPES.SearchVideoCatalog);
        },
        navigateToMemberPage() {
            this.$router.push({ path: sharedConstants.HASH_LOCATIONS.member});
        },
        showTitle(titleType) {
            switch(titleType) {
                case sharedConstants.TITLETYPES.SearchVideoCatalog:
                    this.title = 'Search Video Catalog';
                    break;
                case sharedConstants.TITLETYPES.OnlineVideos:
                    this.title = 'Online Videos';
                    break;
                case sharedConstants.TITLETYPES.VideoDetails:
                    this.title = 'Video Details';
                    break;
                default:
                    this.title = '';
            }
        },
    },
}; </script>
<style lang="scss">
    @import 'lni-safety-video-search'
</style>