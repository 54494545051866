import getPaymentInputs from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-simbic-calculator/actions/getPaymentInputs.js';
import fullCalculation from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-simbic-calculator/actions/fullCalculation.js';
import setResults from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-simbic-calculator/actions/setResults.js';

export default (context, payload) => {
    if (!context.rootGetters['simbic-calculator-error-container/isValid']) {
        return;
    }

    const id = context.getters.moduleId;

    context.dispatch('resetCalculatedValues', payload).then(() => {
        const billingDateString = context.rootState[`${id}_billing-date`].value;
        const balanceDue = parseFloat(context.rootState[`${id}_balance-due`].value);
        if (!billingDateString || !balanceDue) {
            return;
        }

        const inputs = {
            billingDate: new Date(billingDateString),
            balanceDue,
            payments: getPaymentInputs(context),
        };

        const result = fullCalculation(inputs);
        setResults(context, result);

    });
};