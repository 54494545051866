export default (context, payload) => {
    const moduleId = context.getters.moduleId;
    const moduleState = context.rootState[moduleId];

    context.commit('setAttribute', {
        id: moduleId,
        attribute: 'isLoaded',
        value: false,
    }, {
        root: true,
    });

    const searchString = context.rootState[`${moduleId}_search`].query;
    context.commit('setAttribute', {
        id: moduleId,
        attribute: 'query',
        value: searchString,
    }, {
        root: true,
    });

    const rulesEndpoint = '/rules/';
    const endpoint = `${payload.oneLni.environment.current.apiLocation2}${rulesEndpoint}?query=${moduleState.query}`;
    fetch(endpoint, {
        mode: 'cors',
    }).then(response => response.json())
        .then(data => {
            context.commit('setAttribute', {
                id: moduleId,
                attribute: 'rules',
                value: data.results.rules,
            }, {
                root: true,
            });

            context.commit('SET_PAGE', 1);
            context.dispatch('setParam', {
                param: 'page',
                value: 1,
            });
            context.dispatch('setParam', {
                param: 'query',
                value: moduleState.query,
            });

            context.commit('SET_SEARCH_STRING', moduleState.query);

            context.commit('setAttribute', {
                id: moduleId,
                attribute: 'isLoaded',
                value: true,
            }, {
                root: true,
            });
        });
};