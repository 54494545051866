export default (context, { step, oneLni }) => {
    const id = context.getters.moduleId;
    context.commit('SET_STATUS', {
        type: step,
        pending: false,
    });
    context.commit('SET_ERROR', {
        type: step,
        error: null,
    });
    if (step === 'submit') {
        context.commit('setAttribute', {
            id,
            attribute: 'success',
            value: true,
        }, {
            root: true,
        });
        context.dispatch('handleModal', true);
        oneLni.storage.session.clear();
    }

};