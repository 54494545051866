import getLookup from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-vendor-services-lookup/actions/getLookup.js';
import setDefaultFilterValues from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-vendor-services-lookup/actions/setDefaultFilterValues.js';

export default (context, payload) => {
    const moduleId = context.getters.moduleId;
    const stateOptions = context.rootState[`${moduleId}_state`].options;
    const isFirstLoad = !stateOptions || stateOptions.length === 0;
    let stateString = 'WA';
    if (!isFirstLoad) {
        stateString = context.rootState[`${moduleId}_state`].value;
    }

    const promises = [];
    const tableId = `${moduleId}_table`;
    promises.push(getLookup('City', stateString, tableId, context, payload.oneLni));
    promises.push(getLookup('County', stateString, tableId, context, payload.oneLni));

    if (isFirstLoad) {
        promises.push(getLookup('State', null, tableId, context, payload.oneLni));
        promises.push(getLookup('Service', stateString, tableId, context, payload.oneLni));
    }

    Promise.all(promises).then(data => {
        const cities = data[0].map(c => ({
            text: c,
            value: c,
        }));
        cities.unshift({
            text: 'All',
            value: '0',
        });

        const counties = data[1].map(c => ({
            text: c,
            value: c,
        }));

        counties.unshift({
            text: 'All',
            value: '0',
        });

        context.commit('setAttribute', {
            id: `${moduleId}_city`,
            attribute: 'options',
            value: cities,
        }, {
            root: true,
        });

        context.commit('setAttribute', {
            id: `${moduleId}_county`,
            attribute: 'options',
            value: counties,
        }, {
            root: true,
        });

        if (isFirstLoad) {
            const states = data[2].map(s => ({
                text: s,
                value: s,
            }));

            states.unshift({
                text: 'All',
                value: '0',
            });

            context.commit('setAttribute', {
                id: `${moduleId}_state`,
                attribute: 'options',
                value: states,
            }, {
                root: true,
            });


            const services = data[3].map(s => ({
                text: s,
                value: s,
            }));

            services.unshift({
                text: 'All',
                value: '0',
            });

            context.commit('setAttribute', {
                id: `${moduleId}_service`,
                attribute: 'options',
                value: services,
            }, {
                root: true,
            });

            setDefaultFilterValues(context);
        } else {
            const cityValue = context.rootState[`${moduleId}_city`].value;
            const countyValue = context.rootState[`${moduleId}_county`].value;

            const cityExists = cities.find(option => option.value === cityValue);
            const countyExists = counties.find(option => option.value === countyValue);

            // if newly chosen state doesn't include the city, set the city to "All"
            if (!cityExists) {
                context.commit('setAttribute', {
                    id: `${moduleId}_city`,
                    attribute: 'value',
                    value: '0',
                }, {
                    root: true,
                });
            }

            if (!countyExists) {
                context.commit('setAttribute', {
                    id: `${moduleId}_county`,
                    attribute: 'value',
                    value: '0',
                }, {
                    root: true,
                });
            }
        }

        context.dispatch(`${moduleId}/search`, {
            oneLni: payload.oneLni,
        }, {
            root: true,
        });
    });
};