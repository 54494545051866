import actions from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-simbic-calculator/actions';

export default () => ({
    state: {
        totalBalanceDue: 0,
        billingDateResult: '',
        accrualDateResult: '',
        resultsBody: null,
        balanceDueResult: 0,
        interestAccrued: 0,
        paymentFields: [
            1,
        ],
    },
    actions: {
        ...actions,
    },
    mutations: {
        ADD_PAYMENT_ROW(state) {
            const next = state.paymentFields[state.paymentFields.length - 1] + 1;
            state.paymentFields.push(next);
        },
        REMOVE_PAYMENT_ROW(state) {
            state.paymentFields.pop();
        },
    },
    getters: {
        paymentFieldIds(state, getters) {
            const id = getters.moduleId;
            return state.paymentFields.reduce((prev, current) => {
                prev.push(`${id}_payment-date-${current}`);
                prev.push(`${id}_payment-amount-${current}`);
                prev.push(`${id}_interest-${current}`);

                return prev;
            }, [] );
        },
    },
});