export default (context, payload) => {
    const moduleId = context.getters.moduleId;
    const moduleState = context.rootState[moduleId];
    const setAttribute = payload.oneLni.storeUtils.setAttribute;

    const trackedErrors = [
        `${moduleId}_contact-person`,
        `${moduleId}_contact-phone`,
        `${moduleId}_contact-email`,
        `${moduleId}_preferred-language`,
        `${moduleId}_company-name`,
        `${moduleId}_county`,
        `${moduleId}_ubi`,
    ];
    setAttribute(context, `${moduleId}_errors`, 'errorsCollectedFrom', trackedErrors);

    const otherLanguageId = `${moduleId}_other-language`;
    const isOtherLanguage = context.getters.isOtherLanguage;
    setAttribute(context, `${otherLanguageId}__collapse`, 'visible', isOtherLanguage);
    setAttribute(context, otherLanguageId, 'required', isOtherLanguage);

    if (isOtherLanguage) {
        trackedErrors.push(otherLanguageId);
    }

    setAttribute(context, moduleId, 'trackedErrors', trackedErrors);
    setAttribute(context, `${moduleId}_errors`, 'errorsCollectedFrom', moduleState.trackedErrors);

    // Set labels
    moduleState.formData.forEach(field => {
        setAttribute(context, `${moduleId}_${field.id}`, 'labelText', field.label);
    });
};