import actions from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-enforcement-policies/actions';


const comparePolicyNumber = (a, b) => {
/* eslint-disable no-useless-escape */
    const aParts = a.policyNumber.split(/[\.\n\s\t -]/);
    const bParts = b.policyNumber.split(/[\.\n\s\t -]/);
    return comparePolicyNumberSegments(aParts, bParts);
};

const compareSegment = (aSegment, bSegment) => {
    if (aSegment === bSegment) {
        return 0;
    }
    if (isNaN(aSegment) || isNaN(bSegment)) {
        return aSegment.localeCompare(bSegment);
    }
    return (aSegment - bSegment) / Math.abs(aSegment - bSegment);
};
const comparePolicyNumberSegments = (aSegments, bSegments) => {
    if (aSegments.length === 0) {
        return -1;
    }
    if (bSegments.length === 0) {
        return 1;
    }
    const aSegment = aSegments.shift();
    const bSegment = bSegments.shift();
    const compareRes = compareSegment(aSegment, bSegment);
    return compareRes === 0 ?  comparePolicyNumberSegments([...aSegments], [...bSegments]) : compareRes;

};
const compareDate = (a, b) => {
    const aDate = Date.parse(a.dateIssued);
    const bDate = Date.parse(b.dateIssued);

    if (aDate >= bDate) {
        return 1;
    }
    return -1;
};

const policyCompare = (state, a, b) => {
    if (state.sort === 'policyNumber') {
        return comparePolicyNumber(a, b);
    } else if (state.sort === 'dateIssued') {
        return compareDate(a, b);
    }

    if (a.title >= b.title) {
        return 1;
    }
    return -1;
};

export default () => ({
    state: {
        policies: null,
        isLoaded: false,
        sortOptions: [
            {
                text: 'Policy Name',
                value: 'title',
            },
            {
                text: 'Policy Number',
                value: 'policyNumber',
            },
            {
                text: 'Date Issued',
                value: 'dateIssued',
            },
        ],
        currentPage: 1,
        itemsPerPage: 10,
        query: '',
        wac: '',
        sort: 'title',
        index: 'Enforcement_Policies',
    },
    getters: {
        searchedPolicies: state => {
            const minChars = 2;
            if (!state.policies) {
                return [];
            }

            let policies = [
                ...state.policies,
            ];

            if (state.wac) {
                policies = policies.filter(policy => policy.relatedToWac);
            } else if (state.query || state.query.length >= minChars ) {
                policies = policies.filter(policy => {
                    const loweredDescription = policy.description.toLowerCase();
                    const loweredTitle = policy.title.toLowerCase();
                    const loweredQuery = state.query;

                    return policy.policyNumber.includes(loweredQuery.toUpperCase())
                    || loweredDescription.includes(loweredQuery)
                    || loweredTitle.includes(loweredQuery);
                });
            }

            const curriedPolicyCompare = (a, b) => policyCompare(state, a, b);
            policies.sort(curriedPolicyCompare);

            return policies;
        },
        resultCount: (state, getters) => getters.searchedPolicies.length,
        urlParams: (state, getters) => {
            const params = getters.params;
            return Object.keys(params)
                .filter(key => params[key])
                .map(key => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
        },
        params: state => ({
            query: state.query,
            wac: state.wac,
            sort: state.sort,
            page: state.currentPage,
            index: state.index,
        }),
    },
    actions: {
        ...actions,
    },
    mutations: {
        SET_SORT( state, payload ) {
            state.sort = payload;
        },
        SET_PAGE( state, payload ) {
            state.currentPage = payload;
        },
        SET_SEARCH_STRING( state, payload ) {
            state.query = payload;
            state.wac = '';
        },
        SET_WAC( state, payload ) {
            state.wac = payload;
        },
    },
});