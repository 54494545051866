import actions from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-overtime-exempt-budget-tool/actions';

export default () => ({
    state: {
        annualSalary: '0.00',
        calculated: false,
        currentYear: new Date().getFullYear().toString(),
        employerSize: 'smBusiness',
        employerSizes: [
            {
                text: '1 to 50 Washington state employees',
                value: 'smBusiness',
            },
            {
                text: '51 or more Washington state employees',
                value: 'lgBusiness',
            },
        ],
        minimumWage: '0.00',
        multiplierThresholdSchedule: {
            2020: {
                smBusiness: 1.25,
                lgBusiness: 1.25,
            },
            2021: {
                smBusiness: 1.5,
                lgBusiness: 1.75,
            },
            2022: {
                smBusiness: 1.75,
                lgBusiness: 1.75,
            },
            2023: {
                smBusiness: 1.75,
                lgBusiness: 2,
            },
            2024: {
                smBusiness: 2,
                lgBusiness: 2,
            },
            2025: {
                smBusiness: 2,
                lgBusiness: 2.25,
            },
            2026: {
                smBusiness: 2.25,
                lgBusiness: 2.25,
            },
            2027: {
                smBusiness: 2.25,
                lgBusiness: 2.5,
            },
            2028: {
                smBuisness: 2.5,
                lgBuisness: 2.5,
            },
        },
        weeklyHours: 0,
    },
    actions: {
        ...actions,
    },
    getters: {
        multiplier(state) {
            return state.multiplierThresholdSchedule[state.currentYear][state.employerSize];
        },
    },
    mutations: {
        SET_ATTRIBUTE(state, payload) {
            state[payload.attribute] = payload.value;
        },
    },
});