export default context => {
    const moduleId = context.getters.moduleId;
    const moduleState = context.rootState[moduleId];

    if (context.rootState[`${moduleId}_errors`].errors.length > 0) {
        return;
    }

    context.dispatch('setValues');

    const urls = [null, moduleState.letterEndpoint];
    for (let i = 0; i < urls.length; i++) {
        history.pushState({
            firstName: moduleState.firstName,
            lastName: moduleState.lastName,
            address: moduleState.address,
            claimNumber: moduleState.claimNumber,
            jobAddress: moduleState.jobAddress,
            reportDate: moduleState.reportDate,
            reportTime: moduleState.reportTime,
            reportTimeType: moduleState.reportTimeType,
            scheduledDays: moduleState.scheduledDays,
            scheduledHours: moduleState.scheduledHours,
            pay: moduleState.pay,
            companyName: moduleState.companyName,
            supervisorName: moduleState.supervisorName,
            supervisorPhone: moduleState.supervisorPhone,
            contactPhone: moduleState.contactPhone,
            positionOffer: moduleState.positionOffer,
            healthBenefits: moduleState.healthBenefits,
        }, null, urls[i]);
    }

    window.location = moduleState.letterEndpoint;
};