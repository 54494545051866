<template>
    <div class="lni-c-rule-chapter">
        <div class="lni-rule-chapter__main-content">
            <h2>
                <span class="lni-u-type--xs lni-u-type--normal lni-u-block lni-u-line-height--tight">296-{{ chapterRule.chapter }}</span>
                <span class="lni-u-heading--2">{{ chapterRule.title }}</span>
            </h2>
            <p class="lni-u-reading-width--max">
                {{ chapterRule.shortDescription }}
            </p>
            <h3 class="lni-u-heading--3 lni-u-mt3">
                Read This Chapter
            </h3>
            <p>
                <a
                    :href="chapterRule.officialUrl"
                >At leg.wa.gov</a> or
                <a
                    :href="chapterRule.pdfUrl"
                    target="_blank"
                >as a PDF</a>
            </p>
            <div v-if="relatedUnderstanding.length">
                <h3 class="lni-u-heading--4 lni-u-mt3">
                    Understanding This Chapter
                </h3>
                <link-list :links="relatedUnderstanding" />
            </div>
            <div v-if="relatedTools.length">
                <h3 class="lni-u-heading--4 lni-u-mt3">
                    Tools to Meet Requirements
                </h3>
                <link-list :links="relatedTools" />
            </div>
            <div
                v-if="hasPolicies"
                class="lni-u-mt4 lni-u-text--right"
            >
                <a
                    class="lni-u-line-height--tight lni-u-uppercase"
                    :href="`/safety-health/safety-rules/enforcement-policies/?wac=296-${chapterRule.chapter}`"
                >
                    Related L&amp;I Enforcement Policies
                    <span
                        aria-hidden="true"
                        class="lnicon--caret--right"
                    /></a>
            </div>
        </div>
        <div class="lni-u-mt3 lni-u-flex lni-u-justify-center lni-u-bb lni-u-b--gray-base">
            <button
                :id="`${id}_contents-toggle`"
                class="lni-c-button --text lni-u-mhauto"
                type="text"
                :aria-expanded="showSections"
                :aria-controls="`${id}_sections`"
                @click="showSections = !showSections"
            >
                Table of Contents
                <span
                    aria-hidden="true"
                    :class="showSections ? 'lnicon--triangle--up' : 'lnicon--triangle--down'"
                />
            </button>
        </div>
        <transition name="lni-o-slide">
            <div
                v-if="showSections"
                :id="`${id}_sections`"
            >
                <div
                    v-for="(rule, index) in subsections"
                    :id="rule.id"
                    :key="rule.id"
                    class="lni-c-rule lni-u-mt1"
                >
                    <header
                        class="lni-u-flex  lni-u-type--xxs"
                        :class="index !== 0 ? 'lni-u-b--gray-base lni-u-bt' : null"
                    >
                        <span class="lni-c-rule__section">{{ sectionNumber(rule) }}</span> <span
                            v-if="rule.partName"
                            class="lni-c-rule__partName  lni-u-mr0 lni-u-mlauto"
                        >{{ rule.partName }}</span>
                    </header>
                    <h2 class="lni-c-rule__title lni-u-type--sm lni-u-line-height--tight  lni-u-mt0 lni-u-type--normal">
                        <a
                            :href="rule.pdfUrl"
                            target="_blank"
                            class="lni-u-text--no-underline"
                        >{{ rule.title }} <span class="lni-u-visually-hidden">(PDF)</span></a>
                    </h2>
                    <p
                        v-if="rule.shortDescription "
                        class="lni-c-rule__description lni-u-type--xs lni-u-mt1 lni-u-line-height--tight"
                    >
                        {{ rule.shortDescription }}
                    </p>
                    <div v-if="getDocsByCategory(rule.related, 'Understanding Rules').length">
                        <h4 class="lni-u-mt2 lni-u-mb0 lni-u-type--xs">
                            Understanding this Rule
                        </h4>
                        <link-list
                            :links="getDocsByCategory(rule.related, 'Understanding Rules')"
                            class="lni-u-mt0"
                        />
                    </div>
                    <div v-if="getDocsByCategory(rule.related, 'Tools to Meet Rules').length">
                        <h4 class="lni-u-mt2 lni-u-type--xs lni-u-type--bold">
                            Tools to Meet This Rule
                        </h4>
                        <link-list
                            :links="getDocsByCategory(rule.related, 'Tools to Meet Rules')"
                            class="lni-u-mt0"
                        />
                    </div>
                    <div class="lni-u-text--right lni-u-mt2">
                        <a
                            :href="rule.officialUrl"
                            class="lni-u-type--xs"
                        >Read <span class="lni-u-visually-hidden">section {{ rule.section }} of {{ rule.parentTitle }}  </span>at leg.wa.gov</a>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import LinkList from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-rule-chapter/link-list.vue';
import * as api from '@gov.wa.lni/application.public-website-custom-components/source/components/lni-rule-chapter/api/rules.api.js';

export default {
    name: 'LniRuleChapter',
    components: {
        LinkList,
    },
    data() {
        return {
            showSections: true,
        };
    },
    computed: {
        hasPolicies() {
            return this.rules.some(rule => rule.hasPolicies);
        },
        subsections() {
            const sections =  [...this.rules];
            sections.shift();
            return sections;
        },
        chapterRule() {
            return this.rules.length > 0 ? this.rules[0] : {};
        },
        related() {
            let related = [];
            this.rules.forEach(rule => {
                if ( rule.related.length ) {
                    rule.related.forEach(doc => {
                        if ( !related.some(link => link.url === doc.url) ) {
                            related.push(doc);
                        }
                    });
                }
            });
            return related;
        },
        relatedUnderstanding() {
            return this.getDocsByCategory(this.related, 'Understanding Rules');
        },
        relatedTools() {
            return this.getDocsByCategory(this.related, 'Tools to Meet Rules');
        },
    },
    mounted() {
        this.getRules();
    },
    methods: {
        sectionNumber(rule) {
            const start = `296-${this.chapterRule.chapter}-${rule.section}`;
            return rule.sectionEnd
                ? `${start} — 296-${this.chapterRule.chapter}-${rule.sectionEnd}`
                : start;
        },
        // Get specific docs from array of related documents
        getDocsByCategory(relatedDocs, category) {
            if ( relatedDocs.length ) {
                return relatedDocs.filter(
                    doc => doc.categories.includes(category),
                );
            }
            return [];
        },
        getRules() {
            const self = this;
            let rulePromise = api.getRules(this.chapter, this.$oneLni);
            Promise.resolve(rulePromise).then(data => {
                self.$store.commit('setAttribute', {
                    id: self.id,
                    attribute: 'rules',
                    value: data.rules,
                });
            });
        },
    },
}; </script>