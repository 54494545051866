let fetchPromise = null;

export default (params, oneLni) => {
    let endpoint = `${oneLni.environment.current.apiLocation2}/news-and-data-center/`;
    if (oneLni.environment.current.name === 'Local Development') {
        endpoint = 'https://dev-public.lni.wa.gov/api/vtl/news-and-data-center';
    }
    if (params) {
        endpoint += '?' + params.key + '=' + params.value;
    }

    fetchPromise = fetch(endpoint, {
        mode: 'cors',
    }).then(response => response.json())
        .then(data => data);
    return fetchPromise;
};