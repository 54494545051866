export default decodedValue => {
    const specialCharacters = /[;\-\][^%$#@!`~+=\\\\/:*{},()?."<>|&']/gmi;
    const decoded = decodedValue.replace(specialCharacters, ' ');
    let arr = decoded.split(' ');
    let encoded = '';
    arr.forEach((word, index) => {
        if (word.length) {
            let newWord = word.toLowerCase();
            if (index !== 0) {
                newWord = newWord[0].toUpperCase() + newWord.substring(1, newWord.length);
            }
            encoded += newWord;
        }
    });
    return encoded;
};