<template>
    <div class="lni-c-vendor-services-lookup">
        <div class="lni-c-vendor-services-lookup__filter-container">
            <lni-select
                :id="`${id}_state`"
                :customWidth="5"
                class="lni-u-mr2 lni-u-top"
                type="text"
                labelText="State"
                :dense="true"
                :filled="false"
                :changeAction="`${id}/updateFilters`"></lni-select>
            <lni-select
                :id="`${id}_city`"
                :customWidth="8"
                class="lni-u-mr2 lni-u-top"
                type="text"
                labelText="City"
                :dense="true"
                :filled="false"
                :changeAction="`${id}/search`"></lni-select>
            <lni-select
                :id="`${id}_county`"
                :customWidth="8"
                class="lni-u-mr2 lni-u-top"
                type="text"
                labelText="County"
                :dense="true"
                :filled="false"
                :changeAction="`${id}/search`"></lni-select>
            <lni-select
                :id="`${id}_service`"
                class=" lni-u-top"
                type="text"
                labelText="Service"
                :dense="true"
                :filled="false"
                :changeAction="`${id}/search`"></lni-select>
        </div>

        <div class="table-data">
            <lni-table
                :id="`${id}_table`"
                :set-data-action="`${id}/search`"
                :inPlacePagingAndSorting="false"
                scrollTarget=".lni-c-vendor-services-lookup"
                :items="null"
                :sort="{
                    'chrProviderName': 'asc'
                }"
                class="lni-c-vendor-services-lookup__table__wrapper lni-u-page-width--max"
                :pageSize="10"
                :page="1"
                noRowsMessage="Your search returned no results, please check your choices and try again."
                errorMessage="Error Getting Data"
                caption="Vendor Services"
                summary="This table shows vendors by service and location."
                :columns="[{
                               source:'chrProviderName',
                               displayText: 'Provider',
                               sortable: true,
                               headerClasses: 'lni-c-vendor-services-lookup_table-cell lni-u-text--left lni-u-pl3'
                           },
                           {
                               source:'chrPage1ProviderAddr2',
                               displayText: 'Address',
                               sortable: true,
                               headerClasses: 'lni-c-vendor-services-lookup_table-cell lni-u-text--left lni-u-pl3'
                           },
                           {
                               source:'chrProviderSpecialtyDesc',
                               displayText: 'Specialty/Type',
                               sortable: true,
                               headerClasses: 'lni-c-vendor-services-lookup_table-cell lni-u-text--left lni-u-pl3'
                           }
                ]">
                <template
                    slot="body"
                    slot-scope="props">
                    <tr
                        :key="`${props.item.lngProviderNumber}_${props.item.chrPage1ProviderCity }_${props.item.chrProviderPhone}`">
                        <th
                            scope="row"
                            class="lni-u-text--center lni-c-vendor-services-lookup_table-cell">
                            <div class="lni-u-ml2 lni-u-text--left">
                                {{ props.item.chrProviderName }}
                                <br>
                                L&amp;I Provider Number: &nbsp;{{ props.item.lngProviderNumber }}
                            </div>
                        </th>
                        <td class="lni-u-text--center lni-c-vendor-services-lookup_table-cell">
                            <div class="lni-u-ml2 lni-u-text--left">
                                {{ props.item.chrPage1ProviderAddr2 }}
                                <br>
                                {{ props.item.chrPage1ProviderCity }}, {{ props.item.chrPage1ProviderState }} {{ props.item.chrPage1ProviderZipCode }}
                                <br>
                                {{ props.item.chrProviderPhone }}
                                <br>
                                County: {{ props.item.chrCounty }}
                            </div>
                        </td>
                        <td class="lni-u-text--center lni-c-vendor-services-lookup_table-cell">
                            <div class="lni-u-ml2 lni-u-text--left">
                                {{ props.item.chrProviderSpecialtyDesc }}
                                <br>
                                {{ props.item.chrProviderTypeDesc !== props.item.chrProviderSpecialtyDesc ? props.item.chrProviderTypeDesc : '' }}
                            </div>
                        </td>
                    </tr>
                </template>
            </lni-table>
        </div>
    </div>
</template>


<script>

export default {
    name: 'lni-vendor-services-lookup',

    mounted() {
        this.dispatchEvent(`${this.id}/updateFilters`);
    },
}; </script>
<style lang="scss">
@import 'lni-vendor-services-lookup';
</style>