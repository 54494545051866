<template>
    <div
        :id="id + '_calc_display'"
        class="lni-c-calculator-display lni-u-type--xxs lni-u-line-height--tight">
        <div
            v-if="results.method!=undefined && results.method.value!='' && results.stateRate!==undefined && results.stateRate.value!=''"
            class="">
            <h4>Results</h4>
            <div class="lni-c-calculation-display__row-wrapper lni-u-flex lni-u-justify-between lni-u-flex-wrap">
                <div class="lni-u-relative lni-c-calculation-display__input-display lni-u-pa1 lni-u-mb3 lni-c-calculation-display__card_width lni-u-mr2">
                    <label class="lni-c-calculation-display__label">State’s average wage multiplied by 1.5, multiplied by the number of calendar days in the period</label>
                    <div class="lni-u-type--xxs lni-u-type--bold lni-u-pl2">
                        {{ (results.stateRate!==undefined && results.stateRate.value) ? results.stateRate.value : null }}
                    </div>
                </div>

                <div class="lni-u-relative lni-c-calculation-display__input-display  lni-u-pa1 lni-u-mb3 lni-c-calculation-display__card_width">
                    <label class="lni-c-calculation-display__label lni-u-pl2">Calendar days</label>
                    <div class="lni-u-type--xxs lni-u-type--bold lni-u-pl2">
                        {{ (results.totalDays!==undefined && (results.totalDays.value) && (results.totalDays.value != 1)) ? results.totalDays.value : null }}
                    </div>
                </div>
            </div>

            <div class="lni-c-calculation-display__row-wrapper lni-u-flex lni-u-justify-between lni-u-flex-wrap">
                <div class="lni-u-relative lni-c-calculation-display__input-display lni-u-pa1 lni-u-mb3 lni-c-calculation-display__card_width">
                    <label class="lni-c-calculation-display__label lni-u-pl2">LEP entitlement</label>
                    <div class="lni-u-type--xxs lni-u-type--bold lni-u-pl2">
                        {{ ((results.entitlement !== undefined))? results.entitlement.value : '' }}
                    </div>
                </div>

                <div class="lni-u-relative lni-c-calculation-display__input-display lni-u-pa1 lni-u-mb3 lni-c-calculation-display__card_width">
                    <label class="lni-c-calculation-display__label lni-u-pl2">Method used</label>
                    <div class="lni-u-type--xxs lni-u-type--bold lni-u-pl2">
                        {{ (results.method!==undefined) ? results.method.value : '' }}
                    </div>
                </div>
            </div>

            <div class="lni-c-calculation-display__table-wrapper lni-u-flex  lni-u-justify-between lni-u-mr1 lni-u-pr2">
                <div class="lni-u-relative lni-c-calculation-display-methods lni-c-calculation-display__card_width lniu-pa2 lni-pa0">
                    <div class="lni-c-calculation-display__input-display lni-u-pa1 lni-c-calculation-display__mt-5">
                        <h3 class="lni-c-calculation-display__border-bottom lni-u-pa1">
                            Method A
                        </h3>
                        <div class="lni-c-input-display__table-content-table">
                            <div class="lni-u-pa1">
                                <div class="lni-c-calculation-display__calculation__row">
                                    <span class="lni-c-calculation-display__calculation__operation">&nbsp;</span>
                                    <output
                                        v-if="results.wageCurr"
                                        id="output1"
                                        class="lni-c-calculation-display__result-output"
                                        for="_button_2">{{ (results.wageCurr.value) ? results.wageCurr.value : '' }}</output>
                                    <label for="output1">Current wages</label>
                                </div>
                                <div class="lni-c-calculation-display__calculation__row">
                                    <span
                                        class="lni-c-calculation-display__calculation__operation"
                                        aria-label="divided by">&divide;</span>
                                    <output
                                        v-if="results.wageDOI"
                                        id="output2"
                                        class="lni-c-calculation-display__result-output"
                                        for="_button_2">{{ (results.wageDOI.value) ? results.wageDOI.value : '' }}</output>
                                    <label for="output2">DOI Wages</label>
                                </div>

                                <div class="lni-c-calculation-display__calculation__row --evaluation">
                                    <span
                                        class="lni-c-calculation-display__calculation__operation"
                                        aria-label="equals">=</span>
                                    <output
                                        v-if="results.wagePctDOI"
                                        id="output3"
                                        class="lni-c-calculation-display__result-output"
                                        for="output1 output2">{{ (results.wagePctDOI.value) ? results.wagePctDOI.value : '' }}</output>
                                    <label for="output3">Percent of Wages at the time of injury</label>
                                </div>
                            </div>
                            <div class="lni-c-calculation-display__break-line"></div>
                            <div class="lni-u-pa1">
                                <div class="lni-c-calculation-display__calculation__row">
                                    <span class="lni-c-calculation-display__calculation__operation">&nbsp;</span>
                                    <output class="lni-c-calculation-display__result-output">&nbsp;</output>
                                    <label for="output4">100%</label>
                                </div>

                                <div class="lni-c-calculation-display__calculation__row">
                                    <span
                                        class="lni-c-calculation-display__calculation__operation"
                                        aria-label="minus">-</span>
                                    <output
                                        v-if="results.wageDOI"
                                        id="output5"
                                        class="lni-c-calculation-display__result-output"
                                        for="_button_2">{{ (results.wagePctDOI.value) ? results.wagePctDOI.value : '' }}</output>
                                    <label for="output5">Percent of Wages at the Time of Injury</label>
                                </div>

                                <div class="lni-c-calculation-display__calculation__row --evaluation">
                                    <span
                                        class="lni-c-calculation-display__calculation__operation"
                                        aria-label="equals">=</span>
                                    <output
                                        v-if="results.lepPct"
                                        id="output6"
                                        class="lni-c-calculation-display__result-output"
                                        for="output4 output5">{{ (results.lepPct.value) ? results.lepPct.value : '' }}</output>
                                    <label for="output6">Loss of Earning Power Percent</label>
                                </div>
                            </div>
                            <div class="lni-c-calculation-display__break-line"></div>

                            <div class="lni-u-pa1">
                                <div class="lni-c-calculation-display__calculation__row">
                                    <span class="lni-c-calculation-display__calculation__operation">&nbsp;</span>
                                    <output
                                        v-if="results.lepPct"
                                        id="output7"
                                        class="lni-c-calculation-display__result-output"
                                        for="_button_2">{{ (results.lepPct.value) ? results.lepPct.value : '' }}</output>
                                    <label for="output7">Loss of Earning Power Percent</label>
                                </div>

                                <div class="lni-c-calculation-display__calculation__row">
                                    <span
                                        class="lni-c-calculation-display__calculation__operation"
                                        aria-label="multiplied by">X</span>
                                    <output
                                        v-if="results.timeLossRate"
                                        id="output8"
                                        class="lni-c-calculation-display__result-output"
                                        for="_button_2">{{ (results.timeLossRate.value) ? results.timeLossRate.value : '' }}</output>
                                    <label for="output8">Time-Loss Comp. Rate</label>
                                </div>

                                <div class="lni-c-calculation-display__calculation__row --evaluation">
                                    <span
                                        class="lni-c-calculation-display__calculation__operation"
                                        aria-label="equals">=</span>
                                    <output
                                        v-if="results.lepA"
                                        id="output9"
                                        class="lni-c-calculation-display__result-output"
                                        for="output7 output8">{{ (results.lepA.value) ? results.lepA.value : '' }}</output>
                                    <label for="output9">Method A LEP Entitlement</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="lni-u-relative lni-c-calculation-display-methods lni-c-calculation-display__card_width">
                    <div class="lni-c-calculation-display__input-display lni-u-pa1 lni-c-calculation-display__mt-5">
                        <h3 class="lni-c-calculation-display__border-bottom lni-u-pa1">
                            Method B
                        </h3>
                        <div class="lni-u-pa1">
                            <div class="lni-c-calculation-display__calculation__row">
                                <span class="lni-c-calculation-display__calculation__operation">&nbsp;</span>
                                <output
                                    v-if="results.wageDOI"
                                    id="output10"
                                    class="lni-c-calculation-display__result-output"
                                    for="_button_2">{{ (results.wageDOI.value) ? results.wageDOI.value : '' }}</output>
                                <label for="output10">DOI Wages</label>
                            </div>

                            <div class="lni-c-calculation-display__calculation__row">
                                <span
                                    class="lni-c-calculation-display__calculation__operation"
                                    aria-label="minus">-</span>
                                <output
                                    v-if="results.wageCurr"
                                    id="output11"
                                    class="lni-c-calculation-display__result-output"
                                    for="_button_2">{{ (results.wageCurr.value) ? results.wageCurr.value : '' }}</output>
                                <label for="output11">Current Wages</label>
                            </div>

                            <div class="lni-c-calculation-display__calculation__row --evaluation">
                                <span
                                    class="lni-c-calculation-display__calculation__operation"
                                    aria-label="equals">=</span>
                                <output
                                    v-if="results.diff"
                                    id="output12"
                                    class="lni-c-calculation-display__result-output"
                                    for="output10 output11">{{ (results.diff.value) ? results.diff.value : '' }}</output>
                                <label for="output12">Difference</label>
                            </div>
                        </div>
                        <div class="lni-c-calculation-display__break-line"></div>

                        <div class="lni-u-pa1">
                            <div class="lni-c-calculation-display__calculation__row">
                                <span class="lni-c-calculation-display__calculation__operation">&nbsp;</span>
                                <output
                                    v-if="results.diff"
                                    id="output13"
                                    class="lni-c-calculation-display__result-output"
                                    for="_button_2">{{ (results.diff.value) ? results.diff.value : '' }}</output>
                                <label for="output13">Difference</label>
                            </div>

                            <div class="lni-c-calculation-display__calculation__row">
                                <span
                                    class="lni-c-calculation-display__calculation__operation"
                                    aria-label="multiplied by">X</span>
                                <output
                                    id="output14"
                                    class="lni-c-calculation-display__result-output"
                                    for="_button_2">&nbsp;</output>
                                <label for="output14">80%</label>
                            </div>

                            <div class="lni-c-calculation-display__calculation__row --evaluation">
                                <span
                                    class="lni-c-calculation-display__calculation__operation"
                                    aria-label="equals">=</span>
                                <output
                                    v-if="results.lepB"
                                    id="output15"
                                    class="lni-c-calculation-display__result-output"
                                    for="output13 output14">{{ (results.lepB.value) ? results.lepB.value : '' }}</output>
                                <label for="output15">Method B LEP Entitlement</label>
                            </div>
                        </div>

                        <div
                            v-if="results.showCapBSection"
                            id="state-average-rate-section">
                            <div class="lni-c-calculation-display__break-line"></div>
                            <h3 class="lni-c-calculation-display__border-bottom lni-u-pa1 lni-u-pt4">
                                Additional Considerations
                            </h3>
                            <div class="lni-u-pa1">
                                <div class="lni-c-calculation-display__calculation__row">
                                    <span class="lni-c-calculation-display__calculation__operation"></span>
                                    <output
                                        v-if="results.wageCurr"
                                        id="output11"
                                        class="lni-c-calculation-display__result-output"
                                        for="_button_2">{{ (results.wageCurr.value) ? results.wageCurr.value : '' }}</output>
                                    <label for="output11">Current Wages</label>
                                </div>

                                <div class="lni-c-calculation-display__calculation__row">
                                    <span
                                        class="lni-c-calculation-display__calculation__operation"
                                        aria-label="plus">+</span>
                                    <output
                                        v-if="results.wageCurr"
                                        id="output11"
                                        class="lni-c-calculation-display__result-output"
                                        for="_button_2">{{ (results.lepB.value) ? results.lepB.value : '' }}</output>
                                    <label for="output11">Method B</label>
                                </div>

                                <div class="lni-c-calculation-display__calculation__row --evaluation">
                                    <span
                                        class="lni-c-calculation-display__calculation__operation"
                                        aria-label="equals">=</span>
                                    <output
                                        v-if="results.summed"
                                        id="output12"
                                        class="lni-c-calculation-display__result-output"
                                        for="output10 output11">{{ (results.summed.value) ? results.summed.value : '' }}</output>
                                    <label for="output12">Sum</label>
                                </div>

                                <p class="lni-c-calculation-display__border-bottom lni-u-pa1">
&nbsp;
                                </p>

                                <div class="lni-c-calculation-display__calculation__row">
                                    <span class="lni-c-calculation-display__calculation__operation"></span>
                                    <output
                                        v-if="results.wageCurr"
                                        id="output11"
                                        class="lni-c-calculation-display__result-output"
                                        for="_button_2">{{ (results.stateRate.value) ? results.stateRate.value : '' }}</output>
                                    <label for="output11">State Average Rate</label>
                                </div>

                                <div class="lni-c-calculation-display__calculation__row">
                                    <span
                                        class="lni-c-calculation-display__calculation__operation"
                                        aria-label="minus">-</span>
                                    <output
                                        v-if="results.wageCurr"
                                        id="output11"
                                        class="lni-c-calculation-display__result-output"
                                        for="_button_2">{{ (results.wageCurr.value) ? results.wageCurr.value : '' }}</output>
                                    <label for="output11">Current Wages</label>
                                </div>

                                <div class="lni-c-calculation-display__calculation__row --evaluation">
                                    <span
                                        class="lni-c-calculation-display__calculation__operation"
                                        aria-label="equals">=</span>
                                    <output
                                        v-if="results.summed"
                                        id="output12"
                                        class="lni-c-calculation-display__result-output"
                                        for="output10 output11">{{ (results.capB.value) ? results.capB.value : '' }}</output>
                                    <label for="output12">Capped Amount For Method B</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'lni-calculation-display',

    props: {
        results: {
            type: Object,
            default: () => ({}),
        },
    },
}; </script>
<style lang="scss">
@import 'lni-calculation-display';
</style>